/**
 * TableResults.tsx
 * Display a results in table format
 */
/* packages */
import React, { useState, useMemo, memo, useCallback, forwardRef, useImperativeHandle, CSSProperties, PropsWithChildren } from 'react';

/* components */
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';

import TableRow, { TableRowData } from './TableRow';
import TableHead, { TableHeadElement } from './TableHead';

/* types */
interface TableResultsProps {
  hasSelection: boolean;
  hasUnread?: boolean;
  hasBoxTitle?: boolean;
  tableData: TableRowData[];
  tableHead: TableHeadElement[];
  borderBottom?: boolean;
  noBorders?: boolean;
  fixedLayout?: boolean;
  onSelectHandler?(selection: readonly string[]): void;
}

export interface TableRefType {
  getSelected(): readonly string[];
  clearSelection(): void;
}

/* elements */
const TableRowMemo = memo(TableRow);
const TableHeadMemo = memo(TableHead);

const TableResults = forwardRef<TableRefType, PropsWithChildren<TableResultsProps>>((props, ref) => {
  const { hasSelection, hasUnread, hasBoxTitle, tableData, tableHead, borderBottom, noBorders, fixedLayout, onSelectHandler, children } = props;

  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = tableData.map((row) => row.id);
        onSelectHandler?.(newSelected);
        setSelected(newSelected);
        return;
      }
      onSelectHandler?.([]);
      setSelected([]);
    },
    [tableData, onSelectHandler]
  );

  const handleSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setSelected((currentSelection) => {
        const selectedIndex = currentSelection.indexOf(id);
        let newSelection: readonly string[] = [];

        if (selectedIndex < 0) {
          newSelection = [...currentSelection, id];
        } else {
          // choosing filter rather than slicing => performance should be marginally faster for small lists
          newSelection = currentSelection.filter((cs) => cs !== id);
        }

        onSelectHandler?.(newSelection);
        return newSelection;
      });
    },
    [onSelectHandler]
  );

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useImperativeHandle(
    ref,
    () => {
      return {
        getSelected: () => selected,
        clearSelection: () => setSelected([]),
      };
    },
    [selected]
  );

  const sxTable = useMemo(() => {
    const sx: CSSProperties = {};
    if (hasBoxTitle) {
      sx.borderTop = 'none';
      sx.borderTopLeftRadius = 0;
      sx.borderTopRightRadius = 0;
    }
    if (noBorders) {
      sx.borderTop = 'none';
      sx.borderRight = 'none';
      sx.borderBottom = 'none';
      sx.borderLeft = 'none';
    }
    return sx;
  }, [hasBoxTitle, noBorders]);

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer className={`table-results custom-scrollbar-horizontal ${borderBottom && !noBorders ? 'bottom-border' : ''} ${fixedLayout ? 'fixed-layout' : ''}`} sx={sxTable}>
        <Table aria-labelledby="tableTitle" size={'medium'}>
          <TableHeadMemo elements={tableHead} onSelectAllClick={handleSelectAllClick} rowCount={tableData.length} hasSelection={hasSelection} hasUnread={hasUnread} numSelected={selected.length} />

          <TableBody>
            {children
              ? children
              : tableData.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `table-checkbox-${row.id}`;

                  return <TableRowMemo key={row.id} {...{ labelId, isItemSelected, tableHead, row, hasSelection, hasUnread, handleSelected }} />;
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default TableResults;
