/**
 * AuthProvider.ts
 * Register the authentication of user and provide the values
 */
/* packages */
import { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';

/* context */
import { useIsAuthenticated, useAuthenticatedRequest } from './AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { UserType, EditUserResponse, UploadAvatarReponse, DeleteAvatarResponseType, RoleType } from 'models/user';
import { PermissionString } from 'utilities/CheckUserPermissions';
import { TeamType } from 'models/teams';

export interface UserContextType {
  loading: boolean;
  currentUser?: UserType;
  currentUserId?: number;
  permissions: string[];
  changePassword?(user: UserType, currentPassword: string, newPassword: string): void;
  editUser?(user: UserType): Promise<UserType>;
  editCurrentUser?(user: UserType): void;
  uploadAvatar?(formData: FormData): void;
  deleteAvatar?(): void;
}
/* elements */
export const UserContext = createContext<UserContextType>({
  loading: true,
  currentUser: undefined,
  permissions: [],
});

const parsePermissions = (user: UserType | undefined): string[] => {
  if (!user) return [];

  // const allPerms: PermissionString[] = ([] as PermissionString[]).concat(...(user.roles?.map((r) => r.permissions ?? []) ?? []));
  // ([] as PermissionString[]).concat(...(user.groups?.map((g) => g.roles?.map((r) => r.permissions ?? []) ?? [])));
  const allPerms: PermissionString[] = [];

  if (user.groups) {
    for (let i = 0; i < user.groups.length; i++) {
      const group: TeamType | undefined = user.groups[i];

      if (group && group.roles) {
        const role: RoleType[] | undefined = group.roles;
        if (role) {
          for (let j = 0; j < role.length; j++) {
            if (role[j].permissions)
              allPerms.push(...role[j].permissions)
          }
        }
      }
    }
  }

  if (user.roles) {
    const role: RoleType[] | undefined = user.roles;
    if (role) {
      for (let j = 0; j < role.length; j++) {
        if (role[j].permissions)          
          allPerms.push(...role[j].permissions)
      }
    }
  }

  const permissions = Array.from(new Set(allPerms));
  return permissions;
};

const arraysEqual = (a: any[], b: any[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { currentUserName } = useIsAuthenticated();
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();

  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<UserType | undefined>(undefined);
  const [currentUserId, setCurrentUserId] = useState<number | undefined>(undefined);
  const [permissions, setPermissions] = useState<string[]>([]);

  const editPermissions = useCallback((user: UserType) => {
    setPermissions((currentPermissions) => {
      const newPermissions = parsePermissions(user);
      if (arraysEqual(newPermissions, currentPermissions)) return currentPermissions;
      else return newPermissions;
    });
  }, []);

  // init user data on startup
  useEffect(() => {
    setLoading(true);
    setCurrentUser(undefined);
    setCurrentUserId(undefined);
    setPermissions([]);

    const abortController = new AbortController();

    const initUser = async () => {
      if (!currentUserName) return;

      try {
        const userData = (await getAuthenticatedRequest(URLConstants.getUserDetails(currentUserName), abortController)) as { user: UserType };
        setCurrentUser(userData.user);        
        setCurrentUserId(userData.user.id);

        editPermissions(userData.user);

        setLoading(false);
      } catch (userError: any) {
        if (userError?.code === 'ERR_CANCELED') return;

        setCurrentUser(undefined);
        setCurrentUserId(undefined);
        setPermissions([]);
        setLoading(false);
      }
    };

    initUser();

    return () => {
      abortController.abort();
    };
  }, [currentUserName, getAuthenticatedRequest, editPermissions]);

  // edit current user data (from other query)
  const editCurrentUser = useCallback(
    (user: UserType) => {
      setCurrentUser((currentUser) => {
        if (currentUser?.id !== user.id) return currentUser;

        editPermissions(user);
        return user;
      });
    },
    [editPermissions]
  );

  // edit Password
  const changePassword = useCallback(
    async (user: UserType, currentPassword: string, newPassword: string) => {
      const changePasswordPayload = {
        previousPassword: currentPassword,
        user: { ...user, password: newPassword },
      };
      try {
        await postAuthenticatedRequest(URLConstants.userChangePassword, changePasswordPayload);
      } catch (changePasswordError: any) {
        throw (changePasswordError as AxiosError).response?.data ?? {};
      }
    },
    [postAuthenticatedRequest]
  );

  // upload avatar
  const uploadAvatar = useCallback(
    async (formData: FormData) => {
      const avatarUrl = URLConstants.userSetAvatar + `/${currentUserName}`;

      try {
        const result = (await postAuthenticatedRequest(avatarUrl, formData, undefined, {
          contentType: 'multipart/form-data',
          skipStringify: true,
        })) as UploadAvatarReponse;
        return result;
      } catch (changePasswordError: any) {
        throw (changePasswordError as AxiosError).response?.data ?? {};
      }
    },
    [postAuthenticatedRequest, currentUserName]
  );

  // delete avatar
  const deleteAvatar = useCallback(async () => {
    const avatarUrl = URLConstants.userDeleteAvatar + `/${currentUserName}`;

    try {
      const result = (await deleteAuthenticatedRequest(avatarUrl)) as DeleteAvatarResponseType;
      return result;
    } catch (changePasswordError: any) {
      throw (changePasswordError as AxiosError).response?.data ?? {};
    }
  }, [deleteAuthenticatedRequest, currentUserName]);

  // edit Password
  const editUser = useCallback(
    async (user: UserType) => {
      // const changePasswordPayload = {
      //   previousPassword: currentPassword,
      //   user: { ...user, password: newPassword },
      // };
      // do not send photo field (reduce query size)
      const userData = { ...user };
      if (userData.photo) {
        delete userData.photo;
      }

      try {
        const newUserData = (await postAuthenticatedRequest(URLConstants.userUpdate, { user: userData })) as EditUserResponse;
        if (newUserData.user) {
          setCurrentUser(newUserData.user);
          setCurrentUserId(newUserData.user.id);

          editPermissions(newUserData.user);
        }
        return newUserData.user;
      } catch (editUserError: any) {
        throw (editUserError as AxiosError).response?.data ?? {};
      }
    },
    [postAuthenticatedRequest, editPermissions]
  );

  const userContextValue = useMemo(
    (): UserContextType => ({
      loading,
      currentUser,
      currentUserId,
      // permissions: parsePermissions(currentUser),
      permissions,
      changePassword,
      editUser,
      uploadAvatar,
      deleteAvatar,
      editCurrentUser,
    }),
    [loading, currentUser, changePassword, editUser, currentUserId, permissions, uploadAvatar, deleteAvatar, editCurrentUser]
  );
  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

/* exports */
export { UserProvider };
