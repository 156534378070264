/**
 * App Entry point
 */

/* packages */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material/styles';

/* styles */
import 'style.scss';

/* providers */
import { CombineProviders } from 'utilities/CombineProviders';
import { ConfigProvider } from 'contextProviders/ConfigProvider';
import { AuthProvider } from 'contextProviders/AuthProvider';
import { SnackbarProvider } from 'contextProviders/SnackbarProvider';
import { ModalProvider } from 'contextProviders/ModalProvider';

/* components */
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
/* utilities */
import lang_en from './lang/en';
import theme from 'mui-theme';

/* elements */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const RootProviders = CombineProviders([[AuthProvider], [ConfigProvider], [SnackbarProvider], [ModalProvider]]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" defaultLocale="en" messages={lang_en}>
        <RootProviders>
          <CssBaseline />
          <ScrollToTop />
          <App />
        </RootProviders>
      </IntlProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
