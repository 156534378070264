/**
 * DisplayBrandLogo.tsxt
 * Display the brand logo defined in config or the default screena logo
 */
/* packages */
import { useContext, PropsWithoutRef } from 'react';

/* context */
import { ConfigContext } from 'contextProviders/ConfigProvider';

/* utilities */
import logo_small from 'media/images/logo_small.png';

/* elements */
const DisplayBrandLogo = ({ full, className }: PropsWithoutRef<{ full?: boolean; className?: string }>) => {
  const { config } = useContext(ConfigContext);

  const brandLogo = full ? config?.logoFull : config?.logoSquare;

  return (
    <>
      {brandLogo ? (
        <img
          className={className}
          src={brandLogo}
          onError={(event) => {
            const target = event.target as HTMLImageElement;
            const targetSrcUrl = new URL(target.src);

            if (targetSrcUrl.pathname === logo_small) return;
            target.src = logo_small;
          }}
          alt=""
        />
      ) : (
        <img className={className} src={logo_small} alt="" />
      )}
    </>
  );
};

export default DisplayBrandLogo;
