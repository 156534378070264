/**
 * SearchDatasetsProvider.ts
 * Register the authentication of user and provide the values
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef } from 'react';

/* context */
import { useAPIRequest } from './AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { SearchDatasetsResponse, SearchDatasetsType } from 'models/searchDatasets';

interface SearchDatasetContextType {
  searchDatasetsQueried: boolean;
  loadingSearchDatasets: boolean;
  searchDatasets?: SearchDatasetsType;
  makeSearchDatasets?(abortController?: AbortController): void;
}
/* elements */
const SearchDatasetContext = createContext<SearchDatasetContextType>({
  searchDatasetsQueried: false,
  loadingSearchDatasets: false,
  searchDatasets: undefined,
  makeSearchDatasets: undefined,
});

const SearchDatasetsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAPIRequest } = useAPIRequest();

  const searchOngoing = useRef(false);

  const [searchDatasetsQueried, setSearchDatasetsQueried] = useState<boolean>(false);
  const [loadingSearchDatasets, setLoadingSearchDatasets] = useState<boolean>(true);
  const [searchDatasets, setSearchDatasets] = useState<SearchDatasetsType | undefined>(undefined);

  const makeSearchDatasets = useCallback(
    async (abortController?: AbortController) => {
      if (searchOngoing.current) return;

      searchOngoing.current = true;
      setLoadingSearchDatasets(true);
      setSearchDatasets(undefined);
      try {
        const datasetUrl = URLConstants.screenaGetDatasets;
        const results = (await getAPIRequest(datasetUrl, abortController ?? undefined)) as SearchDatasetsResponse;

        setSearchDatasetsQueried(true);
        setLoadingSearchDatasets(false);
        setSearchDatasets(results.datasets);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setSearchDatasetsQueried(true);
        setLoadingSearchDatasets(false);
        setSearchDatasets(undefined);
      }
      searchOngoing.current = false;
    },
    [getAPIRequest]
  );

  const outputValue = useMemo(
    (): SearchDatasetContextType => ({
      searchDatasetsQueried,
      loadingSearchDatasets,
      searchDatasets,
      makeSearchDatasets,
    }),
    [searchDatasetsQueried, loadingSearchDatasets, searchDatasets, makeSearchDatasets]
  );
  return <SearchDatasetContext.Provider value={outputValue}>{children}</SearchDatasetContext.Provider>;
};

/* exports */
export { SearchDatasetContext, SearchDatasetsProvider };
