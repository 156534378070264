import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
        <path
          d="M7.762,33.634a.277.277,0,0,1,.475,0l6.2,10.178a.453.453,0,0,1-.387.688H1.953a.453.453,0,0,1-.453-.453.435.435,0,0,1,.066-.234Zm-1.281-.781L.284,43.031A1.954,1.954,0,0,0,1.953,46H14.047a1.954,1.954,0,0,0,1.669-2.969l-6.2-10.178a1.778,1.778,0,0,0-3.038,0ZM9,42.5a1,1,0,1,0-1,1A1,1,0,0,0,9,42.5Zm-.25-5.75a.75.75,0,0,0-1.5,0v3a.75.75,0,0,0,1.5,0Z"
          transform="translate(0 -32)"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};
