/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useRef, useEffect, MutableRefObject } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectChangeEvent } from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { PopoverActions } from '@mui/material/Popover';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import { ArrowIcon } from 'icons/arrow/arrow';

import { parseDay, parseMonth, parseYear, parseDateFromInput } from './DateFilter';
/* utilities */
// import { searchConstant } from 'models/searchDatasets';

/* types */
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { FilterValueType } from './SearchFilters';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';

interface DateRangeFilterProps {
  title: string;
  currentValue?: FilterValueType;
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  popoverRef?: MutableRefObject<PopoverActions>;
  algoFields?: string[];
  algoValue?: string[];
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onClearValue?: [Dayjs, Dayjs];
}

interface AlgoSelectionType {
  [key: string]: { checked: boolean; value: string };
}

interface ResetAlgoInput {
  currentAlgo?: { [key: string]: string };
  algoFields: string[];
  algoValue: string[];
}

/* elements */
const resetAlgo = ({ currentAlgo, algoFields, algoValue }: ResetAlgoInput) => {
  const initAlgo = algoFields.reduce((acc, cf) => ({ ...acc, [cf]: { checked: false, value: algoValue[0] } }), {} as AlgoSelectionType);

  if (currentAlgo) {
    Object.entries(currentAlgo).forEach(([algo, state]) => {
      if (Object.keys(initAlgo).includes(algo)) {
        initAlgo[algo].checked = true;
        initAlgo[algo].value = state;
      }
    });
  }
  return initAlgo;
};
const parseAlgoSelected = (algoSelected: AlgoSelectionType) => {
  return Object.entries(algoSelected)
    .filter(([_, state]) => state.checked)
    .reduce((acc, [algo, state]) => ({ ...acc, [algo]: state.value }), {});
};

const today = dayjs();
// const minDate = dayjs().subtract(20, 'year');
const defaultMinDate = dayjs('2000-01-01');
const defaultMaxDate = today;

const DateRangeFilter = ({ title, currentValue, setNewFilterValue, triggerSetUpdate, popoverRef, algoFields, algoValue, minDate, maxDate, onClearValue }: DateRangeFilterProps) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = React.useState<(Dayjs | null)[]>([currentValue?.dayValue ?? null, currentValue?.dayEndValue || null]);

  const [algoSelected, setAlgoSelected] = useState<AlgoSelectionType>(() => {
    return resetAlgo({ currentAlgo: currentValue?.algo, algoFields: algoFields ?? [], algoValue: algoValue ?? [] });
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const box = containerRef.current;

    const updateOnResize = () => {
      popoverRef?.current?.updatePosition();
    };
    const resizeObserver = new ResizeObserver(updateOnResize);

    resizeObserver.observe(box);

    return () => {
      resizeObserver.unobserve(box);
    };
  }, [containerRef, popoverRef]);

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue, selectedAlgo: FilterValueType['algo']) => {
      if (setNewFilterValue) setNewFilterValue({ nbValues: newValue[0] != null && newValue[1] !== null ? 1 : 0, dayValue: newValue[0], dayEndValue: newValue[1], algo: selectedAlgo });
    },
    [setNewFilterValue]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValue = onClearValue ?? [null, null];
    setSelectedValue(newValue);

    const newAlgos = resetAlgo({
      currentAlgo: undefined,
      algoFields: algoFields ?? [],
      algoValue: algoValue ?? [],
    });

    setAlgoSelected(newAlgos);

    applyFilterValue(newValue, parseAlgoSelected(newAlgos));
  };

  const hasAlgoSelected: boolean = !algoFields || Object.values(algoSelected).findIndex((v) => v.checked) >= 0;
  const validateSelection = useCallback(() => {
    if (selectedValue[0] === null || selectedValue[1] === null) return;

    const selectedAlgo = parseAlgoSelected(algoSelected);
    applyFilterValue(selectedValue, selectedAlgo);
    // if (setNewFilterValue)
    //   setNewFilterValue({ nbValues: selectedValue[0] != null && selectedValue[1] !== null ? 1 : 0, dayValue: selectedValue[0], dayEndValue: selectedValue[1], algo: selectedAlgo });
  }, [applyFilterValue, algoSelected, selectedValue]);

  useEffect(() => {
    // (selectedValue[0] !== null && selectedValue[1] === null) || !hasAlgoSelected
    if (triggerSetUpdate && hasAlgoSelected) {
      validateSelection();
    }
  }, [triggerSetUpdate, applyFilterValue, hasAlgoSelected, validateSelection]);

  const selectAlgoField = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setAlgoSelected((currentFields) => ({ ...currentFields, [checkboxName]: { ...currentFields[checkboxName], checked: checked } }));
  }, []);

  const setAlgoForCountry = useCallback((event: SelectChangeEvent<unknown>) => {
    const selectName = event.target.name;
    const selectValue = event.target.value as string;
    setAlgoSelected((currentFields) => ({ ...currentFields, [selectName]: { ...currentFields[selectName], value: selectValue } }));
  }, []);

  // propagate on value change

  // const hasValueSelected: boolean = selectedValues.length > 0;

  // let textValue = intl.formatMessage({ id: 'chooseADateRange', defaultMessage: 'Choose a date range' });
  // if (selectedValue[0]) {
  //   textValue = selectedValue[0].format('DD / MM / YYYY');

  //   if (selectedValue[1]) {
  //     textValue += ' - ' + selectedValue[1].format('DD / MM / YYYY');
  //   }
  // }

  const onChangeCalendar = (value: Dayjs | null, selectionState?: PickerSelectionState) => {
    if (selectionState !== 'finish') return;

    let newValues = [value, null];

    if (selectedValue[0] !== null && selectedValue[1] === null) {
      const prev = selectedValue[0];
      if (prev.isBefore(value)) newValues = [prev, value];
      else newValues = [value, prev];
    }
    setSelectedValue(newValues);

    setDayValue(parseDay(newValues[0], true));
    setMonthValue(parseMonth(newValues[0], true));
    setYearValue(parseYear(newValues[0]));

    if (newValues[1] !== null) {
      setDayEndValue(parseDay(newValues[1], true));
      setMonthEndValue(parseMonth(newValues[1], true));
      setYearEndValue(parseYear(newValues[1]));
    } else {
      setDayEndValue('');
      setMonthEndValue('');
      setYearEndValue('');
    }

    // setSelectedValue((currentValues) => {
    //   if (currentValues[0] === null) {
    //     return [value, null];
    //   } else {
    //     if (currentValues[1] === null) {
    //       const prev = currentValues[0];
    //       if (prev.isBefore(value)) {
    //         return [prev, value];
    //       } else return [value, prev];
    //     } else {
    //       return [value, null];
    //     }
    //   }
    // });
  };

  const [dayValue, setDayValue] = useState<string>(parseDay(currentValue?.dayValue, true));
  const [monthValue, setMonthValue] = useState<string>(parseMonth(currentValue?.dayValue, true));
  const [yearValue, setYearValue] = useState<string>(parseYear(currentValue?.dayValue));

  const [dayEndValue, setDayEndValue] = useState<string>(parseDay(currentValue?.dayEndValue, true));
  const [monthEndValue, setMonthEndValue] = useState<string>(parseMonth(currentValue?.dayEndValue, true));
  const [yearEndValue, setYearEndValue] = useState<string>(parseYear(currentValue?.dayEndValue));

  const validateInputs = useCallback(
    (
      day: number,
      month: number,
      year: number,
      dayEnd: number,
      monthEnd: number,
      yearEnd: number,
      params?: { leadingZeroDay?: boolean; leadingZeroMonth?: boolean; leadingZeroDayEnd?: boolean; leadingZeroMonthEnd?: boolean }
    ) => {
      let newStartDate = parseDateFromInput(day, month, year, minDate ?? defaultMinDate, maxDate ?? defaultMaxDate);
      let newEndDate = parseDateFromInput(dayEnd, monthEnd, yearEnd, minDate ?? defaultMinDate, maxDate ?? defaultMaxDate);

      if (newEndDate.isBefore(newStartDate)) [newStartDate, newEndDate] = [newEndDate, newStartDate];
      setSelectedValue([newStartDate, newEndDate]);

      setDayValue(parseDay(newStartDate, params?.leadingZeroDay ?? false));
      setMonthValue(parseMonth(newStartDate, params?.leadingZeroMonth ?? false));
      setYearValue(parseYear(newStartDate));

      setDayEndValue(parseDay(newEndDate, params?.leadingZeroDayEnd ?? false));
      setMonthEndValue(parseMonth(newEndDate, params?.leadingZeroMonthEnd ?? false));
      setYearEndValue(parseYear(newEndDate));
    },
    [minDate, maxDate]
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  const handleDayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputName = event.target.name;
      const setFunction = inputName === 'dayEnd' ? setDayEndValue : setDayValue;

      const floatValue = parseFloat(event.target.value);
      let newDayValue = floatValue;

      if (isNaN(newDayValue)) {
        setFunction('');
        return;
      }
      // if (newDayValue < 1) newDayValue = 1;
      if (newDayValue > 31) newDayValue = 31;

      const hasLeadingZero = newDayValue > 0 && newDayValue === floatValue && event.target.value.startsWith('0');

      if (hasLeadingZero) setFunction(newDayValue.toString().padStart(2, '0'));
      else setFunction(newDayValue.toString());

      if (newDayValue < 1) return; // maybe not yet ready for parsing

      const params = {
        leadingZeroDay: hasLeadingZero,
        leadingZeroMonth: true,
        leadingZeroDayEnd: true,
        leadingZeroMonthEnd: true,
      };
      let dayStart = newDayValue;
      let dayEnd = dayEndValue ? parseFloat(dayEndValue) : 0;
      if (inputName === 'dayEnd') {
        dayStart = dayValue ? parseFloat(dayValue) : 0;
        dayEnd = newDayValue;

        params.leadingZeroDay = true;
        params.leadingZeroDayEnd = hasLeadingZero;
      }

      if (dayStart && monthValue && yearValue && dayEnd && monthEndValue && yearEndValue)
        validateInputs(dayStart, parseFloat(monthValue), parseFloat(yearValue), dayEnd, parseFloat(monthEndValue), parseFloat(yearEndValue), params);
    },
    [dayValue, monthValue, yearValue, dayEndValue, monthEndValue, yearEndValue, validateInputs]
  );
  const handleMonthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputName = event.target.name;
      const setFunction = inputName === 'monthEnd' ? setMonthEndValue : setMonthValue;

      const floatValue = parseFloat(event.target.value);
      let newMonthValue = floatValue;

      if (isNaN(newMonthValue)) {
        setFunction('');
        return;
      }
      // if (newMonthValue < 1) newMonthValue = 1;
      if (newMonthValue > 12) newMonthValue = 12;

      const hasLeadingZero = newMonthValue > 0 && newMonthValue === floatValue && event.target.value.startsWith('0');

      if (hasLeadingZero) setFunction(newMonthValue.toString().padStart(2, '0'));
      else setFunction(newMonthValue.toString());

      if (newMonthValue < 1) return; // maybe not yet ready for parsing

      const params = {
        leadingZeroDay: true,
        leadingZeroMonth: hasLeadingZero,
        leadingZeroDayEnd: true,
        leadingZeroMonthEnd: true,
      };
      let monthStart = newMonthValue;
      let monthEnd = monthEndValue ? parseFloat(monthEndValue) : 0;
      if (inputName === 'monthEnd') {
        monthStart = monthValue ? parseFloat(monthValue) : 0;
        monthEnd = newMonthValue;

        params.leadingZeroMonth = true;
        params.leadingZeroMonthEnd = hasLeadingZero;
      }

      if (dayValue && monthStart && yearValue && dayEndValue && monthEnd && yearEndValue)
        validateInputs(parseFloat(dayValue), monthStart, parseFloat(yearValue), parseFloat(dayEndValue), monthEnd, parseFloat(yearEndValue), params);
    },
    [dayValue, monthValue, yearValue, dayEndValue, monthEndValue, yearEndValue, validateInputs]
  );
  const handleYearChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputName = event.target.name;
      const setFunction = inputName === 'yearEnd' ? setYearEndValue : setYearValue;

      let newYearValue = parseFloat(event.target.value);

      if (isNaN(newYearValue)) {
        setFunction('');
        return;
      }
      // if (newYearValue < minDate.year()) newYearValue = minDate.year();
      if (newYearValue > (maxDate ?? defaultMaxDate).year()) newYearValue = (maxDate ?? defaultMaxDate).year();

      setFunction(newYearValue.toString());

      if (newYearValue < (minDate ?? defaultMinDate).year()) return; // maybe not yet ready for parsing

      const params = {
        leadingZeroDay: true,
        leadingZeroMonth: true,
        leadingZeroDayEnd: true,
        leadingZeroMonthEnd: true,
      };
      let yearStart = newYearValue;
      let yearEnd = yearEndValue ? parseFloat(yearEndValue) : 0;
      if (inputName === 'yearEnd') {
        yearStart = yearValue ? parseFloat(yearValue) : 0;
        yearEnd = newYearValue;
      }

      if (dayValue && monthValue && yearStart && dayEndValue && monthEndValue && yearEnd)
        validateInputs(parseFloat(dayValue), parseFloat(monthValue), yearStart, parseFloat(dayEndValue), parseFloat(monthEndValue), yearEnd, params);
    },
    [dayValue, monthValue, yearValue, dayEndValue, monthEndValue, yearEndValue, validateInputs, minDate, maxDate]
  );

  const handleBlurInput = useCallback(() => {
    if (dayValue && monthValue && yearValue && dayEndValue && monthEndValue && yearEndValue)
      validateInputs(parseFloat(dayValue), parseFloat(monthValue), parseFloat(yearValue), parseFloat(dayEndValue), parseFloat(monthEndValue), parseFloat(yearEndValue), {
        leadingZeroDay: true,
        leadingZeroMonth: true,
        leadingZeroDayEnd: true,
        leadingZeroMonthEnd: true,
      });
    else {
      // unset the values if not correct
      const hasStart = dayValue && monthValue && yearValue;
      const hasEnd = dayEndValue && monthEndValue && yearEndValue;
      if (!hasStart) setSelectedValue([null, null]);
      else if (!hasEnd) setSelectedValue((current) => [current[0], null]);

      if (dayValue) setDayValue(dayValue.padStart(2, '0'));
      if (monthValue) setMonthValue(monthValue.padStart(2, '0'));
      if (dayEndValue) setDayEndValue(dayEndValue.padStart(2, '0'));
      if (monthEndValue) setMonthEndValue(monthEndValue.padStart(2, '0'));
    }
  }, [dayValue, monthValue, yearValue, dayEndValue, monthEndValue, yearEndValue, validateInputs]);

  return (
    <Box
      ref={containerRef}
      className="custom-scrollbar"
      borderRadius={'5px'}
      sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5, maxHeight: '60vh', overflowY: 'auto', width: 320, maxWidth: 320 }}
    >
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue[0] === null && selectedValue[1] === null}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton> */}

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        {/* <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={(selectedValue[0] !== null && selectedValue[1] === null) || !hasAlgoSelected}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button> */}
      </Box>

      {/* algo selection */}
      {algoFields && algoValue && (
        <Box py={0.5} display="grid" gap={0.5} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          {algoFields.map((af) => {
            const checked = algoSelected[af].checked;
            return (
              <Box key={af}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      name={af}
                      color={'primary'}
                      checked={checked}
                      onChange={selectAlgoField}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        py: 1,
                        borderRadius: '5px 0 0 5px',
                        '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
                        '&:hover': { backgroundColor: 'var(--color-hover-background)' },
                      }}
                    />
                  }
                  label={
                    <Typography display="flex" alignItems="center" fontSize={'.875rem'} py={0.5} flex={1} sx={{ lineHeight: 1.2, color: 'var(--color-gray2)', borderRadius: '0 5px 5px 0' }}>
                      <FormattedMessage id={af} />
                    </Typography>
                  }
                  sx={{ width: '100%', display: 'flex', alignItems: 'stretch', ml: 0, mr: 0, px: 2, '&:hover > *': { backgroundColor: 'var(--color-hover-background)' } }}
                />

                {checked && (
                  <Box px={2} py={1} sx={{ background: 'vaf(--color-lightgray)', backgroundColor: '#F7F8FA', fontSize: 'var(--fs-14)' }}>
                    {/* <Select name={af} fullWidth size="small" value={algoSelected[af].value} onChange={setAlgoForCountry} sx={{ backgroundColor: 'white', color: 'inherit', fontSize: 'inherit' }}>
                      {algoValue.map((ca) => (
                        <MenuItem key={ca} value={ca} sx={{ fontSize: 'var(--fs-14)' }}>
                          <FormattedMessage id={ca} />
                        </MenuItem>
                      ))}
                    </Select> */}

                    <SearchSelect
                      name={af}
                      fullWidth
                      size="small"
                      value={algoSelected[af].value}
                      onChange={setAlgoForCountry}
                      placeholder={intl.formatMessage({ id: 'selectAlgorithm' })}
                      choices={algoValue.map((av) => ({ key: av, value: <FormattedMessage id={av} /> }))}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'}>
        <Box px={2} py={1} display="flex" alignItems={'center'} justifyContent={'center'} gap={1} sx={{ color: 'var(--color-gray2)' }}>
          <Box px={0.5} py={0.5} display="flex" justifyContent="center" className="rounded-border" alignItems="center" sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'dayDD', defaultMessage: 'Day (DD)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '3ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 31,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleDayChange}
                onBlur={handleBlurInput}
                value={dayValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'monthMM', defaultMessage: 'Month (MM)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '3ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 12,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleMonthChange}
                onBlur={handleBlurInput}
                value={monthValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'yearYYYY', defaultMessage: 'Year (YYYY)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '5ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: minDate.year(),
                  // max: today.year(),
                  inputMode: 'numeric',
                  maxLength: 4,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleYearChange}
                onBlur={handleBlurInput}
                value={yearValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
          </Box>

          <ArrowIcon sx={{ fontSize: 'var(--fs-12)' }} />

          <Box px={0.5} py={0.5} display="flex" justifyContent="center" alignItems="center" className="rounded-border" sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'dayDD', defaultMessage: 'Day (DD)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '3ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 31,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                name="dayEnd"
                type="text"
                onChange={handleDayChange}
                onBlur={handleBlurInput}
                value={dayEndValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'monthMM', defaultMessage: 'Month (MM)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '3ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 12,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                name="monthEnd"
                type="text"
                onChange={handleMonthChange}
                onBlur={handleBlurInput}
                value={monthEndValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'yearYYYY', defaultMessage: 'Year (YYYY)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '5ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: minDate.year(),
                  // max: today.year(),
                  inputMode: 'numeric',
                  maxLength: 4,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                name="yearEnd"
                type="text"
                onChange={handleYearChange}
                onBlur={handleBlurInput}
                value={yearEndValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
          </Box>
        </Box>

        <Box px={2} py={1} sx={{ backgroundColor: '#F8F6F6' }}>
          <DateCalendar
            maxDate={maxDate ?? defaultMaxDate}
            minDate={minDate ?? defaultMinDate}
            className={'filter-date-picker'}
            value={selectedValue[0]}
            dayOfWeekFormatter={(day) => day}
            onChange={onChangeCalendar}
            slots={{
              day: CustomCalendarDay,
            }}
            slotProps={{
              day: {
                selectedDays: selectedValue,
              } as any,
            }}
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          fullWidth
          className="no-hover"
          // variant="text"
          onClick={clearFilter}
          size="small"
          disabled={selectedValue[0] === null && selectedValue[1] === null}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#F8F6F6',
              color: '#C9C8C7',
              borderColor: '#F8F6F6',
              boxShadow: 'none',
            },
            // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
          }}
        >
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

const CustomCalendarDay = (props: PickersDayProps<Dayjs> & { selectedDays?: (Dayjs | null)[] }) => {
  const { selectedDays = [], day, outsideCurrentMonth, ...other } = props;

  // const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  let ButtonClass = '';
  if (day.isSame(selectedDays[0]) || day.isSame(selectedDays[1])) ButtonClass = 'day-limit';
  else if (selectedDays[0] !== null && selectedDays[1] !== null) {
    if (day.isAfter(selectedDays[0]) && day.isBefore(selectedDays[1])) ButtonClass = 'day-between';
  }
  return <PickersDay {...other} className={`${other.className ?? ''} ${ButtonClass}`} outsideCurrentMonth={outsideCurrentMonth} day={day} />;
};

export default DateRangeFilter;
