import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CrossFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M8.563.563a8,8,0,1,0,8,8A8,8,0,0,0,8.563.563Zm3.923,10.1a.387.387,0,0,1,0,.548l-1.277,1.274a.387.387,0,0,1-.548,0l-2.1-2.116-2.1,2.116a.387.387,0,0,1-.548,0L4.64,11.208a.387.387,0,0,1,0-.548l2.116-2.1L4.64,6.462a.387.387,0,0,1,0-.548L5.917,4.637a.387.387,0,0,1,.548,0l2.1,2.119,2.1-2.116a.387.387,0,0,1,.548,0l1.277,1.277a.387.387,0,0,1,0,.548l-2.119,2.1Z"
          transform="translate(-0.563 -0.563)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
