/**
 * DatasetProvider.ts
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import {
  DatasetsType,
  DatasetsResponse,
  DatasetFolder,
  DatasetFoldersResponse,
  EditDatasetFoldersResponse,
  DeleteDatasetFoldersResponse,
  DatasetClassificationResponse,
  Dataset,
  DeleteDatasetResponse,
  EditDatasetResponse,
  ImportDatasetReponse,
} from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';

interface DatasetsContextType {
  datasetsQueried: boolean;
  loadingDatasets: boolean;
  datasets?: DatasetsType;
  listDatasets?(abortController?: AbortController): void;
  addOrUpdateDataset?(data: Partial<Dataset>, create?: boolean, abortController?: AbortController): Promise<Dataset | undefined>;
  deleteDataset?(datasetId: number, abortController?: AbortController): Promise<DeleteDatasetResponse | undefined>;
  exportDataset?(dataset: Dataset, abortController?: AbortController): Promise<StatusPromiseResponse>;
  importDataset?(file: File, dataset: Dataset): Promise<ImportDatasetReponse>;
  loadingClassifications: boolean;
  classifications?: string[];
  listDatasetsClassifications?(abortController?: AbortController): void;
  loadingFolders: boolean;
  folders?: DatasetFolder[];
  listDatasetFolders?(abortController?: AbortController): void;
  addOrUpdateDatasetFolder?(data: Partial<DatasetFolder>, create?: boolean, abortController?: AbortController): Promise<DatasetFolder | undefined>;
  deleteDatasetFolder?(datasetFolderId: number, abortController?: AbortController): Promise<DeleteDatasetFoldersResponse | undefined>;
}
/* elements */
const DatasetsContext = createContext<DatasetsContextType>({
  datasetsQueried: false,
  loadingDatasets: false,
  datasets: undefined,
  listDatasets: undefined,
  loadingFolders: false,
  loadingClassifications: false,
});

const DatasetsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();

  const [datasetsQueried, setDatasetsQueried] = useState<boolean>(false);
  const [loadingDatasets, setLoadingDatasets] = useState<boolean>(true);
  const [datasets, setDatasets] = useState<DatasetsType | undefined>(undefined);

  const [loadingClassifications, setLoadingClassifications] = useState<boolean>(false);
  const [classifications, setClassifications] = useState<string[] | undefined>(undefined);

  const [loadingFolders, setLoadingFolders] = useState<boolean>(false);
  const [folders, setFolders] = useState<DatasetFolder[] | undefined>(undefined);

  const listClassificationsOngoing = useRef(false);
  const listDatasetsClassifications = useCallback(
    async (abortController?: AbortController) => {
      if (listClassificationsOngoing.current) return;

      listClassificationsOngoing.current = true;
      setLoadingClassifications(true);
      setClassifications(undefined);
      try {
        const datasetClassificationUrl = URLConstants.datasetClassifications;
        const results = (await getAuthenticatedRequest(datasetClassificationUrl, abortController ?? undefined)) as DatasetClassificationResponse;

        setLoadingClassifications(false);
        setClassifications(results.listOfClassifications);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setLoadingClassifications(false);
        setClassifications(undefined);
      }
      listOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );

  const listOngoing = useRef(false);
  const listDatasets = useCallback(
    async (abortController?: AbortController) => {
      if (listOngoing.current) return;

      listOngoing.current = true;
      setLoadingDatasets(true);
      setDatasets(undefined);
      try {
        const datasetUrl = URLConstants.allDatasets;
        const results = (await getAuthenticatedRequest(datasetUrl, abortController ?? undefined)) as DatasetsResponse;

        setDatasetsQueried(true);
        setLoadingDatasets(false);
        setDatasets(results.datasets);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setDatasetsQueried(true);
        setLoadingDatasets(false);
        setDatasets(undefined);
      }
      listOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );
  const addOrUpdateDataset = useCallback(
    async (data: Partial<Dataset>, create = true, abortController?: AbortController): Promise<Dataset | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const editDatasetUrl = create ? URLConstants.datasetCreate : URLConstants.datasetUpdate;
        const results = (await postAuthenticatedRequest(editDatasetUrl, { dataset: data }, abortController ?? undefined)) as EditDatasetResponse;

        const updatedDataset = results.dataset;
        setDatasets((currentDatasets) => {
          if (!updatedDataset) return currentDatasets;
          if (!currentDatasets) return [updatedDataset];

          const newDatasets = [...currentDatasets];
          const folderIndex = currentDatasets?.findIndex((r) => r.id === updatedDataset.id);
          if (folderIndex >= 0) {
            newDatasets[folderIndex] = updatedDataset;
          } else {
            newDatasets.push(updatedDataset);
          }
          return newDatasets;
        });

        return updatedDataset;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [postAuthenticatedRequest]
  );

  const deleteDataset = useCallback(
    async (datasetId: number, abortController?: AbortController): Promise<DeleteDatasetResponse | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const deleteDatasetUrl = URLConstants.datasetDelete + `${datasetId}`;
        const results = (await deleteAuthenticatedRequest(deleteDatasetUrl, abortController ?? undefined)) as DeleteDatasetResponse;

        if (results.operationResult) {
          setDatasets((currentDatasets) => {
            return currentDatasets?.filter((t) => t.id !== datasetId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [deleteAuthenticatedRequest]
  );

  const exportDataset = useCallback(
    async (dataset: Dataset, abortController?: AbortController): Promise<StatusPromiseResponse> => {
      if (!dataset) return { status: 'error' };
      if (!dataset.sourceOfDataType) return { status: 'error' };

      const exportUrl = URLConstants.datasetExport;
      const filename = `${dataset.label.replaceAll(' ', '_') ?? 'dataset'}.zip`;

      try {
        const response = (await postAuthenticatedRequest(
          exportUrl,
          {
            [dataset.sourceOfDataType]: { dataset: { id: dataset.id } },
          },
          undefined,
          { blob: true }
        )) as BlobPart;

        const downloadUrl = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); //any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return { status: 'success' };
      } catch (ExportError) {
        return { status: 'error' };
      }
    },
    [postAuthenticatedRequest]
  );

  const importDataset = useCallback(
    async (file: File, dataset: Dataset): Promise<ImportDatasetReponse> => {
      const importDatasetUrl = URLConstants.datasetImport;

      const formData = new FormData();

      formData.append('upload_file', file, file.name);
      formData.append('dataset', String(dataset.id));
      try {
        const result = (await postAuthenticatedRequest(importDatasetUrl, formData, undefined, {
          contentType: 'multipart/form-data',
          skipStringify: true,
        })) as ImportDatasetReponse;
        return result;
      } catch (uploadError: any) {
        // console.error(uploadError);
        throw uploadError;
      }
    },
    [postAuthenticatedRequest]
  );

  const listFolderOngoing = useRef(false);
  const listDatasetFolders = useCallback(
    async (abortController?: AbortController) => {
      if (listFolderOngoing.current) return;

      listFolderOngoing.current = true;
      setLoadingFolders(true);
      setFolders(undefined);
      try {
        const datasetFoldersUrl = URLConstants.fullDatasetFolderList;
        const results = (await getAuthenticatedRequest(datasetFoldersUrl, abortController ?? undefined)) as DatasetFoldersResponse;

        if (results.datasetFolders) {
          setFolders(results.datasetFolders);
        }
        setLoadingFolders(false);
        // setFolders(results.datasets);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') {
          listFolderOngoing.current = false;
          return;
        }

        setLoadingFolders(false);
        setFolders(undefined);
      }
      listFolderOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );

  const addOrUpdateDatasetFolder = useCallback(
    async (data: Partial<DatasetFolder>, create = true, abortController?: AbortController): Promise<DatasetFolder | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const editDatasetFolderUrl = create ? URLConstants.createDatasetFolder : URLConstants.updateDatasetFolder;
        const results = (await postAuthenticatedRequest(editDatasetFolderUrl, { datasetFolder: data }, abortController ?? undefined)) as EditDatasetFoldersResponse;

        const updatedDatasetFolder = results.datasetFolder;
        setFolders((currentFolders) => {
          if (!updatedDatasetFolder) return currentFolders;
          if (!currentFolders) return [updatedDatasetFolder];

          const newFolders = [...currentFolders];
          const folderIndex = currentFolders?.findIndex((r) => r.id === updatedDatasetFolder.id);
          if (folderIndex >= 0) {
            newFolders[folderIndex] = updatedDatasetFolder;
          } else {
            newFolders.push(updatedDatasetFolder);
          }
          return newFolders;
        });

        return updatedDatasetFolder;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [postAuthenticatedRequest]
  );

  const deleteDatasetFolder = useCallback(
    async (datasetFolderId: number, abortController?: AbortController): Promise<DeleteDatasetFoldersResponse | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const deleteFolderUrl = URLConstants.deleteDatasetFolder + `${datasetFolderId}`;
        const results = (await deleteAuthenticatedRequest(deleteFolderUrl, abortController ?? undefined)) as DeleteDatasetFoldersResponse;

        if (results.operationResult) {
          setFolders((currentFolders) => {
            return currentFolders?.filter((t) => t.id !== datasetFolderId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [deleteAuthenticatedRequest]
  );

  const outputValue = useMemo(
    (): DatasetsContextType => ({
      datasetsQueried,
      loadingDatasets,
      datasets,
      listDatasets,
      addOrUpdateDataset,
      deleteDataset,
      exportDataset,
      importDataset,
      loadingFolders,
      folders,
      listDatasetFolders,
      addOrUpdateDatasetFolder,
      deleteDatasetFolder,
      loadingClassifications,
      classifications,
      listDatasetsClassifications,
    }),
    [
      datasetsQueried,
      loadingDatasets,
      datasets,
      listDatasets,
      addOrUpdateDataset,
      deleteDataset,
      exportDataset,
      importDataset,
      loadingFolders,
      folders,
      listDatasetFolders,
      addOrUpdateDatasetFolder,
      deleteDatasetFolder,
      loadingClassifications,
      classifications,
      listDatasetsClassifications,
    ]
  );
  return <DatasetsContext.Provider value={outputValue}>{children}</DatasetsContext.Provider>;
};

/* exports */
export { DatasetsContext, DatasetsProvider };
