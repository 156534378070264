/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useRef, useEffect, MutableRefObject } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectChangeEvent } from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PopoverActions } from '@mui/material/Popover';

import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
// import { searchConstant } from 'models/searchDatasets';

/* types */
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { FilterValueType } from './SearchFilters';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';

interface DateFilterProps {
  title: string;
  currentValue?: FilterValueType;
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  popoverRef?: MutableRefObject<PopoverActions>;
  algoFields?: string[];
  algoValue?: string[];
}

interface AlgoSelectionType {
  [key: string]: { checked: boolean; value: string };
}

interface ResetAlgoInput {
  currentAlgo?: { [key: string]: string };
  algoFields: string[];
  algoValue: string[];
}

/* elements */
const today = dayjs();
// const minDate = dayjs().subtract(20, 'year');
const minDate = dayjs('1900-01-01');

const resetAlgo = ({ currentAlgo, algoFields, algoValue }: ResetAlgoInput) => {
  const initAlgo = algoFields.reduce((acc, cf) => ({ ...acc, [cf]: { checked: false, value: algoValue[0] } }), {} as AlgoSelectionType);

  if (currentAlgo) {
    Object.entries(currentAlgo).forEach(([algo, state]) => {
      if (Object.keys(initAlgo).includes(algo)) {
        initAlgo[algo].checked = true;
        initAlgo[algo].value = state;
      }
    });
  }
  return initAlgo;
};

const parseAlgoSelected = (algoSelected: AlgoSelectionType) => {
  return Object.entries(algoSelected)
    .filter(([_, state]) => state.checked)
    .reduce((acc, [algo, state]) => ({ ...acc, [algo]: state.value }), {});
};

export const parseDay = (date: Dayjs | null | undefined, leadingZeros: boolean): string => {
  if (!date) return '';

  const dateString = date.date().toString();
  if (leadingZeros) return dateString.padStart(2, '0') ?? '';
  return dateString;
};
export const parseMonth = (date: Dayjs | null | undefined, leadingZeros: boolean): string => {
  if (!date) return '';

  const monthString = (date.month() + 1).toString();
  if (leadingZeros) return monthString.padStart(2, '0') ?? '';
  return monthString;
};
export const parseYear = (date: Dayjs | null | undefined): string => {
  if (!date) return '';

  return date.year().toString() ?? '';
};

export const parseDateFromInput = (day: number, month: number, year: number, minimumDate: Dayjs = minDate, maximumDate: Dayjs = today): Dayjs => {
  if (year < minDate.year()) year = minDate.year();
  if (year > today.year()) year = today.year();

  if (month < 1) month = 1;
  if (month > 12) month = 12;

  let date = dayjs(`${year}-${month}-01`);

  const nbDaysInMonth = date.daysInMonth();
  if (day < 1) day = 1;
  if (day > nbDaysInMonth) day = nbDaysInMonth;

  date = date.date(day);

  if (date < minimumDate) return minimumDate;
  if (date > maximumDate) return maximumDate;

  return date;
};

const DateFilter = ({ title, currentValue, setNewFilterValue, triggerSetUpdate, popoverRef, algoFields, algoValue }: DateFilterProps) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = React.useState<Dayjs | null>(currentValue?.dayValue ?? null);

  const [algoSelected, setAlgoSelected] = useState<AlgoSelectionType>(() => {
    return resetAlgo({ currentAlgo: currentValue?.algo, algoFields: algoFields ?? [], algoValue: algoValue ?? [] });
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const box = containerRef.current;

    const updateOnResize = () => {
      popoverRef?.current?.updatePosition();
    };
    const resizeObserver = new ResizeObserver(updateOnResize);

    resizeObserver.observe(box);

    return () => {
      resizeObserver.unobserve(box);
    };
  }, [containerRef, popoverRef]);

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue, selectedAlgo: FilterValueType['algo']) => {
      if (setNewFilterValue) setNewFilterValue({ nbValues: newValue === null ? 0 : 1, dayValue: newValue, algo: selectedAlgo });
    },
    [setNewFilterValue]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValue = null;
    setSelectedValue(newValue);

    const newAlgos = resetAlgo({
      currentAlgo: undefined,
      algoFields: algoFields ?? [],
      algoValue: algoValue ?? [],
    });
    setAlgoSelected(newAlgos);

    applyFilterValue(newValue, parseAlgoSelected(newAlgos));
  };

  const selectAlgoField = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setAlgoSelected((currentFields) => ({ ...currentFields, [checkboxName]: { ...currentFields[checkboxName], checked: checked } }));
  }, []);

  const setAlgoForCountry = useCallback((event: SelectChangeEvent<unknown>) => {
    const selectName = event.target.name;
    const selectValue = event.target.value as string;
    setAlgoSelected((currentFields) => ({ ...currentFields, [selectName]: { ...currentFields[selectName], value: selectValue } }));
  }, []);

  // propagate on value change
  const hasAlgoSelected: boolean = !algoFields || Object.values(algoSelected).findIndex((v) => v.checked) >= 0;
  // const hasValueSelected: boolean = selectedValues.length > 0;

  const validateSelection = useCallback(() => {
    if (selectedValue === null) return;

    const selectedAlgo = parseAlgoSelected(algoSelected);
    applyFilterValue(selectedValue, selectedAlgo);

    // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValue === null ? 0 : 1, dayValue: selectedValue, algo: selectedAlgo });
  }, [applyFilterValue, algoSelected, selectedValue]);

  // trigger automatic state update
  useEffect(() => {
    if (triggerSetUpdate && hasAlgoSelected) {
      validateSelection();
    }
  }, [triggerSetUpdate, applyFilterValue, hasAlgoSelected, validateSelection]);

  const onChangeCalendar = (value: Dayjs | null, selectionState?: PickerSelectionState) => {
    if (selectionState !== 'finish') return;
    setSelectedValue(value);

    setDayValue(parseDay(value, true));
    setMonthValue(parseMonth(value, true));
    setYearValue(parseYear(value));
  };

  const [dayValue, setDayValue] = useState<string>(parseDay(currentValue?.dayValue, true));
  const [monthValue, setMonthValue] = useState<string>(parseMonth(currentValue?.dayValue, true));
  const [yearValue, setYearValue] = useState<string>(parseYear(currentValue?.dayValue));

  const validateInputs = useCallback((day: number, month: number, year: number, params?: { leadingZeroDay?: boolean; leadingZeroMonth?: boolean }) => {
    const newDate = parseDateFromInput(day, month, year);
    setSelectedValue(newDate);

    setDayValue(parseDay(newDate, params?.leadingZeroDay ?? false));
    setMonthValue(parseMonth(newDate, params?.leadingZeroMonth ?? false));
    setYearValue(parseYear(newDate));
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  const handleDayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const floatValue = parseFloat(event.target.value);
      let newDayValue = floatValue;

      if (isNaN(newDayValue)) {
        setDayValue('');
        return;
      }
      // if (newDayValue < 1) newDayValue = 1;
      if (newDayValue > 31) newDayValue = 31;

      const hasLeadingZero = newDayValue > 0 && newDayValue === floatValue && event.target.value.startsWith('0');

      if (hasLeadingZero) setDayValue(newDayValue.toString().padStart(2, '0'));
      else setDayValue(newDayValue.toString());

      if (newDayValue < 1) return; // maybe not yet ready for parsing

      if (monthValue && yearValue) validateInputs(newDayValue, parseFloat(monthValue), parseFloat(yearValue), { leadingZeroDay: hasLeadingZero, leadingZeroMonth: true });
    },
    [monthValue, yearValue, validateInputs]
  );
  const handleMonthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const floatValue = parseFloat(event.target.value);
      let newMonthValue = floatValue;

      if (isNaN(newMonthValue)) {
        setMonthValue('');
        return;
      }
      // if (newMonthValue < 1) newMonthValue = 1;
      if (newMonthValue > 12) newMonthValue = 12;

      const hasLeadingZero = newMonthValue > 0 && newMonthValue === floatValue && event.target.value.startsWith('0');

      if (hasLeadingZero) setMonthValue(newMonthValue.toString().padStart(2, '0'));
      else setMonthValue(newMonthValue.toString());

      if (newMonthValue < 1) return; // maybe not yet ready for parsing

      if (dayValue && yearValue) validateInputs(parseFloat(dayValue), newMonthValue, parseFloat(yearValue), { leadingZeroDay: true, leadingZeroMonth: hasLeadingZero });
    },
    [dayValue, yearValue, validateInputs]
  );
  const handleYearChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newYearValue = parseFloat(event.target.value);

      if (isNaN(newYearValue)) {
        setYearValue('');
        return;
      }
      // if (newYearValue < minDate.year()) newYearValue = minDate.year();
      if (newYearValue > today.year()) newYearValue = today.year();

      setYearValue(newYearValue.toString());

      if (newYearValue < minDate.year()) return; // maybe not yet ready for parsing

      if (dayValue && monthValue) validateInputs(parseFloat(dayValue), parseFloat(monthValue), newYearValue, { leadingZeroDay: true, leadingZeroMonth: true });
    },
    [dayValue, monthValue, validateInputs]
  );

  const handleBlurInput = useCallback(() => {
    if (dayValue && monthValue && yearValue) validateInputs(parseFloat(dayValue), parseFloat(monthValue), parseFloat(yearValue), { leadingZeroDay: true, leadingZeroMonth: true });
    else {
      if (dayValue) setDayValue(dayValue.padStart(2, '0'));
      if (monthValue) setMonthValue(monthValue.padStart(2, '0'));
    }
  }, [dayValue, monthValue, yearValue, validateInputs]);

  return (
    <Box
      ref={containerRef}
      className="custom-scrollbar"
      borderRadius={'5px'}
      sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5, maxHeight: '60vh', overflowY: 'auto', width: 300, maxWidth: 300 }}
    >
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue === null}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton> */}

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        {/* <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={selectedValue !== null && !hasAlgoSelected}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button> */}
      </Box>

      {/* algo selection */}
      {algoFields && algoValue && (
        <Box py={0.5} display="grid" gap={0.5} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          {algoFields.map((af) => {
            const checked = algoSelected[af].checked;
            return (
              <Box key={af}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      name={af}
                      color={'primary'}
                      checked={checked}
                      onChange={selectAlgoField}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        py: 1,
                        borderRadius: '5px 0 0 5px',
                        '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
                        '&:hover': { backgroundColor: 'var(--color-hover-background)' },
                      }}
                    />
                  }
                  label={
                    <Typography display="flex" alignItems="center" fontSize={'.875rem'} py={0.5} flex={1} sx={{ lineHeight: 1.2, color: 'var(--color-gray2)', borderRadius: '0 5px 5px 0' }}>
                      <FormattedMessage id={af} />
                    </Typography>
                  }
                  sx={{ width: '100%', display: 'flex', alignItems: 'stretch', ml: 0, mr: 0, px: 2, '&:hover > *': { backgroundColor: 'var(--color-hover-background)' } }}
                />

                {checked && (
                  <Box px={2} py={1} sx={{ background: 'vaf(--color-lightgray)', backgroundColor: '#F7F8FA', fontSize: 'var(--fs-14)' }}>
                    {/* <Select name={af} fullWidth size="small" value={algoSelected[af].value} onChange={setAlgoForCountry} sx={{ backgroundColor: 'white', color: 'inherit', fontSize: 'inherit' }}>
                      {algoValue.map((ca) => (
                        <MenuItem key={ca} value={ca} sx={{ fontSize: 'var(--fs-14)' }}>
                          <FormattedMessage id={ca} />
                        </MenuItem>
                      ))}
                    </Select> */}

                    <SearchSelect
                      name={af}
                      fullWidth
                      size="small"
                      value={algoSelected[af].value}
                      onChange={setAlgoForCountry}
                      placeholder={intl.formatMessage({ id: 'selectAlgorithm' })}
                      choices={algoValue.map((av) => ({ key: av, value: <FormattedMessage id={av} /> }))}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'}>
        <Box px={2} py={1}>
          <Box
            display="flex"
            px={0.5}
            py={0.5}
            justifyContent="center"
            alignItems="center"
            className="rounded-border"
            sx={{ color: 'var(--color-gray2)', border: '1px solid var(--color-grayHeaderBorder)' }}
          >
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'dayDD', defaultMessage: 'Day (DD)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '5ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 31,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleDayChange}
                onBlur={handleBlurInput}
                value={dayValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'monthMM', defaultMessage: 'Month (MM)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '5ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: 1,
                  // max: 12,
                  inputMode: 'numeric',
                  maxLength: 2,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleMonthChange}
                onBlur={handleBlurInput}
                value={monthValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
            /
            <ContentWithDarkTooltip tooltipSX={{ marginBottom: '4px!important' }} tooltip={intl.formatMessage({ id: 'yearYYYY', defaultMessage: 'Year (YYYY)' })} placement="top">
              <TextField
                variant="standard"
                inputProps={{
                  className: 'highlight-focus',
                  style: {
                    paddingBlock: 0,
                    fontSize: 'var(--fs-14)',
                    color: 'var(--color-gray2)',
                    textAlign: 'center',
                    WebkitTextFillColor: 'unset',
                    width: '7ch',
                    height: 'auto',
                  },
                  onKeyDown: handleKeyPress,
                  // min: minDate.year(),
                  // max: today.year(),
                  inputMode: 'numeric',
                  maxLength: 4,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                type="text"
                onChange={handleYearChange}
                onBlur={handleBlurInput}
                value={yearValue}
                sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
              />
            </ContentWithDarkTooltip>
          </Box>
        </Box>

        <Box px={2} py={1} sx={{ backgroundColor: '#F8F6F6' }}>
          <DateCalendar maxDate={today} minDate={minDate} className={'filter-date-picker'} value={selectedValue} dayOfWeekFormatter={(day) => day} onChange={onChangeCalendar} sx={{ width: '100%' }} />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          fullWidth
          className="no-hover"
          // variant="text"
          onClick={clearFilter}
          size="small"
          disabled={selectedValue === null}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#F8F6F6',
              color: '#C9C8C7',
              borderColor: '#F8F6F6',
              boxShadow: 'none',
            },
            // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
          }}
        >
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

export default DateFilter;
