/**
 * library.ts
 * Define library data
 */

import { Audit } from './audit';
import { ApiResponse } from './axiosRequest';

export const AllowedLibraries = ['highRiskLocations', 'stopwordsLocations', 'stopwordsIdentifiers', 'stopwordsNames', 'whitelistedNames', 'datasetFilters', 'wckeyword'] as const;
export const AllowedLibrariesDisplay = [
      {key:'highRiskLocations', value : 'High-Risk Locations'},
      {key:'stopwordsLocations', value:'Stopwords for Geo-Matching'},
      {key:'stopwordsIdentifiers', value:'Stopwords for Identifier Matching'},
      {key:'stopwordsNames', value:'Stopwords for Name Matching'},
      {key:'whitelistedNames', value:'Whitelisted Names'},
      {key:'datasetFilters', value:'Dataset Filters'}
] as const;

export const AllowedLibrariesDisplayMap: { [key: string]: string }= {
  highRiskLocations: 'High-Risk Locations',
  stopwordsLocations:'Stopwords for Geo-Matching',
  stopwordsIdentifiers:'Stopwords for Identifier Matching',
  stopwordsNames:'Stopwords for Name Matching',
  whitelistedNames:'Whitelisted Names',
  datasetFilters:'Dataset Filters'
};

export const LibraryColumnLabel: { [key: string]: string } = {      
  RISKLEVEL: 'RISK LEVEL',
  PLACENAME: 'PLACE NAME',
  LINKWORD: 'LINK WORD',
  DATAID: 'DATA ID',
  FILTERID: 'FILTER ID'
};

export type LibraryName = (typeof AllowedLibraries)[number];

// type Libraries = { [key in LibraryName]: LibraryElement[] };
export interface LibraryResponse extends ApiResponse {
  libraryData?: LibraryElement[];
  libraryName: string;
}

export interface LibraryElement {
  [key: string]: string | number;
  elementId: number;
}

export interface ImpotLibraryResponse extends ApiResponse {
  audits?: Audit[];
}
