/**
 * InvestigateSearch.tsx
 */
/* packages */
import React, { forwardRef, useRef, memo, PropsWithChildren, useContext, useEffect, useState, useMemo, useCallback, SyntheticEvent, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* contexts */
import { SearchDatasetContext } from 'contextProviders/SearchDatasetsProvider';
import { CountriesContext } from 'contextProviders/CountriesProvider';
import { UserContext } from 'contextProviders/UserProvider';
import { useAPIRequest } from 'contextProviders/AuthProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* hooks */
import { useViewProfile } from 'components/PersonProfile/PersonProfile';

/* components */
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { SelectChangeEvent } from '@mui/material/Select';

import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

import StatusTag from 'components/StatusTag/StatusTag';
import { GetMatchType } from 'utilities/TermMatching';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType, FilterValueType } from 'components/SearchFilters/SearchFilters';
import Pagination, { defaultNumberPages } from 'components/Pagination/Pagination';

import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import FilterVirtualCheckboxes from 'components/SearchFilters/FilterVirtualCheckboxes';
import CountryFilterVirtualCheckboxes from 'components/SearchFilters/CountryFilterVirtualCheckboxes';
import DateFilter from 'components/SearchFilters/DateFilter';

import { RefreshIcon } from 'icons/refresh/refresh';
import { GearFilledIcon } from 'icons/gearFilled/gearFilled';
import { ClockRotateLeftIcon } from 'icons/clockRotateLeft/clockRotateLeft';
import { ExportArrowIcon } from 'icons/exportArrow/exportArrow';
import { ExportPDFIcon } from 'icons/exportPDF/exportPDF';
import { ExportCSVIcon } from 'icons/exportCSV/exportCSV';

import { AvatarIcon } from 'icons/avatar/avatar';
import { AvatarMaleIcon } from 'icons/avatarMale/avatarMale';
import { AvatarFemaleIcon } from 'icons/avatarFemale/avatarFemale';
import { OrganisationIcon } from 'icons/organisation/organisation';
import { ShipIcon } from 'icons/ship/ship';
import { LeiIcon } from 'icons/lei/lei';
import { BicIcon } from 'icons/bic/bic';
import { IdIcon } from 'icons/id/id';
import { UnknownIcon } from 'icons/unknown/unknown';

import GaugeMeter from 'components/GaugeMeter/GaugeMeter';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';

import OrganisationContent from 'components/InvestigateSearch/OrganisationContent';
import IndividualContent from 'components/InvestigateSearch/IndividualContent';
import VesselContent from 'components/InvestigateSearch/VesselContent';
import IdentificationNumberContent from 'components/InvestigateSearch/IdentificationNumberContent';
import UnknownContent from 'components/InvestigateSearch/UnknownContent';

/* utilities */
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { routerPages } from 'AppRouter';
import { URLConstants } from 'common/URLconstants';

/* types */
import { MatchingDataType, MatchingNameType, formatMatchingName } from 'models/matchingData';
import { SearchDatasetsType, SearchResultsResponse, SearchPayloadType, searchConstant, SourceQuery, SearchResultsElement, MatchingAlgo } from 'models/searchDatasets';
import { UserType } from 'models/user';
import { SearchCardType } from 'components/InvestigateSearch/utils';
import FilterRange from 'components/SearchFilters/FilterRange';

interface SearchFormType {
  submitAction(event: SyntheticEvent): void;
  makingSearch: boolean;
  datasets: SearchDatasetsType;
}

interface InvestigateTabProps {
  datasets: SearchDatasetsType;
}

interface DisplaySearchResultsType {
  searchResults: SearchResultsResponse | null;
  exportResults(format: string): void;
  lastSearchQuery?: SearchPayloadType;
}

interface ExportButtonProps {
  exportResults(format: string): void;
}

/* elements */
const monthAbbreviated = ['Jan', 'Feb', 'Mar', 'Apr', 'May	', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const FILTERNAMES = {
  entityType: 'entityType',
  list: 'label',
  country: 'country',
  date: 'date',
  threshold: 'threshold',
};

const InvestigateSearch = () => {
  const { searchDatasetsQueried, loadingSearchDatasets, searchDatasets, makeSearchDatasets } = useContext(SearchDatasetContext);
  const { countriesQueried, loadingCountries, countries, listCountries } = useContext(CountriesContext);

  // trigger search dataset on load if required
  useEffect(() => {
    if (searchDatasetsQueried) return;
    makeSearchDatasets?.();
  }, [searchDatasetsQueried, makeSearchDatasets]);

  // trigger list countries on load if required
  useEffect(() => {
    if (countriesQueried) return;
    listCountries?.();
  }, [countriesQueried, listCountries]);

  // refresh datasets
  const refreshDataset = () => {
    if (!searchDatasets) makeSearchDatasets?.();
    if (!countries) listCountries?.();
  };

  // display the loading layout
  if (!searchDatasetsQueried || loadingSearchDatasets || !countriesQueried || loadingCountries)
    return (
      <InvestigateSearchLayout>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      </InvestigateSearchLayout>
    );

  // display a reload button if the required data are not fetched
  if (!searchDatasets || !countries)
    return (
      <InvestigateSearchLayout>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={refreshDataset}>
            <RefreshIcon sx={{ mb: 2 }} />
            <Box>
              <Typography>
                <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
              </Typography>
              <Typography>
                <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
              </Typography>
            </Box>
          </Button>
        </Box>
      </InvestigateSearchLayout>
    );

  return (
    <InvestigateSearchLayout>
      <InvestigateTabs datasets={searchDatasets} />
    </InvestigateSearchLayout>
  );
};

const InvestigateSearchLayout = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const { permissions } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'investigateSearchTitle', defaultMessage: 'Search' })} />

        {checkPermissions('investigateSearch', permissions) && false && (
          <Box>
            <Link to={routerPages.manageDatasets}>
              <ShadowedButton>
                <GearFilledIcon fontSize="inherit" sx={{ mr: 1 }} />
                <FormattedMessage id="searchDatasetManageSettings" defaultMessage="Manage Settings" />
              </ShadowedButton>
            </Link>
          </Box>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const addPaginationToPayload = (params: { payload?: SearchPayloadType; currentPage: number; maxPerPage: string }) => {
  const { payload, currentPage, maxPerPage } = params;
  if (!payload) return payload;

  payload.paginationRequest = {
    pageNumber: currentPage,
    maxPerPage: Number(maxPerPage),
    calculateSearchStats: true,
  };
  return payload;
};

const SearchPagination = memo(Pagination);

const InvestigateTabs = (props: InvestigateTabProps) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const { datasets } = props;
  const { postAPIRequest } = useAPIRequest();
  const { currentUser } = useContext(UserContext);

  // unique available categories
  const categories = useMemo(() => Array.from(new Set(datasets?.map((ds) => ds.category).filter((c) => c ?? false))).map((c) => ({ text: c, value: c })), [datasets]);

  const [activeCategory, setActiveCategory] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(defaultNumberPages));
  const [searchResults, setSearchResults] = useState<SearchResultsResponse | null>(null);
  const [makingSearch, setMakingSearch] = useState<boolean>(false);

  const [lastSearchQuery, setLastSearchQuery] = useState<SearchPayloadType>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const computeSearchPayload = useCallback((currentUser?: UserType, activeCategory?: string) => {
    if (!currentUser) return;
    const formData = new FormData(searchFormRef.current ?? undefined);

    const searchInput = formData.get('searchInput') as string;

    // we must have a valid search input
    if (!searchInput) return;

    const searchPayload: SearchPayloadType = {
      requestHeader: {
        userId: currentUser?.userName,
      },
      paginationRequest: {
        pageNumber: 0,
        maxPerPage: defaultNumberPages,
        calculateSearchStats: true,
      },
      queries: [
        {
          threshold: 0.8,
          sourceData: [],
          targetData: [],
        },
      ],
    };

    let bicQuery: SourceQuery | null = null;
    let leiQuery: SourceQuery | null = null;
    let idNumberQuery: SourceQuery | null = null;
    let namesQuery: SourceQuery | null = null;

    const thresholdValues = JSON.parse(formData.get(FILTERNAMES.threshold) as string) as FilterValueType;
    searchPayload.queries[0].threshold = thresholdValues.minValue ?? 0.8;

    const entityTypeValues = JSON.parse(formData.get(FILTERNAMES.entityType) as string) as FilterValueType;

    // add bic query if required
    if (entityTypeValues.nbValues === 0 || entityTypeValues.values?.includes(searchConstant.ENTITIES.BIC)) {
      bicQuery = { bics: [searchInput] };
      searchPayload.queries[0].bicAlgo = { type: 'exact_match' } as MatchingAlgo;
    }
    // add lei query if required
    if (entityTypeValues.nbValues === 0 || entityTypeValues.values?.includes(searchConstant.ENTITIES.LEI)) {
      leiQuery = { leis: [searchInput] };
      searchPayload.queries[0].leiAlgo = { type: 'exact_match' } as MatchingAlgo;
    }
    // add id number query if required
    if (entityTypeValues.nbValues === 0 || entityTypeValues.values?.includes(searchConstant.ENTITIES.IDNUMBER)) {
      idNumberQuery = { identityDocuments: [{ number: searchInput }] };
      searchPayload.queries[0].identityDocumentAlgo = { type: 'exact_match' } as MatchingAlgo;
    }

    // add names query if required
    const entityTypeAlgo: MatchingAlgo = {
      nullMatch: true,
      type: 'exact_match',
      include: [] as string[],
    };
    const fileredEntityType = [searchConstant.ENTITIES.ORGANIZATION, searchConstant.ENTITIES.INDIVIDUAL, searchConstant.ENTITIES.VESSEL, searchConstant.ENTITIES.UNKNOWN];
    const hasName = entityTypeValues.values?.filter((v) => fileredEntityType.includes(v));

    // either no named type or at least 1 of them
    if (entityTypeValues.nbValues === 0 || (hasName && hasName.length)) {
      namesQuery = { names: [{ fullName: searchInput }] };
    }

    // add algo only if at least one is selected (otherwise all are selected)
    if (hasName && hasName.length) {
      if (entityTypeValues.values?.includes(searchConstant.ENTITIES.ORGANIZATION)) {
        entityTypeAlgo.include?.push(searchConstant.ENTITIES.ORGANIZATION);
      }
      if (entityTypeValues.values?.includes(searchConstant.ENTITIES.INDIVIDUAL)) {
        entityTypeAlgo.include?.push(searchConstant.ENTITIES.INDIVIDUAL);
      }
      if (entityTypeValues.values?.includes(searchConstant.ENTITIES.VESSEL)) {
        entityTypeAlgo.include?.push(searchConstant.ENTITIES.VESSEL);
      }
      if (entityTypeValues.values?.includes(searchConstant.ENTITIES.UNKNOWN)) {
        // entityTypeAlgo.nullMatch = true;
        entityTypeAlgo.include?.push(searchConstant.ENTITIES.UNKNOWN);
      }

      // add the entityType Algo
      searchPayload.queries[0].entityTypeAlgo = entityTypeAlgo;
    }

    // add all the queries
    const queries: SourceQuery[] = [];
    if (bicQuery) queries.push(bicQuery);
    if (leiQuery) queries.push(leiQuery);
    if (idNumberQuery) queries.push(idNumberQuery);
    if (namesQuery) queries.push(namesQuery);

    // date filtering
    const dateValues = JSON.parse(formData.get(FILTERNAMES.date) as string) as FilterValueType;
    if (dateValues && dateValues.nbValues > 0 && dateValues.algo) {
      const searchDate = dayjs(dateValues.dayValue);

      Object.entries(dateValues.algo).forEach(([algoName, algoValue]: [string, string]) => {
        switch (algoName) {
          case 'datesOfBirth':
            queries.forEach((q) => {
              q.datesOfBirth = [{ date: searchDate.format('YYYY-MM-DD') }];
            });

            searchPayload.queries[0].dateOfBirthAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'datesOfBuild':
            queries.forEach((q) => {
              q.datesOfBuild = [{ date: searchDate.format('YYYY-MM-DD') }];
            });

            searchPayload.queries[0].dateOfBuildAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'datesOfRegistry':
            queries.forEach((q) => {
              q.datesOfRegistry = [{ date: searchDate.format('YYYY-MM-DD') }];
            });

            searchPayload.queries[0].dateOfRegistryAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          default:
            break;
        }
      });
      searchPayload.queries[0].minimumAmountOfOptional = 1;
    }

    // country filtering
    const countryValues = JSON.parse(formData.get(FILTERNAMES.country) as string) as FilterValueType;
    if (countryValues && countryValues.nbValues > 0 && countryValues.algo) {
      Object.entries(countryValues.algo).forEach(([algoName, algoValue]: [string, string]) => {
        switch (algoName) {
          case 'nationality':
            queries.forEach((q) => {
              q.nationalities = countryValues.values?.map((v: string) => ({ country: v }));
            });

            searchPayload.queries[0].nationalityAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'placeOfBirth':
            queries.forEach((q) => {
              q.placesOfBirth = countryValues.values?.map((v: string) => ({ country: v }));
            });

            searchPayload.queries[0].placeOfBirthAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'placeOfRegistry':
            queries.forEach((q) => {
              q.placesOfRegistry = countryValues.values?.map((v: string) => ({ country: v }));
            });

            searchPayload.queries[0].placeOfRegistryAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'address':
            queries.forEach((q) => {
              q.addresses = countryValues.values?.map((v: string) => ({ country: v }));
            });

            searchPayload.queries[0].addressAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          case 'flag':
            queries.forEach((q) => {
              q.flags = countryValues.values?.map((v: string) => ({ country: v }));
            });

            searchPayload.queries[0].flagAlgo = {
              type: algoValue,
              optional: false,
            };
            break;
          default:
            break;
        }
      });
      searchPayload.queries[0].minimumAmountOfOptional = 1;
    }
    searchPayload.queries[0].sourceData = queries;

    // category & label filtering
    const labelValues = JSON.parse(formData.get(FILTERNAMES.list) as string);

    if (labelValues && labelValues.nbValues > 0) {
      searchPayload.queries[0].targetData.push({ datasets: [] });

      labelValues.values?.forEach((label: string) => {
        searchPayload.queries[0].targetData[0].datasets?.push({ label });
      });
    }
    if (activeCategory) {
      searchPayload.queries[0].categoryAlgo = {
        type: 'exact_match',
        include: [activeCategory],
      };
    }

    return searchPayload;
  }, []);

  const makeSearch = useCallback(
    async (searchPayload?: SearchPayloadType) => {
      if (!searchFormRef.current) return;
      if (!currentUser) return;
      if (!searchPayload) return;

      setMakingSearch(true);
      setSearchResults(null);
      setLastSearchQuery(undefined);

      try {
        // const searchPayload = computeSearchPayload(formData, searchInput as string, currentUser, currentPage, maxPerPage, activeCategory);

        const response = (await postAPIRequest(URLConstants.screenaSearchInDatasetsEngine, searchPayload)) as SearchResultsResponse;

        response.numberOfResults = response.searchStats?.numberOfAll ?? 0;
        setLastSearchQuery(searchPayload);
        setSearchResults(response);
      } catch (searchError) {
        addSnackbar(
          intl.formatMessage({
            id: 'searchRequestError',
            defaultMessage: 'An error occured for your search request',
          }),
          'error'
        );
      }

      setMakingSearch(false);
    },
    [postAPIRequest, currentUser, addSnackbar, intl]
  );

  const handleTabChange = (event: React.SyntheticEvent, tabValue: 'string') => {
    const newCategory = tabValue;
    setActiveCategory(newCategory);

    const newCurrentPage = 0;
    setCurrentPage(newCurrentPage);

    // take the lastest values of search form
    const searchPayload = computeSearchPayload(currentUser, newCategory);
    if (!searchPayload) return;

    const paginatedPayload = addPaginationToPayload({ payload: searchPayload, currentPage: newCurrentPage, maxPerPage: maxPerPage });

    makeSearch(paginatedPayload);
  };

  const setMaxAndSearch = (event: SelectChangeEvent) => {
    const newMaxPerPage = event.target.value;
    if (newMaxPerPage === maxPerPage) return;

    const newCurrentPage = 0;
    setMaxPerPage(newMaxPerPage);
    setCurrentPage(newCurrentPage);

    // take the lastest search payload
    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newCurrentPage, maxPerPage: newMaxPerPage });

    makeSearch(paginatedPayload);
  };

  const changePage = (shift: 1 | -1) => {
    if (!searchResults || !searchResults.numberOfResults) return;
    if (!lastSearchQuery) return;

    const newPage = currentPage + shift;

    // check page is in bounds
    if (newPage < 0) return;
    if (newPage > searchResults.numberOfResults / Number(maxPerPage)) return;

    setCurrentPage(newPage);

    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newPage, maxPerPage: maxPerPage });
    makeSearch(paginatedPayload);
  };

  const submitSearchForm = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const newCurrentPage = 0;
    setCurrentPage(newCurrentPage);

    const searchPayload = computeSearchPayload(currentUser, activeCategory);
    if (!searchPayload) return;
    const paginatedPayload = addPaginationToPayload({
      payload: searchPayload,
      currentPage: newCurrentPage,
      maxPerPage: maxPerPage,
    });

    makeSearch(paginatedPayload);
  };

  const exportResults = async (format: 'csv' | 'pdf') => {
    let exportUrl;
    let filename = '';
    switch (format) {
      case 'csv':
        exportUrl = URLConstants.screenaSearchInDatasetsEngineExport;
        filename = 'search.zip';
        break;
      case 'pdf':
        exportUrl = URLConstants.screenaSearchInDatasetsEngineExportPDF;
        filename = 'search.pdf';
        break;
      default:
        break;
    }
    if (!exportUrl) return;
    if (!lastSearchQuery) return;

    setMakingSearch(true);

    try {
      const response = (await postAPIRequest(exportUrl, lastSearchQuery, undefined, { blob: true })) as BlobPart;

      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); //any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (ExportError) {
      addSnackbar(
        intl.formatMessage({
          id: 'searchExportError',
          defaultMessage: 'An error occured while exporting your results',
        }),
        'error'
      );
    }

    setMakingSearch(false);
  };

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      {/* Search Tabs */}
      <TabsNavigation activeTab={activeCategory} tabTitles={categories} onChange={handleTabChange} label={'dataset category'} disabled={makingSearch} />

      {/* Search Form */}
      <SearchForm ref={searchFormRef} {...{ submitAction: submitSearchForm, makingSearch, datasets }} />

      {/* Search results */}
      {makingSearch ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <DisplaySearchResults {...{ searchResults, exportResults, lastSearchQuery }} />
      )}

      {/* Search pagination */}
      {!makingSearch && searchResults && (searchResults.numberOfResults ?? 0) > 0 && (
        <SearchPagination
          {...{
            maxPerPage: Number(maxPerPage),
            currentPage,
            nbResults: searchResults.numberOfResults ?? 0,
            setMaxAndCallback: setMaxAndSearch,
            changePageCallback: changePage,
          }}
        />
      )}
    </Box>
  );
};

const SearchFiltersMemo = memo(SearchFilters);

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { submitAction, makingSearch, datasets } = props;

    const { countries } = useContext(CountriesContext);

    const [searchInput, setSearchInput] = useState<string>('');

    const labels = useMemo(() => Array.from(new Set(datasets?.map((ds) => ds.label).filter((c) => c ?? false))), [datasets]);

    // define required ref
    const entityRef = useRef<FilterButtonRefType | null>(null);
    const listRef = useRef<FilterButtonRefType | null>(null);
    const countryRef = useRef<FilterButtonRefType | null>(null);
    const dateRef = useRef<FilterButtonRefType | null>(null);
    const thresholdRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(
      () => [
        {
          ref: entityRef,
          text: <FormattedMessage id="Entitytype" defaultMessage="Entity type" />,
          inputName: FILTERNAMES.entityType,
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'Entitytype', defaultMessage: 'Entity type' })}
              list={Object.values(searchConstant.ENTITIES).map((e) => ({ key: e, value: intl.formatMessage({ id: e }) }))}
            />
          ),
        },
        {
          ref: listRef,
          text: <FormattedMessage id="list" defaultMessage="List" />,
          inputName: FILTERNAMES.list,
          filterContent: (
            <FilterVirtualCheckboxes title={intl.formatMessage({ id: 'list', defaultMessage: 'List' })} list={labels.filter((l) => l !== undefined).map((l) => ({ key: l ?? '', value: l ?? '' }))} />
          ),
        },
        {
          ref: countryRef,
          text: <FormattedMessage id="country" defaultMessage="Country" />,
          inputName: FILTERNAMES.country,
          filterContent: (
            <CountryFilterVirtualCheckboxes title={intl.formatMessage({ id: 'country', defaultMessage: 'Country' })} list={countries?.map((c) => ({ key: c.iso2, value: c.name })) ?? []} />
          ),
          algoFields: searchConstant.countryFields,
          algoValue: searchConstant.countryAlgo,
        },
        {
          ref: dateRef,
          text: <FormattedMessage id="date" defaultMessage="Date" />,
          inputName: FILTERNAMES.date,
          filterContent: <DateFilter title={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })} />,
          algoFields: searchConstant.dateFields,
          algoValue: searchConstant.dateAlgo,
        },
        {
          ref: thresholdRef,
          text: <FormattedMessage id="threshold" defaultMessage="Threshold" />,
          inputName: FILTERNAMES.threshold,
          filterContent: <FilterRange title={intl.formatMessage({ id: 'threshold', defaultMessage: 'Threshold' })} minimum={0.6} maximum={1} step={0.01} percent={true} freeze="max" />,
          hideNbValues: true,
        },
      ],
      [intl, labels, countries]
    );

    const updateSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    }, []);
    const clearSearchInput = useCallback(() => {
      setSearchInput('');
    }, []);
    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    return (
      <Box width={'100%'} display={'flex'} px={0} py={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search for a name' })}
                inputProps={searchInputProps}
                disabled={makingSearch}
                clearAction={clearSearchInput}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Box>
              <Button type="submit" variant="contained" disableElevation disabled={!searchInput || makingSearch} onClick={submitAction} sx={{ textTransform: 'none' }}>
                <FormattedMessage id="searchButton" defaultMessage="Search" />
              </Button>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box>
              <SearchFiltersMemo disabled={makingSearch} {...{ searchFiltersButtons }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

const DisplaySearchResults = memo((props: DisplaySearchResultsType) => {
  const { searchResults, exportResults, lastSearchQuery } = props;
  if (!searchResults)
    return (
      <Box display="flex" flex={1} px={4} alignItems="center" sx={{ marginInline: 'auto' }}>
        <Typography px={1.5} py={1} className="rounded-border" sx={{ fontWeight: 500, backgroundColor: '#E4E2E2' }}>
          <FormattedMessage id="emptyInvestigateSearch" />
        </Typography>
      </Box>
    );

  const filteredResults = searchResults.results?.filter((r) => r.targetData);
  const nbFilteredResults = searchResults.searchStats?.numberOfAll ?? 0;

  let leftResults: SearchResultsElement[] = [];
  let rightResults: SearchResultsElement[] = [];
  if (searchResults.results) {
    filteredResults?.forEach((res, ind) => {
      if (ind % 2 === 0) leftResults.push(res);
      else rightResults.push(res);
    });
  }

  return (
    <Box>
      <Box
        px={2}
        py={1}
        border={1}
        borderColor={'var(--color-grayHeaderBorder)'}
        display={'flex'}
        sx={{ flexFlow: 'row wrap', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
        alignItems={'center'}
      >
        <Box flex="1">
          <Typography fontWeight={600} fontSize={'1.25rem'} sx={{ color: 'var(--color-darkgray)', textTransform: 'capitalize' }}>
            <Typography component="span" fontWeight={'inherit'} fontSize={'inherit'} sx={{ color: 'var(--color-gray1)' }}>
              {nbFilteredResults}
            </Typography>{' '}
            {nbFilteredResults > 1 ? <FormattedMessage id="results" defaultMessage="results" /> : <FormattedMessage id="result" defaultMessage="result" />}
          </Typography>
        </Box>
        <Box display="flex" alignItems={'center'}>
          {nbFilteredResults > 0 && lastSearchQuery && <ExportButton {...{ exportResults }} />}
        </Box>
      </Box>

      {nbFilteredResults > 0 && (
        <Box
          p={2}
          display={'grid'}
          gap={'1rem'}
          sx={{
            borderLeft: '1px solid var(--color-grayHeaderBorder)',
            borderRight: '1px solid var(--color-grayHeaderBorder)',
            backgroundColor: 'var(--color-lightgray)',
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          }}
        >
          {searchResults.results?.map((sr, sr_index) => (
            <SearchCard key={sr_index} result={sr} />
          ))}
        </Box>
      )}
      {/* 
      {nbFilteredResults > 0 && (
        <Box p={2} display={'grid'} columnGap={'1rem'} sx={{ backgroundColor: 'var(--color-lightgray)', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } }}>
          <Box>
            {leftResults.map((sr, sr_index) => (
              <SearchCard key={sr_index} result={sr} />
            ))}
          </Box>
          <Box>
            {rightResults.map((sr, sr_index) => (
              <SearchCard key={sr_index} result={sr} />
            ))}
          </Box>
        </Box>
      )} */}
    </Box>
  );
});

const ExportButton = ({ exportResults }: ExportButtonProps) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onButtonClick = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  return (
    <>
      <Button
        ref={buttonRef}
        type="submit"
        variant="contained"
        disableElevation
        sx={{
          '.MuiButton-startIcon svg': {
            fontSize: '14px',
          },
        }}
        startIcon={<ExportArrowIcon />}
        onClick={onButtonClick}
      >
        <FormattedMessage id="Export" defaultMessage="Export" />
      </Button>
      <Popover
        id={'popover-button'}
        open={filterOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
        slotProps={{ paper: { sx: { boxShadow: 'rgba(51, 51, 51, 0.08) 0px 12px 24px' } } }}
      >
        <Box mt={0.5} px={1} py={1} display="flex" rowGap={1} flexDirection={'column'} sx={{ backgroundColor: 'transparent', borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' }}>
          <ShadowedButton
            fullWidth
            variant="text"
            className="no-hover"
            onClick={() => {
              handleClose();
              exportResults('csv');
            }}
            sx={{ justifyContent: 'flex-start', whiteSpace: 'nowrap', '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
          >
            <ExportCSVIcon fontSize="inherit" sx={{ mr: 1, fontSize: '16px' }} />
            <FormattedMessage id="exportCSV" defaultMessage="Export as CSV" />
          </ShadowedButton>

          <ShadowedButton
            fullWidth
            variant="text"
            className="no-hover"
            onClick={() => {
              handleClose();
              exportResults('pdf');
            }}
            sx={{ justifyContent: 'flex-start', whiteSpace: 'nowrap', '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
          >
            <ExportPDFIcon fontSize="inherit" sx={{ mr: 1, fontSize: '16px' }} />
            <FormattedMessage id="exportPDF" defaultMessage="Export as PDF" />
          </ShadowedButton>
        </Box>
      </Popover>
    </>
  );
};

export const NameTooltip = ({ name }: { name?: MatchingNameType }) => {
  const nameKeys = ['fullName', 'givenName', 'fatherName', 'motherName', 'surname', 'type'];
  if (!name) return <></>;
  return (
    <Box display="grid" gap=".5rem" sx={{ gridTemplateColumns: 'repeat(2, auto)' }}>
      {nameKeys.map((nameStr) => {
        const nameType = nameStr as keyof MatchingNameType;
        if (!name[nameType]) return <Fragment key={nameType}></Fragment>;
        return (
          <Fragment key={nameType}>
            <Box sx={{ fontWeight: '600', whiteSpace: 'nowrap' }}>
              <FormattedMessage id={nameType} />:
            </Box>{' '}
            <Box>{name[nameType] ?? ''}</Box>
          </Fragment>
        );
      })}
      {name?.normalized && (
        <>
          <Box sx={{ fontWeight: '600', whiteSpace: 'nowrap' }}>Normalized name:</Box> <Box>{name?.normalized ?? ''}</Box>
        </>
      )}
    </Box>
  );
};
// fullNameTooltip = <FullNameTooltip content={[[intl.formatMessage({ id: "fullName", defaultMessage: "Full name" }), curName.fullName]]} />;
//     } else {
//       const content: [string, string][] = [];
//       if (curName.givenName) {
//         content.push([intl.formatMessage({ id: "givenName", defaultMessage: "Given name" }), curName.givenName]);
//       }
//       if (curName.fatherName) {
//         content.push([intl.formatMessage({ id: "fatherName", defaultMessage: "Father name" }), curName.fatherName]);
//       }
//       if (curName.motherName) {
//         content.push([intl.formatMessage({ id: "motherName", defaultMessage: "Mother name" }), curName.motherName]);
//       }
//       if (curName.surname) {
//         content.push([intl.formatMessage({ id: "surname", defaultMessage: "Surname" }), curName.surname]);
//       }
//       if (curName.type) {
//         content.push([intl.formatMessage({ id: "type", defaultMessage: "Type" }), curName.type]);
//       }

const SearchCard = (props: SearchCardType) => {
  const intl = useIntl();
  const { result } = props;
  const viewProfile = useViewProfile();

  let formatedDate = null;

  if (result.targetData[0].updated) {
    const updateDate = new Date(result.targetData[0].updated);

    const month = monthAbbreviated[updateDate.getMonth()];
    const day = updateDate.getDate();
    const year = updateDate.getFullYear();
    const hours = String(updateDate.getHours()).padStart(2, '0');
    const minutes = String(updateDate.getMinutes()).padStart(2, '0');
    const seconds = String(updateDate.getSeconds()).padStart(2, '0');
    formatedDate = `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
  }

  let cardContent = null;
  let headerIcon;
  let title;
  let titleTooltip;
  let viewProfileButton = false;
  let headerIconTooltip = result.targetData[0].entityType ?? '';
  const currentTargetData = result.targetData[0] ?? ({} as MatchingDataType);
  switch (result.targetData[0].entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      viewProfileButton = true;
      const avatarSx: React.CSSProperties = { fontSize: '1.85rem', color: 'var(--color-lightgray4)' };
      switch (result.targetData[0]?.sex?.toLowerCase()) {
        case 'm':
          headerIcon = <AvatarMaleIcon className="avatar" sx={avatarSx} />;
          headerIconTooltip += ', ' + intl.formatMessage({ id: 'male', defaultMessage: 'Male' });
          break;
        case 'f':
          headerIcon = <AvatarFemaleIcon className="avatar" sx={avatarSx} />;
          headerIconTooltip += ', ' + intl.formatMessage({ id: 'female', defaultMessage: 'Female' });
          break;
        default:
          headerIcon = <AvatarIcon className="avatar" sx={avatarSx} />;
      }

      // headerIcon = <AvatarIcon className="avatar" sx={} />;
      cardContent = <IndividualContent data={currentTargetData} />;
      title = formatMatchingName(result.matchingNames[0]);
      titleTooltip = <NameTooltip name={result.matchingNames[0]} />;
      break;
    case searchConstant.ENTITIES.ORGANIZATION:
      viewProfileButton = true;
      headerIcon = <OrganisationIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <OrganisationContent data={currentTargetData} />;
      title = formatMatchingName(result.matchingNames[0]);
      titleTooltip = <NameTooltip name={result.matchingNames[0]} />;
      break;
    case searchConstant.ENTITIES.VESSEL:
      viewProfileButton = true;
      headerIcon = <ShipIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <VesselContent data={currentTargetData} />;
      title = formatMatchingName(result.matchingNames[0]);
      titleTooltip = <NameTooltip name={result.matchingNames[0]} />;
      break;
    case searchConstant.ENTITIES.IDNUMBER:
      headerIcon = <IdIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentTargetData} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.IDNUMBER });
      break;
    case searchConstant.ENTITIES.LEI:
      headerIcon = <LeiIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentTargetData} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.LEI });
      break;
    case searchConstant.ENTITIES.BIC:
      headerIcon = <BicIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentTargetData} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.BIC });
      break;
    default:
      headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <UnknownContent data={currentTargetData} />;
      title = formatMatchingName(result.matchingNames[0]);
      titleTooltip = <NameTooltip name={result.matchingNames[0]} />;
  }

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      border={1}
      borderColor={'var(--color-grayHeaderBorder)'}
      borderRadius={'5px'}
      sx={{ minWidth: 0, backgroundColor: 'white', WebkitColumnBreakInside: 'avoid' }}
    >
      {/* header */}
      <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} px={3} py={2} columnGap={'1rem'} borderBottom={1} borderColor={'var(--color-grayHeaderBorder)'}>
        <Box flex={1} sx={{ minWidth: 0 }}>
          <Box display="flex" alignItems={'center'} columnGap={'0.5rem'} sx={{ width: '100%' }}>
            <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'center'} sx={{ minWidth: 0 }}>
              <ContentWithDarkTooltip tooltip={<Box sx={{ fontWeight: 600, textTransform: 'capitalize' }}>{headerIconTooltip}</Box>} placement="top" boxProps={{ display: 'flex' }}>
                {headerIcon}
              </ContentWithDarkTooltip>
              <ContentWithDarkTooltip tooltip={titleTooltip} placement="top" boxProps={{ sx: { minWidth: 0 } }}>
                <Typography flex={1} fontSize="1rem" fontWeight={600} color={'var(--color-darkgray)'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {title}
                </Typography>
              </ContentWithDarkTooltip>
            </Box>

            {/* <GaugeMeter value={result.score} additionalText={GetMatchType(result.matchType)} large /> */}
          </Box>
          {result.targetData.length > 0 && (
            <Box display="flex" alignItems={'center'} gap={'1rem'} mt={1} fontSize={'.9rem'} sx={{ flexFlow: 'row wrap' }}>
              {result.targetData[0].dataset?.category && <StatusTag tag_label={result.targetData[0].dataset.category} hideActive type="category" />}
              {result.targetData[0].dataset?.label && <StatusTag tag_label={result.targetData[0].dataset.label} hideActive type="label" />}
            </Box>
          )}
        </Box>
        <Box alignSelf={'flex-start'}>
          <GaugeMeter value={result.score} additionalText={GetMatchType(result.matchType)} large />
        </Box>
      </Box>

      {/* content */}
      <Box px={3} py={3} flex={1} flexDirection={'column'}>
        <Box fontSize=".875rem" display={'flex'} flexDirection={'column'} gap={2}>
          {cardContent}
        </Box>
      </Box>

      {/* footer */}
      <Box mt={'auto'} px={3} py={2} borderTop={1} borderColor={'var(--color-grayHeaderBorder)'} display="flex" alignItems={'center'}>
        <Box flex={1} display="flex" alignItems={'center'} fontSize={'.875rem'} sx={{ flexFlow: 'row wrap' }}>
          {formatedDate && (
            <>
              <ClockRotateLeftIcon sx={{ fontSize: 12, color: 'var(--color-gray2)', mr: 0.5 }} />
              <Typography fontSize={'inherit'} whiteSpace={'nowrap'} sx={{ color: 'var(--color-darkgray)' }}>
                <FormattedMessage id="UpdatedOn" defaultMessage="Updated on" />
              </Typography>
              &nbsp;
              <Typography fontSize={'inherit'} sx={{ color: 'var(--color-gray2)' }}>
                {formatedDate}
              </Typography>
            </>
          )}
        </Box>
        {/* {viewProfileButton && ()} */}
        <ShadowedButton
          onClick={() => {
            viewProfile(currentTargetData);
          }}
          sx={{ pointerEvents: viewProfileButton ? 'all' : 'none', opacity: viewProfileButton ? 1 : 0 }}
        >
          {/* viewProfile() */}
          <AvatarIcon color="inherit" fontSize="inherit" sx={{ mr: 1 }} />
          <FormattedMessage id="Viewprofile" defaultMessage="View profile" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

/* exports */
export default InvestigateSearch;
