/**
 * SavedSearchFilterProvider.ts
 * Provide saved search filter
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
/* types */
import { CreateSavedSearchQuery, SavedSearchResponse, SearchFilterType } from 'models/savedSearch';

interface SavedSearchFilterContextType {
  // statusQueried: boolean;
  loadingFilters: boolean;
  filters?: SearchFilterType[];
  listFilters?(abortController?: AbortController): void;
  deleteFilter?(filterId: number): void;
  createFilter?(query: CreateSavedSearchQuery): void;
}
/* elements */
const SavedSearchFilterContext = createContext<SavedSearchFilterContextType>({
  // statusQueried: false,
  loadingFilters: false,
  filters: undefined,
  listFilters: undefined,
  deleteFilter: undefined,
  createFilter: undefined,
});

const SavedSearchFilterProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, deleteAuthenticatedRequest, postAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<SearchFilterType[] | undefined>(undefined);

  const listFilterOngoing = useRef(false);
  const listFilters = useCallback(
    async (abortController?: AbortController) => {
      if (listFilterOngoing.current) return;

      // check permissions
      const allowed = checkPermissions('investigateAlerts', permissions);
      if (!allowed) return;
      if (!currentUserId) return;

      listFilterOngoing.current = true;
      setLoadingFilters(true);
      setFilters(undefined);
      try {
        const listUrl = URLConstants.savedSearchList + `${currentUserId}`;
        const results = (await getAuthenticatedRequest(listUrl, abortController ?? undefined)) as SavedSearchResponse;

        // // setStatusQueried(true);
        setLoadingFilters(false);
        setFilters(results.savedSearches ?? []);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingFilters(false);
        setFilters(undefined);
      }
      listFilterOngoing.current = false;
    },
    [permissions, getAuthenticatedRequest, currentUserId]
  );

  const deleteFilterOngoing = useRef(false);
  const deleteFilter = useCallback(
    async (filterId: number) => {
      if (deleteFilterOngoing.current) return;

      deleteFilterOngoing.current = true;
      // check permissions
      const allowed = checkPermissions('investigateAlerts', permissions);
      if (!allowed) return;
      if (!currentUserId) return;

      const currentFilters = filters;
      const filterToDeleteIndex = currentFilters?.findIndex((f) => f.id === filterId) ?? -1;
      if (filterToDeleteIndex < 0) return;

      try {
        setFilters(currentFilters?.slice(0, filterToDeleteIndex).concat(currentFilters?.slice(filterToDeleteIndex + 1)));

        const deleteUrl = URLConstants.savedSearchDelete + `${filterId}`;
        await deleteAuthenticatedRequest(deleteUrl);
      } catch (searchError: any) {
        setFilters(currentFilters);
        if (searchError?.code === 'ERR_CANCELED') return;
      }
      deleteFilterOngoing.current = false;
    },
    [permissions, deleteAuthenticatedRequest, currentUserId, filters]
  );

  // const createFilterOngoing = useRef(false);
  const createFilter = useCallback(
    async (query: CreateSavedSearchQuery) => {
      // if (createFilterOngoing.current) return;

      // createFilterOngoing.current = true;
      // check permissions
      const allowed = checkPermissions('investigateAlerts', permissions);
      if (!allowed) return;
      if (!currentUserId) return;

      try {
        const createUrl = URLConstants.savedSearchCreate;
        const create_results = (await postAuthenticatedRequest(createUrl, { savedSearch: query })) as SavedSearchResponse;
        setFilters((currentFilters) => {
          return [...(currentFilters ?? []), ...create_results.savedSearches];
        });

        // // trigger a list after create
        // const listUrl = URLConstants.savedSearchList + `${currentUser.id}`;
        // const results = (await getAuthenticatedRequest(listUrl)) as SavedSearchResponse;
        // setFilters(results.savedSearches ?? []);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;
      }
      // createFilterOngoing.current = false;
    },
    [permissions, postAuthenticatedRequest, currentUserId]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listFilters();
  }, [currentUserId, listFilters]);

  const outputValue = useMemo(
    (): SavedSearchFilterContextType => ({
      // statusQueried,
      loadingFilters,
      filters,
      listFilters,
      deleteFilter,
      createFilter,
    }),
    [loadingFilters, filters, listFilters, deleteFilter, createFilter]
  );
  return <SavedSearchFilterContext.Provider value={outputValue}>{children}</SavedSearchFilterContext.Provider>;
};

/* exports */
export { SavedSearchFilterContext, SavedSearchFilterProvider };
