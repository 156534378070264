/**
 * EditDataset.tsx
 * Edit dataset modal
 */
/* packages */
import { useState, useCallback, useMemo, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import Loader from 'components/Loader/Loader';

/* utilities */
import { AllowedDatasetSourceOfData, AllowedDatasetSourceOfDataDisplay, AllowedDatasetSourceOfDataType, Dataset, DatasetFolder } from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';
import { OrganizationUnit, UserType } from 'models/user';
import { TeamType } from 'models/teams';

/* types */
interface EditDatasetProps {
  dataset?: Dataset;
  setDataset(data: Partial<Dataset>, dataset?: Dataset): Promise<StatusPromiseResponse>;
  classifications?: string[];
  folders?: DatasetFolder[];
  allUsers?: UserType[];
  teams?: TeamType[];
  organizations?: OrganizationUnit[];
}

/* elements */
const SearchFiltersMemo = memo(SearchFilters);

const EditDataset = memo(({ dataset, setDataset, classifications, folders, organizations, allUsers, teams }: EditDatasetProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const [datasetParams, setDatasetParams] = useState<Partial<Dataset>>(dataset ?? {});

  const [creatingDataset, setCreatingDataset] = useState(false);

  const foldersId = useMemo(() => (folders ?? []).map((f) => f.id), [folders]);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDatasetParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);
  const handleChangeSelect = useCallback((event: SelectChangeEvent<unknown>) => {
    setDatasetParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);

  const handleChangeFolder = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setDatasetParams((params) => {
        const newParams = { ...params };
        let newFolder: DatasetFolder | undefined;
        if (event.target.value) {
          const newId = Number(event.target.value);
          newFolder = folders?.filter((folder) => folder.id === newId)[0];
        }
        if (newFolder) newParams.datasetFolder = newFolder;
        else delete newParams.datasetFolder;
        return newParams;
      });
    },
    [folders]
  );

  const organizatinonsRef = useRef<FilterButtonRefType | null>(null);

  const handleChangeOrganizations = useCallback((newOrganizations: OrganizationUnit[]) => {
    setDatasetParams((params) => ({ ...params, organizations: newOrganizations }));
  }, []);
 
  const organizationsButtons: SearchFiltersButtonType[] = useMemo(() => {
    // const filteredPerm = getFilteredPermissions(role?.permissions || []);
    const validOrganizationIds = organizations?.map((t) => t.id) ?? [];

    const filteredOrganizations = datasetParams.organizations?.filter((org) => validOrganizationIds.includes(org.id)) ?? [];

    return [
      {
        ref: organizatinonsRef,
        text: <FormattedMessage id="chooseOrganizations" defaultMessage="Selected organizations" />,
        inputName: 'organizations',
        fullWidth: true,
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
            list={organizations?.map((org) => ({ key: String(org.id), value: org.name })) ?? []}
          />
        ),
        initialValue: {
          nbValues: filteredOrganizations.length ?? 0,
          values: filteredOrganizations.map((org) => String(org.id)),
        },
      },
    ];
  }, [intl, organizations, datasetParams.organizations]);

  const onActivateOrgUnits = useCallback(() => {
    const selectedValues = organizatinonsRef.current?.getValue();
    if (selectedValues) {
      const newOrganizations = organizations?.filter((org) => (selectedValues.values || []).includes(String(org.id))) ?? [];
      handleChangeOrganizations(newOrganizations);
    }
  }, [organizations, handleChangeOrganizations]);

  const createOrUpdateDataset = useCallback(async () => {
    if (!formRef.current) return;

    blockModal?.(true);
    setCreatingDataset(true);

    const datasetParamsPayload = { ...datasetParams };

    if (datasetParamsPayload.classification && datasetParamsPayload.classification === '-1') delete datasetParamsPayload.classification;

    const result = await setDataset(datasetParamsPayload, dataset);

    setCreatingDataset(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = dataset
        ? intl.formatMessage({
            id: 'updateDatasetSuccess',
            defaultMessage: 'Dataset updated',
          })
        : intl.formatMessage({
            id: 'createDatasetSuccess',
            defaultMessage: 'New dataset created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = dataset
        ? intl.formatMessage({
            id: 'updateDatasetError',
            defaultMessage: 'An error occured while updating the dataset',
          })
        : intl.formatMessage({
            id: 'createDatasetError',
            defaultMessage: 'An error occured while creating the dataset',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, datasetParams, dataset, setDataset, addSnackbar, intl]);

  const setMessage = dataset ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const saveDisabled = creatingDataset || !datasetParams.label || !datasetParams.sourceOfDataType || !datasetParams?.organizations || datasetParams.organizations.length <= 0;

  let buttonTooltip: string = '';
  if (!datasetParams.organizations || datasetParams.organizations.length <= 0) {
    buttonTooltip = intl.formatMessage({ id: 'missingDatasetOrganization', defaultMessage: 'You must choose an organization' });
  }

  return (
    <Box width={'min(85vw, 550px)'}>
      <form ref={formRef}>
        <Box px={3} display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={'2rem'} rowGap={'1rem'}>
          <Box>
            <Typography className="modal-label">
              <FormattedMessage id="name" defaultMessage={'Dataset name'} />
            </Typography>
            <SearchText
              fullWidth
              name="label"
              value={datasetParams.label ?? ''}
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'chooseDatasetName', defaultMessage: 'e.g., "Alerts"' })}
              InputProps={MemoInputProps}
              disabled={creatingDataset}
            />
          </Box>

          <Box display="flex" flexDirection={'column'}>
            <Typography className="modal-label">
              <FormattedMessage id="sourceOfData" defaultMessage={'Stored objects'} />
            </Typography>
            <Box flex={1}>
              <SearchSelect
                name="sourceOfDataType"
                value={AllowedDatasetSourceOfData?.includes(datasetParams.sourceOfDataType as AllowedDatasetSourceOfDataType) ? datasetParams.sourceOfDataType : ''}
                sx={{ height: '100%' }}
                onChange={handleChangeSelect}
                fullWidth
                placeholder={intl.formatMessage({ id: 'selectSourceOfData', defaultMessage: 'Select stored objects' })}
                choices={(AllowedDatasetSourceOfDataDisplay )}
                MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
                disabled={creatingDataset || dataset?.sourceOfDataType !== undefined}
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection={'column'}>
            <Typography className="modal-label">
              <FormattedMessage id="organizations" defaultMessage={'Organizations'} />
            </Typography>
            <SearchFiltersMemo disabled={creatingDataset || !organizations} hideClearButton={true} onActivate={onActivateOrgUnits} searchFiltersButtons={organizationsButtons} />
          </Box>

          <Box display="flex" flexDirection={'column'}>
            <Typography className="modal-label">
              <FormattedMessage id="chooseFolder" defaultMessage={'Folder'} />
            </Typography>
            <Box flex={1}>
              <SearchSelect
                disabled={!folders}
                name="classification"
                value={foldersId?.includes(datasetParams.datasetFolder?.id as number) ? `${datasetParams.datasetFolder?.id}` : ''}
                sx={{ height: '100%' }}
                onChange={handleChangeFolder}
                fullWidth
                placeholder={intl.formatMessage({ id: 'selectFolder', defaultMessage: 'Select folder' })}
                choices={[{ key: '-1', value: intl.formatMessage({ id: 'noFolder', defaultMessage: 'No folder' }) }].concat((folders ?? []).map((t) => ({ key: `${t.id}`, value: t.label })))}
                MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
              />
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingDataset ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
                <Button type="submit" variant="contained" onClick={() => createOrUpdateDataset()} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                  {setMessage}
                </Button>
              </ContentWithDarkTooltip>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

export default EditDataset;
