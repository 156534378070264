/**
 * Investigate.tsx
 */
/* packages */
import { Outlet } from 'react-router-dom';

/* components */

/* contexts */
import { RolesProvider } from 'contextProviders/RolesProvider';
import { OrganizationsProvider } from 'contextProviders/OrganizationsProvider';
import { SchedulesProvider } from 'contextProviders/SchedulesProvider';
import { AlertScreeningsProvider } from 'contextProviders/AlertScreeningsProvider';
/* utilities */
import { CombineProviders } from 'utilities/CombineProviders';

/* elements */
const ManageProviders = CombineProviders([[RolesProvider], [OrganizationsProvider], [SchedulesProvider], [AlertScreeningsProvider]]);

const Manage = () => {
  return (
    <ManageProviders>
      <Outlet />
    </ManageProviders>
  );
};

export default Manage;
