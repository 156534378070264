/**
 * MoreContent.tsx
 * Display the more tab of view profile page
 */
/* packages */
import { memo, useContext } from 'react';

/* context */
import { PersonContext } from './PersonProvider';

/* components */
import Box from '@mui/material/Box';

/* utilities */
import { internalPageMaxWidth } from './PersonProfile';

/* types */
interface MoreContentProps {}

/* elements */

const MoreContent = memo((props: MoreContentProps) => {
  const { details } = useContext(PersonContext);

  if (!details || !details.additionalInformations) return <></>;

  return (
    <Box px={2} py={2} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'1fr'} columnGap={'1.5rem'} rowGap={'1.25rem'}>
        {details.additionalInformations?.map((info, index) => {
          return (
            <Box key={index} display="flex" gap={'.5rem'} minWidth={0}>
              <Box flex={1} minWidth={0} fontSize={'var(--fs-14)'} sx={{ color: 'var(--color-gray2)' }}>
                {info.type && (
                  <Box pb={0.5} sx={{ fontWeight: 500 }}>
                    {info.type}
                  </Box>
                )}
                {info.value && <Box>{info.value}</Box>}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

export default MoreContent;
