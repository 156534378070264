/**
 * GeneralContent.tsx
 * show the general content of a single alert page
 */
/* packages */
import { useState, useCallback, memo, useMemo, forwardRef, useRef, useEffect, useContext, ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/*  contexts */
import { AlertContext, AlertContextType } from './alertProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { useViewProfile } from 'components/PersonProfile/PersonProfile';

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType, FilterValueType } from 'components/SearchFilters/SearchFilters';

import FilterRange from 'components/SearchFilters/FilterRange';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import StatusTag from 'components/StatusTag/StatusTag';

import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import { NameTooltip } from 'pages/Investigate/InvestigateSearch';

import OrganisationContent from 'components/InvestigateSearch/OrganisationContent';
import IndividualContent from 'components/InvestigateSearch/IndividualContent';
import VesselContent from 'components/InvestigateSearch/VesselContent';
import IdentificationNumberContent from 'components/InvestigateSearch/IdentificationNumberContent';
import UnknownContent from 'components/InvestigateSearch/UnknownContent';

import { AvatarIcon } from 'icons/avatar/avatar';
import { AvatarMaleIcon } from 'icons/avatarMale/avatarMale';
import { AvatarFemaleIcon } from 'icons/avatarFemale/avatarFemale';
import { OrganisationIcon } from 'icons/organisation/organisation';
import { ShipIcon } from 'icons/ship/ship';
import { LeiIcon } from 'icons/lei/lei';
import { BicIcon } from 'icons/bic/bic';
import { IdIcon } from 'icons/id/id';
import { UnknownIcon } from 'icons/unknown/unknown';
import { ChevronIcon } from 'icons/chevron/chevron';

/* utilities */
import { searchConstant } from 'models/searchDatasets';
import { GetMatchType } from 'utilities/TermMatching';

/* types */
import { DateStringFormat } from 'models/utils';

import { formatMatchingName, MatchingDataType, MatchingDetails } from 'models/matchingData';
import { PersonType } from 'models/person';
import { Alert } from 'models/alerts';

interface SearchFormType {
  submitAction(): void;
}

interface TargetCardProps {
  ind: number;
  total: number;
  data: MatchingDetails;
  hasPrev: boolean;
  hasNext: boolean;
  updating: boolean;
  nextTarget?(): void;
  prevTarget?(): void;
  setTrueHit?: AlertContextType['setTrueHit'];
}

interface DisplayTargetsProps {
  alert?: Alert;
  targets: MatchingDetails[];
  currentTarget: number;
  setCurrentTarget: React.Dispatch<React.SetStateAction<number>>;
}

/* elements */
const FILTERNAMES = {
  score: 'score',
  trueHit: 'trueHit',
};

const GeneralContent = () => {
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { alert } = useContext(AlertContext);

  const [targets, setTargets] = useState<MatchingDetails[]>(alert?.target ?? []);
  const [currentTarget, setCurrentTarget] = useState<number>(0);

  const filterTarget = useCallback(() => {
    const formData = new FormData(searchFormRef.current ?? undefined);

    const scoreValues = JSON.parse(formData.get(FILTERNAMES.score) as string) as FilterValueType;
    const trueHitValues = JSON.parse(formData.get(FILTERNAMES.trueHit) as string) as FilterValueType;
    if ((!scoreValues || scoreValues.nbValues <= 0) && (!trueHitValues || trueHitValues.nbValues <= 0)) {
      setTargets(alert?.target ?? []);
      return;
    }

    const newTargets = (alert?.target ?? []).filter((target) => {
      if (scoreValues && scoreValues.nbValues > 0) {
        if (scoreValues.minValue !== undefined) {
          if (!target.matchScore || target.matchScore < scoreValues.minValue) return false;
        }
        if (scoreValues.maxValue !== undefined) {
          if (!target.matchScore || target.matchScore > scoreValues.maxValue) return false;
        }
      }
      if (trueHitValues && trueHitValues.nbValues > 0 && trueHitValues.booleanValue) {
        if (!target.trueHit) return false;
      }

      return true;
    });
    setTargets(newTargets);
  }, [alert?.target]);

  //   useEffect(() => {
  //     filterTarget();
  //   }, [filterTarget]);

  const submitAction = useCallback(() => {
    filterTarget();
  }, [filterTarget]);

  return (
    <Box>
      <SearchForm
        ref={searchFormRef}
        {...{
          submitAction,
        }}
      />

      <DisplayTargets {...{ alert, targets, currentTarget, setCurrentTarget }} />
    </Box>
  );
};

const SearchFiltersMemo = memo(SearchFilters);

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { submitAction } = props;

    // define required ref
    const scoredRef = useRef<FilterButtonRefType | null>(null);
    const trueHitRef = useRef<FilterButtonRefType | null>(null);
    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      const buttons: SearchFiltersButtonType[] = [
        {
          ref: scoredRef,
          text: <FormattedMessage id="score" defaultMessage="Score" />,
          inputName: FILTERNAMES.score,
          filterContent: <FilterRange title={intl.formatMessage({ id: 'score', defaultMessage: 'Score' })} minimum={0.6} maximum={1} step={0.01} percent={true} freeze="max" />,
          hideNbValues: true,
        },
        {
          ref: trueHitRef,
          text: <FormattedMessage id="trueHit" defaultMessage="True hit" />,
          inputName: FILTERNAMES.trueHit,
          trueFalseButton: true,
          hideNbValues: true,
        },
      ];

      return buttons;
    }, [intl]);

    return (
      <Box width={'100%'} display={'flex'} px={0} pb={2}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Typography sx={{ fontSize: 'var(--fs-14)', fontWeight: 500 }}>
              <FormattedMessage id="filterBy" defaultMessage={'Filter by'} />
            </Typography>
            {searchFiltersButtons.length > 0 && <SearchFiltersMemo disabled={false} onActivate={submitAction} {...{ searchFiltersButtons }} />}
          </Box>
        </form>
      </Box>
    );
  })
);

const nbMaxLabels = 3;

const DisplayTargets = memo(({ alert, targets, currentTarget, setCurrentTarget }: DisplayTargetsProps) => {
  useEffect(() => {
    // reset current target on change targets
    setCurrentTarget((curTarget) => (curTarget > targets.length - 1 ? 0 : curTarget));
  }, [targets, alert, setCurrentTarget]);

  const alertLabels = (alert?.target ?? [])
    .map((t) => t.watchListPersons?.label)
    .reduce((acc, cur) => {
      if (!cur) return acc;
      if (cur in acc) acc[cur] += 1;
      else acc[cur] = 1;
      return acc;
    }, {} as { [key: string]: number });

  const nbAlertLabels = Object.keys(alertLabels).length;

  const sortedAlertLabels = Object.entries(alertLabels).sort((a, b) => b[1] - a[1]);

  let matchedInfo;
  if (targets[currentTarget] && targets[currentTarget].matchType) {
    matchedInfo = GetMatchType(targets[currentTarget].matchType);
  }
  return (
    <Box>
      <Box
        px={2}
        py={2}
        display="flex"
        rowGap={'0.65rem'}
        sx={{ backgroundColor: 'var(--color-lightgray)', border: '1px solid var(--color-grayHeaderBorder)', flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Box flex={1} sx={{ minWidth: 0 }}>
          {alert && <SourceBox source={alert.source} />}
        </Box>

        <Box display="flex" alignItems={'center'} justifyContent={'center'} flexDirection={'column'} sx={{ width: 'calc(10ch + 1rem)' }}>
          {targets.length > 0 && targets[currentTarget] && (
            <>
              <Typography fontWeight={'medium'} mb={0.5}>
                <FormattedMessage id="match" defaultMessage={'Match'} />
              </Typography>
              <Box
                display="grid"
                // justifyContent={'center'}
                sx={{
                  placeItems: 'center',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Box sx={{ zIndex: 10 }}>
                  <GaugeMeter value={targets[currentTarget].matchScore ?? 0} backgroundColor={'white'} info={matchedInfo} />
                </Box>
                <Box
                  sx={{ position: 'absolute', height: '1px', zIndex: 0, width: '100%', top: '50%', left: 0, backgroundColor: 'var(--color-grayHeaderBorder)', display: { xs: 'none', md: 'block' } }}
                ></Box>
              </Box>
            </>
          )}
        </Box>

        <Box flex={1} display="flex" alignItems={'center'} justifyContent={'center'} sx={{ minWidth: 0 }}>
          {targets.length === 0 ? (
            <Box>
              <Typography fontWeight="medium" fontSize={'0.875rem'}>
                <FormattedMessage id="noMatchingTarget" defaultMessage={'No matching target'} />
              </Typography>
            </Box>
          ) : (
            <TargetsBox {...{ targets, currentTarget, setCurrentTarget }} />
          )}
        </Box>
      </Box>

      {/* footer */}
      <Box
        px={2}
        py={2}
        display="flex"
        alignItems={'center'}
        sx={{ fontSize: '0.875rem', flexFlow: 'row wrap', border: '1px solid var(--color-grayHeaderBorder)', borderTop: 'none', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
      >
        <Box display="flex" alignItems="center" gap={'1rem'}>
          <Typography color="darkgray" fontSize="inherit" fontWeight={'medium'}>
            {(alert?.target || []).length}{' '}
            <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
              {(alert?.target?.length ?? 0) > 1 ? <FormattedMessage id="hits" defaultMessage={'hits'} /> : <FormattedMessage id="hit" defaultMessage={'hit'} />}
            </Typography>
          </Typography>

          {sortedAlertLabels.slice(0, nbMaxLabels).map((l) => (
            <StatusTag key={l[0]} tag_label={l[0]} hideActive type="label" />
          ))}
          {nbAlertLabels > nbMaxLabels && (
            <Tooltip
              placement="right-start"
              slotProps={{
                tooltip: {
                  className: 'custom-scrollbar',
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid var(--color-grayHeaderBorder)',
                    maxHeight: 200,
                    overflow: 'auto',
                  },
                },
              }}
              title={
                <Box px={1} py={1} display="flex" flexDirection="column" rowGap={'.5rem'}>
                  {sortedAlertLabels.slice(nbMaxLabels).map((l) => (
                    <Box key={l[0]}>{l && <StatusTag tag_label={l[0]} hideActive={true} bg={'white'} textColor="#6E6C6B" />}</Box>
                  ))}
                </Box>
              }
            >
              <Box>
                <StatusTag tag_label={`+${nbAlertLabels - nbMaxLabels}`} hideActive={true} bg={'white'} textColor="#6E6C6B" />
              </Box>
            </Tooltip>

            // <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
            //   <FormattedMessage id="and" defaultMessage={'and'} /> {nbAlertLabels - nbMaxLabels} <FormattedMessage id="more" defaultMessage={'more'} />
            // </Typography>
          )}
        </Box>

        <Box p={1} display="flex" gap={'1rem'} alignItems={'center'} ml={'auto'} fontSize={'.75rem'}>
          <Typography color="darkgray" fontSize="inherit" fontWeight={'medium'} py={0.5}>
            <FormattedMessage id="createdOn" defaultMessage={'Created on'} />{' '}
            <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
              {dayjs(alert?.creationDate, DateStringFormat).format('MMM. D, YYYY HH:mm:ss')}
            </Typography>
          </Typography>

          <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

          <Typography color="darkgray" fontSize="inherit" fontWeight={'medium'} py={0.5}>
            <FormattedMessage id="updatedOn" defaultMessage={'Updated on'} />{' '}
            <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
              {dayjs(alert?.updatedDate, DateStringFormat).format('MMM. D, YYYY HH:mm:ss')}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

const GetCardContent = (source?: PersonType) => {
  const intl = useIntl();
  const currentResult = source as MatchingDataType;

  let cardContent = null;
  let headerIcon;
  let title;

  if (!source) return { cardContent, headerIcon, title };

  switch (source.entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      switch (currentResult.sex?.toLowerCase()) {
        case 'm':
          headerIcon = <AvatarMaleIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
          break;
        case 'f':
          headerIcon = <AvatarFemaleIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
          break;
        default:
          headerIcon = <AvatarIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      }
      cardContent = <IndividualContent data={currentResult} />;
      title = formatMatchingName(source.names?.[0]);
      break;
    case searchConstant.ENTITIES.ORGANIZATION:
      headerIcon = <OrganisationIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <OrganisationContent data={currentResult} />;
      title = formatMatchingName(source.names?.[0]);
      break;
    case searchConstant.ENTITIES.VESSEL:
      headerIcon = <ShipIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <VesselContent data={currentResult} />;
      title = formatMatchingName(source.names?.[0]);
      break;
    case searchConstant.ENTITIES.IDNUMBER:
      headerIcon = <IdIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentResult} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.IDNUMBER });
      break;
    case searchConstant.ENTITIES.LEI:
      headerIcon = <LeiIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentResult} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.LEI });
      break;
    case searchConstant.ENTITIES.BIC:
      headerIcon = <BicIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <IdentificationNumberContent data={currentResult} />;
      title = intl.formatMessage({ id: searchConstant.ENTITIES.BIC });
      break;
    default:
      headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: '1.85rem', color: 'var(--color-lightgray4)' }} />;
      cardContent = <UnknownContent data={currentResult} />;
      title = formatMatchingName(source.names?.[0]);
  }

  return { cardContent, headerIcon, title };
};

const SourceBox = memo(({ source }: { source?: PersonType }) => {
  const viewProfile = useViewProfile();
  const intl = useIntl();

  if (!source) return <></>;

  const { cardContent, headerIcon, title } = GetCardContent(source);

  let headerIconTooltipText = source?.entityType ?? '';
  const titleTooltip = source?.names ? <NameTooltip name={source?.names?.[0]} /> : null;
  switch (source?.entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      switch (source?.sex?.toLowerCase()) {
        case 'm':
          headerIconTooltipText += ', ' + intl.formatMessage({ id: 'male', defaultMessage: 'Male' });
          break;
        case 'f':
          headerIconTooltipText += ', ' + intl.formatMessage({ id: 'female', defaultMessage: 'Female' });
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  let headerIconTooltip: ReactNode = null;
  if (headerIconTooltipText) headerIconTooltip = <Box sx={{ fontWeight: 600, textTransform: 'capitalize' }}>{headerIconTooltipText}</Box>;

  return (
    <Box border={1} borderColor={'var(--color-grayHeaderBorder)'} borderRadius={'5px'} sx={{ backgroundColor: 'white', WebkitColumnBreakInside: 'avoid' }}>
      {/* header */}
      <Box px={2} py={2} borderBottom={1} borderColor={'var(--color-grayHeaderBorder)'}>
        <Box display="flex" alignItems={'center'}>
          <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'center'} sx={{ minWidth: 0 }}>
            <ContentWithDarkTooltip tooltip={headerIconTooltip} placement="top" boxProps={{ display: 'flex' }}>
              {headerIcon}
            </ContentWithDarkTooltip>
            <ContentWithDarkTooltip tooltip={titleTooltip} placement="top" boxProps={{ sx: { minWidth: 0 } }}>
              <Typography fontSize="1rem" fontWeight={600} color={'var(--color-darkgray)'} className="one-line" sx={{ flex: 1 }}>
                {title}
              </Typography>
            </ContentWithDarkTooltip>
          </Box>
        </Box>
        <Box display="flex" alignItems={'center'} gap={'1rem'} mt={1} fontSize={'.9rem'} sx={{ flexFlow: 'row wrap' }}>
          {source.dataset?.category && (
            <Typography fontSize="inherit" borderRadius={1.25} px={1} py={0.625} sx={{ backgroundColor: 'var(--color-lightgray2)', color: 'var(--color-gray2)' }}>
              {source.dataset.category}
            </Typography>
          )}
          {source.dataset?.label && <StatusTag tag_label={source.dataset.label} hideActive type="label" />}
        </Box>
      </Box>

      {/* content */}
      <Box px={2} py={3}>
        <Box fontSize=".875rem" display={'flex'} flexDirection={'column'} gap={2} sx={{}}>
          {cardContent}
        </Box>
      </Box>

      {/* footer */}
      <Box px={2} py={2} borderTop={1} borderColor={'var(--color-grayHeaderBorder)'} display="flex" alignItems={'center'}>
        <Box ml={'auto'}>
          <ShadowedButton
            onClick={() => {
              viewProfile(source);
            }}
          >
            <AvatarIcon color="inherit" fontSize="inherit" sx={{ mr: 1 }} />
            <FormattedMessage id="Viewprofile" defaultMessage="View profile" />
          </ShadowedButton>
        </Box>
      </Box>
    </Box>
  );
});

const TargetsBox = memo(({ targets, currentTarget, setCurrentTarget }: { targets: MatchingDetails[]; currentTarget: number; setCurrentTarget: React.Dispatch<React.SetStateAction<number>> }) => {
  const containerRef = useRef(null);

  const nextTarget = useCallback(() => {
    setCurrentTarget((curTarget) => {
      if (curTarget < targets.length - 1) {
        return curTarget + 1;
      }
      return curTarget;
    });
  }, [targets, setCurrentTarget]);

  const prevTarget = useCallback(() => {
    setCurrentTarget((curTarget) => {
      if (curTarget >= 1) {
        return curTarget - 1;
      }
      return curTarget;
    });
  }, [setCurrentTarget]);

  const { updatingTarget, setTrueHit } = useContext(AlertContext);

  return (
    <Box ref={containerRef} overflow={'hidden'} width={'100%'} minWidth={0}>
      <Box className="alert__target_card_container" sx={{ '--container-shift': currentTarget }}>
        {targets.map((target, targetInd) => {
          return (
            <TargetCard
              key={target.id ?? targetInd}
              ind={targetInd}
              total={targets.length ?? 0}
              data={target}
              hasPrev={targetInd > 0}
              hasNext={targetInd < targets.length - 1}
              updating={target.id ? updatingTarget?.[target.id] ?? false : false}
              {...{ nextTarget, prevTarget, setTrueHit }}
            />
          );
        })}
      </Box>
    </Box>
  );
});

const TargetCard = memo(({ ind, total, data, updating, nextTarget, prevTarget, hasPrev, hasNext, setTrueHit }: TargetCardProps) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();
  const viewProfile = useViewProfile();

  const [openTrueHit, setOpenTrueHit] = useState<boolean>(false);
  const trueHitbuttonRef = useRef(null);

  const handleClose = useCallback(() => {
    setOpenTrueHit(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenTrueHit(true);
  }, []);

  const defineTrueHit = useCallback(async () => {
    handleClose();
    if (!data.id) return;

    const newTrueHit = !data.trueHit;
    const res = await setTrueHit?.(data?.id, newTrueHit);

    if (res !== 0) {
      addSnackbar(
        intl.formatMessage({
          id: 'requestError',
          defaultMessage: 'An error occured for your request',
        }),
        'error'
      );
    }
  }, [handleClose, data, setTrueHit, addSnackbar, intl]);

  if (!data) return <></>;

  const personData = data.watchListPersons;
  const { cardContent, headerIcon, title } = GetCardContent(personData ?? { names: [], addresses: data.addresses });

  let headerIconTooltipText = personData?.entityType ?? '';
  const titleTooltip = personData?.names ? <NameTooltip name={personData?.names?.[0]} /> : null;
  switch (personData?.entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      switch (personData?.sex?.toLowerCase()) {
        case 'm':
          headerIconTooltipText += ', ' + intl.formatMessage({ id: 'male', defaultMessage: 'Male' });
          break;
        case 'f':
          headerIconTooltipText += ', ' + intl.formatMessage({ id: 'female', defaultMessage: 'Female' });
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  let headerIconTooltip: ReactNode = null;
  if (headerIconTooltipText) headerIconTooltip = <Box sx={{ fontWeight: 600, textTransform: 'capitalize' }}>{headerIconTooltipText}</Box>;

  return (
    <Box
      border={1}
      borderColor={'var(--color-grayHeaderBorder)'}
      borderRadius={'5px'}
      className="alert__target_card"
      sx={{ '--card-shift': ind, backgroundColor: 'white', WebkitColumnBreakInside: 'avoid' }}
    >
      {/* header */}
      <Box px={2} py={2} borderBottom={1} borderColor={'var(--color-grayHeaderBorder)'}>
        <Box display="flex" alignItems={'center'}>
          <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'center'} sx={{ minWidth: 0 }}>
            <ContentWithDarkTooltip tooltip={headerIconTooltip} placement="top" boxProps={{ display: 'flex' }}>
              {headerIcon}
            </ContentWithDarkTooltip>
            <ContentWithDarkTooltip tooltip={titleTooltip} placement="top" boxProps={{ sx: { minWidth: 0 } }}>
              <Typography fontSize="1rem" fontWeight={600} color={'var(--color-darkgray)'} className="one-line" sx={{ wordBreak: 'break-word' }}>
                {title}
              </Typography>
            </ContentWithDarkTooltip>
          </Box>
          <Box ml={1}>
            <ShadowedButton ref={trueHitbuttonRef} onClick={handleOpen} disabled={updating} sx={{ whiteSpace: 'nowrap' }}>
              {data.trueHit ? (
                <>                
                  <FormattedMessage id="trueHit" defaultMessage={'True hit'} />
                  <ChevronIcon sx={{ ml: 0.5, fontSize: '.7rem', rotate: '-90deg' }} />
                </>
              ) : (
                <>                  
                  <FormattedMessage id="falseHit" defaultMessage={'False hit'} />
                  <ChevronIcon sx={{ ml: 0.5, fontSize: '.7rem', rotate: '-90deg' }} />
                </>
              )}
            </ShadowedButton>
            <Popover
              // id={'popover-button'}
              // action={popoverRef}
              open={openTrueHit}
              anchorEl={trueHitbuttonRef.current}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              elevation={0}
            >
              <Box mt={0} px={0} py={1} sx={{ backgroundColor: 'transparent' }}>
                <ShadowedButton onClick={defineTrueHit}>
                  {data.trueHit ? (
                    <>                   
                      <FormattedMessage id="setFalseHit" defaultMessage={'Set as False hit'} />
                    </>
                  ) : (
                    <>                    
                      <FormattedMessage id="setTrueHit" defaultMessage={'Set as True hit'} />
                    </>
                  )}
                </ShadowedButton>
              </Box>
            </Popover>
          </Box>
        </Box>
        <Box display="flex" alignItems={'center'} gap={'1rem'} mt={1} fontSize={'.9rem'} sx={{ flexFlow: 'row wrap' }}>
          {personData?.category && (
            <Typography fontSize="inherit" borderRadius={1.25} px={1} py={0.625} sx={{ backgroundColor: 'var(--color-lightgray2)', color: 'var(--color-gray2)' }}>
              {personData?.category}
            </Typography>
          )}
          {personData?.label && <StatusTag tag_label={personData?.label} hideActive type="label" />}
        </Box>
      </Box>

      {/* content */}
      <Box px={2} py={3} flex={1}>
        <Box fontSize=".875rem" display={'flex'} flexDirection={'column'} gap={2} sx={{}}>
          {cardContent}
        </Box>
      </Box>

      {/* footer */}
      <Box px={2} py={2} borderTop={1} borderColor={'var(--color-grayHeaderBorder)'} display="flex" alignItems={'center'} gap={'1rem'} sx={{ flexFlow: 'row wrap' }}>
        <Box sx={{ fontSize: '0.875rem' }}>
          <Typography color="darkgray" fontSize="inherit" fontWeight={'medium'}>
            {ind + 1}{' '}
            <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
              of
            </Typography>{' '}
            {total}{' '}
            <Typography component="span" fontWeight="normal" fontSize="inherit" sx={{ color: 'var(--color-gray2)' }}>
              {(total ?? 0) > 1 ? <FormattedMessage id="hits" defaultMessage={'hits'} /> : <FormattedMessage id="hit" defaultMessage={'hit'} />}
            </Typography>
          </Typography>
        </Box>

        <Box ml={'auto'} display="flex" gap={'.5rem'} sx={{ flexFlow: 'row wrap' }}>
          <ShadowedButton disabled={!hasPrev} onClick={prevTarget}>
            <FormattedMessage id="previous" defaultMessage={'Previous'} />
          </ShadowedButton>

          <ShadowedButton
            onClick={() => {
              if (!personData) return;

              const personProfile = personData as PersonType;
              viewProfile(personProfile);
            }}
          >
            <AvatarIcon color="inherit" fontSize="inherit" sx={{ mr: 1 }} />
            <FormattedMessage id="Viewprofile" defaultMessage="View profile" />
          </ShadowedButton>
          <ShadowedButton disabled={!hasNext} onClick={nextTarget}>
            <FormattedMessage id="next" defaultMessage={'Next'} />
          </ShadowedButton>
        </Box>
      </Box>
    </Box>
  );
});

export default GeneralContent;
