/**
 * Combine multiple providers to avoid pyramids
 */

/* models & types */
type Provider = [React.FC<React.PropsWithChildren<{}>>, React.PropsWithoutRef<{}>?];
type Providers = Array<Provider>;

/* elements */
export const CombineProviders = (providers: Providers) =>
  providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) =>
      ({ children }: React.PropsWithChildren<{}>) =>
        (
          <AccumulatedProviders>
            <Provider {...props}>
              <>{children}</>
            </Provider>
          </AccumulatedProviders>
        ),
    ({ children }: React.PropsWithChildren<{}>) => <>{children}</>
  );
