/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';

/* types */
import { Notification, ListNotificationsResponse, ListNotificationsQuery, CountNotificationsResponse, MarkNotificationAsReadResponse } from 'models/notifications';

interface NotificationsContextType {
  // statusQueried: boolean;
  loadingNotifications: boolean;
  notifications?: Notification[];
  listNotifications?(abortController?: AbortController): void;
  nbNotifications?: number;
  setNotificationAsRead?(notification: Notification, abortController?: AbortController): void;
  newNotifications: boolean;
  setNotificationPanelOpen?(): void;
}
/* elements */
const NotificationsContext = createContext<NotificationsContextType>({
  // statusQueried: false,
  loadingNotifications: false,
  notifications: undefined,
  listNotifications: undefined,
  newNotifications: false,
});

const NBNOTIFICATIONBATCH = 20;

const NotificationsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { postAuthenticatedRequest, getAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  const listNotificationsOngoing = useRef(false);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined);
  const [nbNotifications, setNbNotifications] = useState<number | undefined>(undefined);
  const [notificationPage, setNotificationPage] = useState<number>(-1);

  const [newNotifications, setNewNotifications] = useState<boolean>(false);

  const listNotifications = useCallback(
    async (abortController?: AbortController) => {
      if (listNotificationsOngoing.current) return;
      if (!currentUserId) return;

      const currentPage = notificationPage + 1;
      // check permissions
      const allowed = checkPermissions('listNotifications', permissions);
      if (!allowed) return;

      listNotificationsOngoing.current = true;
      setLoadingNotifications(true);
      // setNotifications(undefined);

      try {
        const listNotificationsUrl = URLConstants.getNotifications; // + `/${currentUser.id}`;
        const notificationsPayload: ListNotificationsQuery = {
          pageNumber: currentPage,
          maxPerPage: NBNOTIFICATIONBATCH,
        };
        const [results, countResults] = (await Promise.all([
          postAuthenticatedRequest(listNotificationsUrl, notificationsPayload, abortController),
          postAuthenticatedRequest(URLConstants.countNotifications, {}, abortController),
        ])) as [ListNotificationsResponse, CountNotificationsResponse];

        // console.log('Notifications results', results);
        // const results: ListNotificationsResponse = {
        //   notifications: [] as Notification[],
        // };

        const notificationsResults = (results.notifications ?? []).map((notification) => {
          const isRead = (notification.notificationStatus ?? []).findIndex((status) => status.displayed) >= 0;
          notification.read = isRead;
          return notification;
        });

        if ('numberOfObjects' in countResults) {
          setNbNotifications(countResults.numberOfObjects);
        }

        // setStatusQueried(true);
        setLoadingNotifications(false);
        // if (currentPage === 0) {
        //   setNotifications(notificationsResults);
        // } else {
        //   setNotifications((currentNotifications) => [...(currentNotifications??[]), ...notificationsResults]);
        // }
        setNotifications((currentNotifications) => [...(currentNotifications ?? []), ...notificationsResults]);
        setNotificationPage(currentPage);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingNotifications(false);
        setNotifications(undefined);
        setNotificationPage(-1);
      }
      listNotificationsOngoing.current = false;
    },

    [permissions, postAuthenticatedRequest, currentUserId, notificationPage]
  );

  const setNotificationAsRead = useCallback(
    async (notification: Notification, abortController?: AbortController) => {
      if (!notification.id) return;

      const notificationId = notification.id;

      try {
        const readNotificationsUrl = URLConstants.markNotificationAsRead + `/${notificationId}`;

        const results = (await getAuthenticatedRequest(readNotificationsUrl, abortController)) as MarkNotificationAsReadResponse;

        if (results && results.operationResult) {
          // console.log(results);
          setNotifications((currentNotifications) => {
            if (!currentNotifications) return currentNotifications;

            const notifIndex = currentNotifications.findIndex((notif) => notif.id === notificationId);

            const newNotifications = [...currentNotifications];

            if (notifIndex >= 0) {
              newNotifications[notifIndex].read = true;
              return newNotifications;
            } else {
              return currentNotifications;
            }
          });
        }
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;
      }
    },
    [getAuthenticatedRequest]
  );

  const setNotificationOpen = useCallback(
    async (abortController?: AbortController) => {
      try {
        const setNotificationsUrl = URLConstants.setNotificationsOpened;

        (await getAuthenticatedRequest(setNotificationsUrl, abortController)) as { operationResults: boolean };
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;
      }
    },
    [getAuthenticatedRequest]
  );

  const setNotificationPanelOpen = useCallback(() => {
    setNewNotifications(false);
    setNotificationOpen();
  }, [setNotificationOpen]);

  const checkNewNotifications = useCallback(
    async (abortController?: AbortController) => {
      try {
        const checkNewNotificationsUrl = URLConstants.checkNewNotifications;

        const results = (await getAuthenticatedRequest(checkNewNotificationsUrl, abortController)) as { operationResult: boolean };

        setNewNotifications(results.operationResult ?? false);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;
      }
    },
    [getAuthenticatedRequest]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;

    if (notificationPage === -1) {
      listNotifications();
      checkNewNotifications();
    }
  }, [currentUserId, listNotifications, notificationPage, checkNewNotifications]);

  const outputValue = useMemo(
    (): NotificationsContextType => ({
      // statusQueried,
      loadingNotifications,
      notifications,
      listNotifications,
      nbNotifications,
      setNotificationAsRead,
      newNotifications,
      setNotificationPanelOpen,
    }),
    [loadingNotifications, notifications, listNotifications, nbNotifications, setNotificationAsRead, newNotifications, setNotificationPanelOpen]
  );
  return <NotificationsContext.Provider value={outputValue}>{children}</NotificationsContext.Provider>;
};

/* exports */
export { NotificationsContext, NotificationsProvider };
