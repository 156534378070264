/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterCheckboxesProps {
  title: string;
  minimum: number;
  maximum: number;
  step?: number;
  label?: string;
  percent?: boolean;
  currentValue?: { minValue?: number; maxValue?: number };
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  freeze?: 'min' | 'max';
}

/* elements */
const convertToPercent = (value: number): number => {
  return Math.round(value * 10000 + Number.EPSILON) / 100;
};
const FilterRange = ({ title, minimum, maximum, step, label, percent, currentValue, setNewFilterValue, triggerSetUpdate, freeze }: FilterCheckboxesProps) => {
  // const [selectedValue, setSelectedValue] = useState<number[]>([...(freeze === 'min' ? [] : [currentValue?.minValue ?? minimum]), ...(freeze === 'max' ? [] : [currentValue?.maxValue ?? maximum])]);
  const [selectedValue, setSelectedValue] = useState<number[]>([currentValue?.minValue ?? minimum, currentValue?.maxValue ?? maximum]);

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue) => {
      const hasValue = newValue[0] !== minimum || newValue[1] !== maximum;
      if (setNewFilterValue) setNewFilterValue({ nbValues: hasValue ? 1 : 0, minValue: newValue[0], maxValue: newValue[1] });
    },
    [setNewFilterValue, minimum, maximum]
  );

  useEffect(() => {
    if (triggerSetUpdate) applyFilterValue(selectedValue);
  }, [triggerSetUpdate, applyFilterValue, selectedValue]);

  const clearFilter = () => {
    const newValue: typeof selectedValue = [minimum, maximum];
    setSelectedValue(newValue);
    applyFilterValue(newValue);
  };

  // propagate on value change
  // const validateSelection = () => {
  //   applyFilterValue(selectedValue);
  //   // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues });
  // };

  const handleChange = useCallback(
    (_: Event, newValue: number | number[]) => {
      setSelectedValue((currentValue) => {
        if (freeze) {
          if (freeze === 'min') return [currentValue[0], newValue] as number[];
          else if (freeze === 'max') return [newValue, currentValue[1]] as number[];
        }
        return newValue as number[];
      });

      if (!freeze) {
        const setValue = newValue as number[];
        setMinTextValue((percent ? convertToPercent(setValue[0]) : setValue[0]).toString());
        setMaxTextValue((percent ? convertToPercent(setValue[1]) : setValue[1]).toString());
      } else {
        if (freeze === 'min') {
          setMaxTextValue((percent ? convertToPercent(newValue as number) : newValue).toString());
        } else if (freeze === 'max') {
          setMinTextValue((percent ? convertToPercent(newValue as number) : newValue).toString());
        }
      }
    },
    [freeze, percent]
  );

  // let textValue = `${selectedValue[0]}  -  ${selectedValue[1]}`;
  // if (percent) {
  //   textValue = `${Math.round(selectedValue[0] * 10000 + Number.EPSILON) / 100}%  -  ${Math.round(selectedValue[1] * 10000 + Number.EPSILON) / 100}%`;
  // }
  const [minTextValue, setMinTextValue] = useState<string>((percent ? convertToPercent(selectedValue[0]) : selectedValue[0]).toString());
  const [maxTextValue, setMaxTextValue] = useState<string>((percent ? convertToPercent(selectedValue[1]) : selectedValue[1]).toString());

  let sliderValue: number | number[] = selectedValue;
  if (freeze === 'min') sliderValue = selectedValue[1];
  else if (freeze === 'max') sliderValue = selectedValue[0];

  const setMinValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setMinTextValue(newValue);

      const numberValue = parseFloat(newValue) / (percent ? 100 : 1);
      if (numberValue >= minimum && numberValue <= maximum) setSelectedValue((currentValue) => [numberValue, currentValue[1]]);
    },
    [percent, minimum, maximum]
  );

  const setMaxValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setMaxTextValue(newValue);

      const numberValue = parseFloat(newValue) / (percent ? 100 : 1);
      if (numberValue >= minimum && numberValue <= maximum) setSelectedValue((currentValue) => [currentValue[0], numberValue]);
    },
    [percent, minimum, maximum]
  );

  const cleanMinMaxValue = useCallback(() => {
    let numberMinValue = parseFloat(minTextValue) / (percent ? 100 : 1);
    let numberMaxValue = parseFloat(maxTextValue) / (percent ? 100 : 1);

    if (isNaN(numberMinValue)) numberMinValue = minimum;
    if (isNaN(numberMaxValue)) numberMaxValue = maximum;

    if (numberMinValue > numberMaxValue) [numberMinValue, numberMaxValue] = [numberMaxValue, numberMinValue];

    if (numberMinValue < minimum || numberMinValue > maximum) numberMinValue = minimum;
    if (numberMaxValue < minimum || numberMaxValue > maximum) numberMaxValue = maximum;

    setSelectedValue([numberMinValue, numberMaxValue]);
    setMinTextValue((percent ? convertToPercent(numberMinValue) : numberMinValue).toString());
    setMaxTextValue((percent ? convertToPercent(numberMaxValue) : numberMaxValue).toString());
  }, [minTextValue, maxTextValue, percent, minimum, maximum]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <Box borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue[0] === minimum && selectedValue[1] === maximum}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton> */}

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        {/* <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={!selectedValue}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button> */}
      </Box>

      <Box px={2} py={1} display="flex" justifyContent={'center'} alignItems="center" gap=".5rem" color={'var(--color-gray2)'}>
        <TextField
          variant={freeze === 'min' ? 'standard' : 'outlined'}
          inputProps={{
            style: {
              paddingBlock: 8,
              fontSize: 'var(--fs-14)',
              color: 'var(--color-gray2)',
              textAlign: 'center',
              WebkitTextFillColor: 'unset',
              width: '5ch',
            },
            onKeyDown: handleKeyPress,
            min: percent ? minimum * 100 : minimum,
            max: percent ? maximum * 100 : maximum,
          }}
          InputProps={{
            ...(percent ? { endAdornment: <Box color="inherit">%</Box> } : {}),
            ...(freeze === 'min' ? { disableUnderline: true } : {}),
          }}
          type="number"
          disabled={freeze === 'min'}
          onChange={setMinValue}
          onBlur={cleanMinMaxValue}
          value={minTextValue}
          sx={{ '& .MuiInputBase-root': { borderRadius: '5px', color: 'inherit' } }}
        />
        -
        <TextField
          variant={freeze === 'max' ? 'standard' : 'outlined'}
          inputProps={{
            style: {
              paddingBlock: 8,
              fontSize: 'var(--fs-14)',
              color: 'var(--color-gray2)',
              textAlign: 'center',
              WebkitTextFillColor: 'unset',
              width: '5ch',
            },
            onKeyDown: handleKeyPress,
            min: percent ? minimum * 100 : minimum,
            max: percent ? maximum * 100 : maximum,
          }}
          InputProps={{
            ...(percent ? { endAdornment: '%' } : {}),
            ...(freeze === 'max' ? { disableUnderline: true } : {}),
          }}
          type="number"
          disabled={freeze === 'max'}
          value={maxTextValue}
          onChange={setMaxValue}
          onBlur={cleanMinMaxValue}
          sx={{ '& .MuiInputBase-root': { borderRadius: '5px', color: 'inherit' } }}
        />
      </Box>

      <Box px={4} py={1} display={'flex'} flexDirection={'column'}>
        <FormGroup>
          <Slider
            valueLabelFormat={(value) => {
              if (percent) return `${Math.round(value * 10000 + Number.EPSILON) / 100}%`;
              return value;
            }}
            track={freeze === 'max' ? 'inverted' : 'normal'}
            getAriaLabel={() => label ?? 'Range'}
            min={minimum}
            max={maximum}
            step={step ?? 1}
            value={sliderValue}
            onChange={handleChange}
            valueLabelDisplay="auto"
            sx={{
              height: '6px',
              borderRadius: '2px',
              ...(!freeze
                ? {
                    '& .MuiSlider-rail': {
                      // opacity: 0.5,
                      backgroundColor: 'var(--color-gray1)',
                    },
                  }
                : {}),
              ...(freeze
                ? {
                    '& .MuiSlider-track': {
                      // opacity: 0.5,
                      borderColor: 'var(--color-lightgray3)',
                      backgroundColor: 'var(--color-lightgray3)',
                      borderWidth: 0,
                    },
                  }
                : {}),
              '& .MuiSlider-valueLabel': {
                px: 2,
                py: 2,
                backgroundColor: 'var(--color-darkgray)',
                fontSize: 'var(--fs-12)',
              },
              '& .MuiSlider-thumb': {
                // borderRadius: '5px',
                height: '16px',
                width: '16px',
                borderRadius: '2px',
                backgroundColor: 'white',
                // border: '1px solid var(--color-grayHeaderBorder)',
                border: '1px solid #868585',
                boxShadow: 'none!important',

                '&:hover': {
                  backgroundColor: '#F5F6F8',
                },
              },
            }}
          />
        </FormGroup>
      </Box>

      <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          fullWidth
          className="no-hover"
          // variant="text"
          onClick={clearFilter}
          size="small"
          disabled={selectedValue[0] === minimum && selectedValue[1] === maximum}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#F8F6F6',
              color: '#C9C8C7',
              borderColor: '#F8F6F6',
              boxShadow: 'none',
            },
            // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
          }}
        >
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

export default FilterRange;
