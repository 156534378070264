import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const OrganisationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
        <path
          d="M1.5,0A1.5,1.5,0,0,0,0,1.5v13A1.5,1.5,0,0,0,1.5,16h3V13.5a1.5,1.5,0,0,1,3,0V16h3A1.5,1.5,0,0,0,12,14.5V1.5A1.5,1.5,0,0,0,10.5,0ZM2,7.5A.5.5,0,0,1,2.5,7h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,2,8.5ZM5.5,7h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,5,8.5v-1A.5.5,0,0,1,5.5,7ZM8,7.5A.5.5,0,0,1,8.5,7h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,8,8.5ZM2.5,3h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,2,4.5v-1A.5.5,0,0,1,2.5,3ZM5,3.5A.5.5,0,0,1,5.5,3h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,5,4.5ZM8.5,3h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,8,4.5v-1A.5.5,0,0,1,8.5,3Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
