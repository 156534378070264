import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const IdIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
        <path
          d="M8,1.5V2A1,1,0,0,1,7,3H5A1,1,0,0,1,4,2V1.5H2a.5.5,0,0,0-.5.5V14a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5ZM0,2A2,2,0,0,1,2,0h8a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2Zm5,8H7a2.5,2.5,0,0,1,2.5,2.5A.5.5,0,0,1,9,13H3a.5.5,0,0,1-.5-.5A2.5,2.5,0,0,1,5,10ZM4,7A2,2,0,1,1,6,9,2,2,0,0,1,4,7Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
