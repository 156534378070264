import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const HelpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path
          d="M16.313,9A7.313,7.313,0,1,0,9,16.313,7.313,7.313,0,0,0,16.313,9ZM0,9a9,9,0,1,1,9,9A9,9,0,0,1,0,9ZM5.97,5.811A1.972,1.972,0,0,1,7.826,4.5h2.05a2.22,2.22,0,0,1,1.1,4.145L9.844,9.3a.844.844,0,0,1-1.687-.014V8.807a.842.842,0,0,1,.425-.731l1.557-.893a.531.531,0,0,0-.264-.991H7.826a.277.277,0,0,0-.264.186l-.014.042a.843.843,0,0,1-1.589-.562l.014-.042Zm1.905,6.564A1.125,1.125,0,1,1,9,13.5,1.125,1.125,0,0,1,7.875,12.375Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
