import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MagnifyGlassSearch = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15.997" height="16" viewBox="0 0 15.997 16">
        <path
          d="M15.78,13.833l-3.115-3.115a.749.749,0,0,0-.531-.219h-.509A6.5,6.5,0,1,0,10.5,11.624v.509a.749.749,0,0,0,.219.531l3.115,3.115a.747.747,0,0,0,1.059,0l.884-.884A.753.753,0,0,0,15.78,13.833ZM6.5,10.5a4,4,0,1,1,4-4A4,4,0,0,1,6.5,10.5Z"
          fill="#cfd1d2"
        />
      </svg>
    </SvgIcon>
  );
};
