/**
 * PageModalProvider.tsx
 * Display a page as a modal
 */

/* packages */
import React, { useState, createContext, useContext, memo, useCallback, useMemo, useEffect, ReactNode } from 'react';
// import { useTheme } from '@mui/material/styles';

/* components */
// import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';

// import { CrossIcon } from 'icons/cross/cross';
/* types */
interface ModalValueType {
  close?: boolean;
  modalContent?: ReactNode;
  // onCloseHandler?(): any;
}
interface ModalValueWithKey extends ModalValueType {
  key: number;
}
type SetModalType = {
  togglePageModal?(modalValue: ModalValueType): void;
  closePageModal?(): void;
};

interface ModalContextType {
  modal?: ModalValueType;
  setModal: React.Dispatch<React.SetStateAction<ModalValueWithKey[]>>;
}

/* elements */
// contexts
// const ModalValueContext = createContext<ModalContextType>({ modal: undefined, setModal: () => {} });
const ModalSetContext = createContext<SetModalType>({});

// actual Modal
const modalPadding = 0; //25;
const PageModal = memo(({ modal }: { modal: ModalValueType }) => {
  // const theme = useTheme();
  // const { modal } = useContext(ModalValueContext);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    // if (modal.onCloseHandler) modal.onCloseHandler();
    setOpen(false);
  };

  useEffect(() => {
    if (modal?.close) setOpen(false);
    else setOpen(true);
  }, [modal]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '0px',
          border: '1px solid var(--color-grayHeaderBorder)',
          maxWidth: 'unset',
          maxHeight: 'unset',
          // width: `min(calc(100vw - 2 * ${modalPadding}px), 1200px)`, // 1500
          // height: `min(calc(100vh - 2* ${modalPadding}px), 900px)`, // 1125
          width: `calc(100vw - 2 * ${modalPadding}px)`, // full width
          height: `calc(100vh - 2* ${modalPadding}px)`, //full height
          margin: 0,
          // paddingInline: 'var(--main-inline-padding)',
        },
      }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0,0,0,0.7)' },
        },
      }}
    >
      {/* <Box display="flex" width="100%" gap={'1rem'} pl={2} pr={1} alignItems={'flex-start'}>
        <DialogTitle sx={{ m: 0, p: 2, flex: 1, fontWeight: 600, lineHeight: 1.2, fontSize: '1.25rem', color: 'var(--color-darkgray)' }} id="customized-dialog-title">
          {modal?.title ?? ''}&nbsp;
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            fontSize: '1rem',
            mt: '12px',
            color: 'var(--color-lightgray4)',
          }}
        >
          <CrossIcon fontSize="inherit" />
        </IconButton>
      </Box> */}
      <DialogContent dividers sx={{ paddingInline: 0, paddingBlock: 0, border: 'none' }}>
        {modal?.modalContent}
      </DialogContent>
    </Dialog>
  );
});

// modal providers
const ModalSetProvider = memo(
  ({
    setModals,
    children,
  }: React.PropsWithChildren<{
    setModals: ModalContextType['setModal'];
  }>) => {
    const togglePageModal = useCallback(
      (modalValue: ModalValueType) => {
        setModals((modals) => [...modals, { ...modalValue, close: false, key: Date.now() }]);
      },
      [setModals]
    );
    const closePageModal = useCallback(() => {
      // setModals((modalValue) => (!modalValue.close ? { ...modalValue, close: true } : modalValue));
      setModals((modals) => {
        // if (modals.length <= 0) return modals;
        // const lastModal = modals[modals.length - 1];
        // if (lastModal.onCloseHandler) lastModal.onCloseHandler();
        // return modals.slice(0, -1);

        return modals.length <= 0 ? modals : modals.slice(0, -1);
      });
    }, [setModals]);

    const setValue = useMemo(
      () => ({
        togglePageModal,
        closePageModal,
      }),
      [togglePageModal, closePageModal]
    );

    return <ModalSetContext.Provider value={setValue}>{children}</ModalSetContext.Provider>;
  }
);

const PageModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [modals, setModals] = useState<ModalValueWithKey[]>([]);

  // const modalContextValue = useMemo(
  //   () => ({
  //     modal,
  //     setModal,
  //   }),
  //   [modal, setModal]
  // );

  return (
    // <ModalValueContext.Provider value={modalContextValue}>
    <ModalSetProvider setModals={setModals}>
      {children}
      {modals.map((modal) => (
        <PageModal key={modal.key} modal={modal} />
      ))}
    </ModalSetProvider>
    // </ModalValueContext.Provider>
  );
};

// export hooks
const usePageModal = () => useContext(ModalSetContext);

// exports
export { PageModalProvider };
export { usePageModal };
