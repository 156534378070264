/**
 * config.ts
 * Define configuration file
 */
export interface ConfigType {
  apiUrl: string;
  screenaUrl: string;
  contactEmail: string;
  logoFull?: string;
  logoSquare?: string;
}

export const configUrl = '/config.json';
