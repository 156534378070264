/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
/* types */
import { WorkflowStatusType, WorkflowStatusResponse, DeleteWorkflowResponse, EditWorkflowResponse, WorfklowAction, WorkflowActionsResponse } from 'models/workflow';

interface WorkflowContextType {
  // statusQueried: boolean;
  loadingStatus: boolean;
  workflowStatus?: WorkflowStatusType[];
  listStatus?(abortController?: AbortController): void;
  addOrUpdateStatus?(data: Partial<WorkflowStatusType>, create?: boolean, abortController?: AbortController): Promise<WorkflowStatusType | undefined>;
  deleteStatus?(workflowStatusId: number, abortController?: AbortController): Promise<DeleteWorkflowResponse | undefined>;
  loadingWorkflowActions?: boolean;
  workflowActions?: WorfklowAction[];
  listWorkflowActions?(abortController?: AbortController): void;
}
/* elements */
const WorkflowContext = createContext<WorkflowContextType>({
  // statusQueried: false,
  loadingStatus: false,
  workflowStatus: undefined,
  listStatus: undefined,
});

const sortWorkflowStatus = (workflowStatus: WorkflowStatusType[]) => {
  return workflowStatus.sort((wsA, wsB) => {
    if (wsA.closeStatus === false) return -1;
    if (wsB.closeStatus === false) return 1;
    if (wsA.closeStatus) return -1;
    if (wsB.closeStatus) return 1;
    return 1;
  });
};

const WorkflowProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  const listStatusOngoing = useRef(false);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [workflowStatus, setWorkflowStatus] = useState<WorkflowStatusType[] | undefined>(undefined);

  const [loadingWorkflowActions, setLoadingWorkflowActions] = useState<boolean>(false);
  const [workflowActions, setWorkflowActions] = useState<WorfklowAction[] | undefined>(undefined);

  const listStatus = useCallback(
    async (abortController?: AbortController) => {
      if (listStatusOngoing.current) return;

      // check permissions
      const allowed = checkPermissions('listWorkflowStatus', permissions);
      if (!allowed) return;

      listStatusOngoing.current = true;
      setLoadingStatus(true);
      setWorkflowStatus(undefined);
      try {
        const listUrl = URLConstants.workflowList;
        const results = (await getAuthenticatedRequest(listUrl, abortController ?? undefined)) as WorkflowStatusResponse;

        const workflowStatus = sortWorkflowStatus(results.listOfWorkflowStatus ?? []);

        // setStatusQueried(true);
        setLoadingStatus(false);
        setWorkflowStatus(workflowStatus);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingStatus(false);
        setWorkflowStatus(undefined);
      }
      listStatusOngoing.current = false;
    },
    [permissions, getAuthenticatedRequest]
  );

  const listWorkflowActions = useCallback(
    async (abortController?: AbortController) => {
      // check permissions
      const allowed = checkPermissions('listWorkflowStatus', permissions);
      if (!allowed) return;

      setLoadingWorkflowActions(true);
      setWorkflowActions(undefined);
      try {
        const listActionsUrl = URLConstants.workflowActionsList;
        const results = (await getAuthenticatedRequest(listActionsUrl, abortController ?? undefined)) as WorkflowActionsResponse;

        if (results.listOfWorkflowActions) {
          setWorkflowActions(results.listOfWorkflowActions);
        }

        setLoadingWorkflowActions(false);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingWorkflowActions(false);
        setWorkflowActions(undefined);
      }
    },
    [permissions, getAuthenticatedRequest]
  );

  const addOrUpdateStatus = useCallback(
    async (data: Partial<WorkflowStatusType>, create = true, abortController?: AbortController): Promise<WorkflowStatusType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageScreeningsWorkflow', permissions);
      if (!allowed) return undefined;

      try {
        const editWorkflowStatusUrl = create ? URLConstants.createWorkflowStatus : URLConstants.updateWorkflowStatus;
        const results = (await postAuthenticatedRequest(editWorkflowStatusUrl, { workflowStatus: data }, abortController ?? undefined)) as EditWorkflowResponse;

        const updatedWorkflowStatus = results.workflowStatus;

        setWorkflowStatus((currentStatus) => {
          if (!updatedWorkflowStatus) return currentStatus;
          if (!currentStatus) return [updatedWorkflowStatus];

          let newWorkflowStatus = [...currentStatus];
          const statusIndex = currentStatus?.findIndex((r) => r.id === updatedWorkflowStatus.id);
          if (statusIndex >= 0) {
            newWorkflowStatus[statusIndex] = updatedWorkflowStatus;
          } else {
            newWorkflowStatus.push(updatedWorkflowStatus);
            // sorting
            newWorkflowStatus = sortWorkflowStatus(newWorkflowStatus);
          }
          return newWorkflowStatus;
        });

        return updatedWorkflowStatus;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [postAuthenticatedRequest, permissions]
  );

  const deleteStatus = useCallback(
    async (workflowStatusId: number, abortController?: AbortController): Promise<DeleteWorkflowResponse | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const deleteWorkflowUrl = URLConstants.deleteWorkflowStatus + `${workflowStatusId}`;
        const results = (await deleteAuthenticatedRequest(deleteWorkflowUrl, abortController ?? undefined)) as DeleteWorkflowResponse;

        if (results.operationResult) {
          setWorkflowStatus((currentStatus) => {
            return currentStatus?.filter((t) => t.id !== workflowStatusId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [deleteAuthenticatedRequest]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listStatus();
  }, [currentUserId, listStatus]);

  const outputValue = useMemo(
    (): WorkflowContextType => ({
      // statusQueried,
      loadingStatus,
      workflowStatus,
      listStatus,
      addOrUpdateStatus,
      deleteStatus,
      loadingWorkflowActions,
      workflowActions,
      listWorkflowActions,
    }),
    [loadingStatus, workflowStatus, listStatus, addOrUpdateStatus, deleteStatus, loadingWorkflowActions, workflowActions, listWorkflowActions]
  );
  return <WorkflowContext.Provider value={outputValue}>{children}</WorkflowContext.Provider>;
};

/* exports */
export { WorkflowContext, WorkflowProvider };
