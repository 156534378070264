/**
 * ScrollToTop.tsx
 * Add a scroll to top element
 */
/* packages */
import { useCallback, useEffect, useRef } from 'react';

/* components */
import Box from '@mui/material/Box';
import { ChevronIcon } from 'icons/chevron/chevron';

/* styles */
import './style.scss';

/* elements */
const scrollLimit = 300;
const ScrollToTop = () => {
  const scroller = useRef<HTMLElement>();

  useEffect(() => {
    if (!scroller.current) return;

    const registerScrolling = () => {
      const scrollDistance = window.scrollY || (document.documentElement || document.body.parentNode || document.body).scrollTop;

      if (scrollDistance > scrollLimit && !scroller.current?.classList.contains('visible')) scroller.current?.classList.add('visible');
      else if (scrollDistance < scrollLimit && scroller.current?.classList.contains('visible')) scroller.current?.classList.remove('visible');
    };
    window.addEventListener('scroll', registerScrolling);
    return () => {
      window.removeEventListener('scroll', registerScrolling);
    };
  }, [scroller]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box ref={scroller} id="scrollToTop" position={'fixed'} p={'.625rem'} fontSize={'1.25rem'} onClick={scrollToTop}>
      <ChevronIcon />
    </Box>
  );
};

export default ScrollToTop;
