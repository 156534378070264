/**
 * GetUser.tsx
 * get a user display name with avatar
 */

/* packages */
import { ReactNode } from 'react';

/* components */
import Box from '@mui/material/Box';
import { AvatarIcon } from 'icons/avatar/avatar';
import { TeamIcon } from 'icons/team/team';
// import { PokeIcon } from 'icons/poke/poke';

/* types */
import { UserType, prependBase64Photo } from 'models/user';
import { TeamType } from 'models/teams';
import DisplayBrandLogo from 'utilities/DisplayBrandLogo';

/* elements */
export const GetUser = (userName: string | number, allUsers?: UserType[], withAvatar: boolean = true, avatarSize: string = '24px'): ReactNode | string | number => {
  const user = allUsers?.filter((u) => (typeof userName === 'number' ? u.id === userName : u.userName === userName))[0];

  const initials = (user?.fullName ?? `${userName}`)
    ?.split(' ')
    .slice(0, 2)
    .map((s) => s[0] && s[0].toUpperCase() )
    .join('');

  let defaultIcon = <AvatarIcon className="avatar" sx={{ fontSize: 'inherit', color: 'var(--color-lightgray4)' }} />;
  if (typeof userName === 'string' && userName.toLowerCase() === 'system') {
    // defaultIcon = <PokeIcon className="avatar" sx={{ fontSize: 'inherit', color: 'var(--color-lightgray4)' }} />;
    defaultIcon = <DisplayBrandLogo className="circled-border" />;
  } else if (initials) {
    defaultIcon = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: 'var(--color-azure)', color: 'white', aspectRatio: 1, borderRadius: '50%', width: avatarSize, fontSize: `calc(0.4 * ${avatarSize})` }}
      >
        {initials}
      </Box>
    );
  }

  if (withAvatar) {
    return (
      <Box display="inline-flex" alignItems={'center'}>
        <Box textAlign={'left'} mr={1} display={'flex'} alignItems={'center'} sx={{ fontSize: avatarSize, width: avatarSize }}>
          {user?.photo ? <img src={prependBase64Photo + user.photo} alt="" className="user-avatar" /> : <>{defaultIcon}</>}
        </Box>
        <Box className="username" flex={1} textAlign={'left'} maxWidth={200} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} sx={{ textTransform: 'none' }}>
          {user?.fullName ?? user?.userName ?? userName}
        </Box>
      </Box>
    );
  }

  return user?.fullName ?? user?.userName ?? userName;
};

export const GetAvatar = (userName: string | number, allUsers?: UserType[], withUsername = false, initialsFontSize: string = ''): ReactNode | [ReactNode, string] => {
  const user = allUsers?.filter((u) => (typeof userName === 'number' ? u.id === userName : u.userName === userName))[0];

  const initials = (user?.fullName ?? `${userName}`)
    ?.split(' ')
    .slice(0, 2)
    .map((s) => s[0] && s[0].toUpperCase())
    .join('');

  let content;
  if (user && user.photo) content = <img src={prependBase64Photo + user.photo} alt="" className="user-avatar" />;
  else if (`${userName}`.toLowerCase() === 'system') {
    // content = <PokeIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />;
    content = <DisplayBrandLogo className="circled-border" />;
  } else if (initials)
    content = (
      <Box
        className="avatar"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: 'var(--color-azure)', color: 'white', aspectRatio: 1, borderRadius: '50%', fontSize: initialsFontSize ?? 'inherit' }}
      >
        {initials}
      </Box>
    );
  else content = <AvatarIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />;

  if (withUsername) {
    return [content, user?.fullName ?? user?.userName ?? userName];
  }
  return content;
};

export const GetTeam = (teamName: string, allTeams?: TeamType[], withAvatar = true, iconWidth = '24px'): ReactNode => {
  const team = allTeams?.filter((t) => t.name === teamName)[0];

  const initials = (team?.name ?? teamName)
    .split(' ')
    .slice(0, 2)
    .map((s) => s[0].toUpperCase())
    .join('');

  if (withAvatar) {
    return (
      <Box display="inline-flex" alignItems={'center'}>
        <Box textAlign={'left'} mr={1} display={'flex'} alignItems={'center'}>
          {initials ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ backgroundColor: 'var(--color-gray2)', color: 'white', aspectRatio: 1, borderRadius: '50%', width: iconWidth, fontSize: `calc(0.4 * ${iconWidth})` }}
            >
              {initials}
            </Box>
          ) : (
            <TeamIcon className="avatar" sx={{ fontSize: '1.5rem', color: 'var(--color-lightgray4)' }} />
          )}
        </Box>
        <Box className="username" flex={1} textAlign={'left'} maxWidth={200} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} sx={{ textTransform: 'none' }}>
          {team?.name ?? teamName}
        </Box>
      </Box>
    );
  }
  return team?.name ?? teamName;
};
