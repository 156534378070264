/**
 * audit.ts
 * Define audit
 */

import { ApiResponse } from './axiosRequest';
import { DateString } from './utils';

export interface Audit {
  id?: number;

  created?: DateString;
  action?: string;
  userName?: string;
  createdBy?: string;

  error?: string;

  entityDescription?: string;

  started: DateString;
  ended: DateString;

  objectId?: string;
  objectType?: string;

  totalRecords?: number;
  totalRecordsProcessed?: number;

  status: 'Failed' | 'Completed' | 'In progress';
}

export interface SearchAuditsPayload {
  pageNumber: number;
  maxPerPage: number;

  sortField?: string;
  sortOrder?: 'asc' | 'desc';

  keyword?: string;
  date1?: string;
  date2?: string;

  status?: 'failed' | 'completed'[];
}
export interface SearchAuditsResponse extends ApiResponse {
  audits: Audit[];
  numberOfObjects: number;
}
export interface SearchAuditsCountResponse extends ApiResponse {
  numberOfObjects: number;
}
export const ActiveStatusColors: { [key in Audit['status']]: string } = {
  Completed: 'var(--color-azure)',
  Failed: 'var(--color-fushia)',
  'In progress': 'var(--color-orange)',
};
