/**
 * PageTitle.tsx
 * Display the page title
 */
/* packages */
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

const styling = {
  color: 'var(--color-gray2)',
  fontSize: '0.6875rem',
  lineHeight: '1.5',
};
const FooterLink = styled(Link)(() => styling);
const FooterTypography = styled(Typography)(() => styling);

const Footer = () => {
  return (
    <Box display="flex" gap={1} px={'var(--main-inline-padding)'} py={4} alignItems={'center'} justifyContent={'center'}>
      <FooterTypography color={'var(--color-gray2)'}>
        <FormattedMessage id="footerCopy" defaultMessage="Copyright &copy; Screena 2023" />
      </FooterTypography>
      <Divider orientation="vertical" flexItem />
      <FooterLink target="_blank" rel="noreferrer" href={process.env.REACT_APP_TOS_URL}>
        <FormattedMessage id="footerTerms" defaultMessage="Terms & conditions" />
      </FooterLink>
      <Divider orientation="vertical" flexItem />
      <FooterLink target="_blank" rel="noreferrer" href={process.env.REACT_APP_HELP_URL}>
        <FormattedMessage id="footerHelp" defaultMessage="Do you need help ?" />
      </FooterLink>
    </Box>
  );
};

export default Footer;
