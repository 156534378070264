/**
 * PropertiesContent.tsx
 * Display the properties tab of view profile page
 */
/* packages */
import { memo, useContext, useState, useCallback, PropsWithChildren, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

/* context */
import { CountriesContext } from 'contextProviders/CountriesProvider';
import { PersonContext, parsePeriodDate } from './PersonProvider';

/* components */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { TriangleIcon } from 'icons/triangle/triangle';
import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

/* utilities */
import { MatchingIdDoc, MatchingLocation, MatchingNameType } from 'models/matchingData';
import { GetGender } from 'utilities/TermMatching';
import { UnknownMessage, Flag } from 'components/InvestigateSearch/utils';
import { getCountryFromIso } from 'components/InvestigateSearch/IndividualContent';
import { internalPageMaxWidth } from './PersonProfile';

/* types */
import { searchConstant } from 'models/searchDatasets';
import { FormatDayJSDate } from 'models/utils';

interface PropertiesContentProps {
  // details?: PersonType;
}

/* elements */
const sameElementGap = '.25rem';
const CategoryTitle = styled(Typography)(({ theme }) => ({
  color: 'var(--color-gray2)',
  fontSize: 'var(--fs-14)',
  // '--title-min-width': '15ch',
  // '--title-padding': '1.75rem',

  // paddingLeft: 'var(--title-padding)',
  // minWidth: 'calc(var(--title-min-width) + var(--title-padding))',

  // '&.internal': {
  //   '--title-min-width': '20ch',
  //   '--title-padding': '0px',
  // },
}));
const categoryStyle = {
  color: 'var(--color-darkgray)',
  fontWeight: 500,
  fontSize: 'var(--fs-14)',
};
const CategoryEntry = styled(Typography)(({ theme }) => categoryStyle);
const BoxCategoryEntry = styled(Box)(({ theme }) => categoryStyle);
const PropertiesBox = styled(Box)(({ theme }) => ({
  '& > div:not(:last-of-type)': { marginBottom: '1rem' },
}));
const PropertiesElements = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(20ch, auto) 1fr',
  rowGap: sameElementGap,
  '&.one-column': {
    gridTemplateColumns: '1fr',
  },
}));

const UnkownBox = () => {
  return (
    <BoxCategoryEntry>
      <UnknownMessage color={'var(--color-darkgray)'} />
    </BoxCategoryEntry>
  );
};

const DisplayName = ({ name }: { name: MatchingNameType }) => {
  return (
    // <Box display="grid" gridTemplateColumns={'auto 1fr'} gap={'.25rem'}>
    <PropertiesElements>
      {/* fullName */}
      {name.fullName && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="fullName" defaultMessage={'Full name'} />:
          </CategoryTitle>
          <CategoryEntry>{name.fullName}</CategoryEntry>
        </>
      )}
      {/* normalized */}
      {name.normalized && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="normalized" defaultMessage={'Normalized'} />:
          </CategoryTitle>
          <CategoryEntry>{name.normalized}</CategoryEntry>
        </>
      )}
      {/* givenName */}
      {name.givenName && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="givenName" defaultMessage={'Given name'} />:
          </CategoryTitle>
          <CategoryEntry>{name.givenName}</CategoryEntry>
        </>
      )}
      {/* givenName */}
      {name.surname && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="surname" defaultMessage={'Surname'} />:
          </CategoryTitle>
          <CategoryEntry>{name.surname}</CategoryEntry>
        </>
      )}
      {/* givenName */}
      {name.fatherName && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="fatherName" defaultMessage={'Father name'} />:
          </CategoryTitle>
          <CategoryEntry>{name.fatherName}</CategoryEntry>
        </>
      )}
      {/* givenName */}
      {name.motherName && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="motherName" defaultMessage={'Mother name'} />:
          </CategoryTitle>
          <CategoryEntry>{name.motherName}</CategoryEntry>
        </>
      )}
      {/* type */}
      {name.type && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="type" defaultMessage={'Type'} />:
          </CategoryTitle>
          <CategoryEntry>{name.type}</CategoryEntry>
        </>
      )}
    </PropertiesElements>
  );
};
const DisplayAddress = ({ address }: { address: MatchingLocation }) => {
  const { countries } = useContext(CountriesContext);
  let countryName = null;
  if (address.country) {
    countryName = getCountryFromIso(address.country, countries);
  }

  return (
    <PropertiesElements>
      {/* street */}
      {address.street && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="street" defaultMessage={'Street'} />:
          </CategoryTitle>
          <CategoryEntry>{address.street}</CategoryEntry>
        </>
      )}
      {/* city */}
      {address.poBox && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="poBox" defaultMessage={'PO box'} />:
          </CategoryTitle>
          <CategoryEntry>{address.poBox}</CategoryEntry>
        </>
      )}
      {/* city */}
      {address.city && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="city" defaultMessage={'City'} />:
          </CategoryTitle>
          <CategoryEntry>{address.city}</CategoryEntry>
        </>
      )}
      {/* zipCode */}
      {address.zipCode && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="zipCode" defaultMessage={'ZIP code'} />:
          </CategoryTitle>
          <CategoryEntry>{address.zipCode}</CategoryEntry>
        </>
      )}
      {/* state */}
      {address.state && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="state" defaultMessage={'State'} />:
          </CategoryTitle>
          <CategoryEntry>{address.state}</CategoryEntry>
        </>
      )}
      {/* country */}
      {address.country && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          <Box>
            {countryName ? (
              <Box flex={1} display="flex" alignItems={'center'} columnGap={'5px'}>
                <Flag country={address.country} />
                <Box className="capitalize">
                  <CategoryEntry>{countryName}</CategoryEntry>
                </Box>
              </Box>
            ) : (
              <CategoryEntry>{address.country}</CategoryEntry>
            )}
          </Box>
        </>
      )}
    </PropertiesElements>
  );
};
const DisplayIdDoc = ({ doc }: { doc: MatchingIdDoc }) => {
  const { countries } = useContext(CountriesContext);
  let countryName = null;
  let issuingCountryName = null;

  if (doc.country) {
    countryName = getCountryFromIso(doc.country, countries);
  }
  if (doc.issuingCountry) {
    issuingCountryName = getCountryFromIso(doc.issuingCountry, countries);
  }

  const doiDate = doc.dateOfIssue ?? '';
  const parsedDOI = parsePeriodDate(doiDate);
  const dateOfIssue = parsedDOI !== null ? parsedDOI[0].format(FormatDayJSDate) : null;

  const doeDate = doc.dateOfExpiry ?? '';
  const parsedDOE = parsePeriodDate(doeDate);
  const dateOfExpiry = parsedDOE !== null ? parsedDOE[0].format(FormatDayJSDate) : null;

  return (
    <PropertiesElements>
      {/* type */}
      {doc.type && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="type" defaultMessage={'Type'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.type}</CategoryEntry>
        </>
      )}
      {/* description */}
      {doc.description && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="description" defaultMessage={'Description'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.description}</CategoryEntry>
        </>
      )}
      {/* city */}
      {doc.number && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="number" defaultMessage={'Number'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.number}</CategoryEntry>
        </>
      )}
      {/* authority */}
      {doc.authority && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="authority" defaultMessage={'Authority'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.authority}</CategoryEntry>
        </>
      )}
      {/* dateOfIssue */}
      {dateOfIssue && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="dateOfIssue" defaultMessage={'Date of issue'} />:
          </CategoryTitle>
          <CategoryEntry>{getParsedDate(dateOfIssue)}</CategoryEntry>
        </>
      )}
      {/* dateOfExpiry */}
      {dateOfExpiry && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="dateOfExpiry" defaultMessage={'Date of expiry'} />:
          </CategoryTitle>
          <CategoryEntry>{getParsedDate(doc.dateOfExpiry)}</CategoryEntry>
        </>
      )}

      {/* country */}
      {doc.country && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          <Box>
            {countryName ? (
              <Box flex={1} display="flex" alignItems={'center'} columnGap={'5px'}>
                <Flag country={doc.country} />
                <Box className="capitalize">
                  <CategoryEntry>{countryName}</CategoryEntry>
                </Box>
              </Box>
            ) : (
              <CategoryEntry>{doc.country}</CategoryEntry>
            )}
          </Box>
        </>
      )}

      {/* issuingCountry */}
      {doc.issuingCountry && (
        <>
          <CategoryTitle className="internal">
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          <Box>
            {issuingCountryName ? (
              <Box flex={1} display="flex" alignItems={'center'} columnGap={'5px'}>
                <Flag country={doc.issuingCountry} />
                <Box className="capitalize">
                  <CategoryEntry>{issuingCountryName}</CategoryEntry>
                </Box>
              </Box>
            ) : (
              <CategoryEntry>{doc.issuingCountry}</CategoryEntry>
            )}
          </Box>
        </>
      )}
    </PropertiesElements>
  );
};

const Collapser = memo(({ nbItems, titleID, children }: PropsWithChildren<{ nbItems: number; titleID: string }>) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const expand = useCallback(() => {
    setExpanded((ex) => !ex);
  }, []);

  const mayCollapse = nbItems > 2;
  const collapsedSize = 0; //'calc( 1.5 * var(--fs-14))'
  return (
    <>
      <Box>
        {/* arrow */}
        {mayCollapse && (
          <IconButton onClick={expand} sx={{ p: '4px', borderRadius: '5px', '&:hover': { backgroundColor: 'var(--color-hover-button)' } }}>
            <TriangleIcon sx={{ fontSize: 'var(--fs-12)', color: 'inherit', rotate: expanded ? '90deg' : '0deg' }} />
          </IconButton>
        )}
      </Box>

      <CategoryTitle pt={mayCollapse ? '1.5px' : 0}>
        <FormattedMessage id={nbItems > 1 ? `${titleID}_plural` : titleID} />:
      </CategoryTitle>

      <Collapse in={expanded} collapsedSize={collapsedSize}>
        {children}
      </Collapse>
    </>
  );
});

const getParsedDate = (date: string | undefined) => {
  const strDate = date ?? '';
  const parsedDate = parsePeriodDate(strDate);
  return parsedDate !== null ? parsedDate[0].format(FormatDayJSDate) : date;
};

const PropertiesContent = memo((props: PropertiesContentProps) => {
  const { details } = useContext(PersonContext);
  // const { details: inputDetails } = useContext(PersonContext);

  // const [details, setDetails] = useState<PersonType | undefined>(undefined);

  const { countries } = useContext(CountriesContext);

  if (!details) return <></>;

  const type = details.entityType;

  return (
    <Box px={3} py={3} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={2} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'minmax(20px, auto) minmax(15ch, auto) 1fr'} columnGap={'1.5rem'} rowGap={'2rem'}>
        {/* categories */}
        {details.categories && details.categories.length > 0 && (
          <Collapser nbItems={details.categories.length} titleID={'category'}>
            <PropertiesElements className="one-column">
              {details.categories.map((cat, cat_index) => {
                return <CategoryEntry key={cat_index}>{cat}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* dataset */}
        {details.dataset && (
          <Collapser nbItems={1} titleID={'dataset'}>
            <CategoryEntry>{details.dataset.label}</CategoryEntry>
          </Collapser>
        )}

        {/* creation date */}
        {details.creationDate && (
          <Collapser nbItems={1} titleID={'creationDate'}>
            <CategoryEntry>{getParsedDate(details.creationDate)}</CategoryEntry>
          </Collapser>
        )}

        {/* updated date */}
        {details.updatedDate && (
          <Collapser nbItems={1} titleID={'updatedDate'}>
            <CategoryEntry>{getParsedDate(details.updatedDate)}</CategoryEntry>
          </Collapser>
        )}

        {/* label */}
        {details.label && (
          <Collapser nbItems={1} titleID={'label'}>
            <CategoryEntry>{details.label}</CategoryEntry>
          </Collapser>
        )}

        {/* type */}
        {details.type && (
          <Collapser nbItems={1} titleID={'type'}>
            <CategoryEntry>{details.type}</CategoryEntry>
          </Collapser>
        )}

        {/* names */}
        {details.names && details.names.length > 0 && (
          <Collapser nbItems={details.names.length} titleID={'name'}>
            <PropertiesBox>
              {details.names.map((name, name_index) => {
                return <DisplayName key={name_index} name={name} />;
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* titles */}
        {details.titles && details.titles.length > 0 && (
          <Collapser nbItems={details.titles.length} titleID={'title'}>
            <PropertiesElements className="one-column">
              {details.titles.map((title, index) => {
                return <CategoryEntry key={index}>{title}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* gender */}
        {type === searchConstant.ENTITIES.INDIVIDUAL && (
          <Collapser nbItems={1} titleID={'sex'}>
            {details.sex ? <CategoryEntry>{GetGender(details.sex.toUpperCase())}</CategoryEntry> : <UnkownBox />}
          </Collapser>
        )}

        {/* nationalities */}
        {details.nationalities && details.nationalities.length > 0 && (
          <Collapser nbItems={details.nationalities.length} titleID={'nationality'}>
            <PropertiesElements className="one-column">
              {details.nationalities.map((nationality, index) => {
                let countryName = null;
                const code = nationality.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} display="flex" alignItems={'center'} columnGap={'5px'}>
                    <Flag country={code ?? undefined} />
                    <Box className="capitalize">
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox key={index} />
                );
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* dates of birth */}
        {type === searchConstant.ENTITIES.INDIVIDUAL && (
          <Collapser nbItems={details.datesOfBirth?.length ?? 1} titleID={'dateOfBirth'}>
            <PropertiesElements className="one-column">
              {details.datesOfBirth ? (
                details.datesOfBirth.map((dob, dob_index) => {
                  const date = getParsedDate(dob.date);
                  return <Fragment key={dob_index}> {date ? <CategoryEntry>{date} </CategoryEntry> : <UnkownBox />}</Fragment>;
                })
              ) : (
                <UnkownBox />
              )}
            </PropertiesElements>
          </Collapser>
        )}

        {/* placesOfBirth */}
        {details.placesOfBirth && details.placesOfBirth.length > 0 && (
          <Collapser nbItems={details.placesOfBirth?.length} titleID={'placeOfBirth'}>
            <PropertiesElements className="one-column">
              {details.placesOfBirth.map((pob, index) => {
                let countryName = null;
                const code = pob.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} display="flex" alignItems={'center'} columnGap={'5px'}>
                    <Flag country={code ?? undefined} />
                    <Box className="capitalize">
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox key={index} />
                );
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* deceased */}
        {'deceased' in details && (
          <Collapser nbItems={1} titleID={'deceased'}>
            <CategoryEntry>{details.deceased}</CategoryEntry>
          </Collapser>
        )}

        {/* dates of death */}
        {details.datesOfDeath && details.datesOfDeath.length > 0 && (
          <Collapser nbItems={details.datesOfDeath?.length} titleID={'dateOfDeath'}>
            <PropertiesElements className="one-column">
              {details.datesOfDeath ? (
                details.datesOfDeath.map((dod, index) => {
                  const date = getParsedDate(dod.date);
                  return <Fragment key={index}>{date ? <CategoryEntry>{date}</CategoryEntry> : <UnkownBox />}</Fragment>;
                })
              ) : (
                <UnkownBox />
              )}
            </PropertiesElements>
          </Collapser>
        )}

        {/* addresses */}
        {details.addresses && details.addresses.length > 0 && (
          <Collapser nbItems={details.addresses.length} titleID={'address'}>
            <PropertiesBox>
              {details.addresses.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* datesOfRegistry */}
        {details.datesOfRegistry && details.datesOfRegistry.length > 0 && (
          <Collapser nbItems={details.datesOfRegistry?.length} titleID={'dateOfRegistry'}>
            <PropertiesElements className="one-column">
              {details.datesOfRegistry.map((dor, index) => {
                const date = getParsedDate(dor.date);
                return <Fragment key={index}>{date ? <CategoryEntry>{date}</CategoryEntry> : <UnkownBox />}</Fragment>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* placesOfRegistry */}
        {details.placesOfRegistry && details.placesOfRegistry.length > 0 && (
          <Collapser nbItems={details.placesOfRegistry?.length} titleID={'placeOfRegistry'}>
            <PropertiesBox>
              {details.placesOfRegistry.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* identityDocuments */}
        {details.identityDocuments && details.identityDocuments.length > 0 && (
          <Collapser nbItems={details.identityDocuments?.length} titleID={'identityDocument'}>
            <PropertiesBox>
              {details.identityDocuments.map((doc, index) => {
                return <DisplayIdDoc key={index} doc={doc} />;
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* leis */}
        {details.leis && details.leis.length > 0 && (
          <Collapser nbItems={details.leis.length} titleID={'lei'}>
            <PropertiesElements className="one-column">
              {details.leis.map((lei, index) => {
                return <CategoryEntry key={index}>{lei}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}
        {/* bics */}
        {details.bics && details.bics.length > 0 && (
          <Collapser nbItems={details.bics.length} titleID={'bic'}>
            <PropertiesElements className="one-column">
              {details.bics.map((bic, index) => {
                return <CategoryEntry key={index}>{bic}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* digitalCurrencies */}
        {details.digitalCurrencies && details.digitalCurrencies.length > 0 && (
          <Collapser nbItems={details.digitalCurrencies?.length} titleID={'digitalCurrency'}>
            <PropertiesBox>
              {details.digitalCurrencies.map((dc, index) => {
                return (
                  <PropertiesElements key={index}>
                    {dc.currency && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="currency" defaultMessage={'Currency'} />:
                        </CategoryTitle>
                        <CategoryEntry>{dc.currency}</CategoryEntry>
                      </>
                    )}
                    {dc.identifier && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="identifier" defaultMessage={'Identifier'} />:
                        </CategoryTitle>
                        <CategoryEntry>{dc.identifier}</CategoryEntry>
                      </>
                    )}
                  </PropertiesElements>
                );
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* flags */}
        {details.flags && details.flags.length > 0 && (
          <Collapser nbItems={details.flags.length} titleID={'flag'}>
            <PropertiesElements className="one-column">
              {details.flags.map((flag, index) => {
                let countryName = null;
                const code = flag.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} display="flex" alignItems={'center'} columnGap={'5px'}>
                    <Flag country={code ?? undefined} />
                    <Box className="capitalize">
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox key={index} />
                );
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* datesOfBuild */}
        {details.datesOfBuild && details.datesOfBuild.length > 0 && (
          <Collapser nbItems={details.datesOfBuild?.length} titleID={'dateOfBuild'}>
            <PropertiesElements className="one-column">
              {details.datesOfBuild.map((dob, index) => {
                const date = getParsedDate(dob.date);
                return <Fragment key={index}>{date ? <CategoryEntry>{date}</CategoryEntry> : <UnkownBox />}</Fragment>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* placeOfBuild */}
        {details.placeOfBuild && details.placeOfBuild.length > 0 && (
          <Collapser nbItems={details.placeOfBuild.length} titleID={'placeOfBuild'}>
            <PropertiesBox>
              {details.placeOfBuild.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* positions */}
        {details.positions && details.positions.length > 0 && (
          <Collapser nbItems={details.positions.length} titleID={'position'}>
            <PropertiesElements className="one-column">
              {details.positions.map((position, index) => {
                return <CategoryEntry key={index}>{position}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* sectors */}
        {details.sectors && details.sectors.length > 0 && (
          <Collapser nbItems={details.sectors.length} titleID={'sector'}>
            <PropertiesElements className="one-column">
              {details.sectors.map((sector, index) => {
                return <CategoryEntry key={index}>{sector}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* classifications */}
        {details.classifications && details.classifications.length > 0 && (
          <Collapser nbItems={details.classifications.length} titleID={'classification'}>
            <PropertiesElements className="one-column">
              {details.classifications.map((classification, index) => {
                return <CategoryEntry key={index}>{classification}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* dissolved */}
        {'dissolved' in details && (
          <Collapser nbItems={1} titleID={'dissolved'}>
            <CategoryEntry>{details.dissolved ? 'Yes' : 'No'}</CategoryEntry>
          </Collapser>
        )}

        {/* datesOfDissolution */}
        {details.datesOfDissolution && details.datesOfDissolution.length > 0 && (
          <Collapser nbItems={details.datesOfDissolution?.length} titleID={'dateOfDissolution'}>
            <PropertiesElements className="one-column">
              {details.datesOfDissolution.map((dod, index) => {
                const date = getParsedDate(dod.date);
                return <Fragment key={index}> {date ? <CategoryEntry>{date} </CategoryEntry> : <UnkownBox />}</Fragment>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* keywords */}
        {details.keywords && details.keywords.length > 0 && (
          <Collapser nbItems={details.keywords?.length} titleID={'keyword'}>
            <PropertiesElements className="one-column">
              {details.keywords.map((keyword, index) => {
                return <CategoryEntry key={index}>{keyword}</CategoryEntry>;
              })}
            </PropertiesElements>
          </Collapser>
        )}

        {/* riskSince */}
        {details.riskSince && (
          <Collapser nbItems={1} titleID={'riskSince'}>
            <CategoryEntry>{getParsedDate(details.riskSince)}</CategoryEntry>
          </Collapser>
        )}

        {/* risks */}
        {details.risks && details.risks.length > 0 && (
          <Collapser nbItems={details.risks?.length} titleID={'risk'}>
            <PropertiesBox>
              {details.risks.map((risk, index) => {
                return (
                  <PropertiesElements key={index}>
                    {risk.score && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="score" defaultMessage={'Score'} />:
                        </CategoryTitle>
                        <GaugeMeter value={risk.score} backgroundColor={'white'} />
                      </>
                    )}

                    {risk.categories && risk.categories.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id={risk.categories.length > 1 ? 'category_plural' : 'category'} />
                        </CategoryTitle>

                        <PropertiesElements className="one-column">
                          {risk.categories.map((cat, index) => {
                            return <CategoryEntry key={index}>{cat}</CategoryEntry>;
                          })}
                        </PropertiesElements>
                      </>
                    )}

                    {risk.subcategories && risk.subcategories.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id={risk.subcategories.length > 1 ? 'subCategory_plural' : 'subCategory'} />:
                        </CategoryTitle>

                        <PropertiesElements className="one-column">
                          {risk.subcategories.map((subcat, index) => {
                            return <CategoryEntry key={index}>{subcat}</CategoryEntry>;
                          })}
                        </PropertiesElements>
                      </>
                    )}

                    {risk.periodsOfRisk && risk.periodsOfRisk.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id={risk.periodsOfRisk.length > 1 ? 'periodOfRisk_plural' : 'periodOfRisk'} />:
                        </CategoryTitle>

                        <PropertiesElements className="one-column">
                          {risk.periodsOfRisk.map((period, index) => {
                            const periodStarted = getParsedDate(period.started);
                            const periodEnded = getParsedDate(period.ended);

                            return (
                              <Box key={index} display="flex" alignItems={'center'} columnGap={'.5rem'}>
                                {periodStarted ? (
                                  <CategoryEntry>
                                    <FormattedMessage id="from" /> {periodStarted}
                                  </CategoryEntry>
                                ) : (
                                  <CategoryEntry>.</CategoryEntry>
                                )}
                                {periodEnded && (
                                  <>
                                    {/* <CategoryEntry>-</CategoryEntry> */}
                                    <CategoryEntry>
                                      <FormattedMessage id="to" /> {periodEnded}
                                    </CategoryEntry>
                                  </>
                                )}
                              </Box>
                            );
                          })}
                        </PropertiesElements>
                      </>
                    )}

                    {risk.riskInformations && risk.riskInformations.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id={risk.riskInformations.length > 1 ? 'information_plural' : 'information'} />:
                        </CategoryTitle>

                        <PropertiesElements className="one-column">
                          {risk.riskInformations.map((information, index) => {
                            let countryName = null;
                            const countryKeys = ['COUNTRY OF AUTHORITY', 'COUNTRY CODE'];
                            if (information.type && countryKeys.includes(information.type.toUpperCase())) {
                              countryName = getCountryFromIso(information.value, countries);
                            }

                            return (
                              <Box key={index} display="flex" columnGap={'.5rem'}>
                                {information.type && <CategoryEntry>{information.type}:</CategoryEntry>}
                                {information.value &&
                                  (countryName ? (
                                    <Box display="flex" alignItems={'center'} columnGap={'5px'}>
                                      <Flag country={information.value ?? undefined} />
                                      <Box className="capitalize">
                                        <CategoryEntry>{countryName}</CategoryEntry>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <CategoryEntry flex={1}>{information.value}</CategoryEntry>
                                  ))}
                              </Box>
                            );
                          })}
                        </PropertiesElements>
                      </>
                    )}
                  </PropertiesElements>
                );
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* medias */}
        {details.medias && details.medias.length > 0 && (
          <Collapser nbItems={details.medias?.length} titleID={'media'}>
            <PropertiesBox>
              {details.medias.map((media, index) => {
                return (
                  <PropertiesElements key={index}>
                    {media.description && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="description" defaultMessage={'Description'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.description}</CategoryEntry>
                      </>
                    )}

                    {media.type && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="type" defaultMessage={'Type'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.type}</CategoryEntry>
                      </>
                    )}

                    {media.value && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="value" defaultMessage={'Value'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.value}</CategoryEntry>
                      </>
                    )}
                  </PropertiesElements>
                );
              })}
            </PropertiesBox>
          </Collapser>
        )}

        {/* contactInformations */}
        {details.contactInformations && details.contactInformations.length > 0 && (
          <Collapser nbItems={details.contactInformations?.length} titleID={'contactInformation'}>
            <PropertiesBox>
              {details.contactInformations.map((contact, index) => {
                return (
                  <PropertiesElements key={index}>
                    {contact.type && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="type" defaultMessage={'Type'} />:
                        </CategoryTitle>
                        <CategoryEntry>{contact.type}</CategoryEntry>
                      </>
                    )}

                    {contact.value && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="value" defaultMessage={'Value'} />:
                        </CategoryTitle>
                        <CategoryEntry>{contact.value}</CategoryEntry>
                      </>
                    )}
                  </PropertiesElements>
                );
              })}
            </PropertiesBox>
          </Collapser>
        )}
      </Box>
    </Box>
  );
});

export default PropertiesContent;
