/**
 * NotificationsContent.tsx
 * Display the notification content
 */

/* packages */
import React, { memo, useContext } from 'react';

/* components */
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CSSObject } from '@mui/material';
import { CrossIcon } from 'icons/cross/cross';
import { NotificationsContext } from 'contextProviders/NotificationsProvider';
import Loader from 'components/Loader/Loader';

// import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

/* types */
import { Notification } from 'models/notifications';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

const NotificationsContent = memo(({ setShowNotifications }: { setShowNotifications: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <Box px={'40px'} py={4} display="flex" flexDirection={'column'} sx={{ width: 'max(30vw, 400px)', maxWidth: '90vw', height: '100%' }}>
      <Box display="flex" alignItems={'center'} mb={3}>
        <Box flex={1}>
          <Typography color="darkgray" fontSize={'1.25rem'} fontWeight={600}>
            <FormattedMessage id="notifications" defaultMessage={'Notifications'} />
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setShowNotifications(false);
          }}
          sx={{
            fontSize: '1rem',
            borderRadius: '5px',
            color: 'var(--color-lightgray4)',
            '&:hover': {
              color: 'var(--color-table-content)',
              backgroundColor: 'var(--color-hover-button)',
            },
          }}
        >
          {<CrossIcon fontSize={'inherit'} />}
        </IconButton>
      </Box>

      <Box flex={1} display={'flex'} minHeight={0}>
        {/* <NotificationsTabs /> */}
        <NotificationsList />
      </Box>
    </Box>
  );
});

// const NotificationsTabs = memo(({}: PropsWithChildren<{}>) => {
//   // const [activeTab, setActiveTab] = useState<string>('Investigation');

//   // const tabs = useMemo(
//   //   () => [
//   //     {
//   //       text: 'Investigation',
//   //       value: 'Investigation',
//   //     },
//   //     {
//   //       text: 'List Import',
//   //       value: 'ListImport',
//   //     },
//   //   ],
//   //   []
//   // );

//   // const handleTabChange = (_: React.SyntheticEvent, tabValue: 'string') => {
//   //   const newTab = tabValue;
//   //   setActiveTab(newTab);
//   // };

//   return (
//     <Box width={'100%'} display="flex" flexDirection={'column'}>
//       {/* <Box mb={2}>
//         <TabsNavigation skipAll={true} activeTab={activeTab} tabTitles={tabs} onChange={handleTabChange} label={'Notification tab'} disabled={false} />
//       </Box> */}

//       <Box flex={1} className="custom-scrollbar" sx={{ overflowY: 'auto' }}>
//         <NotificationsList {...{  }} />
//       </Box>
//     </Box>
//   );
// });

const NotificationsList = memo(() => {
  const { loadingNotifications, listNotifications, nbNotifications, notifications } = useContext(NotificationsContext);

  if (!loadingNotifications && notifications && notifications.length <= 0) {
    return (
      <Box>
        <Typography fontSize={'1rem'} textAlign={'center'} fontWeight={500} mt={2} sx={{ color: 'var(--color-gray2)' }}>
          <FormattedMessage id="noNotifications" defaultMessage={"You don't have any notification yet"} />
        </Typography>
      </Box>
    );
  }
  return (
    <Box flex={1} className="custom-scrollbar" sx={{ minHeight: 0, overflowY: 'auto' }}>
      {notifications?.map((notification) => (
        <NotificationElement key={notification.id} {...{ notification }} />
      ))}

      {loadingNotifications ? (
        <Box flex={1} py={5}>
          <Loader />
        </Box>
      ) : (
        <>
          {(nbNotifications ?? 0) > 0 && notifications?.length && notifications.length < (nbNotifications ?? 0) && (
            <Box textAlign={'center'} mt={4}>
              <ShadowedButton
                onClick={() => {
                  listNotifications?.();
                }}
              >
                <FormattedMessage id="loadMore" defaultMessage={'Load more'} />
              </ShadowedButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
});

const NotificationElement = ({ notification }: { notification: Notification }) => {
  const { setNotificationAsRead } = useContext(NotificationsContext);

  const BoxCSS: CSSObject = {
    borderBottom: '1px solid var(--color-grayHeaderBorder)',
    fontSize: 'var(--fs-14)',
    color: 'var(--color-gray2)',
  };

  if (!notification.read) {
    BoxCSS['&:hover'] = { backgroundColor: 'var(--color-lightgray2)' };
    BoxCSS.cursor = 'pointer';
  }

  return (
    <Box
      pl={1}
      py={2}
      sx={BoxCSS}
      onClick={() => {
        if (!notification.read) setNotificationAsRead?.(notification);
      }}
    >
      <Typography
        component="span"
        display="inline-block"
        mr={1}
        sx={{ verticalAlign: 'middle', width: '5px', height: '5px', backgroundColor: notification.read ? 'transparent' : 'var(--color-fushia)', borderRadius: '50%' }}
      />
      <Typography component="span" display="inline" sx={{ fontSize: 'inherit' }}>
        {notification.message}
      </Typography>
    </Box>
  );
};

export default NotificationsContent;
