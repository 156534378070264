import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const GarbageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16">
        <path
          d="M5.5,6.928v10a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1v-10Zm3,9h-1v-7h1Zm2,0h-1v-7h1Zm2,0h-1v-7h1Zm2,0h-1v-7h1Zm2.25-12H13.5V2.678a.752.752,0,0,0-.75-.75h-3.5a.752.752,0,0,0-.75.75v1.25H5.249a.752.752,0,0,0-.75.75v1.25h13V4.678a.752.752,0,0,0-.75-.75Zm-4.25,0h-3V2.941h3v.987Z"
          transform="translate(-4.499 -1.928)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
