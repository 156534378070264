/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
/* types */
import { AllUsersResponseType } from 'models/allUsers';
import { DeleteUserResponseType, UserType } from 'models/user';
import { EditUserResponse } from 'models/user';

export interface AllUsersContextType {
  // statusQueried: boolean;
  loadingAllUsers: boolean;
  allUsers?: UserType[];
  listAllUsers?(abortController?: AbortController): Promise<UserType[] | undefined>;
  editSingleUser?(user: UserType): void;
  updateUser?(user: UserType, propagate?: boolean): Promise<UserType | undefined>;
  createUser?(user: UserType, propagate?: boolean): Promise<UserType | undefined>;
  deleteUser?(userId: number, abortController?: AbortController): Promise<DeleteUserResponseType | undefined>;
}
/* elements */
const AllUsersContext = createContext<AllUsersContextType>({
  // statusQueried: false,
  loadingAllUsers: false,
  allUsers: undefined,
});

const AllUsersProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  const listAllUsersOngoing = useRef(false);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingAllUsers, setLoadingAllUsers] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<UserType[] | undefined>(undefined);

  const listAllUsers = useCallback(
    async (abortController?: AbortController) => {
      if (listAllUsersOngoing.current) return;

      // check permissions
      // const allowed = checkPermissions('listAllUsers', permissions);
      // if (!allowed) return;

      listAllUsersOngoing.current = true;
      setLoadingAllUsers(true);
      setAllUsers(undefined);
      let allUsersResults = undefined;
      try {
        const listAllUsersUrl = URLConstants.listAllUsers;
        const results = (await getAuthenticatedRequest(listAllUsersUrl, abortController ?? undefined)) as AllUsersResponseType;

        allUsersResults = results.users ?? [];

        // setStatusQueried(true);
        setLoadingAllUsers(false);
        setAllUsers(allUsersResults);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingAllUsers(false);
        setAllUsers(undefined);
      }
      listAllUsersOngoing.current = false;
      return allUsersResults;
    },
    [getAuthenticatedRequest]
  );

  const editSingleUser = useCallback((user: UserType) => {
    setAllUsers((currentAllUser) => {
      const userIndex = currentAllUser?.findIndex((cau) => cau.id === user.id) ?? -1;

      const newCurrentUser = [...(currentAllUser ?? [])];
      if (userIndex < 0) {
        newCurrentUser.push(user);
      } else {
        newCurrentUser[userIndex] = user;
      }
      return newCurrentUser;
    });
  }, []);

  const updateUser = useCallback(
    async (user: UserType, propagate: boolean = true) => {
      const allowed = checkPermissions('manageUsers', permissions);
      if (!allowed) return undefined;

      // do not send photo field (reduce query size)
      const userData = { ...user };
      if (userData.photo) {
        delete userData.photo;
      }

      try {
        const newUserData = (await postAuthenticatedRequest(URLConstants.userUpdate, { user: userData })) as EditUserResponse;

        if (propagate) {
          editSingleUser(newUserData.user);
        }

        return newUserData.user;
      } catch (listError: any) {
        throw new Error(listError);
      }
    },
    [permissions, postAuthenticatedRequest, editSingleUser]
  );

  const createUser = useCallback(
    async (user: UserType) => {
      const allowed = checkPermissions('manageUsers', permissions);
      if (!allowed) return undefined;

      try {
        const newUserData = (await postAuthenticatedRequest(URLConstants.userCreate, { user })) as EditUserResponse;

        if (newUserData.user) {
          editSingleUser(newUserData.user);
          return newUserData.user;
        }

        return;
      } catch (listError: any) {
        throw new Error(listError);
      }
    },
    [permissions, postAuthenticatedRequest, editSingleUser]
  );

  const deleteUser = useCallback(
    async (userId: number, abortController?: AbortController): Promise<DeleteUserResponseType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageUsers', permissions);
      if (!allowed) return undefined;

      try {
        const deleteUserUrl = URLConstants.userDelete + `${userId}`;
        const results = (await deleteAuthenticatedRequest(deleteUserUrl, abortController ?? undefined)) as DeleteUserResponseType;

        if (results.operationResult) {
          setAllUsers((currentUsers) => {
            return currentUsers?.filter((u) => u.id !== userId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [permissions, deleteAuthenticatedRequest]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listAllUsers();
  }, [currentUserId, listAllUsers]);

  const outputValue = useMemo(
    (): AllUsersContextType => ({
      // statusQueried,
      loadingAllUsers,
      allUsers,
      listAllUsers,
      editSingleUser,
      updateUser,
      createUser,
      deleteUser,
    }),
    [loadingAllUsers, allUsers, listAllUsers, editSingleUser, updateUser, createUser, deleteUser]
  );
  return <AllUsersContext.Provider value={outputValue}>{children}</AllUsersContext.Provider>;
};

/* exports */
export { AllUsersContext, AllUsersProvider };
