import Button, { ButtonProps } from '@mui/material/Button';
import { AddIcon } from 'icons/add/add';

export const AddButton = (props: ButtonProps) => {
  const { children, ...buttonProps } = props;
  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      startIcon={<AddIcon />}
      sx={{
        '.MuiButton-startIcon svg': {
          fontSize: 'var(--fs-12)',
        },
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
