import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ValidatedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16.361" height="16.361" viewBox="0 0 16.361 16.361">
        <path
          d="M16.923,8.743A8.18,8.18,0,1,1,8.743.563,8.18,8.18,0,0,1,16.923,8.743ZM7.8,13.074,13.866,7a.528.528,0,0,0,0-.746l-.746-.746a.528.528,0,0,0-.746,0l-4.95,4.95L5.112,8.151a.528.528,0,0,0-.746,0L3.62,8.9a.528.528,0,0,0,0,.746l3.43,3.43a.528.528,0,0,0,.746,0Z"
          transform="translate(-0.563 -0.563)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
