/**
 * FullPageBox.tsx
 * Generate a box with full page height and content centered
 */
/* components */
import Box from '@mui/material/Box';

/* elements */
const FullPageBox = ({ children }: React.PropsWithChildren) => {
  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} minHeight={'100vh'}>
      {children}
    </Box>
  );
};

export default FullPageBox;
