import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SignOutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.751" viewBox="0 0 18 15.751">
        <path
          d="M17.67,40.67a1.127,1.127,0,0,0,0-1.593l-4.5-4.5a1.126,1.126,0,1,0-1.593,1.593l2.581,2.581H6.75a1.125,1.125,0,1,0,0,2.25h7.408l-2.581,2.581a1.126,1.126,0,1,0,1.593,1.593l4.5-4.5ZM5.625,34.25a1.125,1.125,0,1,0,0-2.25H3.375A3.376,3.376,0,0,0,0,35.375v9a3.376,3.376,0,0,0,3.375,3.375h2.25a1.125,1.125,0,1,0,0-2.25H3.375A1.124,1.124,0,0,1,2.25,44.376v-9A1.124,1.124,0,0,1,3.375,34.25Z"
          transform="translate(0 -32)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
