import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ChevronIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17.725" height="31" viewBox="0 0 17.725 31">
        <g transform="translate(17.725 31) rotate(180)">
          <path
            d="M15.506,12.382,27.227.651a2.206,2.206,0,0,1,3.129,0,2.234,2.234,0,0,1,0,3.138L17.075,17.079a2.211,2.211,0,0,1-3.055.065L.646,3.8A2.216,2.216,0,0,1,3.775.66Z"
            transform="translate(0 31) rotate(-90)"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
