import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BellIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.573" viewBox="0 0 18 20.573">
        <path
          d="M9.022,0A1.284,1.284,0,0,0,7.737,1.286v.719A6.109,6.109,0,0,0,2.593,8.037V9.379A8.044,8.044,0,0,1,.833,14.4l-.6.751a.964.964,0,0,0,.751,1.567H17.059a.964.964,0,0,0,.751-1.567l-.6-.747a8.063,8.063,0,0,1-1.76-5.023V8.037a6.109,6.109,0,0,0-5.143-6.031V1.286A1.284,1.284,0,0,0,9.022,0Zm0,3.858h.321a4.181,4.181,0,0,1,4.179,4.179V9.379a9.98,9.98,0,0,0,1.6,5.409H2.927a9.98,9.98,0,0,0,1.6-5.409V8.037A4.181,4.181,0,0,1,8.7,3.858ZM11.594,18H6.451a2.572,2.572,0,1,0,5.143,0Z"
          transform="translate(-0.022)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
