import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PenIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.005" viewBox="0 0 12 12.005">
        <path
          d="M2757.3-10932.3a1.032,1.032,0,0,1-.258-1.02l.759-2.582a2.43,2.43,0,0,1,.606-1.021l5.365-5.367,1.31-1.312h.021a1.878,1.878,0,0,1,1.165-.4,1.873,1.873,0,0,1,1.333.554l.847.849a1.89,1.89,0,0,1,0,2.668l-1.159,1.159-5.369,5.369a2.421,2.421,0,0,1-1.019.606l-2.587.762a1.066,1.066,0,0,1-.283.038A1.026,1.026,0,0,1,2757.3-10932.3Zm1.835-3.9a1.382,1.382,0,0,0-.346.586l-.759,2.58v0h0l2.579-.76a1.385,1.385,0,0,0,.584-.346l4.641-4.642-2.062-2.062Z"
          transform="translate(-2757.003 10944.008)"
          fill="#cfd1d2"
        />
      </svg>
    </SvgIcon>
  );
};
