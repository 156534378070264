import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="18.001" viewBox="0 0 18.001 18.001">
        <path
          d="M-13929.5,498a9.011,9.011,0,0,1-9-9,9.011,9.011,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-13929.5,498Zm-5.433-9.386h0l5.433,5.369,5.433-5.37h-3.1v-4.6h-4.659v4.6h-3.1Z"
          transform="translate(13938.501 -479.999)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
