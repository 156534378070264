/**
 * Investigate.tsx
 */
/* packages */

import { Outlet } from 'react-router-dom';

/* components */

/* contexts */

/* utilities */

/* elements */
const Investigate = () => {
  return <Outlet />;
};

export default Investigate;
