import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.556" viewBox="0 0 16 19.556">
        <path
          d="M4759.667-6180.444a2.67,2.67,0,0,1-2.667-2.667v-14.222a2.67,2.67,0,0,1,2.667-2.667h6.244a.89.89,0,0,1,.556.213l0,0,0,0c.015.013.029.026.043.04l6.223,6.222a.915.915,0,0,1,.131.167h0l.008.015,0,0,0,.011a.065.065,0,0,1,.006.01l0,.006.007.014v0a.884.884,0,0,1,.091.332h0c0,.008,0,.015,0,.022v.005c0,.005,0,.011,0,.017v9.8a2.669,2.669,0,0,1-2.667,2.667Zm-.889-16.889v14.222a.89.89,0,0,0,.889.889h10.667a.89.89,0,0,0,.889-.889V-6192h-5.334a.889.889,0,0,1-.889-.889v-5.333h-5.333A.89.89,0,0,0,4758.778-6197.333Zm8,3.556h3.188l-3.188-3.188Z"
          transform="translate(-4757 6200)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
