/**
 * user.ts
 * Define workflow
 */
import { ApiResponse } from './axiosRequest';
import { DateString } from './utils';

export interface SearchFilterType {
  id: number;
  name?: string;
  description?: string;
  userId?: number;
  createdBy?: string;
  creationDate?: DateString;
  searchFields?: {
    id: number;
    field?: string;
    valueStr?: string;
    valueDate1?: DateString;
    valueDate2?: DateString;
    listOfValueStr?: string[];
    listOfValueInt?: number[];
  }[];
}

export interface SavedSearchResponse extends ApiResponse {
  savedSearches: SearchFilterType[];
}

export const savedSearchFields = {
  alertId: 'alertIdSearch',
  keyword: 'keywordSearch',
  dateStart: 'date1Search',
  dateEnd: 'date2Search',
  group: 'groupSearch',
  user: 'userSearch',
};

type SavedSearchFieldsKeys = keyof typeof savedSearchFields;
type SavedSearchFieldsValues = (typeof savedSearchFields)[SavedSearchFieldsKeys];

export interface CreateSavedSearchQuery {
  name?: string;
  description?: string;
  searchFields?: {
    field?: SavedSearchFieldsValues;
    valueStr?: string;
    valueDate1?: DateString;
    valueDate2?: DateString;
    listOfValueStr?: string[];
    listOfValueInt?: number[];
  }[];
}
