/**
 * searchDatasets.ts
 * Define search datasets
 */

import { DateStringISO } from 'models/utils';
import { ApiResponse } from './axiosRequest';
import { MatchingNameType, MatchingDataType, MatchingDataset } from 'models/matchingData';

// Search dataset Types
export interface SearchDatasetsResponse extends ApiResponse {
  datasets: SearchDataset[];
}
export type SearchDatasetsType = SearchDataset[];

export interface SearchDataset extends MatchingDataset {
  created?: DateStringISO;
  active?: boolean;
  totalCount?: number;
  category?: string;
  dateOfPublication?: DateStringISO;
  dateOfUpload?: DateStringISO;

  subscription?: string;
  displayOrder?: number;
}

export interface SourceQuery {
  bics?: string[];
  identityDocuments?: { number: string }[];
  leis?: string[];
  names?: { fullName?: string }[];

  nationalities?: { country: string }[];
  placesOfBirth?: { country: string }[];
  addresses?: { country: string }[];
  placesOfRegistry?: { country: string }[];
  flags?: { country: string }[];

  datesOfBirth?: MatchingDate[];
  datesOfBuild?: MatchingDate[];
  datesOfRegistry?: MatchingDate[];
}

interface TargetQuery {
  datasets?: ({ category: string } | { label: string })[];
}
export interface SearchPayloadType {
  requestHeader: {
    userId: string;
  };
  paginationRequest: {
    pageNumber: Number;
    maxPerPage: Number;
    calculateSearchStats: boolean;
  };
  queries: {
    sourceData: SourceQuery[];

    targetData: TargetQuery[];

    threshold: Number;

    entityTypeAlgo?: MatchingAlgo;
    identityDocumentAlgo?: MatchingAlgo;
    bicAlgo?: MatchingAlgo;
    leiAlgo?: MatchingAlgo;
    categoryAlgo?: MatchingAlgo;

    flagAlgo?: MatchingAlgo;
    placeOfRegistryAlgo?: MatchingAlgo;
    addressAlgo?: MatchingAlgo;
    placeOfBirthAlgo?: MatchingAlgo;
    nationalityAlgo?: MatchingAlgo;

    dateOfBuildAlgo?: MatchingAlgo;
    dateOfRegistryAlgo?: MatchingAlgo;
    dateOfBirthAlgo?: MatchingAlgo;

    minimumAmountOfOptional?: number;
  }[];
}

export interface MatchingAlgo {
  type: string;
  nullMatch?: boolean;
  active?: boolean;
  permutation?: boolean;
  entityTypes?: string[];
  exclude?: string[];
  include?: string[];
  optional?: boolean;
}

interface MatchingDate {
  date?: string;
  normalized?: string;
}

export interface SearchResultsResponse extends ApiResponse {
  numberOfObjects: number;
  responseHeader: {
    requestID: string;
    responded: string;
  };
  results?: SearchResultsElement[];
  searchStats?: {
    numberOfPeps?: number;
    numberOfSanctions?: number;
    numberOfWanteds?: number;
    numberOfAll?: number;
    numberOfOrganizations?: number;
    numberOfIndividuals?: number;
    numberOfUnknowns?: number;
    numberOfVessels?: number;
    numberOfObjects?: number;
    foundDataset?: string[];
  };
  numberOfResults?: number;
}

export interface SearchResultsElement {
  matchType: string;
  score: number;
  matchingNames: MatchingNameType[];
  targetData: MatchingDataType[];
  sourceData: MatchingDataType[];
}

export const searchConstant = {
  ENTITIES: {
    INDIVIDUAL: 'individual',
    ORGANIZATION: 'organization',
    VESSEL: 'vessel',
    IDNUMBER: 'idnumber',
    LEI: 'lei',
    BIC: 'bic',
    UNKNOWN: 'unknown',
  },

  countryAlgo: ['same_country', 'same_region', 'same_subregion'],
  countryFields: ['nationality', 'placeOfBirth', 'placeOfRegistry', 'address', 'flag'],

  dateAlgo: ['same_day_month_year', 'same_month_year', 'same_year', 'same_quadrennium', 'same_decade'],
  dateFields: ['datesOfBirth', 'datesOfRegistry', 'datesOfBuild'],
};
