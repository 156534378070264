/**
 * InvestigateSearch.tsx
 */
/* packages */
import { PropsWithChildren, useContext, useEffect, useState, useCallback, useMemo, useRef, memo, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { UserContext } from 'contextProviders/UserProvider';
import { RolesContext } from 'contextProviders/RolesProvider';
import { OrganizationsContext } from 'contextProviders/OrganizationsProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { ContentWithDarkTooltip, UnknownMessage } from 'components/InvestigateSearch/utils';

import FilterVirtualCheckboxes from 'components/SearchFilters/FilterVirtualCheckboxes';
import { RightSidePanel } from 'components/NotificationsPanel/NotificationsPanel';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

import { AddButton } from 'components/Buttons/Buttons';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';
import Loader from 'components/Loader/Loader';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import { GetAvatar, GetUser } from 'components/GetUser/GetUser';

import TableResults, { TableRefType } from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';

import SearchText from 'components/SearchElements/SearchText/SearchText';

import { CrossIcon } from 'icons/cross/cross';
import { GarbageIcon } from 'icons/garbage/garbage';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

/* utilities */
import { displayWarning } from 'utilities/Logger';
import { validateEmail } from 'components/EditProfile/EditProfile';

/* types */
import { TeamType } from 'models/teams';
import { OrganizationUnit, RoleType, UserType } from 'models/user';
import { SortedDirectionType } from 'components/TableResults/TableHead';
import { DateString } from 'models/utils';
import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import { TeamAdministrationPrint } from 'utilities/CheckUserPermissions';


interface ManageSetupTeamsLayoutProps {
  loading: boolean;
  handleCreateTeam(): void;
  isError: boolean;
}
interface ManageSetupTeamsContentProps {
  loading: boolean;
  teams?: TeamType[];
  teamsUsers: { [teamId: number]: number[] };
  users?: UserType[] | undefined;
  // teamsError: boolean;
  // getData(abortController?: AbortController): void;
  handleCreateTeam(team?: TeamType): void;
  handleDeleteTeam(team: TeamType): void;
  isError: boolean;
  load: () => void;
  removeUserFromTeam(team?: TeamType, user?: UserType): Promise<undefined | UserType>;
  addUsersToTeam(team?: TeamType, users?: UserType[]): Promise<boolean | undefined>;
}
interface EditTeamProps {
  team?: TeamType;
  setTeam(data: Partial<TeamType>, team?: TeamType): Promise<{ status: string }>;  
  organizations?:OrganizationUnit[];
  roles?:RoleType[];
}
interface DeleteTeamProps {
  team: TeamType;
  removeTeam(removedId: number): Promise<{ status: string }>;
}
interface EditTeamContentProps {
  team?: TeamType;
  users: UserType[];
  teamsUsers: ManageSetupTeamsContentProps['teamsUsers'];
  close(): void;
  removeUserFromTeam: ManageSetupTeamsContentProps['removeUserFromTeam'];
  addUsersToTeam: ManageSetupTeamsContentProps['addUsersToTeam'];
}

interface TeamRowProps {
  teamId: number;
  teamName: string;
  teamDescription?: string;
  teamUsers?: string;
  email?: string;
  creator?: string;
  createdOn?: DateString;
  updatedOn?: DateString;
  users?: UserType[] | undefined;
  rowClick?(team: TeamType): void;
  handleCreateTeam: ManageSetupTeamsContentProps['handleCreateTeam'];
  handleDeleteTeam: ManageSetupTeamsContentProps['handleDeleteTeam'];
  team?:TeamType;
}

/* elements */
const ManageSetupTeams = () => {
  const intl = useIntl();
  const { loadingAllUsers, allUsers, listAllUsers, updateUser } = useContext(AllUsersContext);
  const { loadingTeams, teams, listTeams, addOrUpdateTeam, deleteTeam } = useContext(TeamsContext);
  const { toggleModal } = useAddModal();
  const { currentUser } = useContext(UserContext);

  const { organizations, listOrganizations} = useContext(OrganizationsContext);
  const { roles, listRoles } = useContext(RolesContext);

  const [snapshotedUsers, setSnapshotedUsers] = useState<UserType[] | undefined>(undefined);
  const [initialLoading, setInitialLoading] = useState(true);
  
  // refresh user and teams on page load
  const load = useCallback(async () => {
    listTeams?.();
    
    const listedUsers = await listAllUsers?.();
    listOrganizations?.();
    listRoles?.();
    
    setSnapshotedUsers(listedUsers);
    
  }, [listAllUsers, listTeams,listOrganizations, listRoles]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const setTeam = useCallback(
    async (data: Partial<TeamType>, team?: TeamType): Promise<{ status: string }> => {
      const newData = {
        ...team,
        ...data,
      };

      // if ('users' in newData) delete newData['users'];

      try {
        await addOrUpdateTeam?.(newData, team ? false : true);
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateTeam]
  );

  const removeTeam = useCallback(
    async (removedId: number): Promise<{ status: string }> => {
      try {
        const deletedTeam = await deleteTeam?.(removedId);

        if (deletedTeam) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteTeam]
  );
  

  const handleCreateTeam = useCallback(
    
    (team?: TeamType) => {
      if (!currentUser?.isSystemAdmin) return;     
     
      toggleModal?.({
        title: team ? intl.formatMessage({ id: 'editTeam', defaultMessage: 'Edit team' }) : intl.formatMessage({ id: 'addTeam', defaultMessage: 'Add team' }),
        modalContent: <EditTeam {...{ team, setTeam, organizations, roles}}/>,
      });
    },
    [intl, toggleModal, setTeam, currentUser, organizations, roles]
  );

  const handleDeleteTeam = useCallback(
    (team: TeamType) => {
      if (!currentUser?.isSystemAdmin) return;

      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteTeam', defaultMessage: 'Delete team' }),
        modalContent: <DeleteTeam {...{ team, removeTeam }} />,
      });
    },
    [intl, toggleModal, removeTeam, currentUser]
  );

  const teamsUsers: ManageSetupTeamsContentProps['teamsUsers'] = useMemo(() => {
    const teamsUser: ManageSetupTeamsContentProps['teamsUsers'] = {};

    if (teams) {
      allUsers?.forEach((user) => {
        user.groups?.forEach((g) => {
          if (g.deleted) return;
          const a = (teamsUser[g.id] || []) as number[];
          a.push(user.id);
          teamsUser[g.id] = a;
        });
      });
    }
    return teamsUser;
  }, [allUsers, teams]);

  const removeUserFromTeam = useCallback(
    async (team?: TeamType, user?: UserType) => {
      if (!team || !user) return;
      if (!currentUser?.isSystemAdmin) return;

      const baseGroups = user.groups;
      const newTeams = user.groups?.filter((g) => g.id !== team.id) ?? [];

      user.groups = newTeams;

      try {
        const updatedUser = await updateUser?.(user);
        return updatedUser;
      } catch (updateError: any) {
        // reset groups of user object
        user.groups = baseGroups;
        throw new Error(updateError);
      }
    },
    [updateUser, currentUser]
  );

  const addUsersToTeam = useCallback(
    async (team?: TeamType, users?: UserType[]) => {
      if (!team || !users || users.length <= 0) return;
      if (!updateUser) return;
      if (!currentUser?.isSystemAdmin) return;

      try {
        const updatedUsersQueries = users.map((u) => {
          const newTeams = u.groups || [];
          newTeams.push(team);
          u.groups = newTeams;
          return updateUser(u);
        });
        const updatedUsersResults = await Promise.allSettled<Promise<UserType | undefined>[]>(updatedUsersQueries);

        const isFulfilled = updatedUsersResults.map((r) => r.status).indexOf('fulfilled') >= 0;

        return isFulfilled;
      } catch (updateError: any) {
        throw new Error(updateError);
      }
    },
    [updateUser, currentUser]
  );

  const isError = (!loadingTeams && !teams) || (!loadingAllUsers && !allUsers);
  const loading = initialLoading || loadingTeams || loadingAllUsers;

  return (
    <ManageSetupTeamsLayout {...{ loading, handleCreateTeam, isError }}>
      <ManageSetupTeamsContent {...{ loading, teams, teamsUsers, users: snapshotedUsers, handleCreateTeam, handleDeleteTeam, isError, load, removeUserFromTeam, addUsersToTeam }} />
    </ManageSetupTeamsLayout>
  );
};

const ManageSetupTeamsLayout = ({ loading, handleCreateTeam, isError, children }: PropsWithChildren<ManageSetupTeamsLayoutProps>) => {
  const intl = useIntl();
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'ManageSetupTeamsTitle', defaultMessage: 'Teams' })} />

        {currentUser?.isSystemAdmin && (
          <AddButton
            onClick={() => {
              handleCreateTeam();
            }}
            disabled={loading || isError}
          >
            <FormattedMessage id="addTeam" defaultMessage={'Add team'} />
          </AddButton>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const getSortedTeams = (teams: TeamType[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!teams || !sortedDirection) return teams;

  const direction = sortedDirection === 'asc' ? 1 : -1;
  switch (sortedColumn) {
    case 'team':
      return [...teams].sort((t1, t2) => direction * t1.name.localeCompare(t2.name));
    case 'updatedon':
      return [...teams].sort((t1, t2) => direction * (t1.updatedDate ?? '').localeCompare(t2.updatedDate ?? ''));
    case 'createdon':
      return [...teams].sort((t1, t2) => direction * (t1.creationDate ?? '').localeCompare(t2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return teams;
  }
};

const ManageSetupTeamsContent = ({ loading, teams, teamsUsers, users, handleCreateTeam, handleDeleteTeam, isError, load, removeUserFromTeam, addUsersToTeam }: ManageSetupTeamsContentProps) => {
  const intl = useIntl();
  const tableRef = useRef<TableRefType>(null);

  const [openSide, setOpenSide] = useState<TeamType | undefined>(undefined);

  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'team',
        label: intl.formatMessage({
          id: 'team',
          defaultMessage: 'Team',
        }),
        minWidth: '150px',
        sorted: () => {
          const newDirection = sortedColumn !== 'team' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('team');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'team' ? sortedDirection : false,
      },
      {
        id: 'description',
        label: intl.formatMessage({
          id: 'description',
          defaultMessage: 'Description',
        }),
        minWidth: '180px',
      },
      {
        id: 'members',
        label: intl.formatMessage({
          id: 'members',
          defaultMessage: 'Members',
        }),
        minWidth: '180px',
      },
      {
        id: 'createdby',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created by',
        }),
        minWidth: '180px',
      },

      {
        id: 'createdon',
        label: intl.formatMessage({
          id: 'createdon',
          defaultMessage: 'Created on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdon' ? sortedDirection : false,
      },
      {
        id: 'updatedon',
        label: intl.formatMessage({
          id: 'updatedon',
          defaultMessage: 'Updated on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedon' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  const rowClick = useCallback((team: TeamType) => {
    // edit row on click?
    setOpenSide(team);
  }, []);

  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  if (loading || !teams || !users) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      {teams.length > 0 ? (
        <>
          <TableResults ref={tableRef} hasBoxTitle={false} borderBottom={true} hasUnread={false} hasSelection={false} tableData={[]} tableHead={tableHeaders}>
            {getSortedTeams(teams, sortedColumn, sortedDirection)?.map((team) => {
              return (
                <TeamRow
                  key={team.id}
                  teamId={team.id}
                  teamName={team.name}
                  teamUsers={teamsUsers[team.id]?.join(';')}
                  email={team.email}
                  teamDescription={team.description}
                  creator={team.createdBy}
                  createdOn={team.creationDate}
                  updatedOn={team.updatedDate}
                  {...{ users, rowClick, handleCreateTeam, handleDeleteTeam, team }}
                />
              );
            })}
          </TableResults>

          <RightSidePanel
            open={openSide !== undefined}
            onClose={() => {
              setOpenSide(undefined);
            }}
          >
            <EditTeamContent
              team={openSide}
              close={() => {
                setOpenSide(undefined);
              }}
              {...{ users, teamsUsers, removeUserFromTeam, addUsersToTeam }}
            />
          </RightSidePanel>
        </>
      ) : (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            <FormattedMessage id="noTeams" defaultMessage={'No team has been defined'} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const TeamRow = memo(({ teamId, teamName, teamDescription, teamUsers, email, creator, createdOn, updatedOn, users, rowClick, handleCreateTeam, handleDeleteTeam, team }: TeamRowProps) => {
  const { currentUser } = useContext(UserContext);

  const usersInTeam = teamUsers?.split(';').map((u) => Number(u));

  const teamObj: TeamType = {
    id: teamId,
    description: teamDescription,
    name: teamName,
    email: email,    
  };
  return (
    <TableRow
      hover
      onClick={(_) => {
        rowClick?.(teamObj);
      }}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      //   selected={isItemSelected}
      sx={{ cursor: rowClick ? 'pointer' : 'cursor' }}
    >
      <TableCell align={'left'}>{teamName}</TableCell>
      <TableCell align={'left'}>{teamDescription}</TableCell>
      <TableCell align={'left'}>{<MemberList members={usersInTeam || []} users={users} />}</TableCell>
      <TableCell align={'left'}>
        <Box display="flex" alignItems={'center'}>
          {GetUser(creator ?? '', users)}
        </Box>
      </TableCell>
      <TableCell align={'left'}>{createdOn && <DisplayDate date={createdOn} />}</TableCell>
      <TableCell align={'left'}>{updatedOn && <DisplayDate date={updatedOn} />}</TableCell>
      <TableCell align={'left'}>
        <Box display={'flex'} alignItems={'center'}>
          {currentUser?.isSystemAdmin && (
            <>
              <ShadowedButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleCreateTeam(team);
                }}
                sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
              >
                <FormattedMessage id="edit" defaultMessage="Edit" />
              </ShadowedButton>
              <IconButton
                className="square-icon-button"
                sx={{ ml: 1, fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', '&:hover': { color: 'var(--color-fushia)' } }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleDeleteTeam(teamObj);
                }}
              >
                <GarbageIcon fontSize="inherit" />
              </IconButton>
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
});

const EditTeam = memo(({ team, setTeam, organizations, roles}: EditTeamProps) => {
  
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [teamParams] = useState<Partial<TeamType>>(team ?? {});
  const [name, setName] = useState<string>(team?.name ?? '');
  const [description, setDescription] = useState<string>(team?.description ?? '');
  const [email, setEmail] = useState<string>(team?.email ?? '');

  const [creatingTeam, setCreatingTeam] = useState(false);

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);
  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  }, []);
  const handleChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const hanldeChangeOrganizations = useCallback((newOrganizations: OrganizationUnit[]) => {
    if (team) {
      team.organizationUnits = newOrganizations;
    }
  }, [team]);

  const hanldeChangeRoles = useCallback((newRoles: RoleType[]) => {
    if (team) 
      team.roles = newRoles;
  }, [team]);

  const hanldeChangeAdmin = useCallback((newAdmins: {key:string;value:string}[]) => {
    if (team && newAdmins) {
      team.isSystemAdmin = false;
      team.isDatasetAdmin = false;
      team.isLibraryAdmin = false;
      team.isScreeningAdmin = false;
      team.isUserAdmin = false;
      for (let i=0;i<newAdmins.length;i++) {
        if (newAdmins[i].key==='setupAdmin') {
          team.isSystemAdmin = true;
        } else if (newAdmins[i].key==='userAdministration') {
          team.isUserAdmin = true;
        } else if (newAdmins[i].key==='datasetsAdministration') {
          team.isDatasetAdmin = true;
        } else if (newAdmins[i].key==='libraryAdministration') {
          team.isLibraryAdmin = true;
        } else if (newAdmins[i].key==='screeningAdministration') {
          team.isScreeningAdmin = true;
        }
      }
    }      
  }, [team]);

  const addOrCreateTeam = useCallback(async () => {
    blockModal?.(true);
    setCreatingTeam(true);

    const result = await setTeam(
      {
        name,
        description,
        email       
      },
      team
    );

    setCreatingTeam(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = team
        ? intl.formatMessage({
            id: 'updateTeamSuccess',
            defaultMessage: 'Team updated',
          })
        : intl.formatMessage({
            id: 'createTeamSuccess',
            defaultMessage: 'New team created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = team
        ? intl.formatMessage({
            id: 'updateTeamError',
            defaultMessage: 'An error occured while updating the team',
          })
        : intl.formatMessage({
            id: 'createTeamError',
            defaultMessage: 'An error occured while creating the team',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, team, setTeam, name, description, email, addSnackbar, intl]);

  const setMessage = team ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const SearchFiltersMemo = memo(SearchFilters);
  const organizatinonsRef = useRef<FilterButtonRefType | null>(null);
  const rolesRef = useRef<FilterButtonRefType | null>(null);
  const adminRef = useRef<FilterButtonRefType | null>(null);

  const organizationsButtons: SearchFiltersButtonType[] = useMemo(() => {

    const validOrganizationIds = organizations?.map((t) => t.id) ?? [];
    const filteredOrganizations = teamParams.organizationUnits?.filter((org) => validOrganizationIds.includes(org.id)) ?? [];

    return [
      {
        ref: organizatinonsRef,
        text: <FormattedMessage id="chooseOrganizations" defaultMessage="Selected organizations" />,
        inputName: 'organizations',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
            list={organizations?.map((org) => ({ key: String(org.id), value: org.name }))?? []}                       
          />
        ),
        initialValue: {
          nbValues: filteredOrganizations.length ?? 0,
          values: filteredOrganizations.map((org) => String(org.id)),
        },
      },
    ];
  }, [intl, organizations, teamParams.organizationUnits]);

  const onActivateOrgUnits = useCallback(() => {
    const selectedValues = organizatinonsRef.current?.getValue();
    if (selectedValues && organizations) {
      const newOrganizations = organizations.filter((org) => (selectedValues.values || []).includes(String(org.id)));
      hanldeChangeOrganizations(newOrganizations);
    }
  }, [hanldeChangeOrganizations, organizations]);

  const rolesButtons: SearchFiltersButtonType[] = useMemo(() => {   
      const validRolesIds = roles?.map((t) => t.id) ?? [];
      const filteredRoles = teamParams.roles?.filter((org) => validRolesIds.includes(org.id)) ?? [];

      return [
          {
            ref: rolesRef,
            text: <FormattedMessage id="chooseRoles" defaultMessage="Selected roles" />,
            inputName: 'roles',
            filterContent: (
              <FilterCheckboxes
                maxHeight="min(30vh, 200px)"
                title={intl.formatMessage({ id: 'roles', defaultMessage: 'Roles' })}
                list={roles?.map((role) => ({ key: String(role.id), value: role.name })) ?? []}                
              />
            ),
            initialValue: {
              nbValues: filteredRoles.length ?? 0,
              values: filteredRoles.map((role) => String(role.id)),
            },
          },
        ];
  }, [intl, teamParams, roles]);

  const onActivateRoles = useCallback(() => {
    const selectedValues = rolesRef.current?.getValue();
    if (selectedValues && roles) {
      const newRoles = roles.filter((roles) => (selectedValues.values || []).includes(String(roles.id)));
      hanldeChangeRoles(newRoles);
    }
  }, [hanldeChangeRoles, roles]);

  const rolesAdmins: SearchFiltersButtonType[] = useMemo(() => {   

    const filteredRoles : string[] = [];
    if (team?.isDatasetAdmin) {
      filteredRoles.push('datasetsAdministration');
    }
    if (team?.isSystemAdmin) {
      filteredRoles.push('setupAdmin');
    }
    if (team?.isUserAdmin) {
      filteredRoles.push('userAdministration');
    }
    if (team?.isLibraryAdmin) {
      filteredRoles.push('libraryAdministration');
    }
    if (team?.isScreeningAdmin) {
      filteredRoles.push('screeningAdministration');
    }

    return [
        {
          ref: adminRef,
          text: <FormattedMessage id="chooseAdmins" defaultMessage="Selected objects" />,
          inputName: 'admins',
          filterContent: (
            <FilterCheckboxes
              maxHeight="min(30vh, 200px)"
              title={intl.formatMessage({ id: 'admins', defaultMessage: 'Objects' })}
              list={TeamAdministrationPrint}                
            />
          ),
          initialValue: {
            nbValues: filteredRoles.length ?? 0,
            values: filteredRoles.map((role) => String(role)),
          },
        },
      ];
}, [intl, team]);

const onActivateAdmin = useCallback(() => {
  const selectedValues = adminRef.current?.getValue();
  if (selectedValues) {
    const newAdmin = TeamAdministrationPrint.filter((admins) => (selectedValues.values || []).includes(String(admins.key)));
    hanldeChangeAdmin(newAdmin);
  }
}, [hanldeChangeAdmin]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">
          <FormattedMessage id="teamName" defaultMessage={'Team name'} />
        </Typography>

        <SearchText
          fullWidth
          value={name}
          onChange={handleChangeName}
          placeholder={intl.formatMessage({ id: 'chooseName', defaultMessage: 'e.g., "Global Investigation"' })}
          InputProps={MemoInputProps}
          disabled={creatingTeam}
        />

        <Typography className="modal-label" mt={2}>
          <FormattedMessage id="teamDescription" defaultMessage={'Team description'} />
        </Typography>

        <SearchText
          fullWidth
          value={description}
          onChange={handleChangeDescription}
          placeholder={intl.formatMessage({ id: 'optional', defaultMessage: 'Optional' })}
          multiline
          rows={4}
          InputProps={MemoInputProps}
          disabled={creatingTeam}
        />

        <Typography className="modal-label" mt={2}>
          <FormattedMessage id="teamEmail" defaultMessage={'Email'} />
        </Typography>

        <SearchText
          fullWidth
          value={email}
          onChange={handleChangeEmail}
          placeholder={intl.formatMessage({ id: 'chooseEmail', defaultMessage: 'e.g., "investigation@organization.com"' })}
          InputProps={MemoInputProps}
          disabled={creatingTeam}
        />

        <Typography className="modal-label" mt={2}>
          <FormattedMessage id="organizations" defaultMessage={'Organizations'} />
        </Typography>
        <SearchFiltersMemo hideClearButton={false} onActivate={onActivateOrgUnits} searchFiltersButtons={organizationsButtons} />

        <Typography className="modal-label" mt={2}>
            <FormattedMessage id="roles" defaultMessage={'Roles'} />
        </Typography>
        <SearchFiltersMemo  hideClearButton={false} onActivate={onActivateRoles} searchFiltersButtons={rolesButtons} />
       
        <Typography className="modal-label" mt={2}>
            <FormattedMessage id="admins" defaultMessage={'Can edit'} />
        </Typography>
        <SearchFiltersMemo  hideClearButton={false} onActivate={onActivateAdmin} searchFiltersButtons={rolesAdmins} />
        
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {creatingTeam ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={!name.trim() || !email.trim() || !validateEmail(email)} onClick={addOrCreateTeam} disableElevation sx={{ textTransform: 'none' }}>
              {setMessage}
            </Button>
          </>
        )}
      </Box>

    </Box>
  );
});


const DeleteTeam = memo(({ team, removeTeam }: DeleteTeamProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingTeam, setDeletingTeam] = useState(false);

  const deleteTeam = useCallback(async () => {
    blockModal?.(true);
    setDeletingTeam(true);

    const result = await removeTeam(team.id);
    setDeletingTeam(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteTeamSuccess',
        defaultMessage: 'Team deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteTeamError',
        defaultMessage: 'An error occured while deleting the team',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, team, removeTeam, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Team: {team?.name}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteTeamMessage" defaultMessage={'Are you sure that you want to delete this team?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingTeam ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingTeam} onClick={deleteTeam} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

const maxMembers = 4;
const MemberList = memo(({ members, users }: { members: number[]; users?: UserType[] }) => {
  return (
    <Box display="flex" sx={{ minWidth: 0 }}>
      {members.length > 0 ? (
        <>
          {members.slice(0, maxMembers).map((member, ind) => {
            const [avatar, fullName] = GetAvatar(member, users, true, '10px') as [ReactNode, string];
            return (
              <ContentWithDarkTooltip key={member} tooltip={fullName} placement="top-end" pad={1}>
                <Box
                  key={member}
                  sx={{ ml: ind > 0 ? '-4px' : 0, borderRadius: '50%', border: '1px solid white', color: 'var(--color-lightgray4)', width: '24px', fontSize: '24px', '& svg': { display: 'block' } }}
                >
                  {avatar}
                </Box>
              </ContentWithDarkTooltip>
            );
          })}
          {members.length > maxMembers && <Box ml={'.5rem'}>+{members.length - maxMembers}</Box>}
        </>
      ) : (
        <UnknownMessage />
      )}
    </Box>
  );
});

const EditTeamContent = memo(({ team, close, users, teamsUsers, removeUserFromTeam, addUsersToTeam }: EditTeamContentProps) => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const { currentUser } = useContext(UserContext);

  const [removingUser, setRemovingUsers] = useState<number[]>([]);

  const userInTeam = useMemo(() => {
    return team?.id ? teamsUsers[team?.id] ?? [] : [];
  }, [team, teamsUsers]);
  const usersToAdd = useMemo(() => {
    return users.filter((u) => !userInTeam.includes(u.id));
  }, [users, userInTeam]);

  const addUsersInTeam = useCallback(() => {
    if (!team || usersToAdd.length <= 0) return;
    toggleModal?.({
      title: intl.formatMessage({ id: 'addUserInTeam', defaultMessage: 'Add users to' }) + ` "${team?.name}"`,
      modalContent: <AddUserInTeam {...{ team, usersToAdd, addUsersToTeam }} />,
      maxWidth: 'sm',
    });
  }, [intl, toggleModal, team, usersToAdd, addUsersToTeam]);

  const removeUser = useCallback(
    async (user: UserType) => {
      setRemovingUsers((ru) => [...ru, user.id]);
      try {
        const updatedUser = await removeUserFromTeam(team, user);
        if (updatedUser === undefined) {
          const notAllowedMessage = intl.formatMessage({
            id: 'notAllowedUserUpdated',
            defaultMessage: 'You do not have the rights to updated users',
          });
          addSnackbar(notAllowedMessage, 'error');
        }
      } catch {
        const removeErrorMessage = intl.formatMessage({
          id: 'removeUserFromTeamError',
          defaultMessage: 'An error occured while removing user from team',
        });
        addSnackbar(removeErrorMessage, 'error');
      }
      setRemovingUsers((ru) => ru.filter((v) => v !== user.id));
    },
    [team, removeUserFromTeam, addSnackbar, intl]
  );

  return (
    <Box p={4} width={'min(85vw, 400px)'} display="flex" flexDirection={'column'} height={'100%'}>
      <Box display="flex" width="100%" gap={'1rem'} alignItems={'center'}>
        <Box sx={{ flex: 1, fontWeight: 600, lineHeight: 1.2, fontSize: 'var(--fs-20)', color: 'var(--color-darkgray)', minWidth: 0, overflow: 'hidden', wordBreak: 'break-word' }}>{team?.name}</Box>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            fontSize: '1rem',
            color: 'var(--color-lightgray4)',
            borderRadius: '5px',
            '&:hover': {
              color: 'var(--color-table-content)',
              backgroundColor: 'var(--color-hover-button)',
            },
          }}
        >
          <CrossIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box mb={3} sx={{ fontWeight: 500, lineHeight: 1.2, fontSize: 'var(--fs-14)', color: '#7A7E85', whiteSpace: 'nowrap', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {team?.email}
      </Box>

      <TabsNavigation activeTab={''} onChange={() => {}} tabTitles={[]} label={'team content'} allLabel={intl.formatMessage({ id: 'members', defaultMessage: 'Members' })} />

      <Box my={3} flex={1} sx={{ minHeight: 0, overflowY: 'auto' }} className="custom-scrollbar">
        {userInTeam.length <= 0 ? (
          <Box fontWeight={500} textAlign={'center'} sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
            <FormattedMessage id="noMemberInTeam" defaultMessage={'There is no user in the team'} />
          </Box>
        ) : (
          <Box sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}>
            {users
              .filter((u) => userInTeam.includes(u.id))
              .map((user) => {
                return (
                  <Box
                    key={user.id}
                    display={'flex'}
                    alignItems={'center'}
                    px={1}
                    py={1}
                    className="rounded-border"
                    sx={{
                      boxSizing: 'content-box',
                      minHeight: '30px',
                      '& .delete-container': { display: 'none' },
                      '&:hover': {
                        '& .delete-container': { display: 'block' },
                        backgroundColor: 'var(--color-hover-background)',
                      },
                    }}
                  >
                    <Box display="flex" sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
                      {GetUser(user.userName, users, true, '20px')}
                    </Box>

                    {currentUser?.isSystemAdmin && (
                      <Box className="delete-container">
                        {removingUser.includes(user.id) ? (
                          <Box>
                            <Loader cssStyle={{ width: '20px' }} />
                          </Box>
                        ) : (
                          <ShadowedButton onClick={() => removeUser(user)} sx={{ fontSize: 'var(--fs-12)', px: '4px', py: '8px', lineHeight: 1, backgroundColor: 'white' }}>
                            <FormattedMessage id="delete" defaultMessage="Delete" />
                          </ShadowedButton>
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>

      {currentUser?.isSystemAdmin && (
        <Box textAlign={'center'}>
          <AddButton
            onClick={() => {
              addUsersInTeam();
            }}
            disabled={usersToAdd.length <= 0}
          >
            <FormattedMessage id="addUsers" defaultMessage={'Add users'} />
          </AddButton>
        </Box>
      )}
    </Box>
  );
});

const AddUserInTeam = ({ team, usersToAdd, addUsersToTeam }: { team: TeamType; usersToAdd: UserType[]; addUsersToTeam: ManageSetupTeamsContentProps['addUsersToTeam'] }) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [addingUsers, setAddingUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<{ nbValues: number; values: string[] }>({ nbValues: 0, values: [] });

  const setSelected = useCallback(({ nbValues, values }: { nbValues: number; values: string[] }) => {
    setSelectedUsers({ nbValues, values });
  }, []);

  const addUsers = useCallback(
    async (selection: typeof selectedUsers) => {
      blockModal?.(true);
      setAddingUsers(true);

      const newUsers = usersToAdd.filter((u) => selection.values.includes(String(u.id)));

      const success = await addUsersToTeam(team, newUsers);

      blockModal?.(false);
      setAddingUsers(false);

      if (success) {
        closeModal?.();
      } else {
        const updateErrorMessage = intl.formatMessage({
          id: 'updateTeamError',
          defaultMessage: 'An error occured while updating the team',
        });

        addSnackbar(updateErrorMessage, 'error');
      }
    },
    [addUsersToTeam, team, usersToAdd, blockModal, addSnackbar, intl, closeModal]
  );

  const virtualCheckboxList = useMemo(() => {
    return usersToAdd.map((u) => ({ key: String(u.id), value: u.fullName ?? u.userName, displayValue: GetUser(u.id, usersToAdd) }));
  }, [usersToAdd]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        {/* <Typography className="modal-label">
          <FormattedMessage id="users" defaultMessage={'Users'} />
        </Typography> */}

        <FilterVirtualCheckboxes
          title={intl.formatMessage({ id: 'users', defaultMessage: 'Users' })}
          list={virtualCheckboxList}
          hideTitle
          setNewFilterValue={setSelected}
          setOnSelection
          noBorder
          virtualWidth={'100%'}
        />
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {addingUsers ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={selectedUsers.nbValues <= 0} onClick={() => addUsers(selectedUsers)} disableElevation sx={{ textTransform: 'none' }}>
              {intl.formatMessage({ id: 'addUsers', defaultMessage: 'Add users' })}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
export default ManageSetupTeams;
