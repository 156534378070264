import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ShipIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17.992" height="16" viewBox="0 0 17.992 16">
        <path
          d="M5.815,1a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V2h1.25a1.75,1.75,0,0,1,1.749,1.749v3.7l.809.3a1.749,1.749,0,0,1,.622,2.874l-2.149,2.152a.749.749,0,0,1-1.059-1.059l2.152-2.152a.249.249,0,0,0-.087-.409L8.9,6.832a.241.241,0,0,0-.175,0L2.535,9.153a.248.248,0,0,0-.087.409L4.6,11.714a.749.749,0,1,1-1.059,1.059L1.386,10.624A1.749,1.749,0,0,1,2.007,7.75l.809-.3v-3.7A1.75,1.75,0,0,1,4.566,2h1.25Zm-1.5,2.749V6.885L8.2,5.429a1.731,1.731,0,0,1,1.228,0l3.886,1.456V3.749a.251.251,0,0,0-.25-.25h-8.5A.251.251,0,0,0,4.316,3.749Zm-1,9.687a4.052,4.052,0,0,0,2.5,1.059,4.052,4.052,0,0,0,2.5-1.059.749.749,0,0,1,1,0,4.06,4.06,0,0,0,2.5,1.059,4.052,4.052,0,0,0,2.5-1.059.749.749,0,0,1,1,0,4.455,4.455,0,0,0,1.915.993.751.751,0,1,1-.328,1.465,6.107,6.107,0,0,1-2.084-.95,5.368,5.368,0,0,1-3,1.056,5.368,5.368,0,0,1-3-1.056,5.368,5.368,0,0,1-3,1.056,5.368,5.368,0,0,1-3-1.056,6.048,6.048,0,0,1-2.084.95A.751.751,0,0,1,.4,14.429a4.525,4.525,0,0,0,1.912-.99.749.749,0,0,1,1,0Z"
          transform="translate(0.188)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
