/**
 * TitleLayout.tsx
 * Display a title with a button
 */

/* packages */
import { PropsWithChildren, ReactNode, memo } from 'react';

/* components */
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';
import { AddButton } from 'components/Buttons/Buttons';

/* types */
interface TitleLayoutProps {
  pageTitle: string;
  addButton?: {
    addButtonText: string | ReactNode;
    handleButtonClick(): void;
    disableAddButton?: boolean;
  };
}
const TitleLayout = memo(({ pageTitle, addButton, children }: PropsWithChildren<TitleLayoutProps>) => {
  return (
    <>
      <PageHeader>
        <PageTitle title={pageTitle} />

        {addButton && (
          <AddButton onClick={addButton.handleButtonClick} disabled={addButton.disableAddButton}>
            {addButton.addButtonText}
          </AddButton>
        )}
      </PageHeader>
      {children}
    </>
  );
});

export default TitleLayout;
