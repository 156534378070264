/**
 * TableRrow.tsx
 * Display the row of a table result
 */
/* packages */
import React, { ReactNode } from 'react';

/* components */
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { TableHeadElement } from './TableHead';

/* types */
interface EnhancedTableRowProps {
  isItemSelected?: boolean;
  labelId?: string;
  hasSelection?: boolean;
  hasUnread?: boolean;
  tableHead: TableHeadElement[];
  row: TableRowData;
  handleSelected?(event: React.ChangeEvent<HTMLInputElement>, id: string): void;
}

export interface TableRowData {
  id: string;
  unread?: boolean;
  columns: { [col: TableHeadElement['id']]: ReactNode };
  rowClick?(): void;
  align?: 'left' | 'center' | 'right';
}

/* elements */
const EnhancedTableRow = (props: EnhancedTableRowProps) => {
  const { isItemSelected, labelId, hasSelection, hasUnread, tableHead, row, handleSelected } = props;
  return (
    <>
      <TableRow
        hover
        onClick={(_) => {
          row.rowClick?.();
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        //   selected={isItemSelected}
        sx={{ cursor: row.rowClick ? 'pointer' : 'cursor' }}
      >
        {hasUnread && <TableCell className="unread-cell">{row.unread && <Box className="unread-mark"></Box>}</TableCell>}
        {hasSelection && (
          <TableCell padding="checkbox" className="select-cell">
            <Checkbox
              disableRipple
              color="primary"
              name={labelId}
              checked={isItemSelected}
              inputProps={
                {
                  //   'aria-labelledby': labelId,
                }
              }
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                handleSelected?.(event, row.id);
              }}
              sx={{ ml: hasUnread ? 0 : 2, py: 1, '& .MuiSvgIcon-root': { fontSize: 20, color: isItemSelected ? 'unset' : 'var(--color-grayHeaderBorder)' } }}
            />
          </TableCell>
        )}
        {tableHead.map((th) => {
          const colId = th.id;
          const cellElement = row.columns[colId] ?? '';
          // id={labelId}
          return (
            <TableCell key={`${colId}-${row.id}`} align={row.align ?? 'left'}>
              {cellElement}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default EnhancedTableRow;
