import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TeamIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g id="Groupe_822" data-name="Groupe 822" transform="translate(-1287 -893)">
          <circle cx="8" cy="8" r="8" transform="translate(1287 893)" fill="currentcolor" />
          <path
            d="M1.937,6.125A1.292,1.292,0,1,0,.646,4.833,1.293,1.293,0,0,0,1.937,6.125Zm9.041,0A1.292,1.292,0,1,0,9.687,4.833,1.293,1.293,0,0,0,10.978,6.125Zm.646.646H10.332a1.288,1.288,0,0,0-.91.375,2.952,2.952,0,0,1,1.516,2.208H12.27a.645.645,0,0,0,.646-.646V8.062A1.293,1.293,0,0,0,11.624,6.77Zm-5.166,0A2.26,2.26,0,1,0,4.2,4.51,2.259,2.259,0,0,0,6.458,6.77Zm1.55.646H7.84a3.121,3.121,0,0,1-2.765,0H4.908A2.325,2.325,0,0,0,2.583,9.741v.581a.969.969,0,0,0,.969.969H9.364a.969.969,0,0,0,.969-.969V9.741A2.325,2.325,0,0,0,8.008,7.416Zm-4.514-.27a1.288,1.288,0,0,0-.91-.375H1.292A1.293,1.293,0,0,0,0,8.062v.646a.645.645,0,0,0,.646.646h1.33A2.959,2.959,0,0,1,3.493,7.146Z"
            transform="translate(1288.542 894.229)"
            fill="#fff"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
