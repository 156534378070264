/**
 * PageTitle.tsx
 * Display the page title
 */
/* packages */
import { PropsWithChildren } from 'react';

/* components */
import Box from '@mui/material/Box';

const PageContent = ({ children }: PropsWithChildren) => {
  return (
    // flex={1}
    <Box id="page-content" width={'100%'} overflow={'hidden'} display={'flex'} flexDirection={'column'} px={'var(--main-inline-padding)'}>
      {children}
    </Box>
  );
};

export default PageContent;
