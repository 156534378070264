import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CrossIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13.754" height="13.754" viewBox="0 0 13.754 13.754">
        <path
          d="M21.254,8.885,19.869,7.5l-5.492,5.492L8.885,7.5,7.5,8.885l5.492,5.492L7.5,19.869l1.385,1.385,5.492-5.492,5.492,5.492,1.385-1.385-5.492-5.492Z"
          transform="translate(-7.5 -7.5)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
