/**
 * InvestigateSearch.tsx
 */
/* packages */
import { PropsWithChildren, useContext, useEffect, useState, useCallback, useMemo, useRef, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
import { RolesContext } from 'contextProviders/RolesProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { UserContext } from 'contextProviders/UserProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { AddButton } from 'components/Buttons/Buttons';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';
import Loader from 'components/Loader/Loader';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { GetUser } from 'components/GetUser/GetUser';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import TableResults, { TableRefType } from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
// import { TableRowData } from 'components/TableResults/TableRow';

import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import SearchText from 'components/SearchElements/SearchText/SearchText';

import { GarbageIcon } from 'icons/garbage/garbage';

/* utilities */
import { PermissionsPrint, PermissionString } from 'utilities/CheckUserPermissions';
import { displayWarning } from 'utilities/Logger';

/* types */
import { SortedDirectionType } from 'components/TableResults/TableHead';
import { UserType, RoleType } from 'models/user';

interface ManageSetupRolesLayoutProps {
  handleEditRole(role?: RoleType): void;
  // disableAddButton: boolean;
  loading: boolean;
  isError: boolean;
}
interface ManageSetupRolesContentProps {
  loading: boolean;
  isError: boolean;
  roles?: RoleType[];
  load(abortController?: AbortController): void;
  handleEditRole(role?: RoleType): void;
  handleDeleteRole(role: RoleType): void;
}
interface EditRoleProps {
  role?: RoleType;
  setRole(data: Partial<RoleType>, role?: RoleType): Promise<{ status: string }>;
}
interface DeleteRoleProps {
  role: RoleType;
  removeRole(removedId: number): Promise<{ status: string }>;
}
/* elements */
const ManageSetupRoles = () => {
  const intl = useIntl();
  const { loadingRoles, roles, listRoles, addOrUpdateRole, deleteRole } = useContext(RolesContext);
  const { loadingAllUsers, allUsers, listAllUsers } = useContext(AllUsersContext);
  const { toggleModal } = useAddModal();
  const { currentUser } = useContext(UserContext);
  const [initialLoading, setInitialLoading] = useState(true);

  // const [roles, setRoles] = useState<RoleType[] | undefined>(undefined);
  // const [rolesError, setRolesError] = useState(false);

  const load = useCallback(
    async (abortController?: AbortController) => {
      listAllUsers?.(abortController);
      listRoles?.(abortController);
    },
    [listRoles, listAllUsers]
  );

  useEffect(() => {
    // const abortController = new AbortController();
    setInitialLoading(false);
    load();

    // return () => {
    //   abortController.abort();
    // };
  }, [load]);

  const setRole = useCallback(
    async (data: Partial<RoleType>, role?: RoleType): Promise<{ status: string }> => {
      const newData = {
        ...role,
        ...data,
      };

      try {
        await addOrUpdateRole?.(newData, role ? false : true);
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateRole]
  );

  const removeRole = useCallback(
    async (removedId: number): Promise<{ status: string }> => {
      try {
        const deletedRole = await deleteRole?.(removedId);

        if (deletedRole) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteRole]
  );

  const handleEditRole = useCallback(
    (role?: RoleType) => {
      if (!currentUser?.isSystemAdmin) return;
      toggleModal?.({
        title: role ? intl.formatMessage({ id: 'editRole', defaultMessage: 'Edit role' }) : intl.formatMessage({ id: 'addRole', defaultMessage: 'Add role' }),
        modalContent: <EditRole {...{ role, setRole }} />,
      });
    },
    [intl, toggleModal, setRole, currentUser]
  );

  const handleDeleteRole = useCallback(
    (role: RoleType) => {
      if (!currentUser?.isSystemAdmin) return;
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteRole', defaultMessage: 'Delete role' }),
        modalContent: <DeleteRole {...{ role, removeRole }} />,
      });
    },
    [intl, toggleModal, removeRole, currentUser]
  );

  const isError = (!loadingRoles && !roles) || (!loadingAllUsers && !allUsers);
  const loading = initialLoading || loadingRoles || loadingAllUsers;

  return (
    // disableAddButton: roles === undefined
    <ManageSetupRolesLayout {...{ handleEditRole, loading, isError }}>
      <ManageSetupRolesContent {...{ loading, isError, roles, load, handleEditRole, handleDeleteRole }} />
    </ManageSetupRolesLayout>
  );
};

const ManageSetupRolesLayout = ({ handleEditRole, loading, isError, children }: PropsWithChildren<ManageSetupRolesLayoutProps>) => {
  const intl = useIntl();
  const { currentUser } = useContext(UserContext);
  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'ManageSetupRolesTitle', defaultMessage: 'Roles' })} />
        {currentUser?.isSystemAdmin && (
          <AddButton
            onClick={() => {
              handleEditRole();
            }}
            disabled={loading || isError}
          >
            <FormattedMessage id="addRole" defaultMessage={'Add role'} />
          </AddButton>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const getSortedRoles = (roles: RoleType[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!roles || !sortedDirection) return roles;

  const direction = sortedDirection === 'asc' ? 1 : -1;
  switch (sortedColumn) {
    case 'role':
      return [...roles].sort((t1, t2) => direction * t1.name.localeCompare(t2.name));
    case 'updatedon':
      return [...roles].sort((t1, t2) => direction * (t1.updatedDate ?? '').localeCompare(t2.updatedDate ?? ''));
    case 'createdon':
      return [...roles].sort((t1, t2) => direction * (t1.creationDate ?? '').localeCompare(t2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return roles;
  }
};

const ManageSetupRolesContent = ({ loading, isError, roles, load, handleEditRole, handleDeleteRole }: ManageSetupRolesContentProps) => {
  const intl = useIntl();
  const tableRef = useRef<TableRefType>(null);
  const { allUsers } = useContext(AllUsersContext);
  const { currentUser } = useContext(UserContext);

  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'role',
        label: intl.formatMessage({
          id: 'role',
          defaultMessage: 'Role',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'role' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('role');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'role' ? sortedDirection : false,
      },
      {
        id: 'description',
        label: intl.formatMessage({
          id: 'description',
          defaultMessage: 'Description',
        }),
        minWidth: '180px',
      },
      // {
      //   id: 'teams',
      //   label: intl.formatMessage({
      //     id: 'teams',
      //     defaultMessage: 'Teams',
      //   }),
      //   minWidth: '180px',
      // },
      {
        id: 'createdby',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created by',
        }),
        minWidth: '180px',
      },
      {
        id: 'createdon',
        label: intl.formatMessage({
          id: 'createdon',
          defaultMessage: 'Created on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdon' ? sortedDirection : false,
      },
      {
        id: 'updatedon',
        label: intl.formatMessage({
          id: 'updatedon',
          defaultMessage: 'Updated on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedon' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  const rowClick = useCallback(
    (role: RoleType) => {
      if (!currentUser?.isSystemAdmin) return;
      // edit row on click?
      handleEditRole(role);
    },
    [handleEditRole, currentUser]
  );

  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }
  if (loading) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  // display roles array
  return (
    <Box>
      {(roles?.length ?? 0) > 0 ? (
        <TableResults ref={tableRef} hasBoxTitle={false} borderBottom={true} hasUnread={false} hasSelection={false} tableData={[]} tableHead={tableHeaders}>
          {getSortedRoles(roles, sortedColumn, sortedDirection)?.map((role) => {
            return <RoleRow key={role.id} role={role} users={allUsers} {...{ rowClick, handleEditRole, handleDeleteRole }} />;
          })}
        </TableResults>
      ) : (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            <FormattedMessage id="noRoles" defaultMessage={'No role has been defined'} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

interface RoleRowProps {
  role: RoleType;
  users?: UserType[] | undefined;
  rowClick?(role: RoleType): void;
  // handleEditRole: ManageSetupRolesContentProps['handleEditRole'];
  handleDeleteRole: ManageSetupRolesContentProps['handleDeleteRole'];
}

const RoleRow = memo(({ role, users, rowClick, handleDeleteRole }: RoleRowProps) => {
  const { currentUser } = useContext(UserContext);
  return (
    <TableRow
      hover
      onClick={(_) => {
        if (!currentUser?.isSystemAdmin) return;
        rowClick?.(role);
      }}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      //   selected={isItemSelected}
      sx={{ cursor: rowClick ? 'pointer' : 'cursor' }}
    >
      <TableCell align={'left'}>{role.name}</TableCell>
      <TableCell align={'left'}>{role.description}</TableCell>
      <TableCell align={'left'}>
        <Box display="flex" alignItems={'center'}>
          {GetUser(role.createdBy ?? '', users)}
        </Box>
      </TableCell>
      <TableCell align={'left'}>{role.creationDate && <DisplayDate date={role.creationDate} />}</TableCell>
      <TableCell align={'left'}>{role.updatedDate && <DisplayDate date={role.updatedDate} />}</TableCell>
      <TableCell align={'left'}>
        <Box display={'flex'} alignItems={'center'}>
          {currentUser?.isSystemAdmin && (
            <IconButton
              className="square-icon-button"
              sx={{ ml: 1, fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', '&:hover': { color: 'var(--color-fushia)' } }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDeleteRole(role);
              }}
            >
              <GarbageIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
});

const SearchFiltersMemo = memo(SearchFilters);

const getFilteredPermissions = (perm: PermissionString[]) => {
  return perm.filter((p) => p in PermissionsPrint);
};
const EditRole = memo(({ role, setRole }: EditRoleProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [name, setName] = useState<string>(role?.name ?? '');
  const [description, setDescription] = useState<string>(role?.description ?? '');
  const [permissions, setPermissions] = useState<PermissionString[]>(getFilteredPermissions(role?.permissions ?? []));

  const [sendingRole, setSendingRole] = useState(false);

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  }, []);

  const permissionsRef = useRef<FilterButtonRefType | null>(null);

  const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
    const filteredPerm = getFilteredPermissions(role?.permissions || []);
    return [
      {
        ref: permissionsRef,
        text: <FormattedMessage id="choosePermissions" defaultMessage="Selected permissions" />,
        inputName: 'permissions',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'permissions', defaultMessage: 'Permissions' })}
            list={Object.entries(PermissionsPrint).map(([key, value]) => ({ key, value }))}
          />
        ),
        initialValue: {
          nbValues: filteredPerm.length ?? 0,
          values: filteredPerm, //role?.permissions ? role.permissions.map((p) => p) : [],
        },
      },
    ];
  }, [intl, role?.permissions]);

  const onActivatePermissions = useCallback(() => {
    const selectedValues = permissionsRef.current?.getValue();
    setPermissions((selectedValues?.values as PermissionString[]) ?? []);
  }, []);

  const sendRole = useCallback(async () => {
    blockModal?.(true);
    setSendingRole(true);

    const result = await setRole(
      {
        name,
        description,
        permissions,
      },
      role
    );
    setSendingRole(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = role
        ? intl.formatMessage({
            id: 'updateRoleSuccess',
            defaultMessage: 'Role updated',
          })
        : intl.formatMessage({
            id: 'createRoleSuccess',
            defaultMessage: 'New role created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = role
        ? intl.formatMessage({
            id: 'updateRoleError',
            defaultMessage: 'An error occured while updating the role',
          })
        : intl.formatMessage({
            id: 'createRoleError',
            defaultMessage: 'An error occured while creating the role',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, role, setRole, name, description, permissions, addSnackbar, intl]);

  const setMessage = role ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'add', defaultMessage: 'Add' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">
          <FormattedMessage id="roleName" defaultMessage={'Role name'} />
        </Typography>

        <SearchText
          fullWidth
          value={name}
          onChange={handleChangeName}
          placeholder={intl.formatMessage({ id: 'chooseName', defaultMessage: 'e.g., "Investigator"' })}
          InputProps={MemoInputProps}
          disabled={sendingRole}
        />

        <Typography className="modal-label" mt={2}>
          <FormattedMessage id="roleDescription" defaultMessage={'Role description'} />
        </Typography>

        <SearchText
          fullWidth
          value={description}
          onChange={handleChangeDescription}
          placeholder={intl.formatMessage({ id: 'optional', defaultMessage: 'Optional' })}
          multiline
          rows={4}
          InputProps={MemoInputProps}
          disabled={sendingRole}
        />

        <Typography className="modal-label" mt={2}>
          <FormattedMessage id="permissions" defaultMessage={'Permissions'} />
        </Typography>
        <Box mt={2}>
          <SearchFiltersMemo disabled={sendingRole} hideClearButton={true} onActivate={onActivatePermissions} {...{ searchFiltersButtons }} />
        </Box>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {sendingRole ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={!name.trim() || permissions.length === 0} onClick={sendRole} disableElevation sx={{ textTransform: 'none' }}>
              {setMessage}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

const DeleteRole = memo(({ role, removeRole }: DeleteRoleProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingRole, setDeletingRole] = useState(false);

  const deleteRole = useCallback(async () => {
    blockModal?.(true);
    setDeletingRole(true);

    const result = await removeRole(role.id);
    setDeletingRole(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteRoleSuccess',
        defaultMessage: 'Role deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteRoleError',
        defaultMessage: 'An error occured while deleting the role',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, role, removeRole, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Role: {role?.name}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteRoleMessage" defaultMessage={'Are you sure that you want to delete this role?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingRole ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingRole} onClick={deleteRole} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default ManageSetupRoles;
