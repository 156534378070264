/**
 * SourcesContent.tsx
 * Display the sources tab of view profile page
 */
/* packages */
import { memo, useContext } from 'react';

/* context */
import { PersonContext } from './PersonProvider';

/* components */
import Box from '@mui/material/Box';

import { GlobeIcon } from 'icons/globe/globe';
/* utilities */
import { internalPageMaxWidth } from './PersonProfile';

/* types */
interface SourcesContentProps {}

/* elements */

const SourcesContent = memo((props: SourcesContentProps) => {
  const { details } = useContext(PersonContext);

  if (!details) return <></>;

  return (
    <Box px={2} py={2} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'1fr'} columnGap={'1.5rem'} rowGap={'1.25rem'}>
        {details.sources?.map((source, index) => {
          return (
            <Box key={index} display="flex" gap={'.5rem'} minWidth={0}>
              <Box pt={'2px'} sx={{ color: 'var(--color-lightgray4)' }}>
                <GlobeIcon sx={{ fontSize: '1rem', color: 'red' }} />
              </Box>
              <Box flex={1} minWidth={0} fontSize={'var(--fs-14)'}>
                <a target="_blank" rel="noreferrer" href={source.path} style={{ display: 'block' }}>
                  {source.path}
                </a>
                {source.title && <Box>{source.title}</Box>}
                {source.summary && <Box>{source.summary}</Box>}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

export default SourcesContent;
