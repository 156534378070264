/**
 * Switch.tsx
 * Display a switch with a label
 */
/* packages */

/* components */
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ReactNode } from 'react';

/* types */
interface SwitchWithLabelProps {
  label: string | ReactNode;
  labelClassName?: string;
  inputSwitchProps?: SwitchProps;
  disabled?: boolean;
  switchColor?: string;
  switchPosition?: 'left' | 'right';
}
/*elements */
const switchWidth = 40;
const switchHeight = 20;
const switchPadding = 2;
export const SearchSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: switchWidth,
  height: switchHeight,
  // width: 42,
  // height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: switchPadding,
    transitionDuration: '250ms',
    '&.Mui-checked': {
      transform: `translateX(${switchWidth - switchHeight}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: switchHeight - 2 * switchPadding,
    height: switchHeight - 2 * switchPadding,
  },
  '& .MuiSwitch-track': {
    borderRadius: switchHeight / 2,
    backgroundColor: '#CFD1D2',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SwitchWithLabel = ({ label, labelClassName, disabled, inputSwitchProps, switchColor, switchPosition }: SwitchWithLabelProps) => {
  const swithcBackgroundColor = {
    '& .MuiSwitch-switchBase.Mui-checked+ .MuiSwitch-track': {
      backgroundColor: switchColor ?? 'var(--color-azure)',
    },
  };
  return (
    <FormControlLabel
      control={<SearchSwitch sx={{ ...inputSwitchProps?.sx, ...swithcBackgroundColor, m: 1 }} {...inputSwitchProps} />}
      label={label}
      disabled={disabled}
      className={labelClassName}
      sx={{
        ml: 0,
        mr: 0,
        '& .MuiFormControlLabel-label': { order: switchPosition === 'left' ? 2 : 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' },
        '& .MuiSwitch-root': { order: switchPosition === 'left' ? 1 : 2, ml: switchPosition === 'left' ? 0 : 'auto' },
      }}
    />
  );
};
export default SwitchWithLabel;
