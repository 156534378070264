/**
 * ScheduleProvider.ts
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { DeleteScheduleResponse, EditScheduleResponse, JobType, ListScheduleResponse, RunScheduleResponse } from 'models/job';

interface ScheduleContextType {
  loadingSchedule: boolean;
  schedules?: JobType[];
  listSchedules?(abortController?: AbortController): void;
  addOrUpdateSchedule?(data: Partial<JobType>, create?: boolean, abortController?: AbortController): Promise<JobType | undefined>;
  deleteSchedule?(scheduleId: number, abortController?: AbortController): Promise<DeleteScheduleResponse | undefined>;
  runSchedule?(scheduleId: number, run: 'start' | 'stop', abortController?: AbortController): Promise<JobType | undefined>;
}
/* elements */
const SchedulesContext = createContext<ScheduleContextType>({
  loadingSchedule: false,
});

const SchedulesProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();

  const [loadingSchedule, setLoadingSchedule] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<JobType[] | undefined>(undefined);

  const listOngoing = useRef(false);
  const listSchedules = useCallback(
    async (abortController?: AbortController) => {
      if (listOngoing.current) return;

      listOngoing.current = true;
      setLoadingSchedule(true);
      setSchedules(undefined);
      try {
        const datasetUrl = URLConstants.fullSchedulerList;
        const results = (await getAuthenticatedRequest(datasetUrl, abortController ?? undefined)) as ListScheduleResponse;

        setLoadingSchedule(false);
        if (results.jobs) {
          setSchedules(results.jobs);
        }
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setLoadingSchedule(false);
        setSchedules(undefined);
      }
      listOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );
  const addOrUpdateSchedule = useCallback(
    async (data: Partial<JobType>, create = true, abortController?: AbortController): Promise<JobType | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const editScheduleUrl = create ? URLConstants.schedulerCreate : URLConstants.schedulerUpdate;
        const results = (await postAuthenticatedRequest(editScheduleUrl, { job: data }, abortController ?? undefined)) as EditScheduleResponse;

        const updatedSchedule = results.job;

        setSchedules((currentSchedules) => {
          if (!updatedSchedule) return currentSchedules;
          if (!currentSchedules) return [updatedSchedule];

          const newSchedules = [...currentSchedules];
          const folderIndex = currentSchedules?.findIndex((r) => r.id === updatedSchedule.id);
          if (folderIndex >= 0) {
            newSchedules[folderIndex] = updatedSchedule;
          } else {
            newSchedules.push(updatedSchedule);
          }
          return newSchedules;
        });

        return updatedSchedule;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;
        throw new Error(listError);
      }
    },
    [postAuthenticatedRequest]
  );

  const deleteSchedule = useCallback(
    async (scheduleId: number, abortController?: AbortController): Promise<DeleteScheduleResponse | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const deleteScheduleUrl = URLConstants.schedulerDelete + `${scheduleId}`;
        const results = (await deleteAuthenticatedRequest(deleteScheduleUrl, abortController ?? undefined)) as DeleteScheduleResponse;

        if (results.operationResult) {
          setSchedules((currentSchedules) => {
            return currentSchedules?.filter((t) => t.id !== scheduleId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [deleteAuthenticatedRequest]
  );

  const runSchedule = useCallback(
    async (scheduleId: number, run: 'start' | 'stop', abortController?: AbortController): Promise<JobType | undefined> => {
      // check permissions
      // const allowed = checkPermissions('manageSetupTeams', permissions);
      // if (!allowed) return undefined;

      try {
        const runDatasetUrl = (run === 'start' ? URLConstants.schedulerStart : URLConstants.schedulerStop) + `${scheduleId}`;

        const results = (await getAuthenticatedRequest(runDatasetUrl, abortController ?? undefined)) as RunScheduleResponse;

        const updatedSchedule = results.job;
        if (results.job) {
          setSchedules((currentSchedules) => {
            if (!updatedSchedule) return currentSchedules;
            if (!currentSchedules) return [updatedSchedule];

            const newSchedules = [...currentSchedules];
            const folderIndex = currentSchedules?.findIndex((r) => r.id === updatedSchedule.id);
            if (folderIndex >= 0) {
              newSchedules[folderIndex] = updatedSchedule;
            } else {
              newSchedules.push(updatedSchedule);
            }
            return newSchedules;
          });
          return updatedSchedule;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [getAuthenticatedRequest]
  );

  const outputValue = useMemo(
    (): ScheduleContextType => ({
      loadingSchedule,
      schedules,
      listSchedules,
      addOrUpdateSchedule,
      deleteSchedule,
      runSchedule,
    }),
    [loadingSchedule, schedules, listSchedules, addOrUpdateSchedule, deleteSchedule, runSchedule]
  );
  return <SchedulesContext.Provider value={outputValue}>{children}</SchedulesContext.Provider>;
};

/* exports */
export { SchedulesProvider, SchedulesContext };
