/**
 * AuthProvider.ts
 * Register the authentication of user and provide the values
 */
/* packages */
import { createContext, useCallback, useState, useMemo, useContext } from 'react';
import Axios, { AxiosResponse, AxiosError } from 'axios';
import { URLConstants } from 'common/URLconstants';

/* utilities */
import { displayWarning } from 'utilities/Logger';

/* types */
import { AxiosJson, AxiosWithJWT, AuthenticationRequest, AuthenticationResponse, ApiResponse, ApiKeyResponse, RequestData, AxiosWithAPIkey, AuthenticatedError } from 'models/axiosRequest';

interface AuthContextType {
  jwt?: string;
  currentUserName?: string;
  authenticateUser?: (request: AuthenticationRequest) => Promise<AuthenticationResponse>;
  logoutUser?: () => void;
  apiKey?: string;
  getApiKey?: (jwt: string) => Promise<ApiKeyResponse>;
}

interface IsAuthenticatedType {
  isAuthenticated: boolean;
  currentUserName: string | undefined;
}

interface AuthenticationFunctionType {
  authenticateUser?(request: AuthenticationRequest): Promise<AuthenticationResponse>;
  getApiKey?(jwt: string): Promise<ApiKeyResponse>;
  logoutUser?(): void;
}

interface PostRequestParamsType {
  blob?: boolean;
  skipStringify?: boolean;
  contentType?: 'multipart/form-data';
}

/* elements */
const AuthContext = createContext<AuthContextType>({});

const AuthProvider = (props: React.PropsWithChildren<{}>) => {
  const [jwt, setJwt] = useState<string | undefined>(undefined);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);
  const [currentUserName, setCurrentUserName] = useState<string | undefined>(undefined);

  // logout user
  const logoutUser = useCallback(() => {
    setJwt(undefined);
    setCurrentUserName(undefined);
    setApiKey(undefined);
  }, []);

  // authenticate user
  const authenticateUser = useCallback(
    (request: AuthenticationRequest) => {
      return Axios.post(URLConstants.login, JSON.stringify(request), AxiosJson)
        .then((response: AxiosResponse<AuthenticationResponse>) => {
          setJwt(response.data.token);
          setCurrentUserName(request.userName);
          return response.data;
        })
        .catch((authenticationError: AxiosError) => {
          logoutUser();
          throw authenticationError?.response?.data;
        });
    },
    [logoutUser]
  );

  // get api key
  const getApiKey = useCallback(
    (jwt: string) => {
      if (!jwt) throw new Error('You must be authentified');

      return Axios.get(URLConstants.screenaApiKey, AxiosWithJWT(jwt))
        .then((response: AxiosResponse<ApiKeyResponse>) => {
          setApiKey(response.data.screenaApiKey);
          if (!response.data.screenaApiKey) {
            displayWarning('EMPTY API KEY');
          }
          return response.data;
        })
        .catch((apiKeyError: AxiosError) => {
          logoutUser();
          throw apiKeyError?.response?.data;
        });
    },
    [logoutUser]
  );

  // memo the output value
  const authContextValue = useMemo(
    () => ({
      jwt,
      currentUserName,
      authenticateUser,
      logoutUser,
      apiKey,
      getApiKey,
    }),
    [jwt, currentUserName, authenticateUser, logoutUser, apiKey, getApiKey]
  );

  return <AuthContext.Provider value={authContextValue}>{props.children}</AuthContext.Provider>;
};

// hooks
const useIsAuthenticated = (): IsAuthenticatedType => {
  // const { jwt, apiKey, currentUserName } = useContext(AuthContext);
  const { jwt, currentUserName } = useContext(AuthContext);

  return {
    // isAuthenticated: !!jwt && !!apiKey && !!currentUserName,
    isAuthenticated: !!jwt && !!currentUserName,
    currentUserName,
  };
};

const useAuthenticationFunction = (): AuthenticationFunctionType => {
  const { authenticateUser, getApiKey, logoutUser } = useContext(AuthContext);
  return {
    authenticateUser,
    getApiKey,
    logoutUser,
  };
};

const useAuthenticatedRequest = () => {
  const { jwt, logoutUser } = useContext(AuthContext);

  const getAuthenticatedRequest = useCallback(
    (apiEndpoint: string, abortController?: AbortController, blob = false): Promise<ApiResponse> => {
      if (!jwt) throw new Error('You must be authentified');

      const requestConfig = AxiosWithJWT(jwt);
      if (abortController) {
        requestConfig.signal = abortController?.signal;
      }

      if (blob) {
        requestConfig.responseType = 'blob';
      }

      return Axios.get(apiEndpoint, requestConfig)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((userError: AuthenticatedError) => {
          if (userError.response && userError.response.data) {
            const error = userError.response.data.error ?? '';
            if (error === 'Unauthorized') {
              logoutUser?.();
            }
          }
          throw userError;
        });
    },
    [jwt, logoutUser]
  );

  const postAuthenticatedRequest = useCallback(
    (apiEndpoint: string, params: RequestData, abortController?: AbortController, requestParams?: PostRequestParamsType): Promise<ApiResponse> => {
      if (!jwt) throw new Error('You must be authentified');

      const requestConfig = AxiosWithJWT(jwt);
      if (abortController) {
        requestConfig.signal = abortController?.signal;
      }

      if (requestParams?.blob) {
        requestConfig.responseType = 'blob';
      }

      let postParams: string | RequestData = JSON.stringify(params);
      if (requestParams?.skipStringify) {
        postParams = params;
      }

      if (requestParams?.contentType && requestConfig.headers) {
        requestConfig.headers['Content-type'] = requestParams.contentType;
      }

      return Axios.post(apiEndpoint, postParams, requestConfig)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((userError: AuthenticatedError) => {
          if (userError.response && userError.response.data) {
            const error = userError.response.data.error ?? '';
            if (error === 'Unauthorized') {
              logoutUser?.();
            }
          }
          throw userError;
        });
    },
    [jwt, logoutUser]
  );

  const deleteAuthenticatedRequest = useCallback(
    (apiEndpoint: string, abortController?: AbortController): Promise<ApiResponse> => {
      if (!jwt) throw new Error('You must be authentified');

      const requestConfig = AxiosWithJWT(jwt);
      if (abortController) {
        requestConfig.signal = abortController?.signal;
      }

      return Axios.delete(apiEndpoint, requestConfig)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((userError: AuthenticatedError) => {
          if (userError.response && userError.response.data) {
            const error = userError.response.data.error ?? '';
            if (error === 'Unauthorized') {
              logoutUser?.();
            }
          }
          throw userError;
        });
    },
    [jwt, logoutUser]
  );

  return { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest };
};

const useAPIRequest = () => {
  const { apiKey } = useContext(AuthContext);

  const getAPIRequest = useCallback(
    (apiEndpoint: string, abortController?: AbortController): Promise<ApiResponse> => {
      if (!apiKey) throw new Error('You must have an api key');

      const requestConfig = AxiosWithAPIkey(apiKey);
      if (abortController) {
        requestConfig.signal = abortController?.signal;
      }

      return Axios.get(apiEndpoint, requestConfig)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((userError) => {
          throw userError;
        });
    },
    [apiKey]
  );

  const postAPIRequest = useCallback(
    (apiEndpoint: string, params: RequestData, abortController?: AbortController, requestParams?: { [key: string]: string | boolean }): Promise<ApiResponse> => {
      if (!apiKey) throw new Error('You must have an api key');

      const requestConfig = AxiosWithAPIkey(apiKey);
      if (abortController) {
        requestConfig.signal = abortController?.signal;
      }

      if (requestParams?.blob) {
        requestConfig.responseType = 'blob';
      }

      return Axios.post(apiEndpoint, JSON.stringify(params), requestConfig)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((userError) => {
          throw userError;
        });
    },
    [apiKey]
  );

  return { getAPIRequest, postAPIRequest };
};

// exports
export { AuthProvider, useIsAuthenticated, useAuthenticationFunction, useAuthenticatedRequest, useAPIRequest };
