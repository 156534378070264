/**
 * SchedulesExecutions.tsx
 */
/* packages */
import { useState, useCallback, memo, useMemo, useRef, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material';

import Loader from 'components/Loader/Loader';
import ReloadButton from 'components/ReloadButton/ReloadButton';
// import TableResults from 'components/TableResults/TableResults';
import Pagination, { defaultNumberPages } from 'components/Pagination/Pagination';
import DisplayAuditResults from 'components/ManageAudits/DisplayAuditResults';
import StatusTag from 'components/StatusTag/StatusTag';

/* utilities */
import { GetUser } from 'components/GetUser/GetUser';
import { URLConstants } from 'common/URLconstants';
import { ActiveStatusColors } from 'models/audit';

/* types */
import { Audit, SearchAuditsPayload, SearchAuditsResponse } from 'models/audit';
import { AuditList } from 'pages/manage/ManageAudits';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';
import { DateStringISOFormat } from 'models/utils';
import DisplayDate from 'components/DisplayDate/DisplayDate';

interface SchedulesExecutionsProps {}

interface DisplaySearchResultsProps extends AuditList {
  sortedColumn: string;
  sortedDirection: SortedDirectionType;
  makeSortedSearch(columnId: string, columnDirection: SortedDirectionType): Promise<void>;
  currentPage: number;
  maxPerPage: string;
  setMaxAndSearch(event: SelectChangeEvent): void;
  changePage(shift: 1 | -1, nbAudits: number, currentPage: number, maxPerPage: string): void;
}

/* elements */
const SchedulesExecutions = memo((props: SchedulesExecutionsProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(defaultNumberPages));
  const [makingSearch, setMakingSearch] = useState<boolean>(true);
  const [audits, setAudits] = useState<Audit[] | undefined>();
  const [nbAudits, setNbAudits] = useState<number>(0);
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const { postAuthenticatedRequest } = useAuthenticatedRequest();

  const listAudits = useCallback(
    async (payload: SearchAuditsPayload, abortController?: AbortController): Promise<AuditList> => {
      try {
        const auditUrl = URLConstants.auditList.replace('*auditType*', 'job');
        // const auditCountUrl = URLConstants.auditCount.replace('*auditType*', 'import');
        // const [auditResults, countResults] = (await Promise.all([
        //   postAuthenticatedRequest(auditUrl, payload, abortController ?? undefined),
        //   getAuthenticatedRequest(auditCountUrl, abortController ?? undefined),
        // ])) as [SearchAuditsResponse, SearchAuditsCountResponse];

        const auditResults = (await postAuthenticatedRequest(auditUrl, payload, abortController ?? undefined)) as SearchAuditsResponse;

        if (!auditResults.audits) {
          throw Error('invalid');
        }
        return {
          audits: auditResults.audits,
          nbAudits: auditResults.numberOfObjects,
          // nbAudits: countResults.numberOfObjects ?? auditResults.audits.length,
        };
      } catch (searchError: any) {
        throw searchError;
      }
    },
    [postAuthenticatedRequest]
  );

  const listingAudits = useRef(false);
  const makeSearch = useCallback(
    async (currentPage: number, maxPerPage: string, sortedColumn?: string, sortedDirection?: SortedDirectionType) => {
      listingAudits.current = true;
      setMakingSearch(true);
      const payload: SearchAuditsPayload = {
        pageNumber: currentPage,
        maxPerPage: Number(maxPerPage),
      };
      if (sortedColumn && sortedDirection) {
        payload.sortField = sortedColumn;
        payload.sortOrder = sortedDirection;
      }

      setAudits(undefined);
      setNbAudits(0);

      try {
        const { audits, nbAudits } = await listAudits(payload);
        setAudits(audits);
        setNbAudits(nbAudits);
      } catch (searchError) {
        console.error(searchError);
      }
      setMakingSearch(false);
      listingAudits.current = false;
    },
    [listAudits]
  );

  useEffect(() => {
    makeSearch(0, String(defaultNumberPages));
  }, [makeSearch]);

  const makeSortedSearch = useCallback(
    async (columnId: string, columnDirection: SortedDirectionType) => {
      setSortedColumn(columnId);
      setSortedDirection(columnDirection);

      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);

      makeSearch(0, maxPerPage, columnId, columnDirection);
    },
    [makeSearch, maxPerPage]
  );

  const setMaxAndSearch = useCallback(
    (event: SelectChangeEvent) => {
      const newMaxPerPage = event.target.value;
      if (newMaxPerPage === maxPerPage) return;

      const newCurrentPage = 0;
      setMaxPerPage(newMaxPerPage);
      setCurrentPage(newCurrentPage);

      // take the lastest search payload
      // const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newCurrentPage, maxPerPage: newMaxPerPage });

      makeSearch(newCurrentPage, newMaxPerPage);
    },
    [makeSearch, maxPerPage]
  );

  const changePage = useCallback(
    (shift: 1 | -1, nbAudits: number, currentPage: number, maxPerPage: string) => {
      const newPage = currentPage + shift;

      // check page is in bounds
      if (newPage < 0) return;
      if (newPage > nbAudits / Number(maxPerPage)) return;

      setCurrentPage(newPage);

      makeSearch(newPage, maxPerPage);
    },
    [makeSearch]
  );

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1}>
      {makingSearch ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          {!audits ? (
            <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <ReloadButton
                onClick={() => {
                  makeSearch(currentPage, maxPerPage);
                }}
              />
            </Box>
          ) : (
            <DisplaySearchResults {...{ audits, nbAudits, sortedColumn, sortedDirection, makeSortedSearch, currentPage, maxPerPage, setMaxAndSearch, changePage }} />
          )}
        </>
      )}
    </Box>
  );
});

const SearchPagination = memo(Pagination);
const DisplaySearchResults = memo(({ audits, nbAudits, sortedColumn, sortedDirection, makeSortedSearch, currentPage, maxPerPage, setMaxAndSearch, changePage }: DisplaySearchResultsProps) => {
  const intl = useIntl();

  const { allUsers } = useContext(AllUsersContext);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'id',
        label: intl.formatMessage({
          id: 'id',
          defaultMessage: 'ID',
        }),
        sorted: () => {
          makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'id' ? sortedDirection : false,
      },
      {
        id: 'action',
        label: intl.formatMessage({
          id: 'action',
          defaultMessage: 'Action',
        }),
        sorted: () => {
          makeSortedSearch?.('action', sortedColumn !== 'action' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'action' ? sortedDirection : false,
        minWidth: '200px',
      },
      {
        id: 'started',
        label: intl.formatMessage({
          id: 'started',
          defaultMessage: 'Started',
        }),
        sorted: () => {
          makeSortedSearch?.('started', sortedColumn !== 'started' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'started' ? sortedDirection : false,
        minWidth: '180px',
      },
      {
        id: 'ended',
        label: intl.formatMessage({
          id: 'ended',
          defaultMessage: 'Ended',
        }),
        sorted: () => {
          makeSortedSearch?.('ended', sortedColumn !== 'ended' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'ended' ? sortedDirection : false,
        minWidth: '180px',
      },
      {
        id: 'status',
        label: intl.formatMessage({
          id: 'status',
          defaultMessage: 'Status',
        }),
      },
      {
        id: 'totalRecords',
        label: intl.formatMessage({
          id: 'totalRecords',
          defaultMessage: 'Total records',
        }),
      },
      {
        id: 'totalRecordsProcessed',
        label: intl.formatMessage({
          id: 'totalRecordsProcessed',
          defaultMessage: 'Total records processed',
        }),
      },
      {
        id: 'createdBy',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created By',
        }),
        // minWidth: '180px',
      },
      {
        id: 'created',
        label: intl.formatMessage({
          id: 'createdOn',
          defaultMessage: 'Created On',
        }),
        // minWidth: '180px',
        sorted: () => {
          makeSortedSearch?.('created', sortedColumn !== 'created' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'created' ? sortedDirection : false,
      },
    ];
  }, [intl, sortedColumn, sortedDirection, makeSortedSearch]);

  const tableData = useMemo((): TableRowData[] => {
    return audits.map((audit, auditIndex) => ({
      id: audit.id ? String(audit.id) : String(auditIndex),
      columns: {
        id: audit.id ? String(audit.id) : String(auditIndex),
        action: audit.action ?? '',
        entity: audit.entityDescription ?? '',
        error: audit.error,
        status: audit.status ? <StatusTag tag_label={audit.status} active activeColor={ActiveStatusColors[audit.status]} /> : '',
        started: audit.started ? <DisplayDate date={audit.started} /> : '',
        ended: audit.ended ? <DisplayDate date={audit.ended} /> : '',
        totalRecords: audit.totalRecords ?? '',
        totalRecordsProcessed: audit.totalRecordsProcessed ?? '',
        createdBy: audit.createdBy ? GetUser(audit.createdBy ?? '', allUsers) : '',
        createdOn: audit.created ? <DisplayDate date={audit.created} format={DateStringISOFormat} /> : '',
      },
    }));
  }, [audits, allUsers]);

  const tableColumnTemplate = '100px minmax(200px, 1fr) repeat(2, minmax(180px, 1fr)) 150px 150px 220px repeat(2, minmax(180px, 1fr))';

  return (
    <Box mt={3}>
      {(nbAudits ?? 0) > 0 && audits && audits.length > 0 && (
        <DisplayAuditResults {...{ tableData, tableHeaders, tableColumnTemplate }} />
        // <Box>
        //   <TableResults hasBoxTitle={false} hasUnread={false} hasSelection={false} tableData={tableData} tableHead={tableHeaders} />
        // </Box>
      )}

      {audits && nbAudits > 0 && (
        <SearchPagination
          {...{
            maxPerPage: Number(maxPerPage),
            currentPage,
            nbResults: nbAudits ?? 0,
            setMaxAndCallback: setMaxAndSearch,
            changePageCallback: (shift) => {
              changePage(shift, nbAudits, currentPage, maxPerPage);
            },
          }}
        />
      )}
      {audits && nbAudits === 0 && (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            <FormattedMessage id="auditLogNotAvailable" defaultMessage={'Audit logs are not yet available'} />
          </Typography>
        </Box>
      )}
    </Box>
  );
});

export default SchedulesExecutions;
