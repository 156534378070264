/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useRef, useMemo, useEffect, memo, PropsWithoutRef, forwardRef, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { VariableSizeList as VirtualList, ListChildComponentProps } from 'react-window';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import SearchText from 'components/SearchElements/SearchText/SearchText';

/* utilities */

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterVirtualCheckboxesProps {
  title: string;
  list: { key: string; value: string; displayValue?: ReactNode }[];
  currentValue?: { values: string[] };
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  setOnSelection?: boolean;
  emptyMessage?: string;
  hideTitle?: boolean;
  noBorder?: boolean;
  virtualWidth?: string | number;
}

interface VirtualListElements {
  list: FilterVirtualCheckboxesProps['list'];
  checked: boolean;
  setRowHeight(key: string, size: number): void;
  checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void;
}

interface RowContentProps {
  itemKey: string;
  itemValue: string;
  itemDisplayValue: ReactNode;
  checked: boolean;
  checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void;
}

/* elements */
const FilterVirtualCheckboxes = memo(
  ({ title, list, currentValue, setNewFilterValue, emptyMessage, hideTitle, setOnSelection, triggerSetUpdate, noBorder, virtualWidth }: FilterVirtualCheckboxesProps) => {
    const intl = useIntl();
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedValues, setSelectedValues] = useState<string[]>(currentValue?.values ?? []);

    const applyFilterValue = useCallback(
      (newValues: typeof selectedValues) => {
        if (setNewFilterValue) setNewFilterValue({ nbValues: newValues.length, values: newValues });
      },
      [setNewFilterValue]
    );

    useEffect(() => {
      if (triggerSetUpdate && !setOnSelection && list.length > 0) applyFilterValue(selectedValues);
    }, [setOnSelection, triggerSetUpdate, applyFilterValue, selectedValues, list]);

    const clearFilter = () => {
      const newValues: typeof selectedValues = [];
      setSelectedValues(newValues);
      applyFilterValue(newValues);
    };

    const checkboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const checkboxName = event.target.name;
      const checked = event.target.checked;

      setSelectedValues((currentValues) => {
        if (checked) return [...currentValues, checkboxName];
        else return currentValues.filter((cv) => cv !== checkboxName);
      });
    }, []);

    // propagate on value change
    // const validateSelection = () => {
    //   applyFilterValue(selectedValues);
    //   // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues });
    // };

    useEffect(() => {
      if (setOnSelection) {
        applyFilterValue(selectedValues);
      }
    }, [selectedValues, setOnSelection, applyFilterValue]);

    const searchInputLower = searchInput.toLowerCase();
    const virtualList = list.filter((l) => {
      if (selectedValues.includes(l.key)) return false;
      if (searchInput) return l.value.toLowerCase().includes(searchInputLower);
      return true;
    });

    const selectedVirtualList = useMemo(
      () =>
        list.filter((l) => {
          return selectedValues.includes(l.key);
        }),
      [list, selectedValues]
    );

    return (
      <Box borderRadius={'5px'} sx={{ border: noBorder ? 'none' : '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
        {/* filter title */}
        {!hideTitle && (
          <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
            {/* {list.length > 0 && (
              <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValues.length <= 0}>
                <FormattedMessage id="Clear" defaultMessage="Clear" />
              </ShadowedButton>
            )} */}

            <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
              {title}
            </Typography>

            {/* {list.length > 0 && (
              <Button variant="contained" disableElevation size="small" onClick={validateSelection}>
                <FormattedMessage id="done" defaultMessage="Done" />
              </Button>
            )} */}
          </Box>
        )}

        {!list.length ? (
          <Box px={2} py={2} maxWidth={300}>
            <Typography fontSize={'.875rem'} textAlign={'center'} sx={{ color: 'var(--color-gray2)', textWrap: 'balance' }}>
              {emptyMessage ?? 'No filter available'}
            </Typography>
          </Box>
        ) : (
          <>
            <Box px={2} py={0.5} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
              <SearchText
                variant="standard"
                fullWidth
                value={searchInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchInput(event.target.value);
                }}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={{
                  name: 'searchInput',
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{ pl: 1 }}
                adormentSize={14}
              />
            </Box>

            <Box px={2} py={1} display={'flex'} flexDirection={'column'}>
              {selectedValues.length > 0 && (
                <>
                  <Typography color={'darkgray'} flex={1} fontSize={14} px={1} py={0.5} fontWeight={500} textAlign={'left'}>
                    <FormattedMessage id="selected" defaultMessage={'Selected'} />
                  </Typography>

                  <MemoizedVirtualList virtualList={selectedVirtualList} checked={true} checkboxChanged={checkboxChanged} virtualWidth={virtualWidth} />
                </>
              )}

              <Typography color={'darkgray'} flex={1} fontSize={14} px={1} py={0.5} fontWeight={500} textAlign={'left'}>
                <FormattedMessage id="all" defaultMessage={'All'} />
              </Typography>

              <MemoizedVirtualList virtualList={virtualList} checked={false} checkboxChanged={checkboxChanged} virtualWidth={virtualWidth} />

              {virtualList.length === 0 && selectedValues.length !== list.length && (
                <Box px={2}>
                  <Typography fontSize={'.875rem'} sx={{ color: 'var(--color-gray2)' }}>
                    <FormattedMessage id="noMatch" defaultMessage={'No match'} />
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}

        {list.length > 0 && (
          <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
            <ShadowedButton
              fullWidth
              className="no-hover"
              // variant="text"
              onClick={clearFilter}
              size="small"
              disabled={selectedValues.length <= 0}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: '#F8F6F6',
                  color: '#C9C8C7',
                  borderColor: '#F8F6F6',
                  boxShadow: 'none',
                },
                // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
              }}
            >
              <FormattedMessage id="Clear" defaultMessage="Clear" />
            </ShadowedButton>
          </Box>
        )}
      </Box>
    );
  }
);

const itemSize = 37;
const listMaxHeight = 150;
const defaultVirtulaWidth = 250;
const rowHeights: { [key: string]: number } = {};

const MemoizedVirtualList = memo(
  ({
    virtualList,
    checked,
    checkboxChanged,
    virtualWidth,
  }: PropsWithoutRef<{ virtualList: FilterVirtualCheckboxesProps['list']; checked: boolean; checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void; virtualWidth?: number | string }>) => {
    const listRef = useRef<VirtualList<VirtualListElements> | null>(null);

    const getRowHeight = useCallback(
      (index: number) => {
        const key = virtualList[index].key;
        return rowHeights[key] ?? itemSize;
      },
      [virtualList]
    );

    const setRowHeight = useCallback((key: string, size: number) => {
      if (listRef.current) listRef.current.resetAfterIndex(0);
      if (!(key in rowHeights)) {
        rowHeights[key] = size;
      }
    }, []);

    const nbVirtualItems = virtualList.length;

    const getHeight = useMemo(() => {
      const minNbItems = 5;
      const initElements = virtualList.slice(0, minNbItems).map((el) => rowHeights[el.key] ?? itemSize);
      const initSize = initElements.reduce((acc, cur) => acc + cur, 0);
      return Math.min(listMaxHeight, initSize);
    }, [virtualList]);

    return (
      <VirtualList
        className="custom-scrollbar"
        ref={listRef}
        itemData={{ list: virtualList, checked, setRowHeight, checkboxChanged }}
        height={getHeight}
        itemSize={getRowHeight}
        width={virtualWidth ?? defaultVirtulaWidth}
        itemCount={nbVirtualItems}
        overscanCount={10}
      >
        {RenderRow}
      </VirtualList>
    );
  }
);

// & { checked: boolean, setRowHeight():void }
const RenderRow = (props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const { list, checked, setRowHeight, checkboxChanged } = data as VirtualListElements;
  const labelRef = useRef<HTMLSpanElement | null>(null);

  const item = list[index];

  useEffect(() => {
    if (labelRef.current) {
      setRowHeight(item.key, labelRef.current.clientHeight);
    }
  }, [index, labelRef, setRowHeight, item.key, item.value]);

  const rowContentData = { itemKey: item.key, itemValue: item.value, itemDisplayValue: item.displayValue, checked, checkboxChanged };
  return (
    <div className="row-container" style={{ ...style, display: 'flex' }}>
      <RowContent ref={labelRef} {...rowContentData} />
    </div>
  );
};

const RowContent = memo(
  forwardRef<HTMLSpanElement, RowContentProps>(({ itemKey, itemValue, itemDisplayValue, checked, checkboxChanged }, ref) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={itemKey}
            color={'primary'}
            checked={checked}
            onChange={checkboxChanged}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              py: 1,
              '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
              '&:hover': { backgroundColor: 'var(--color-hover-background)' },
            }}
          />
        }
        label={
          itemDisplayValue ?? (
            <Typography ref={ref} fontSize={'.875rem'} pt={1} pb={1} sx={{ color: 'var(--color-gray2)' }}>
              {itemValue}
            </Typography>
          )
        }
        className="rounded-border"
        sx={{ pr: 2, ml: 0, mr: 0, alignItems: 'center', '&:hover': { backgroundColor: 'var(--color-hover-background)' }, '& .MuiFormControlLabel-label': { display: 'flex' } }}
      />
    );
  })
);

export default FilterVirtualCheckboxes;
