import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PaperClipAddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17.277" height="21" viewBox="0 0 17.277 21">
        <g transform="translate(3.334 -6)">
          <path d="M17,13.943a.5.5,0,0,1-.5-.5V6.5a.5.5,0,0,1,.993,0v6.95A.5.5,0,0,1,17,13.943Z" transform="translate(-7.025)" fill="currentColor" />
          <path d="M13.447,17.493H6.5a.5.5,0,0,1,0-.993h6.95a.5.5,0,0,1,0,.993Z" transform="translate(0 -7.025)" fill="currentColor" />
        </g>
        <path
          d="M18.561,5.742V15.5a3.394,3.394,0,0,1-6.788,0V4.894a2.121,2.121,0,1,1,4.242,0V13.8a.848.848,0,1,1-1.7,0V5.742H13.045V13.8a2.121,2.121,0,1,0,4.242,0V4.894a3.394,3.394,0,0,0-6.788,0V15.5a4.667,4.667,0,1,0,9.333,0V5.742Z"
          transform="translate(-10.5 0.833)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
