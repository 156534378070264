/**
 * PageTitle.tsx
 * Display the page title
 */
/* packages */
import { PropsWithoutRef, ReactNode } from 'react';

/* components */
import Typography from '@mui/material/Typography';

const PageTitle = ({ title }: PropsWithoutRef<{ title: string | ReactNode }>) => {
  return (
    <Typography flex={1} variant="h1" fontWeight={600} fontSize={'1.75rem'} sx={{ color: 'var(--color-darkgray)', maxWidth: '100%' }}>
      {title}
    </Typography>
  );
};

export default PageTitle;
