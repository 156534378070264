import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

/* functions */
export const AxiosJson: AxiosRequestConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const AxiosWithJWT: (jwt: string) => AxiosRequestConfig = (jwt) => ({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + jwt,
  },
});

export const AxiosWithAPIkey: (apikey: string) => AxiosRequestConfig = (apikey) => ({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Screena-API-Key': '' + apikey,
  },
});

/* types */
export interface ApiResponse {}
export interface RequestData {}

export interface AuthenticationRequest {
  userName: string;
  password: string;
}

export interface AuthenticationResponse extends ApiResponse {
  token: string;
}

export interface ApiKeyResponse extends ApiResponse {
  screenaApiKey: string;
}

export interface AuthenticatedError extends AxiosError {
  response: AxiosResponse<{
    error?: string;
    path?: string;
    status?: number;
    timestamp?: string;
  }>;
}
