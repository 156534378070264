import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RefreshIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16.833" height="19.64" viewBox="0 0 16.833 19.64">
        <g transform="translate(0 0)">
          <path
            d="M20.677,13.508a.654.654,0,0,0-.653.6,7.117,7.117,0,1,1-7.277-7.668.172.172,0,0,1,.18.175V8.817A.7.7,0,0,0,14,9.409l4.217-2.95a.7.7,0,0,0,0-1.188L14.009,2.36a.7.7,0,0,0-1.078.592v2a.172.172,0,0,1-.171.175,8.381,8.381,0,1,0,8.571,9.084.652.652,0,0,0-.653-.706Z"
            transform="translate(-4.5 -2.251)"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
