/**
 * ListAlertScreening.tsx
 */
/* packages */
import { useContext, useState, useMemo, memo, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
/* components */
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Loader from 'components/Loader/Loader';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ArrowDownSolidIcon } from 'icons/arrowDownSolid/arrowDownSolid';
import { GarbageIcon } from 'icons/garbage/garbage';

import StatusTag from 'components/StatusTag/StatusTag';

/* utilities */
import { displayWarning } from 'utilities/Logger';
import { GetUser } from 'components/GetUser/GetUser';

/* types */
import { AlertScreeningType } from 'models/alertScreening';

interface ListAlertScreeningProps {
  searching?: ReturnType<typeof setTimeout> | null;
  filtered?: boolean;
  alertScreenings?: AlertScreeningType[];
  handleCreateAlertScreening(alertScreening?: AlertScreeningType): void;
  handleDeleteAlertScreening(alertScreening: AlertScreeningType): void;
  handleImportAlertScreening(alertScreening: AlertScreeningType): void;
}

interface DisplayAlertScreeningProps {
  alertScreening: AlertScreeningType;
  handleCreateAlertScreening: ListAlertScreeningProps['handleCreateAlertScreening'];
  handleDeleteAlertScreening: ListAlertScreeningProps['handleDeleteAlertScreening'];
  handleImportAlertScreening: ListAlertScreeningProps['handleImportAlertScreening'];
}

/* elements */
// const DEFAULTCOLUMNWIDTH = '180px';
const COLUMNTEMPLATE = 'minmax(250px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(150px, 1fr) repeat(2, minmax(180px, 1fr)) auto';

const getSortedAlertScreenings = (alertScreenings: AlertScreeningType[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!alertScreenings) return alertScreenings;

  if (!sortedDirection || !sortedDirection) {
    return alertScreenings;
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;

  switch (sortedColumn) {
    case 'name':
      return alertScreenings.sort((as1, as2) => direction * (as1.dataId ?? '').localeCompare(as2.dataId ?? ''));
    case 'source':
      return alertScreenings.sort((as1, as2) => direction * (as1.sourceDataSet?.label ?? '').localeCompare(as2.sourceDataSet?.label ?? ''));
    case 'alertDataset':
      return alertScreenings.sort((as1, as2) => direction * (as1.matchingRuleScoring?.dataset?.label ?? '').localeCompare(as2.matchingRuleScoring?.dataset?.label ?? ''));
    case 'updatedOn':
      return alertScreenings.sort((as1, as2) => direction * (as1.updatedDate ?? '').localeCompare(as2.updatedDate ?? ''));
    case 'createdOn':
      return alertScreenings.sort((as1, as2) => direction * (as1.creationDate ?? '').localeCompare(as2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return alertScreenings;
  }
};

const ListAlertScreening = memo(({ searching, filtered, alertScreenings, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }: ListAlertScreeningProps) => {
  const intl = useIntl();

  // const [sortedColumn, setSortedColumn] = useState<string>('');
  // const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'name',
          defaultMessage: 'Name',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'name' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('name');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'name' ? sortedDirection : false,
      },
      {
        id: 'source',
        label: intl.formatMessage({
          id: 'source',
          defaultMessage: 'Customer dataset',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'source' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('source');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'source' ? sortedDirection : false,
      },
      {
        id: 'alertDataset',
        label: intl.formatMessage({
          id: 'alertDataset',
          defaultMessage: 'Alert dataset',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'alertDataset' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('alertDataset');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'alertDataset' ? sortedDirection : false,
      },
      {
        id: 'createdBy',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created by',
        }),
      },
      {
        id: 'createdOn',
        label: intl.formatMessage({
          id: 'createdOn',
          defaultMessage: 'Created on',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'createdOn' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdOn');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdOn' ? sortedDirection : false,
      },
      {
        id: 'updatedOn',
        label: intl.formatMessage({
          id: 'updatedOn',
          defaultMessage: 'Updated on',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedOn' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedOn');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedOn' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  if (searching || !alertScreenings) {
    return (
      <Box pt={'30vh'}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box mt={2}>
      {!alertScreenings || alertScreenings.length <= 0 ? (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            {filtered ? (
              <FormattedMessage id="noMatchedAlertScreening" defaultMessage={'No alert settings match your search'} />
            ) : (
              <FormattedMessage id="noAlertScreening" defaultMessage={'No alert settings have been defined'} />
            )}
          </Typography>
        </Box>
      ) : (
        <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={COLUMNTEMPLATE} sx={{ overflowX: 'auto' }}>
          {tableHeaders.map((th, thIndex) => {
            return (
              <Box
                key={th.id}
                className={`header-element with-border noBottomRadius ${th.sorted ? 'has-sorting' : ''} ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                {...(th.sorted
                  ? {
                      onClick: th.sorted,
                    }
                  : {})}
              >
                {th.label.toUpperCase()}{' '}
                {th.sorted &&
                  (th.direction ? (
                    <ArrowDownSolidIcon sx={{ fontSize: 10, color: 'inherit', rotate: th.direction === 'asc' ? '180deg' : '0deg' }} />
                  ) : (
                    <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                  ))}
              </Box>
            );
          })}

          {getSortedAlertScreenings(alertScreenings, sortedColumn, sortedDirection)?.map((alertScreening) => {
            return (
              <DisplayAlertScreening
                key={alertScreening.id}
                {...{ alertScreening, sortedColumn, sortedDirection, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
});

const StyledAlertScreeningElementDefault = styled(Box)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontSize: 'var(--fs-14)',
  padding: theme.spacing(2),
  wordBreak: 'break-word',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const StyledAlertScreeningElement = ({ children, ...props }: PropsWithChildren<BoxProps>) => {
  return (
    <StyledAlertScreeningElementDefault className="cell-element" {...props}>
      {children}
    </StyledAlertScreeningElementDefault>
  );
};

const DisplayAlertScreening = memo(({ alertScreening, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }: DisplayAlertScreeningProps) => {
  // const borderKey = topBorderOnly ? 'borderTop' : 'border';
  const { allUsers } = useContext(AllUsersContext);

  return (
    <Box
      display={'contents'}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handleCreateAlertScreening(alertScreening);
      }}
      sx={{
        cursor: 'pointer',
        '& > div': {
          borderBottom: '1px solid var(--color-grayHeaderBorder)',
          '&:first-of-type': {
            borderLeft: '1px solid var(--color-grayHeaderBorder)',
          },
          '&:last-of-type': {
            borderRight: '1px solid var(--color-grayHeaderBorder)',
          },
        },
        '&:hover > div': { backgroundColor: 'var(--color-lightgray2)' },
        position: 'relative',
      }}
    >
      <StyledAlertScreeningElement>
        <span>{alertScreening.name}</span>
      </StyledAlertScreeningElement>
      <StyledAlertScreeningElement alignItems={'flex-start'}>
        {alertScreening.sourceDataSet?.label && <StatusTag withoutOverflow tag_label={alertScreening.sourceDataSet?.label} hideActive={true} bg={'white'} textColor="#6E6C6B" />}
      </StyledAlertScreeningElement>
      <StyledAlertScreeningElement alignItems={'flex-start'}>
        {alertScreening.matchingRuleScoring?.dataset?.label && (
          <StatusTag withoutOverflow tag_label={alertScreening.matchingRuleScoring?.dataset?.label} hideActive={true} bg={'white'} textColor="#6E6C6B" />
        )}
      </StyledAlertScreeningElement>

      <StyledAlertScreeningElement>{GetUser(alertScreening.createdBy ?? '', allUsers, true, '20px')}</StyledAlertScreeningElement>
      <StyledAlertScreeningElement>{alertScreening.creationDate && <DisplayDate date={alertScreening.creationDate} />}</StyledAlertScreeningElement>
      <StyledAlertScreeningElement>{alertScreening.updatedDate && <DisplayDate date={alertScreening.updatedDate} />}</StyledAlertScreeningElement>

      <StyledAlertScreeningElement sx={{ alignItems: 'center' }}>
        <Box display="flex" columnGap={'10px'} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>          
          <IconButton
            className="square-icon-button"
            sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', '&:hover': { color: 'var(--color-fushia)' } }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleDeleteAlertScreening(alertScreening);
            }}
          >
            <GarbageIcon fontSize="inherit" sx={{ color: 'inherit' }} />
          </IconButton>
        </Box>
      </StyledAlertScreeningElement>
    </Box>
  );
});

export default ListAlertScreening;
