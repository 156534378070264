import { createTheme } from '@mui/material/styles';

declare global {
  interface String {
    toUpperCase(this: 'asc' | 'desc'): 'ASC' | 'DESC';
    toLowerCase(this: 'ASC' | 'DESC'): 'asc' | 'desc';
  }
}

declare module 'react' {
  interface CSSProperties {
    [key: `--${string}`]: string | number;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    red: Palette['primary'];
    lightRed: Palette['primary'];
    green: Palette['primary'];
    gray2: Palette['primary'];
    darkgray: Palette['primary'];
    lightgray: Palette['primary'];
  }

  interface PaletteOptions {
    red: PaletteOptions['primary'];
    lightRed: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    gray2: PaletteOptions['primary'];
    darkgray: PaletteOptions['primary'];
    lightgray: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray2: true;
    darkgray: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    gray2: true;
    darkgray: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    lightgray: true;
    red: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2FB6B4',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#E0C2FF',
    },
    error: {
      main: '#ed1f55',
    },
    red: {
      main: '#ed1f55',
      contrastText: '#FFFFFF',
    },
    lightRed: {
      main: '#FFA0A0',
    },
    green: {
      main: '#5EBE70',
    },
    gray2: {
      main: '#666666',
    },
    darkgray: {
      main: '#333333',
    },
    lightgray: {
      main: '#f0f0f0',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:not(.Mui-focused):hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#a7a7a7',
            },
          },
        },
        notchedOutline: {
          borderColor: '#e3e4e5',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            '&:hover': {
              backgroundColor: 'var(--color-azure)',
              // boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: 'var(--color-hover-background)',
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className?.includes('square-icon-button') && {
            borderRadius: '5px',
            '&:hover': {
              color: 'var(--color-table-content)',
              backgroundColor: 'var(--color-hover-button)',
            },
          }),
        }),
      },
    },
  },
});

export default theme;
