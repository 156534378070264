import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TriangleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
        <path d="M4.714,1A1,1,0,0,1,6.286,1L9.729,5.382A1,1,0,0,1,8.943,7H2.057a1,1,0,0,1-.786-1.618Z" transform="translate(7) rotate(90)" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};
