/**
 * CheckUserPermissions.ts
 * Check that current user has the right permissions
 */

export type PermissionString =
  | 'administration'
  | 'investigate'
  | 'alerts'
  | 'datasets'
  | 'search'
  | 'audits'
  | 'UserManagement'
  | 'RoleManagement'
  | 'GroupManagement'
  | 'DatasetManagement'
  | 'SchedulerManagement'
  | 'OrganizationUnitManagement'
  | 'AuditManagement'
  | 'screenaaudits'
  | 'WorlkflowManagement'
  | 'ScreeningManagement'
  | 'LibrariesManagement';

export const PermissionsPrint: { [key: string]: string } = {      
  search: 'Search',
  datasets: 'Explore',
  alerts: 'Alerts',
  OrganizationUnitManagement: 'Organizations',
  GroupManagement: 'Teams',
  RoleManagement: 'Roles',
  UserManagement: 'Users',  
  DatasetManagement: 'Datasets',
  LibrariesManagement: 'Libraries',
  WorlkflowManagement: 'Workflows',
  ScreeningManagement: 'Alert Settings',
  SchedulerManagement: 'Schedules',  
  AuditManagement: 'Audits'  
};

export const TeamAdministrationPrint : { key: string; value:string }[] = [      
  {key : 'setupAdmin', value :'Organizations, Teams, Roles'},
  {key : 'userAdministration', value : 'Users'},
  {key : 'datasetsAdministration', value : 'Datasets'},
  {key : 'libraryAdministration', value : 'Libraries'},
  {key : 'screeningAdministration', value : 'Workflows, Alert Settings'}
];

export type AllowedMainMenu =
| 'investigate';

export type AllowedPageCheck =
  | 'investigate'
  | 'investigateSearch'
  | 'investigateExplore'
  | 'investigateAlerts'
  | 'investigateAlert'
  | 'investigateRisks'
  | 'manage'
  | 'manageSetupOrganizations'
  | 'manageSetupTeams'
  | 'manageSetupRoles'
  | 'manageUsers'
  | 'manageDatasets'
  | 'manageLibrairies'
  | 'manageScreeningsWorkflow'
  | 'manageScreeningsAlert'
  | 'manageScreeningsRisk'
  | 'manageScreeningsSchedule'
  | 'manageAudits'
  | 'listWorkflowStatus'
  | 'listTeams'
  | 'listAllUsers'
  | 'listNotifications';

const PermissionsMap: { [key in AllowedPageCheck]: boolean | PermissionString[]} = {
  // pages
  investigate: ['search', 'datasets', 'alerts'],
  investigateSearch: ['search'],
  investigateExplore: ['datasets'],
  investigateAlerts: ['alerts'],
  investigateAlert: ['alerts'],
  // investigateRisks: 'risks',
  investigateRisks: false,

  manage: ['administration', 'UserManagement', 'DatasetManagement', 'LibrariesManagement', 'OrganizationUnitManagement'
          , 'GroupManagement', 'RoleManagement', 'WorlkflowManagement', 'ScreeningManagement', 'SchedulerManagement',
          'AuditManagement'
   ],

  manageSetupOrganizations: ['OrganizationUnitManagement'],
  manageSetupTeams: ['GroupManagement'],
  manageSetupRoles: ['RoleManagement'],

  manageUsers: ['UserManagement'],
  manageDatasets: ['DatasetManagement'],
  manageLibrairies: ['LibrariesManagement'],

  manageScreeningsWorkflow: ['WorlkflowManagement'],
  manageScreeningsAlert: ['ScreeningManagement'],
  manageScreeningsRisk: ['ScreeningManagement'],
  manageScreeningsSchedule: ['SchedulerManagement'],

  manageAudits: ['AuditManagement'],

  // list API
  listWorkflowStatus: ['alerts'],
  listTeams: ['alerts'],
  listAllUsers: ['alerts'],
  listNotifications: true,
};

// check the a page is accessible wrt permissions array
const checkPermissions = (page: AllowedPageCheck, permissions: string[]): boolean => {
  if (!Object.keys(PermissionsMap).includes(page)) return false;

  const requiredPermission = PermissionsMap[page];
  if (typeof requiredPermission === 'boolean') return requiredPermission;

  if (requiredPermission) {
     for (let i=0;i<requiredPermission.length; i++) {
      if (permissions.includes(requiredPermission[i])) {
        return true;
      }
     }
  }

  return false;
};

export { checkPermissions };
