import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const GearFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path
          d="M21.014,13.5a2.316,2.316,0,0,1,1.486-2.16,9.178,9.178,0,0,0-1.111-2.676,2.348,2.348,0,0,1-.942.2,2.311,2.311,0,0,1-2.114-3.252A9.155,9.155,0,0,0,15.661,4.5a2.314,2.314,0,0,1-4.322,0A9.182,9.182,0,0,0,8.662,5.611,2.311,2.311,0,0,1,6.548,8.863a2.271,2.271,0,0,1-.942-.2A9.38,9.38,0,0,0,4.5,11.342a2.315,2.315,0,0,1,0,4.321,9.177,9.177,0,0,0,1.111,2.676,2.312,2.312,0,0,1,3.052,3.051A9.236,9.236,0,0,0,11.344,22.5a2.31,2.31,0,0,1,4.312,0,9.183,9.183,0,0,0,2.677-1.111,2.314,2.314,0,0,1,3.052-3.051A9.231,9.231,0,0,0,22.5,15.663,2.327,2.327,0,0,1,21.014,13.5Zm-7.472,3.744a3.749,3.749,0,1,1,3.75-3.749A3.748,3.748,0,0,1,13.542,17.242Z"
          transform="translate(-4.5 -4.5)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
