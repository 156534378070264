/**
 * SearchText.tsx
 */
/* packages */
import { forwardRef, memo, useRef } from 'react';

/* components */
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import { MagnifyGlassSearch } from 'icons/magnifyGlassSearch/magnifyGlassSearch';

import { CrossIcon } from 'icons/cross/cross';

/* types */

const SearchText = memo(
  forwardRef<HTMLInputElement, TextFieldProps & { adormentSize?: string | number; clearAction?(): void }>((props, ref) => {
    const { adormentSize, clearAction, ...otherProps } = props;
    const internalRef = useRef<HTMLDivElement>(null);

    // const [value, setValue] = useState<string | null>(null)
    let endAdornment = null;
    if (clearAction && 'value' in otherProps && otherProps.value && !otherProps.disabled) {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="clear search field"
            onClick={clearAction}
            // onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{ p: 1, mr: '-10px', borderRadius: '5px', '&:hover': { backgroundColor: 'var(--color-hover-button)' } }}
          >
            <CrossIcon sx={{ color: 'inherit', fontSize: '.5rem' }} />
          </IconButton>
        </InputAdornment>
      );
    }

    return (
      <TextField
        variant="outlined"
        {...otherProps}
        color={undefined}
        ref={internalRef}
        inputProps={{
          ref: ref,
          className: 'custom-scrollbar',
          ...otherProps.inputProps,
          sx: {
            paddingBlock: 'var(--fs-10)',
            fontSize: 'var(--fs-14)',
            color: 'var(--color-gray2)',
            ...otherProps.inputProps?.style,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifyGlassSearch sx={{ color: 'inherit', fontSize: adormentSize ?? 16 }} />
            </InputAdornment>
          ),
          endAdornment: endAdornment,
          ...otherProps.InputProps,
        }}
        sx={{
          ...otherProps.sx,
          '& .MuiInputBase-root': {
            // paddingBlock: otherProps.multiline ? 'var(--fs-10)' : 0,
            // paddingBlock: 'var(--fs-10)',
            paddingBlock: 0,
            fontSize: 'var(--fs-14)',
          },
          // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          //   borderColor: '#a7a7a7!important',
          //   boxShadow: '0 0 0px 3px  #5ebe7050',
          // },
          ...(otherProps.disabled ? { '& .MuiOutlinedInput-notchedOutline': { borderColor: 'var(--color-lightgray3)!important' } } : {}),
        }}
      />
    );
  })
);

export default SearchText;
