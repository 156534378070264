/**
 * InvestigateSearch.tsx
 */
/* packages */
import { PropsWithChildren, useContext, useState, useMemo, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import { styled } from '@mui/material/styles';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import Loader from 'components/Loader/Loader';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ChevronIcon } from 'icons/chevron/chevron';
import { GarbageIcon } from 'icons/garbage/garbage';
import { ArrowDownSolidIcon } from 'icons/arrowDownSolid/arrowDownSolid';

import { GetUser } from 'components/GetUser/GetUser';
import { StyledOrganizationElement } from 'pages/manage/ManageSetupOrganizations';

/* utilities */
import { displayWarning } from 'utilities/Logger';
import { Dataset, DatasetFolder } from 'models/datasets';

/* types */
interface ListDatasetsandFoldersProps {
  searching: ReturnType<typeof setTimeout> | null;
  filtered: boolean;
  datasets?: Dataset[];
  folders?: DatasetFolder[];
  handleCreateDataset(dataset?: Dataset): void;
  handleDeleteDataset(dataset: Dataset): void;
  handleImportDataset(dataset: Dataset): void;
  handleCreateFolder(folder?: DatasetFolder): void;
  handleDeleteFolder(folder: DatasetFolder): void;
}

interface DisplayDatasetProps {
  dataset: Dataset;
  level: number;
  topBorderOnly?: boolean;
  handleCreateDataset: ListDatasetsandFoldersProps['handleCreateDataset'];
  handleDeleteDataset: ListDatasetsandFoldersProps['handleDeleteDataset'];
  handleImportDataset: ListDatasetsandFoldersProps['handleImportDataset'];
}

interface DisplayFolderProps {
  folder: DatasetFolder;
  nbDatasets: number;
  handleCreateFolder: ListDatasetsandFoldersProps['handleCreateFolder'];
  handleDeleteFolder: ListDatasetsandFoldersProps['handleDeleteFolder'];
}

/* elements */
const DEFAULTCOLUMNWIDTH = '180px';
const COLUMNTEMPLATE = 'minmax(250px, 1fr) minmax(150px, 1fr) repeat(3, minmax(180px, 1fr)) 220px';

const getSortedDataset = (datasets: Dataset[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!datasets) return datasets;

  const validDataset = datasets.filter((dataset) => !dataset.deleted);

  if (!sortedDirection || !sortedDirection) {
    return validDataset;
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;
  switch (sortedColumn) {
    case 'label':
      return validDataset.sort((d1, d2) => direction * d1.label.localeCompare(d2.label));
    case 'type':
      return validDataset.sort((d1, d2) => direction * (d1.type ?? '').localeCompare(d2.type ?? ''));
    case 'updatedon':
      return validDataset.sort((d1, d2) => direction * (d1.updatedDate ?? '').localeCompare(d2.updatedDate ?? ''));
    case 'createdon':
      return validDataset.sort((d1, d2) => direction * (d1.creationDate ?? '').localeCompare(d2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return validDataset;
  }
};

const ListDatasetsandFolders = memo(
  ({ searching, filtered, datasets, folders, handleCreateDataset, handleDeleteDataset, handleImportDataset, handleCreateFolder, handleDeleteFolder }: ListDatasetsandFoldersProps) => {
    const intl = useIntl();
    const rootDatasets = useMemo(() => {
      const foldersId = (folders ?? []).map((folder) => folder.id);
      return (datasets ?? []).filter((dataset) => !dataset.datasetFolder || !foldersId.includes(dataset.datasetFolder.id));
    }, [datasets, folders]);

    const [sortedColumn, setSortedColumn] = useState<string>('');
    const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

    const tableHeaders = useMemo((): TableHeadElement[] => {
      return [
        {
          id: 'label',
          label: intl.formatMessage({
            id: 'label',
            defaultMessage: 'Label',
          }),
          sorted: () => {
            const newDirection = sortedColumn !== 'label' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
            setSortedColumn('label');
            setSortedDirection(newDirection);
          },
          direction: sortedColumn === 'label' ? sortedDirection : false,
          minWidth: '250px',
        },
        {
          id: 'type',
          label: intl.formatMessage({
            id: 'type',
            defaultMessage: 'type',
          }),
          sorted: () => {
            const newDirection = sortedColumn !== 'type' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
            setSortedColumn('type');
            setSortedDirection(newDirection);
          },
          direction: sortedColumn === 'type' ? sortedDirection : false,
          minWidth: '150px',
        },
        {
          id: 'createdby',
          label: intl.formatMessage({
            id: 'createdBy',
            defaultMessage: 'Created by',
          }),
          minWidth: DEFAULTCOLUMNWIDTH,
        },
        {
          id: 'createdon',
          label: intl.formatMessage({
            id: 'createdon',
            defaultMessage: 'Created on',
          }),
          minWidth: DEFAULTCOLUMNWIDTH,
          sorted: () => {
            const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
            setSortedColumn('createdon');
            setSortedDirection(newDirection);
          },
          direction: sortedColumn === 'createdon' ? sortedDirection : false,
        },
        {
          id: 'updatedon',
          label: intl.formatMessage({
            id: 'updatedon',
            defaultMessage: 'Updated on',
          }),
          minWidth: DEFAULTCOLUMNWIDTH,
          sorted: () => {
            const newDirection = sortedColumn !== 'updatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
            setSortedColumn('updatedon');
            setSortedDirection(newDirection);
          },
          direction: sortedColumn === 'updatedon' ? sortedDirection : false,
        },
        {
          id: 'edit',
          label: '',
        },
      ];
    }, [intl, sortedColumn, sortedDirection]);

    if (searching || !datasets) {
      return (
        <Box pt={'30vh'}>
          <Loader />
        </Box>
      );
    }
    return (
      <Box mt={2}>
        {!datasets || datasets.length <= 0 ? (
          <Box p={1} mb={2}>
            <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
              {filtered ? (
                <FormattedMessage id="noMatchedDataset" defaultMessage={'No dataset matches your search'} />
              ) : (
                <FormattedMessage id="noDataset" defaultMessage={'No dataset has been defined'} />
              )}
            </Typography>
          </Box>
        ) : (
          <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={COLUMNTEMPLATE} sx={{ rowGap: '1rem', borderRadius: '5px', overflowX: 'auto' }}>
            {tableHeaders.map((th, thIndex) => {
              return (
                <Box
                  key={th.id}
                  className={`header-element with-border ${th.sorted ? 'has-sorting' : ''} ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                  {...(th.sorted
                    ? {
                        onClick: th.sorted,
                      }
                    : {})}
                >
                  {th.label.toUpperCase()}{' '}
                  {th.sorted &&
                    (th.direction ? (
                      <ArrowDownSolidIcon sx={{ fontSize: 10, color: 'inherit', rotate: th.direction === 'asc' ? '180deg' : '0deg' }} />
                    ) : (
                      <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                    ))}
                </Box>
              );
            })}

            {/* folders */}
            {folders &&
              folders.length > 0 &&
              folders.map((folder) => {
                const folderDataset = datasets.filter((dataset) => dataset.datasetFolder?.id === folder.id);
                const nbDatasetsInFolder = folderDataset.length;
                return (
                  <DisplayFolder key={folder.id} nbDatasets={nbDatasetsInFolder} {...{ folder, handleCreateFolder, handleDeleteFolder }}>
                    {folderDataset.length > 0 ? (
                      getSortedDataset(folderDataset, sortedColumn, sortedDirection)?.map((dataset) => {
                        return (
                          <DisplayDataset topBorderOnly key={dataset.id} level={1} {...{ dataset, sortedColumn, sortedDirection, handleCreateDataset, handleDeleteDataset, handleImportDataset }} />
                        );
                      })
                    ) : (
                      <Box px={4} pb={2}>
                        <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
                          <FormattedMessage id="noDatasetInFolder" defaultMessage={'No dataset'} />
                        </Typography>
                      </Box>
                    )}
                  </DisplayFolder>
                );
              })}

            {/* root dataset */}
            {getSortedDataset(rootDatasets, sortedColumn, sortedDirection)?.map((dataset) => {
              return <DisplayDataset key={dataset.id} level={0} {...{ dataset, sortedColumn, sortedDirection, handleCreateDataset, handleDeleteDataset, handleImportDataset }} />;
            })}
          </Box>
        )}
      </Box>
    );
  }
);

// const StyledDatasetElement = styled(Box)(({ theme }) => ({
//   color: 'var(--color-darkgray)',
//   fontSize: 'var(--fs-14)',
//   padding: theme.spacing(2),
//   wordBreak: 'break-word',
// }));
const StyledDatasetElement = StyledOrganizationElement;
const DisplayDataset = memo(({ dataset, level, topBorderOnly, handleCreateDataset, handleDeleteDataset, handleImportDataset }: DisplayDatasetProps) => {
  const borderKey = topBorderOnly ? 'borderTop' : 'border';
  const { allUsers } = useContext(AllUsersContext);
  return (
    <Box sx={{ gridColumn: '1/-1' }}>
      <Box display={'grid'} gridTemplateColumns={COLUMNTEMPLATE} alignItems={'center'} sx={{ borderRadius: '5px', [borderKey]: '1px solid var(--color-grayHeaderBorder)' }}>
        <StyledDatasetElement ml={2 * level}>
          <span>{dataset.label}</span>
        </StyledDatasetElement>
        <StyledDatasetElement ml={2 * level}>
          <span>{dataset.type}</span>
        </StyledDatasetElement>
        <StyledDatasetElement>{GetUser(dataset.createdBy ?? '', allUsers, true, '20px')}</StyledDatasetElement>
        <StyledDatasetElement>{dataset.creationDate && <DisplayDate date={dataset.creationDate} />}</StyledDatasetElement>
        <StyledDatasetElement>{dataset.updatedDate && <DisplayDate date={dataset.updatedDate} />}</StyledDatasetElement>
        <StyledDatasetElement sx={{ textAlign: 'right' }}>
          <Box display="flex" columnGap={'10px'} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            {dataset.isImportable && (
              <ShadowedButton
                sx={{ minWidth: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleImportDataset(dataset);
                }}
              >
                <FormattedMessage id="import" defaultMessage="Import" />
              </ShadowedButton>
            )}
            {dataset.isEditable && (
              <>
                <ShadowedButton
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleCreateDataset(dataset);
                  }}
                  sx={{ minWidth: 0 }}
                >
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </ShadowedButton>
                <IconButton
                  className="square-icon-button"
                  sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', '&:hover': { color: 'var(--color-fushia)' } }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleDeleteDataset(dataset);
                  }}
                >
                  <GarbageIcon fontSize="inherit" sx={{ color: 'inherit' }} />
                </IconButton>
              </>
            )}
          </Box>
        </StyledDatasetElement>
      </Box>
    </Box>
  );
});

const DisplayFolder = memo(({ folder, nbDatasets, handleCreateFolder, handleDeleteFolder, children }: PropsWithChildren<DisplayFolderProps>) => {
  const level = 0;
  return (
    <Box sx={{ gridColumn: '1/-1' }}>
      <Accordion elevation={0} sx={{ borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' }}>
        <AccordionSummary
          expandIcon={null}
          sx={{
            px: 0,
            minHeight: 0,
            '&.Mui-expanded': {
              minHeight: 0,
            },
            '& .MuiAccordionSummary-content': {
              m: 0,
              '& .expand-icon': {
                rotate: '180deg',
              },
              '&.Mui-expanded': {
                m: 0,
                '& .expand-icon': {
                  rotate: '-90deg',
                },
              },
            },
            '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
          }}
        >
          <Box display={'grid'} gridTemplateColumns={COLUMNTEMPLATE} alignItems={'center'} sx={{ width: '100%' }}>
            <StyledDatasetElement ml={2 * level} display="grid" gridTemplateColumns={'auto 1fr'} alignItems={'flex-start'} sx={{ gridColumn: '1/-2' }}>
              <span>
                <ChevronIcon className="expand-icon" sx={{ fontSize: 'var(--fs-10)', mr: '.5rem' }} />
              </span>
              <Box flex={1} sx={{ fontWeight: 'medium', fontSize: 'var(--fs-16)' }}>
                <span>{folder.label}</span>
              </Box>
            </StyledDatasetElement>
            <StyledDatasetElement display={'flex'} columnGap="10px" sx={{ justifyContent: 'flex-end' }}>              
              <ShadowedButton
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleCreateFolder(folder);
                  }}
                  sx={{ minWidth: 0 }}
                >
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </ShadowedButton>
              {nbDatasets <= 0 && (
                <IconButton
                  sx={{ fontSize: 'var(--fs-14)' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleDeleteFolder(folder);
                  }}
                >
                  <GarbageIcon fontSize="inherit" sx={{ color: 'var(--color-lightgray4)' }} />
                </IconButton>
              )}
            </StyledDatasetElement>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
});
export default ListDatasetsandFolders;
