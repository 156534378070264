/**
 * FilterSchedules.tsx
 */
/* packages */
import { useState, useCallback, memo, useMemo, useRef, forwardRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import SearchText from 'components/SearchElements/SearchText/SearchText';

import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import DateRangeFilter from 'components/SearchFilters/DateRangeFilter';

/* utilities */

/* types */
import { AllowedJobType } from 'models/job';
export interface SelectedFiltersSchedulesValues {
  search: string;
  type: string[];
  date?: [Dayjs, Dayjs];
  active: string[];
}
interface SearchFormType {
  disabled?: boolean;
  onChangeFilter(filters: SelectedFiltersSchedulesValues, debounce?: boolean): void;
}

const SearchFiltersMemo = memo(SearchFilters);
const FilterSchedulesForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { disabled, onChangeFilter } = props;

    const [searchInput, setSearchInput] = useState<string>('');

    // // define required ref
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const dateRef = useRef<FilterButtonRefType | null>(null);
    const typeRef = useRef<FilterButtonRefType | null>(null);
    const activeRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(
      () => [
        {
          ref: typeRef,
          text: <FormattedMessage id="type" defaultMessage="Type" />,
          inputName: 'type',
          filterContent: <FilterCheckboxes title={intl.formatMessage({ id: 'type', defaultMessage: 'Type' })} list={AllowedJobType.map((type) => ({ key: type, value: type }))} />,
        },
        {
          ref: dateRef,
          text: <FormattedMessage id="date" defaultMessage="Date" />,
          inputName: 'date',
          filterContent: <DateRangeFilter title={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })} />,
        },
        {
          ref: activeRef,
          text: <FormattedMessage id="active" defaultMessage="Active" />,
          inputName: 'status',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'active', defaultMessage: 'Active' })}
              list={[
                { key: 'active', value: intl.formatMessage({ id: 'active', defaultMessage: 'Active' }) },
                { key: 'unactive', value: intl.formatMessage({ id: 'unactive', defaultMessage: 'Unactive' }) },
              ]}
            />
          ),
        },
      ],
      [intl]
    );

    const onActivate = useCallback(
      (searchTerm?: string, debounce: boolean = false) => {
        const dateValues = dateRef.current?.getValue();
        let dates: [Dayjs, Dayjs] | undefined = undefined;
        if (dateValues?.dayValue && dateValues.dayEndValue) {
          dates = [dateValues.dayValue, dateValues.dayEndValue];
        }

        const selectedFilters: SelectedFiltersSchedulesValues = {
          search: searchTerm ?? searchInputRef.current?.value ?? '',
          type: typeRef.current?.getValue().values || [],
          date: dates,
          active: activeRef.current?.getValue().values || [],
        };
        onChangeFilter(selectedFilters, debounce);
      },
      [onChangeFilter]
    );

    const updateSearchInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        onActivate(searchTerm, searchTerm ? true : false);
      },
      [onActivate]
    );

    const clearSearchInput = useCallback(() => {
      const searchTerm = '';
      setSearchInput(searchTerm);

      onActivate(searchTerm);
    }, [onActivate]);

    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    const searchBigInputProps = useMemo(() => {
      return { readOnly: disabled };
    }, [disabled]);

    return (
      <Box width={'100%'} display={'flex'} px={0} py={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                ref={searchInputRef}
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                disabled={disabled}
                clearAction={clearSearchInput}
                InputProps={searchBigInputProps}
              />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box display={'flex'} gap={'1rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
              <Typography sx={{ fontSize: 'var(--fs-14)', fontWeight: 500 }}>
                <FormattedMessage id="filterBy" defaultMessage={'Filter by'} />
              </Typography>
              <SearchFiltersMemo disabled={disabled} {...{ searchFiltersButtons, onActivate }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

export default FilterSchedulesForm;
