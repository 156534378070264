/**
 *  EN language file
 */

const lang_en: {} = {
  configReadError: 'Configuration file could not be read. Contact your network administrator.',

  emptyInvestigateSearch: 'Search for individuals, organizations and vessels on watchlists',
  emptyInvestigateExplore: 'Explore datasets to browse records, access profiles, and review changes',
  emptyManageLibraries: 'Explore and manage the content of your libraries',

  unassigned: 'Unassigned',

  // login
  loginTitle: 'Sign in',
  loginUsername: 'User name',
  loginPassword: 'Password',
  loginButton: 'Sign in',
  loginNeedHelp: 'Do you need help?',
  loginContact: 'Contact us',
  loginTerms: 'Terms of Use',
  loginPrivacy: 'Privacy & security',
  loginHelpCenter: 'Help Center',
  loginAuthenticationError: 'Authentication failed',
  loginAuthenticationSuccess: 'Authentication success',

  // menu
  helpButton: 'Help',
  signoutButton: 'Sign out',

  // search constants
  idnumber: 'Identification number',
  lei: 'LEI',
  bic: 'BIC',
  individual: 'Individual',
  organization: 'Organization',
  vessel: 'Vessel',
  unknown: 'Unknown',

  // country fields
  nationality: 'Nationality',
  placeOfBirth: 'Place of birth',
  placeOfRegistry: 'Place of registry',
  address: 'Address',
  flag: 'Flag',

  // country algo
  same_country: 'Same country',
  same_subregion: 'Same subregion',
  same_region: 'Same region',

  // date fields
  datesOfBirth: 'Date of birth',
  datesOfBuild: 'Date of build',
  datesOfRegistry: 'Date of registry',

  //date algo
  selectAlgorithm: 'Select algorithm',
  same_day_month_year: 'Same day/month/year',
  same_month_year: 'Same month/year',
  same_year: 'Same year',
  same_quadrennium: 'Same quadrennium (source date +/-2 year)',
  same_decade: 'Same decade (source date +/-5 year)',

  // schedules
  startScheduleSuccess: 'The schedule has been started',
  stopScheduleSuccess: 'The schedule has been stopped',
  startScheduleError: 'The schedule could not be started',
  stopScheduleError: 'The schedule could not be stopped',

  label_sourceDataSet: 'Source dataset',
  label_matchingProfile: 'Matching profile',

  // name types
  fullName: 'Full name',
  givenName: 'Given name',
  fatherName: 'Father name',
  motherName: 'Mother name',
  surname: 'Surname',
  type: 'Type',

  // properties keys
  from: 'From',
  to: 'To',
  category: 'Category',
  category_plural: 'Categories',
  dataset: 'Dataset',
  dataset_plural: 'Datasets',
  creationDate: 'Creation date',
  creationDate_plural: 'Creation dates',
  updatedDate: 'Updated date',
  updatedDate_plural: 'Updated dates',
  label: 'Label',
  label_plural: 'Labels',
  type_plural: 'Types',
  name: 'Name',
  name_plural: 'Names',
  title: 'Title',
  title_plural: 'Titles',
  sex: 'Sex',
  nationality_plural: 'Nationalities',
  dateOfBirth: 'Date of birth',
  dateOfBirth_plural: 'Dates of birth',
  placeOfBirth_plural: 'Places of birth',
  deceased: 'Deceased',
  deceased_plural: 'Deceased',
  dateOfDeath: 'Date of death',
  dateOfDeath_plural: 'Dates of death',
  address_plural: 'Addresses',
  dateOfRegistry: 'Date of registry',
  dateOfRegistry_plural: 'Dates of registry',
  placeOfRegistry_plural: 'Places of registry',
  identityDocument: 'Identity document',
  identityDocument_plural: 'Identity documents',
  lei_plural: 'LEIS',
  bic_plural: 'BICS',
  digitalCurrency: 'Digital currency',
  digitalCurrency_plural: 'Digital currencies',
  flag_plural: 'Flags',
  dateOfBuild: 'Date of build',
  dateOfBuild_plural: 'Dates of build',
  placeOfBuild: 'Place of build',
  placeOfBuild_plural: 'Places of build',
  position: 'Position',
  position_plural: 'Positions',
  sector: 'Sector',
  sector_plural: 'Sectors',
  classification: 'Classification',
  classification_plural: 'Classifications',
  dissolved: 'Dissolved',
  dissolved_plural: 'Dissolved',
  dateOfDissolution: 'Date of dissolution',
  dateOfDissolution_plural: 'Dates of dissolution',
  keyword: 'Keyword',
  keyword_plural: 'Keywords',
  riskSince: 'Risk since',
  riskSince_plural: 'Risks since',
  risk: 'Risk',
  risk_plural: 'Risks',
  subCategory: 'Subcategory',
  subCategory_plural: 'Subcategories',
  periodOfRisk: 'Period of risk',
  periodOfRisk_plural: 'Periods of risk',
  information: 'Information',
  information_plural: 'Informations',
  media: 'Media',
  media_plural: 'Medias',
  contactInformation: 'Contact information',
  contactInformation_plural: 'Contact information',

  placeName: 'Place name',
  streetNumber: 'Street number',
  streetName: 'Street name',
  street: 'Street',
  poBox: 'PO box',
  zipCode: 'ZIP code',
  city: 'City',
  state: 'State',
  country: 'Country',
};

export default lang_en;
