/**
 * AuthProvider.ts
 * Register the authentication of user and provide the values
 */
/* packages */
import { createContext, useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { CountryType, ListCountriesResponse } from 'models/countries';

interface CountriesContextType {
  countriesQueried: boolean;
  loadingCountries: boolean;
  countries?: CountryType;
  listCountries?(abortController?: AbortController): void;
}
/* elements */
const CountriesContext = createContext<CountriesContextType>({
  countriesQueried: false,
  loadingCountries: false,
  countries: undefined,
  listCountries: undefined,
});

const CountriesProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId } = useContext(UserContext);

  const listingCountries = useRef(false);
  const [queried, setQueried] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<CountryType | undefined>(undefined);

  const listCountries = useCallback(
    async (abortController?: AbortController) => {
      if (listingCountries.current) return;

      listingCountries.current = true;
      setLoading(true);
      setCountries(undefined);
      try {
        const countriesUrl = URLConstants.allCountries;
        const results = (await getAuthenticatedRequest(countriesUrl, abortController ?? undefined)) as ListCountriesResponse;
        setQueried(true);
        setLoading(false);
        setCountries(results.listOfCountries);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setQueried(true);
        setLoading(false);
        setCountries(undefined);
      }
      listingCountries.current = false;
    },
    [getAuthenticatedRequest]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listCountries();
  }, [currentUserId, listCountries]);

  const userContextValue = useMemo(
    (): CountriesContextType => ({
      countriesQueried: queried,
      loadingCountries: loading,
      countries,
      listCountries,
    }),
    [queried, loading, countries, listCountries]
  );
  return <CountriesContext.Provider value={userContextValue}>{children}</CountriesContext.Provider>;
};

/* exports */
export { CountriesContext, CountriesProvider };
