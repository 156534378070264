/**
 * AlertsProvider.tsx
 * provide the function to handle alerts
 */
/* packages */
import { useMemo, createContext, PropsWithChildren, useCallback } from 'react';

/* contexts */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { Alert, AlertChangeWorkflowStatusQuery, AlertChangeWorkflowStatusResponse } from 'models/alerts';
import { EditAlertStatusPayload } from 'components/InvestigateAlert/alertProvider';

export interface AlertsContextType {
  editAlertStatus?(payload: EditAlertStatusPayload): Promise<Alert | undefined>;
}

const AlertsContext = createContext<AlertsContextType>({});

const AlertsProvider = ({ children }: PropsWithChildren) => {
  const { postAuthenticatedRequest } = useAuthenticatedRequest();

  // edit Status
  const editAlertStatus = useCallback(
    async (payload: EditAlertStatusPayload) => {
      const { newStatusId, comment, alertId, editUser, userType, userId } = payload;
      if (!alertId) throw new Error('Invalid alert id');
      // if (!comment) return;
      if (newStatusId === undefined) throw new Error('Invalid new status id');

      const queryPayload: AlertChangeWorkflowStatusQuery = {
        alert: { id: alertId },
        comment: { comment },
      };

      let editStatusUrl;

      if (editUser && userId) {
        editStatusUrl = URLConstants.alertChangeAssigned;
        switch (userType) {
          case 'user':
            if (userId === -1) throw new Error('Invalid user id');
            queryPayload['user'] = { id: userId };
            break;
          case 'group':
            queryPayload['group'] = { id: userId };
            break;
          case null:
            queryPayload['unassigned'] = true;
            break;
          default:
            break;
        }
      } else {
        editStatusUrl = URLConstants.alertExecuteWorkflow;
        queryPayload.alertNextStatus = {
          id: newStatusId,
        };
      }

      try {
        const results = (await postAuthenticatedRequest(editStatusUrl, queryPayload)) as AlertChangeWorkflowStatusResponse;
        if (!results.alert) {
          throw new Error('Invalid response');
        }

        return results.alert;
      } catch (editStatusError: any) {
        if (editStatusError?.code === 'ERR_CANCELED') return;
        throw new Error('An error occured while editing the status');
      }
    },
    [postAuthenticatedRequest]
  );

  const value = useMemo(() => {
    return {
      editAlertStatus,
    };
  }, [editAlertStatus]);

  return <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>;
};

export { AlertsContext, AlertsProvider };
