/**
 * FilterAlertScreenings.tsx
 */
/* packages */
import { useState, useCallback, memo, useMemo, useRef, forwardRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import SearchText from 'components/SearchElements/SearchText/SearchText';

import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';

/* utilities */
import { AlertScreeningType } from 'models/alertScreening';

/* types */

export interface SelectedFiltersAlertScreeningsValues {
  search: string;
  source: string[];
  alertDataset: string[];
}
interface SearchFormType {
  disabled?: boolean;
  onChangeFilter(filters: SelectedFiltersAlertScreeningsValues, debounce?: boolean): void;
  alertScreenings?: AlertScreeningType[];
}

const SearchFiltersMemo = memo(SearchFilters);
const FilterAlertScreenings = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { disabled, onChangeFilter, alertScreenings } = props;

    const [searchInput, setSearchInput] = useState<string>('');

    // // define required ref
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const sourceRef = useRef<FilterButtonRefType | null>(null);
    const alertDatasetRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      const filtersButton: SearchFiltersButtonType[] = [];
      if (alertScreenings && alertScreenings.length > 0) {
        const sources = Array.from(new Set(alertScreenings.filter((as) => as.sourceDataSet).map((as) => as.sourceDataSet?.label ?? '')));
        filtersButton.push({
          ref: sourceRef,
          text: <FormattedMessage id="source" defaultMessage="Customer dataset" />,
          inputName: 'source',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'source', defaultMessage: 'Customer dataset' })}
              list={sources.map((source) => ({
                key: source,
                value: source,
              }))}
            />
          ),
        });

        const alertDatasets = Array.from(new Set(alertScreenings.filter((as) => as.matchingRuleScoring && as.matchingRuleScoring.dataset).map((as) => as.matchingRuleScoring?.dataset?.label ?? '')));
        filtersButton.push({
          ref: alertDatasetRef,
          text: <FormattedMessage id="alertDatast" defaultMessage="Alert dataset" />,
          inputName: 'alertDataset',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'alertDataset', defaultMessage: 'Alert dataset' })}
              list={alertDatasets.map((source) => ({
                key: source,
                value: source,
              }))}
            />
          ),
        });
      }
      return filtersButton;
    }, [intl, alertScreenings]);

    const onActivate = useCallback(
      (searchTerm?: string, debounce: boolean = false) => {
        const selectedFilters: SelectedFiltersAlertScreeningsValues = {
          search: searchTerm ?? searchInputRef.current?.value ?? '',
          source: sourceRef.current?.getValue().values || [],
          alertDataset: alertDatasetRef.current?.getValue().values || [],
        };
        onChangeFilter(selectedFilters, debounce);
      },
      [onChangeFilter]
    );

    const updateSearchInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        onActivate(searchTerm, searchTerm ? true : false);
      },
      [onActivate]
    );

    const clearSearchInput = useCallback(() => {
      const searchTerm = '';
      setSearchInput(searchTerm);

      onActivate(searchTerm);
    }, [onActivate]);

    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    return (
      <Box width={'100%'} display={'flex'} px={0} py={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                ref={searchInputRef}
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                disabled={disabled}
                clearAction={clearSearchInput}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box display={'flex'} gap={'1rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
              <Typography sx={{ fontSize: 'var(--fs-14)', fontWeight: 500 }}>
                <FormattedMessage id="filterBy" defaultMessage={'Filter by'} />
              </Typography>
              <SearchFiltersMemo disabled={disabled} {...{ searchFiltersButtons, onActivate }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

export default FilterAlertScreenings;
