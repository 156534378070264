/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useEffect, useCallback, useState, useMemo, memo, useContext, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */
import { DatasetsContext } from 'contextProviders/DatasetsProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { OrganizationsContext } from 'contextProviders/OrganizationsProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';

/* components */
import Box from '@mui/material/Box';
import TitleLayout from 'components/Layouts/TitleLayout';

import ImportDataset from 'components/ManageDatasets/ImportDataset';
import AuditExecutions from 'components/ManageDatasets/AuditExecutions';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import EditDataset from 'components/ManageDatasets/EditDataset';
import EditFolder from 'components/ManageDatasets/EditFolder';
import Loader from 'components/Loader/Loader';
import FilterDatasetsForm, { SelectedFiltersDatasetsValues } from 'components/ManageDatasets/FilterDatasets';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import ListDatasetsandFolders from 'components/ManageDatasets/ListDatasetsAndFolders';
import DeleteFolder from 'components/ManageDatasets/DeleteFolder';
import DeleteDataset from 'components/ManageDatasets/DeleteDataset';
import { AddIcon } from 'icons/add/add';
/* utilities */

/* types */
import { Dataset, DatasetFolder } from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';

interface ManageDatasetsContentProps {
  load(): void;
  loading: boolean;
  handleCreateDataset(dataset?: Dataset): void;
  handleDeleteDataset(dataset: Dataset): void;
  handleImportDataset(dataset: Dataset): void;
  handleCreateFolder(folder?: DatasetFolder): void;
  handleDeleteFolder(folder: DatasetFolder): void;
}
/* elements */
const ManageDatasets = () => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();

  const [initialLoading, setInitialLoading] = useState(true);

  const { listDatasets, loadingDatasets, addOrUpdateDataset, deleteDataset, exportDataset, importDataset } = useContext(DatasetsContext);
  const { folders, loadingFolders, listDatasetFolders, addOrUpdateDatasetFolder, deleteDatasetFolder } = useContext(DatasetsContext);
  const { classifications, loadingClassifications, listDatasetsClassifications } = useContext(DatasetsContext);
  const { allUsers, loadingAllUsers, listAllUsers } = useContext(AllUsersContext);
  const { teams, loadingTeams, listTeams } = useContext(TeamsContext);
  const { organizations, loadingOrganizations, listOrganizations } = useContext(OrganizationsContext);

  // methods
  const load = useCallback(async () => {
    listDatasets?.();
    listDatasetFolders?.();
    listAllUsers?.();
    listTeams?.();
    listOrganizations?.();
    listDatasetsClassifications?.();
    setInitialLoading(false);
  }, [listDatasets, listDatasetFolders, listAllUsers, listOrganizations, listTeams, listDatasetsClassifications]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const setDataset = useCallback(
    async (data: Partial<Dataset>, dataset?: Dataset): Promise<StatusPromiseResponse> => {
      try {
        const updatedDataset = await addOrUpdateDataset?.(data, dataset ? false : true);

        if (!updatedDataset) {
          return { status: 'error' };
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateDataset]
  );

  const handleCreateDataset = useCallback(
    (dataset?: Dataset) => {
      toggleModal?.({
        title: dataset ? intl.formatMessage({ id: 'editDataset', defaultMessage: 'Edit dataset' }) : intl.formatMessage({ id: 'addDataset', defaultMessage: 'Add dataset' }),
        modalContent: <EditDataset {...{ dataset, setDataset, allUsers, teams, organizations, classifications, folders }} />,
      });
    },
    [intl, toggleModal, setDataset, allUsers, teams, organizations, classifications, folders]
  );

  const removeDataset = useCallback(
    async (removedId: number): Promise<StatusPromiseResponse> => {
      try {
        const deletedDataset = await deleteDataset?.(removedId);

        if (deletedDataset) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteDataset]
  );

  const handleDeleteDataset = useCallback(
    (dataset: Dataset) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteDataset', defaultMessage: 'Delete dataset' }),
        modalContent: <DeleteDataset {...{ dataset, removeDataset }} />,
      });
    },
    [intl, toggleModal, removeDataset]
  );

  const handleImportDataset = useCallback(
    (dataset: Dataset) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'importDataset', defaultMessage: 'Import dataset' }),
        modalContent: <ImportDataset {...{ dataset, exportDataset, importDataset }} />,
        noContentPadding: true,
      });
    },
    [intl, toggleModal, exportDataset, importDataset]
  );

  const setFolder = useCallback(
    async (data: Partial<DatasetFolder>, folder?: DatasetFolder): Promise<StatusPromiseResponse> => {
      // const newData = {
      //   ...folder,
      //   ...data,
      // } as DatasetFolder;

      try {
        const updatedFolder = await addOrUpdateDatasetFolder?.(data, folder ? false : true);

        if (!updatedFolder) {
          return { status: 'error' };
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateDatasetFolder]
  );

  const handleCreateFolder = useCallback(
    (folder?: DatasetFolder) => {
      toggleModal?.({
        title: folder ? intl.formatMessage({ id: 'editFolder', defaultMessage: 'Edit folder' }) : intl.formatMessage({ id: 'addFolder', defaultMessage: 'Add folder' }),
        modalContent: <EditFolder {...{ folder, setFolder, allUsers, teams, organizations }} />,
      });
    },
    [intl, toggleModal, setFolder, allUsers, teams, organizations]
  );

  const removeFolder = useCallback(
    async (removedId: number): Promise<StatusPromiseResponse> => {
      try {
        const deletedDatasetFolder = await deleteDatasetFolder?.(removedId);

        if (deletedDatasetFolder) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteDatasetFolder]
  );

  const handleDeleteFolder = useCallback(
    (folder: DatasetFolder) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteDatasetFolder', defaultMessage: 'Delete folder' }),
        modalContent: <DeleteFolder {...{ folder, removeFolder }} />,
      });
    },
    [intl, toggleModal, removeFolder]
  );

  const loading = initialLoading || loadingDatasets || loadingFolders || loadingAllUsers || loadingTeams || loadingOrganizations || loadingClassifications;

  const addButton = useMemo(() => {
    return {
      addButtonText: intl.formatMessage({ id: 'addDataset', defaultMessage: 'Add dataset' }),
      handleButtonClick: () => handleCreateDataset(),
      disableAddButton: loading,
    };
  }, [intl, handleCreateDataset, loading]);

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageDatasetsTitle', defaultMessage: 'Datasets' })} addButton={addButton}>
      <ManageDatasetsContent {...{ load, loading, handleCreateDataset, handleDeleteDataset, handleImportDataset, handleCreateFolder, handleDeleteFolder }} />
    </TitleLayout>
  );
};

const DEBOUNCETIMING = 500;
const ManageDatasetsContent = memo(({ load, loading, handleCreateDataset, handleCreateFolder, handleImportDataset, handleDeleteDataset, handleDeleteFolder }: ManageDatasetsContentProps) => {
  const { datasets, folders } = useContext(DatasetsContext);
  const intl = useIntl();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const [visibleDatasets, setVisibleDatasets] = useState<typeof datasets | undefined>(datasets);
  const [visibleFolders, setVisibleFolders] = useState<typeof folders | undefined>(folders);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [searching, setSearching] = useState<ReturnType<typeof setTimeout> | null>(null);

  const makeSearch = useCallback(
    async (filters: SelectedFiltersDatasetsValues | null) => {
      let visDataset = datasets;
      let isFiltered = false;

      if (filters) {
        if (filters.type.length > 0) {
          isFiltered = true;
          visDataset = visDataset?.filter((ds) => filters.type.includes(ds?.type as string));
        }
        if (filters.datasetFolder.length > 0) {
          isFiltered = true;
          visDataset = visDataset?.filter((ds) => ds.datasetFolder && filters.datasetFolder.includes(String(ds.datasetFolder.id)));
        }
        if (filters.search) {
          isFiltered = true;
          visDataset = visDataset?.filter(
            (ds) => (ds.label ?? '').toLowerCase().includes(filters.search.toLowerCase()) || (ds.datasetFolder && ds.datasetFolder.label.toLowerCase().includes(filters.search.toLowerCase()))
          );
        }
      }

      setFiltered(isFiltered);
      setVisibleDatasets(visDataset);
      let visFolders = folders;
      if (isFiltered) {
        const datasetFolderIds = new Set(visDataset?.filter((ds) => ds.datasetFolder)?.map((ds) => ds.datasetFolder?.id) ?? []);
        visFolders = visFolders?.filter((folder) => datasetFolderIds.has(folder.id));
      }
      setVisibleFolders(visFolders);
    },
    [datasets, folders]
  );

  const onChangeFilter = useCallback(
    (newFilters: SelectedFiltersDatasetsValues, debounce = false) => {
      setSearching((currentSearch) => {
        if (currentSearch) {
          clearTimeout(currentSearch);
          return null;
        }
        return currentSearch;
      });

      // setFilters(newFilters);
      const applySearch = async () => {
        await makeSearch(newFilters);
      };

      if (debounce) {
        const filterTimeout = setTimeout(async () => {
          await applySearch();
          setSearching(null);
        }, DEBOUNCETIMING);

        setSearching(filterTimeout);
      } else {
        applySearch();
      }
    },
    [makeSearch]
  );

  const [activeTab, setActiveTab] = useState<'datasets' | 'lastExecutions'>('datasets');

  const handleTabChange = (_: React.SyntheticEvent, tabValue: typeof activeTab) => {
    const newTab = tabValue;

    setActiveTab(newTab);
  };
  const tabs = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'datasets', defaultMessage: 'Datasets' }),
        value: 'datasets',
      },
      {
        text: intl.formatMessage({ id: 'lastExecutions', defaultMessage: 'Last executions' }),
        value: 'lastExecutions',
      },
    ],
    [intl]
  );

  const isError = !loading && (!datasets || !folders);
  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  let content;
  switch (activeTab) {
    case 'datasets':
      content = (
        <ListDatasetsandFolders
          {...{ searching, filtered, datasets: visibleDatasets, folders: visibleFolders, handleCreateDataset, handleDeleteDataset, handleImportDataset, handleCreateFolder, handleDeleteFolder }}
        />
      );
      break;
    case 'lastExecutions':
      content = <AuditExecutions />;
      break;
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      <Box display="flex" columnGap={'1rem'}>
        <FilterDatasetsForm ref={searchFormRef} {...{ disabled: activeTab !== 'datasets', onChangeFilter, datasets: datasets, datasetFolders: folders }} />
        <ShadowedButton onClick={() => handleCreateFolder()} sx={{ alignSelf: 'center' }}>
          <Box display="flex" gap={'5px'} sx={{ whiteSpace: 'nowrap', alignItems: 'center' }}>
            <AddIcon sx={{ fontSize: '12px' }} />
            <FormattedMessage id="createFolder" defaultMessage="Add folder" />
          </Box>
        </ShadowedButton>
      </Box>

      <TabsNavigation activeTab={activeTab} onChange={handleTabChange} skipAll tabTitles={tabs} label={'dataset content'} />

      {content}
    </Box>
  );
});

export default ManageDatasets;
