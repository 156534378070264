/**
 * ManageScreeningsWorkflow.tsx
 */
/* packages */
import { useCallback, useEffect, useState, memo, useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';

/* contexts */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { WorkflowContext } from 'contextProviders/WorkflowProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';

/* components */
import Box from '@mui/material/Box';

import TitleLayout from 'components/Layouts/TitleLayout';
import Loader from 'components/Loader/Loader';
import EditWorkflowStatus from 'components/ManageWorkflowStatus/EditWorkflowStatus';
import DeleteWorkflowStatus from 'components/ManageWorkflowStatus/DeleteWorkflowStatus';
import ListWorkflowStatus from 'components/ManageWorkflowStatus/ListWorkflowStatus';
import ReloadButton from 'components/ReloadButton/ReloadButton';

/* utilities */

/* types */
import { WorkflowStatusType } from 'models/workflow';
import { StatusPromiseResponse } from 'models/utils';

interface ManageScreeningsWorkflowContentProps {
  load(): void;
  handleCreateWorkflowStatus(workflowStatus?: WorkflowStatusType): void;
  handleDeleteWorkflowStatus(workflowStatus: WorkflowStatusType): void;
}
/* elements */
const ManageScreeningsWorkflow = () => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();

  const [initialLoading, setInitialLoading] = useState(true);
  const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);
  const {
    loadingStatus,
    workflowStatus: listOfWorkflowStatus,
    listStatus,
    addOrUpdateStatus,
    deleteStatus,
    loadingWorkflowActions,
    workflowActions,
    listWorkflowActions,
  } = useContext(WorkflowContext);

  const load = useCallback(() => {
    listAllUsers?.();
    listStatus?.();
    listWorkflowActions?.();
  }, [listAllUsers, listStatus, listWorkflowActions]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const setWorkflowStatus = useCallback(
    async (data: Partial<WorkflowStatusType>, workflowStatus?: WorkflowStatusType): Promise<StatusPromiseResponse> => {
      try {
        const updatedWorkflowStatus = await addOrUpdateStatus?.(data, workflowStatus ? false : true);

        if (!updatedWorkflowStatus) {
          return { status: 'error' };
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateStatus]
  );

  const handleCreateWorkflowStatus = useCallback(
    (workflowStatus?: WorkflowStatusType) => {
      toggleModal?.({
        title: workflowStatus
          ? intl.formatMessage({ id: 'editWorkflowStatus', defaultMessage: 'Edit status' })
          : intl.formatMessage({ id: 'createWorkflowStatus', defaultMessage: 'Add status' }),
        modalContent: <EditWorkflowStatus {...{ workflowStatus, setWorkflowStatus, workflowActions, listOfWorkflowStatus }} />,
      });
    },
    [intl, toggleModal, setWorkflowStatus, workflowActions, listOfWorkflowStatus]
  );

  const removeWorkflowStatus = useCallback(
    async (removedId: number): Promise<StatusPromiseResponse> => {
      try {
        const deletedWorkflowStatus = await deleteStatus?.(removedId);

        if (deletedWorkflowStatus) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteStatus]
  );

  const handleDeleteWorkflowStatus = useCallback(
    (workflowStatus: WorkflowStatusType) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteWorkflowStatus', defaultMessage: 'Delete status' }),
        modalContent: <DeleteWorkflowStatus {...{ workflowStatus, removeWorkflowStatus }} />,
      });
    },
    [intl, toggleModal, removeWorkflowStatus]
  );

  const loading = initialLoading || loadingAllUsers || loadingStatus || loadingWorkflowActions;

  const addButton = useMemo(() => {
    return {
      addButtonText: intl.formatMessage({ id: 'createWorkflow', defaultMessage: 'Add status' }),
      handleButtonClick: () => handleCreateWorkflowStatus(),
      disableAddButton: loading,
    };
  }, [intl, handleCreateWorkflowStatus, loading]);

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageScreeningsWorkflowTitle', defaultMessage: 'Workflows' })} addButton={addButton}>
      {loading ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <ManageScreeningsWorkflowContent {...{ load, handleCreateWorkflowStatus, handleDeleteWorkflowStatus }} />
      )}
    </TitleLayout>
  );
};

const ManageScreeningsWorkflowContent = memo(({ load, handleCreateWorkflowStatus, handleDeleteWorkflowStatus }: ManageScreeningsWorkflowContentProps) => {
  const { workflowStatus: listOfWorkflowStatus, workflowActions } = useContext(WorkflowContext);

  const isError = !listOfWorkflowStatus || !workflowActions;
  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      <ListWorkflowStatus {...{ listOfWorkflowStatus, handleCreateWorkflowStatus, handleDeleteWorkflowStatus, workflowActions }} />
    </Box>
  );
});

export default ManageScreeningsWorkflow;
