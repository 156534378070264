/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useContext, useState, useMemo, memo, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

/* contexts */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { DatasetsContext } from 'contextProviders/DatasetsProvider';
import { AlertScreeningsContext } from 'contextProviders/AlertScreeningsProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';

/* components */
import Box from '@mui/material/Box';

import TitleLayout from 'components/Layouts/TitleLayout';
import Loader from 'components/Loader/Loader';
import EditAlertScreening from 'components/ManageAlertScreenings/EditAlertScreening';
import DeleteAlertScreening from 'components/ManageAlertScreenings/DeleteAlertScreening';
import ImportAlertScreening from 'components/ManageAlertScreenings/ImportAlertScreening';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import ListAlertScreening from 'components/ManageAlertScreenings/ListAlertScreening';

/* utilities */

/* types */
import { AlertScreeningType } from 'models/alertScreening';
import { StatusPromiseResponse } from 'models/utils';
import FilterAlertScreenings, { SelectedFiltersAlertScreeningsValues } from 'components/ManageAlertScreenings/FilterAlertScreenings';
import { WorkflowContext } from 'contextProviders/WorkflowProvider';

interface ManageScreeningsAlertContentProps {
  load(): void;
  loading: boolean;
  handleCreateAlertScreening(alertScreening?: AlertScreeningType): void;
  handleDeleteAlertScreening(alertScreening: AlertScreeningType): void;
  handleImportAlertScreening(alertScreening: AlertScreeningType): void;
}

/* elements */
const ManageScreeningsAlert = () => {
  const intl = useIntl();

  const { toggleModal } = useAddModal();

  const [initialLoading, setInitialLoading] = useState(true);
  const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);
  const { loadingDatasets, listDatasets, datasets } = useContext(DatasetsContext);
  const { loadingAlertScreenings, listAlertScreenings, addOrUpdateAlertScreenings, deleteAlertScreening, exportAlertScreening, importAlertScreening } = useContext(AlertScreeningsContext);
  const { loadingStatus, listStatus, workflowStatus } = useContext(WorkflowContext);

  const load = useCallback(() => {
    listAllUsers?.();
    listDatasets?.();
    listStatus?.();
    listAlertScreenings?.();
  }, [listAllUsers, listDatasets, listAlertScreenings, listStatus]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const setAlertScreening = useCallback(
    async (data: Partial<AlertScreeningType>, alertScreening?: AlertScreeningType): Promise<StatusPromiseResponse> => {
      try {
        const updatedAlertScreening = await addOrUpdateAlertScreenings?.(data, alertScreening ? false : true);

        if (!updatedAlertScreening) {
          return { status: 'error' };
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateAlertScreenings]
  );

  const handleCreateAlertScreening = useCallback(
    (alertScreening?: AlertScreeningType) => {
      toggleModal?.({
        title: alertScreening
          ? intl.formatMessage({ id: 'editAlertScreening', defaultMessage: 'Edit alert settings' })
          : intl.formatMessage({ id: 'createAlertScreening', defaultMessage: 'Add alert settings' }),
        modalContent: <EditAlertScreening {...{ alertScreening, setAlertScreening, datasets, workflowStatus }} />,
      });
    },
    [intl, toggleModal, setAlertScreening, datasets, workflowStatus]
  );

  const removeAlertScreening = useCallback(
    async (removedId: number): Promise<StatusPromiseResponse> => {
      try {
        const deletedAlertScreening = await deleteAlertScreening?.(removedId);

        if (deletedAlertScreening) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteAlertScreening]
  );

  const handleDeleteAlertScreening = useCallback(
    (alertScreening: AlertScreeningType) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteAlertScreening', defaultMessage: 'Delete alert settings' }),
        modalContent: <DeleteAlertScreening {...{ alertScreening, removeAlertScreening }} />,
      });
    },
    [intl, toggleModal, removeAlertScreening]
  );

  const handleImportAlertScreening = useCallback(
    (alertScreening: AlertScreeningType) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'importAlertScreening', defaultMessage: 'Import alert settings' }),
        modalContent: <ImportAlertScreening {...{ alertScreening, exportAlertScreening, importAlertScreening }} />,
        noContentPadding: true,
      });
    },
    [intl, toggleModal, exportAlertScreening, importAlertScreening]
  );

  const loading = ((initialLoading || loadingAllUsers || loadingDatasets || loadingAlertScreenings) ?? false) || (loadingStatus ?? false);

  const addButton = useMemo(() => {
    return {
      addButtonText: intl.formatMessage({ id: 'createAlertScreening', defaultMessage: 'Add alert settings' }),
      handleButtonClick: () => handleCreateAlertScreening(),
      disableAddButton: loading,
    };
  }, [intl, loading, handleCreateAlertScreening]);

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageScreeningsAlertTitle', defaultMessage: 'Alert settings' })} addButton={addButton}>
      {loading ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <ManageScreeningsAlertContent {...{ load, loading, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }} />
      )}
    </TitleLayout>
  );
};

const DEBOUNCETIMING = 500;
const ManageScreeningsAlertContent = memo(({ load, loading, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }: ManageScreeningsAlertContentProps) => {
  const { alertScreenings } = useContext(AlertScreeningsContext);
  const { datasets } = useContext(DatasetsContext);

  const searchFormRef = useRef<HTMLFormElement>(null);
  const [visibleAlertScreenings, setVisibleAlertScreenings] = useState<typeof alertScreenings | undefined>(alertScreenings);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [searching, setSearching] = useState<ReturnType<typeof setTimeout> | null>(null);

  const makeSearch = useCallback(
    async (filters: SelectedFiltersAlertScreeningsValues | null) => {
      let visAlertScreenings = alertScreenings;
      let isFiltered = false;

      if (filters) {
        if (filters.source.length > 0) {
          isFiltered = true;
          visAlertScreenings = visAlertScreenings?.filter((as) => as.sourceDataSet && filters.source.includes(as.sourceDataSet.label));
        }
        if (filters.alertDataset.length > 0) {
          isFiltered = true;
          visAlertScreenings = visAlertScreenings?.filter((as) => as.matchingRuleScoring && as.matchingRuleScoring.dataset && filters.alertDataset.includes(as.matchingRuleScoring.dataset.label));
        }
        if (filters.search) {
          isFiltered = true;
          visAlertScreenings = visAlertScreenings?.filter((as) => (as.dataId ?? '').toLowerCase().includes(filters.search.toLowerCase()));
        }
      }

      setFiltered(isFiltered);
      setVisibleAlertScreenings(visAlertScreenings);
    },
    [alertScreenings]
  );

  const onChangeFilter = useCallback(
    (newFilters: SelectedFiltersAlertScreeningsValues, debounce = false) => {
      setSearching((currentSearch) => {
        if (currentSearch) {
          clearTimeout(currentSearch);
          return null;
        }
        return currentSearch;
      });

      // setFilters(newFilters);
      const applySearch = async () => {
        await makeSearch(newFilters);
      };

      if (debounce) {
        const filterTimeout = setTimeout(async () => {
          await applySearch();
          setSearching(null);
        }, DEBOUNCETIMING);

        setSearching(filterTimeout);
      } else {
        applySearch();
      }
    },
    [makeSearch]
  );

  const isError = !loading && (!datasets || !alertScreenings);
  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      <FilterAlertScreenings ref={searchFormRef} {...{ disabled: false, onChangeFilter, alertScreenings }} />

      <ListAlertScreening {...{ searching, filtered, alertScreenings: visibleAlertScreenings, handleCreateAlertScreening, handleDeleteAlertScreening, handleImportAlertScreening }} />
    </Box>
  );
});

export default ManageScreeningsAlert;
