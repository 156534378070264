/**
 * SearchList.tsx
 */
/* packages */
import React, { forwardRef, useRef, useMemo, useEffect, createContext, useContext, memo, useCallback, SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import { VariableSizeList as VirtualList, ListChildComponentProps } from 'react-window';

/* components */
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Autocomplete, { AutocompleteRenderOptionState, autocompleteClasses } from '@mui/material/Autocomplete';
// import ListSubheader from '@mui/material/ListSubheader';

import SearchText from 'components/SearchElements/SearchText/SearchText';

import { ChevronIcon } from 'icons/chevron/chevron';
import { CrossIcon } from 'icons/cross/cross';
import { CheckFilledIcon } from 'icons/checkedFilled/checkFilled';

/* types */
interface SearchListProps {
  value: string | null;
  setValue(value: SearchListProps['value']): void;
  placeholder?: string;
  list: string[];
  disabled?: boolean;
  noOptionsText?: string;
}

type RenderOptionType = [React.HtmlHTMLAttributes<HTMLLIElement>, string, AutocompleteRenderOptionState['index']][];

// Adapter for react-window
const itemSize = 37;
const listMaxHeight = 350;
const virtualWidth = 250;

const OuterElementContext = createContext<React.HTMLAttributes<HTMLElement>>({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} className={`${outerProps.className} custom-scrollbar `} sx={{ borderRadius: '5px' }} />;
});

const RenderRow = (props: ListChildComponentProps) => {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const { data, index, style } = props;

  const { itemData, setRowHeight } = data;

  const dataSet = itemData[index];
  const text = dataSet[1];

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(text, rowRef.current.clientHeight);
    }
  }, [index, rowRef, setRowHeight, text]);

  // if (dataSet.hasOwnProperty('group')) {
  //   return (
  //     <div style={inlineStyle}>
  //       <ListSubheader ref={rowRef} key={dataSet.key} component="div">
  //         {dataSet.group}
  //       </ListSubheader>
  //     </div>
  //   );
  // }

  return (
    // <div style={style}>
    <div style={{ ...style, paddingInline: style.paddingInline ?? '8px' }}>
      <Typography ref={rowRef} component="li" {...dataSet[0]} sx={{ borderRadius: '5px', fontSize: 'var(--fs-14)', fontWeight: 500, color: 'var(--color-gray2)' }}>
        <CheckFilledIcon sx={{ fontSize: 'inherit', color: 'inherit', mr: 1 }} backgroundColor="transparent" />
        {dataSet[1]}
      </Typography>
    </div>
  );
};

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;

  const itemData: RenderOptionType = children as RenderOptionType;

  const rowHeights = useRef<{ [key: string]: number }>({});

  const setRowHeight = useCallback((key: string, size: number) => {
    if (listRef.current) listRef.current.resetAfterIndex(0);
    if (!(key in rowHeights)) {
      rowHeights.current[key] = size;
    }
  }, []);

  const itemCount = itemData.length;

  const getRowHeight = useCallback(
    (index: number) => {
      const key = itemData[index];
      if (!key) return itemSize;

      const itemKey = key[1];
      return rowHeights.current[itemKey] ?? itemSize;
    },
    [itemData]
  );

  const getHeight = useMemo(() => {
    const minNbItems = 8;
    const initElements = itemData.slice(0, minNbItems).map((el) => rowHeights.current[el[1]] ?? itemSize);
    const initSize = initElements.reduce((acc, cur) => acc + cur, 0);
    return Math.min(listMaxHeight, initSize);
  }, [itemData]);

  const listRef = useRef<VirtualList<{}> | null>(null);

  return (
    <Box ref={ref} className="rounded-border bordered-shadow" sx={{ py: 1 }}>
      <OuterElementContext.Provider value={other}>
        <VirtualList
          ref={listRef}
          itemData={{ itemData, setRowHeight }}
          itemSize={getRowHeight}
          height={getHeight}
          width={virtualWidth}
          outerElementType={OuterElementType}
          innerElementType="ul"
          overscanCount={10}
          itemCount={itemCount}
        >
          {RenderRow}
        </VirtualList>
      </OuterElementContext.Provider>
    </Box>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const SearchList = memo(
  forwardRef<HTMLInputElement, SearchListProps>((props, ref) => {
    const { value, setValue, placeholder, list, disabled, noOptionsText } = props;

    const onChange = useCallback(
      (_: SyntheticEvent, newValue: string | null) => {
        setValue?.(newValue);
      },
      [setValue]
    );

    return (
      <Autocomplete
        id="search-list"
        disabled={disabled}
        disableListWrap
        value={value}
        onChange={onChange}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={list}
        noOptionsText={noOptionsText ?? 'No options'}
        // groupBy={(option) => option[0].toUpperCase()}
        renderInput={(params) => {
          return (
            <SearchText
              {...params}
              fullWidth
              placeholder={placeholder}
              inputProps={{ ...params.inputProps, style: { ...(params.inputProps.style ?? {}), paddingBlock: 'var(--fs-10)', fontSize: 'var(--fs-14)', fontWeight: 500 } }}
              sx={{ pl: 1 }}
              adormentSize={14}
            />
          );
        }}
        popupIcon={<ChevronIcon sx={{ fontSize: 10, rotate: '-90deg' }} />}
        clearIcon={<CrossIcon sx={{ fontSize: 10 }} />}
        renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
        // TODO: Post React 18 update - validate this conversion, look like a hidden bug
        // renderGroup={(params) => params as unknown as React.ReactNode}
        componentsProps={{
          paper: {
            elevation: 0,
            sx: {
              width: virtualWidth,
              mt: 1,
              borderRadius: 0,
              '& .MuiAutocomplete-listbox': {
                paddingBlock: 0,
              },
              '& svg': {
                visibility: 'hidden',
              },
              '& .MuiAutocomplete-option': {
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'var(--color-hover-background)',
                },
              },
              '& .MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: 'transparent!important',
                pointerEvents: 'none',
                '& svg': {
                  visibility: 'visible',
                },
              },
              '& .MuiAutocomplete-noOptions': {
                borderRadius: '5px',
                boxShadow: 'inset 0px 0px 0px 1px var(--color-grayHeaderBorder)',
                py: 1,
                px: 2,
              },
            },
          },
        }}
        sx={{
          '& .MuiFormControl-root': {
            paddingLeft: 0,
            // '& .MuiInputBase-root.MuiOutlinedInput-root': {
            //   paddingBlock: 0,
            // },
          },
          '&.MuiAutocomplete-hasPopupIcon': {
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              paddingRight: '24px',
            },
            '& .MuiAutocomplete-popupIndicator': {
              mr: 0.5,
              p: 0.5,
              borderRadius: '5px',
              '&:hover': { backgroundColor: 'var(--color-hover-button)' },
            },
          },
          '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              paddingRight: '50px',
            },
            '& .MuiAutocomplete-clearIndicator': {
              mr: 0.5,
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#e5e2e2',
              },
            },
          },
        }}
      />
    );
  })
);
export default SearchList;
