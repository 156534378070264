/**
 * RunSchedule.tsx
 * run schedule modal
 */
/* packages */
import { useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { JobType } from 'models/job';

/* types */
interface RunScheduleProps {
  schedule: JobType;
  startOrStopSchedule(scheduleId: number, run: 'start' | 'stop'): Promise<StatusPromiseResponse>;
  run: 'start' | 'stop';
}

const RunSchedule = memo(({ schedule, startOrStopSchedule, run }: RunScheduleProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [startingSchedule, setStartingSchedule] = useState(false);

  const runSchedule = useCallback(async () => {
    blockModal?.(true);
    setStartingSchedule(true);

    const result = await startOrStopSchedule(schedule.id, run);
    setStartingSchedule(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const runSuccessMessage = intl.formatMessage({
        id: `${run}ScheduleSuccess`,
      });

      addSnackbar(runSuccessMessage, 'success');
      closeModal?.();
    } else {
      const runErrorMessage = intl.formatMessage({
        id: `${run}ScheduleError`,
      });
      addSnackbar(runErrorMessage, 'error');
    }
  }, [blockModal, closeModal, schedule, startOrStopSchedule, run, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Schedule: {schedule.name ?? schedule.id}</Typography>

        <Typography className="modal-label">
          {run === 'start' && <FormattedMessage id="startScheduleMessage" defaultMessage={'Are you sure that you want to start this schedule?'} />}
          {run === 'stop' && <FormattedMessage id="stopScheduleMessage" defaultMessage={'Are you sure that you want to stop this schedule?'} />}
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {startingSchedule ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={startingSchedule} onClick={runSchedule} disableElevation sx={{ textTransform: 'none' }}>
              {run === 'start' && <FormattedMessage id="start" defaultMessage="Start" />}
              {run === 'stop' && <FormattedMessage id="stop" defaultMessage="Stop" />}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default RunSchedule;
