/**
 * SearchText.tsx
 */
/* packages */
import { ReactNode, forwardRef } from 'react';

/* components */
import Select, { BaseSelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ChevronIcon } from 'icons/chevron/chevron';
import { CheckFilledIcon } from 'icons/checkedFilled/checkFilled';
/* elements */
const SearchSelect = forwardRef<HTMLSelectElement, BaseSelectProps & { choices: { key: string; value: ReactNode }[]; placeholder?: string; hidePlaceholderChoice?: boolean }>((props, ref) => {
  const { choices, placeholder, hidePlaceholderChoice, ...otherProps } = props;

  return (
    <Select
      {...otherProps}
      size="small"
      sx={{
        lineHeight: 1.5,
        backgroundColor: 'white',
        color: !otherProps.value ? '#7B7E84' : 'inherit',
        // fontSize: otherProps?.value === '' ? 'var(--fs-12)' : 'inherit',
        // fontSize: 'inherit',
        fontSize: 'var(--fs-14)',
        fontWeight: 500,
        ...otherProps.sx,
        '& .MuiSelect-select.MuiSelect-outlined': {
          display: 'flex',
          paddingBlock: '10px',
          // color: '#151414',
          color: otherProps?.value === '' ? 'var(--color-gray1)' : 'var(--color-gray2)',

          '& svg': {
            display: 'none',
          },
        },
      }}
      displayEmpty={true}
      IconComponent={(_props) => {
        const rotate = _props.className.toString().includes('iconOpen');
        return <ChevronIcon sx={{ fontSize: '10px', rotate: rotate ? '90deg' : '-90deg', position: 'absolute', right: '7px', top: 'calc(50% - 5px)', pointerEvents: 'none' }} />;
      }}
      MenuProps={{
        sx: {
          '& .MuiMenu-list ': {
            p: '5px',
            borderRadius: '5px',
            border: '1px solid var(--color-grayHeaderBorder)',
            '& svg': {
              visibility: 'hidden',
            },
            '& .Mui-selected': {
              // backgroundColor: 'var(--color-lightgray6)',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
              '& svg': {
                visibility: 'visible',
              },
            },
          },
        },
      }}
    >
      {placeholder && !hidePlaceholderChoice && (
        <MenuItem value={''} disabled sx={{ fontSize: 'var(--fs-14)' }}>
          {placeholder}
        </MenuItem>
      )}
      {choices.map((c) => (
        <MenuItem
          key={c.key}
          value={c.key}
          sx={{ borderRadius: '5px', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)', fontWeight: 500, '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
        >
          <CheckFilledIcon sx={{ fontSize: 'inherit', color: 'inherit', mr: 1 }} backgroundColor="transparent" />
          {c.value}
        </MenuItem>
      ))}
    </Select>
  );
});

export default SearchSelect;
