/**
 * DisplayDate.tsx
 * Display a date in two rows
 */
/* packages */
import { memo } from 'react';
import dayjs from 'dayjs';
/* contexts */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/* utilities */

/* types */
import { DateString, DateStringFormat } from 'models/utils';

/* elements */
const DisplayDate = memo(({ date, timezone, fontSizeSmall, format }: { date: DateString | string; timezone?: string; fontSizeSmall?: string; format?: string }) => {
  if (!date) return null;

  const parsedDate = dayjs(date, format ?? DateStringFormat);

  if (!parsedDate.isValid()) {
    return (
      <Box>
        <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit', whiteSpace: 'nowrap' }}>
          {date}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit', whiteSpace: 'nowrap' }}>
        {parsedDate.format('MMM DD, YYYY')}
      </Typography>
      <Typography fontSize="inherit" fontWeight="inherit" sx={{ fontSize: fontSizeSmall ?? 'var(--fs-12)', color: 'var(--color-gray-new);', whiteSpace: 'nowrap' }}>
        {parsedDate.format('HH:mm')} {timezone ?? 'UTC'}
      </Typography>
    </Box>
  );
});

export default DisplayDate;
