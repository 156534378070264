/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useImperativeHandle, forwardRef, useRef, memo, useState, useCallback, ReactElement, cloneElement, useEffect, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';
/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popover, { PopoverActions } from '@mui/material/Popover';
import Input from '@mui/material/Input';
// import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import { CrossIcon } from 'icons/cross/cross';
import { AddIcon } from 'icons/add/add';
import { GarbageIcon } from 'icons/garbage/garbage';
import { ChevronIcon } from 'icons/chevron/chevron';

import { SearchFilterType } from 'models/savedSearch';

/* utilities */

/* types */
export interface SearchFiltersRefType {
  clearFilters?(): void;
}

interface BaseButtonProps {
  text: String | React.ReactNode;
  inputName: string;
  trueFalseButton?: boolean;
  filterContent?: ReactElement;
  hideNbValues?: boolean;
  initialValue?: FilterValueType;
  fullWidth?: boolean;
}
interface FilterButtonProps extends BaseButtonProps {
  activateFilters(inputName: string, nbActiveFilters: number): void;
  // text: String | React.ReactNode;
  // inputName: string;
  // trueFalseButton?: boolean;
  // filterContent?: ReactElement;
  disabled?: boolean;
  doneOnClose?: SearchFiltersProps['doneOnClose'];
}
export interface FilterButtonRefType {
  clearFilter(): void;
  setFilter(value: FilterValueType): void;
  getValue(): FilterValueType;
}

export interface SearchFiltersButtonType extends BaseButtonProps {
  ref: React.MutableRefObject<FilterButtonRefType | null>;
  // text: String | React.ReactNode;
  // inputName: string;
  // trueFalseButton?: boolean;
  // filterContent?: ReactElement;
  placeholder?: string;
  algoFields?: string[];
  algoValue?: string[];
  checkboxesAllButton?: boolean;
}

interface SearchFiltersProps {
  searchFiltersButtons: SearchFiltersButtonType[];
  disabled?: boolean;
  onActivate?(): void;
  canSaveFilters?: boolean;
  savedFilters?: SearchFilterType[];
  loadSavedFilter?(filter: SearchFilterType): void;
  deleteSavedFilter?(filterId: number): void;
  saveCurrentFilter?(): void;
  hasExternalFilterValue?: boolean;
  hideClearButton?: boolean;
  doneOnClose?: boolean;
}

export interface FilterValueType {
  nbValues: number;
  values?: string[];
  value?: string;
  booleanValue?: boolean;
  dayValue?: Dayjs | null;
  dayEndValue?: Dayjs | null;
  algo?: { [key: string]: string };
  minValue?: number;
  maxValue?: number;
}

type ActiveFiltersType = {
  [key: string]: number;
};

interface DisplaySavedFiltersProps {
  disabled?: boolean;
  hasActiveFilters?: boolean;
  savedFilters?: SearchFilterType[];
  loadSavedFilter?(filter: SearchFilterType): void;
  deleteSavedFilter?(filterId: number): void;
  saveCurrentFilter?(): void;
}

/* elements */
const initFilters = (searchFiltersButtons: SearchFiltersButtonType[]) => {
  return searchFiltersButtons.reduce((acc, sfb) => {
    acc[sfb.inputName] = 0;
    return acc;
  }, {} as ActiveFiltersType);
};

const SearchFilters = forwardRef<SearchFiltersRefType, SearchFiltersProps>(
  (
    { disabled, searchFiltersButtons, onActivate, canSaveFilters, savedFilters, loadSavedFilter, deleteSavedFilter, saveCurrentFilter, hasExternalFilterValue, hideClearButton, doneOnClose },
    searchRef
  ) => {
    const [activeFilters, setActiveFilters] = useState<ActiveFiltersType>(initFilters(searchFiltersButtons));

    const clearFilters = useCallback(() => {
      searchFiltersButtons.forEach((sfb) => sfb.ref?.current?.clearFilter());
      setActiveFilters(initFilters(searchFiltersButtons));
    }, [searchFiltersButtons]);

    useImperativeHandle(
      searchRef,
      () => ({
        clearFilters,
      }),
      [clearFilters]
    );

    const activateFilters = useCallback(
      (inputName: string, nbActiveFilters: number) => {
        setActiveFilters((currentActiveFilters) => ({ ...currentActiveFilters, [inputName]: nbActiveFilters }));
        if (onActivate) onActivate();
      },
      [onActivate]
    );

    const hasActiveFilters: boolean = Object.values(activeFilters).reduce((acc, nb) => acc + nb, 0) > 0;

    return (
      <Box ref={searchRef} display={'flex'} alignItems={'center'} gap=".5rem" sx={{ flex: 1, flexFlow: 'row' }}>
        {/* <Typography fontSize={'.875rem'} fontWeight={600} whiteSpace={'nowrap'}>
          Filter by
        </Typography> */}

        {searchFiltersButtons.map((sfb, sfb_i) => (
          <FilterButton disabled={disabled} key={sfb_i} {...{ ...sfb, activateFilters, doneOnClose: doneOnClose ?? true }} />
        ))}

        {hasActiveFilters && !hideClearButton && (
          <Button
            variant="text"
            disableElevation
            color="darkgray"
            sx={{
              '.MuiButton-startIcon svg': {
                fontSize: '12px',
              },
              color: 'var(--color-azure)',
              fontSize: '.8rem',
              lineHeight: 1.5,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            startIcon={<CrossIcon />}
            onClick={clearFilters}
            disabled={disabled}
          >
            <FormattedMessage id="clear" defaultMessage="Clear" />
          </Button>
        )}
        {searchFiltersButtons.length > 0 && canSaveFilters && (
          <Box className="button-box-shadow" display="flex" sx={{ borderRadius: '5px' }}>
            <DisplaySavedFilters
              disabled={disabled}
              hasActiveFilters={hasActiveFilters || hasExternalFilterValue}
              savedFilters={savedFilters}
              loadSavedFilter={loadSavedFilter}
              deleteSavedFilter={deleteSavedFilter}
              saveCurrentFilter={saveCurrentFilter}
            />
          </Box>
        )}
      </Box>
    );
  }
);

const FilterButton = memo(
  forwardRef<FilterButtonRefType, FilterButtonProps>(
    ({ activateFilters, text, inputName, filterContent, disabled, hideNbValues, trueFalseButton, initialValue, fullWidth, doneOnClose, ...otherProps }, ref) => {
      const popoverRef = useRef<PopoverActions>(null);
      const [filterOpen, setFilterOpen] = useState<boolean>(false);
      const [filterValue, setFilterValue] = useState<FilterValueType>(initialValue ?? { nbValues: 0 });

      const [triggerSetUpdate, setTriggerSetUpdate] = useState<boolean>(false);

      const buttonRef = useRef<HTMLButtonElement>(null);

      useImperativeHandle(
        ref,
        () => {
          return {
            clearFilter: () => {
              setFilterValue(initialValue ?? { nbValues: 0 });
            },
            setFilter: (value: FilterValueType) => {
              setFilterValue(value);
              // activateFilters(inputName, value.nbValues);
            },
            getValue: () => {
              return filterValue;
            },
          };
        },
        // [activateFilters, inputName, filterValue]
        [filterValue, initialValue]
      );

      const onButtonClick = () => {
        if (trueFalseButton) {
          if (filterValue.nbValues > 0) {
            setNewFilterValue({
              nbValues: 0,
              booleanValue: false,
            });
          } else {
            setNewFilterValue({
              nbValues: 1,
              booleanValue: true,
            });
          }
          return;
        }
        setTriggerSetUpdate(false);
        setFilterOpen(true);
      };

      const handleClose = useCallback(() => {
        if (doneOnClose) setTriggerSetUpdate(true);
        setFilterOpen(false);
      }, [doneOnClose]);

      const setNewFilterValue = useCallback((newValue: FilterValueType) => {
        setFilterValue(newValue);
        // activateFilters(inputName, newValue.nbValues);

        setFilterOpen(false);
      }, []);

      /* activate filter on state update */
      useEffect(() => {
        activateFilters(inputName, filterValue.nbValues);
      }, [activateFilters, filterValue, inputName]);

      let addSx: CSSProperties = {
        whiteSpace: 'nowrap',
        backgroundColor: filterOpen ? '#E5E2E2' : 'unset',
        letterSpacing: '0.01em',
      };
      if (filterValue.nbValues > 0) {
        addSx['color'] = 'var(--color-azure)';
      }
      if (fullWidth) {
        addSx['width'] = '100%';
        addSx['justifyContent'] = 'space-between';
        addSx['border'] = '1px solid var(--color-grayHeaderBorder)';

        if (filterValue.nbValues <= 0) {
          addSx.color = 'var(--color-gray1)';
        }
      }

      return (
        <>
          <Button
            ref={buttonRef}
            onClick={onButtonClick}
            disabled={disabled}
            // color={filterValue.nbValues > 0 ? 'primary' : 'darkgray'}
            color={'darkgray'}
            sx={addSx}
          >
            {text}
            {filterValue.nbValues > 0 && !hideNbValues && (
              <>
                {' '}
                ({filterValue.nbValues}){/* <Divider orientation="vertical" flexItem sx={{ mx: 1 }} /> {filterValue.nbValues} */}
              </>
            )}
            {filterContent && <ChevronIcon sx={{ ml: 0.5, fontSize: 'var(--fs-10)', rotate: '-90deg' }} />}
          </Button>

          <Input name={inputName} value={JSON.stringify(filterValue)} sx={{ opacity: 0, display: 'none' }} />

          {filterContent && (
            <Popover
              disableRestoreFocus
              id={'popover-button'}
              action={popoverRef}
              open={filterOpen}
              anchorEl={buttonRef.current}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              elevation={0}
            >
              {cloneElement(filterContent, {
                currentValue: filterValue,
                setNewFilterValue,
                triggerSetUpdate,
                popoverRef,
                ...otherProps,
              })}
            </Popover>
          )}
        </>
      );
    }
  )
);

const DisplaySavedFilters = memo(({ disabled, hasActiveFilters, savedFilters, loadSavedFilter, deleteSavedFilter, saveCurrentFilter }: DisplaySavedFiltersProps) => {
  const popoverRef = useRef<PopoverActions>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  return (
    <>
      <ShadowedButton
        onClick={saveCurrentFilter}
        disabled={disabled || !hasActiveFilters}
        sx={{ whiteSpace: 'nowrap', boxShadow: 'none', ...(savedFilters?.length ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}) }}
      >
        <AddIcon fontSize="inherit" sx={{ mr: 1 }} />
        <FormattedMessage id="saveFilters" defaultMessage="Save filters" />
      </ShadowedButton>
      {savedFilters && savedFilters?.length > 0 && (
        <>
          <ShadowedButton
            ref={buttonRef}
            disabled={disabled}
            onClick={onButtonClick}
            sx={{
              whiteSpace: 'nowrap',
              paddingInline: '10px',
              minWidth: 0,
              color: 'var(--color-azure)',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftColor: 'transparent!important',
              boxShadow: 'none',
            }}
          >
            {savedFilters?.length}
          </ShadowedButton>
          <Popover
            id={'popover-saved-search-button'}
            action={popoverRef}
            open={filterOpen}
            anchorEl={buttonRef.current}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            elevation={0}
          >
            <Box borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
              <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
                <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
                  <FormattedMessage id="savedFilters" defaultMessage={'Saved filters'} />
                </Typography>

                <Button disableElevation size="small" onClick={handleClose} sx={{ minWidth: 0 }}>
                  <CrossIcon fontSize={'inherit'} sx={{ color: 'var(--color-lightgray4)' }} />
                </Button>
              </Box>

              {savedFilters?.length && (
                <Box className="custom-scrollbar" maxHeight={300} sx={{ overflowY: 'auto' }}>
                  <List sx={{ width: '100%', maxWidth: 250, bgcolor: 'background.paper' }}>
                    {savedFilters.map((sf) => {
                      return (
                        <ListItem
                          key={sf.id}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              sx={{ fontSize: 16 }}
                              onClick={() => {
                                deleteSavedFilter?.(sf.id);
                              }}
                            >
                              <GarbageIcon fontSize="inherit" sx={{ color: 'var(--color-lightgray4)' }} />
                            </IconButton>
                          }
                          disablePadding
                          sx={{
                            '& .MuiListItemSecondaryAction-root': {
                              visibility: 'hidden',
                            },
                            '&:hover .MuiListItemSecondaryAction-root': {
                              visibility: 'visible',
                            },
                          }}
                        >
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              loadSavedFilter?.(sf);
                              handleClose();
                            }}
                            dense
                          >
                            <ListItemText primary={sf.name ?? sf.description ?? sf.id} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              )}
            </Box>
          </Popover>
        </>
      )}
    </>
  );
});

export default SearchFilters;
