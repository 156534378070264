/**
 * TimelineContent.tsx
 * Display the timeline tab of view profile page
 */
/* packages */
import { memo, useCallback, useContext, useState, Fragment, useMemo, useRef } from 'react';
import { Dayjs } from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
import { PersonContext } from './PersonProvider';
import { CountriesContext } from 'contextProviders/CountriesProvider';

/* components */
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

// import DateFilter from 'components/SearchFilters/DateFilter';
import { CalendarIcon } from 'icons/calendar/calendar';
import StatusTag from 'components/StatusTag/StatusTag';
import { Flag } from 'components/InvestigateSearch/utils';

import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType } from 'components/SearchFilters/SearchFilters';
import DateRangeFilter from 'components/SearchFilters/DateRangeFilter';

/* utilities */
import { internalPageMaxWidth } from './PersonProfile';
import { getCountryFromIso } from 'components/InvestigateSearch/IndividualContent';

/* types */
interface TimelineContentProps {}
// interface DateSelectorProps {
//   title?: string;
//   value: Dayjs | null;
//   minDate: Dayjs;
//   maxDate: Dayjs;
//   setDate(date: Dayjs | null): void;
// }
/* elements */
const SearchFiltersMemo = memo(SearchFilters);

const TimelineContent = memo((props: TimelineContentProps) => {
  const { details, hasTimeline, timelineElements } = useContext(PersonContext);
  const { countries } = useContext(CountriesContext);
  const intl = useIntl();

  const [startingDate, setStartingDate] = useState<Dayjs | null>(timelineElements ? timelineElements[timelineElements.length - 1].date : null);
  const [endingDate, setEndingDate] = useState<Dayjs | null>(timelineElements ? timelineElements[0].date : null);

  // const updateStartingDate = useCallback((date: Dayjs) => {
  //   setStartingDate(date);
  // }, []);
  // const updateEndingDate = useCallback((date: Dayjs) => {
  //   setEndingDate(date);
  // }, []);

  const dateRef = useRef<FilterButtonRefType | null>(null);

  const setDates = useCallback(() => {
    if (!dateRef.current) return;
    const filterValues = dateRef.current?.getValue();

    if (filterValues.dayValue) setStartingDate(filterValues.dayValue);
    if (filterValues.dayEndValue) setEndingDate(filterValues.dayEndValue);
  }, []);

  const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
    if (!timelineElements) return [];

    const initialValue = {
      dayValue: timelineElements[timelineElements.length - 1].date,
      dayEndValue: timelineElements[0].date,
      nbValues: 1,
    };

    const buttons: SearchFiltersButtonType[] = [
      {
        ref: dateRef,
        text: <FormattedMessage id="selectPeriod" defaultMessage="Select period" />,
        inputName: 'period',
        filterContent: (
          <DateRangeFilter
            title={intl.formatMessage({ id: 'selectPeriod', defaultMessage: 'Select period' })}
            minDate={initialValue.dayValue}
            maxDate={initialValue.dayEndValue}
            onClearValue={[initialValue.dayValue, initialValue.dayEndValue]}
          />
        ),
        hideNbValues: true,
        initialValue: initialValue,
      },
    ];

    return buttons;
  }, [intl, timelineElements]);

  const getStatusColor = useCallback((status: string) => {
    const lowerStatus = status.toLowerCase();
    if (['active', 'current', 'present'].includes(lowerStatus)) return 'var(--color-azure)';

    if (['inactive', 'deactivated', 'former', 'previous', 'old', 'past', 'delisted'].includes(lowerStatus)) return 'var(--color-fushia)';

    return 'var(--color-lightgray5)';
  }, []);

  if (!details || !hasTimeline || !timelineElements || timelineElements.length <= 0) return <></>;

  const displayedElements = timelineElements.filter((elem) => elem.date.isAfter(startingDate?.subtract(1, 'hour')) && elem.date.isBefore(endingDate?.add(1, 'hour')));
  const nbElement = displayedElements.length;

  return (
    <Box px={5} py={2} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', height: '100%' }}>
      <Box display="grid" gridTemplateColumns={'1fr 300px'} columnGap={'1.5rem'} sx={{ height: '100%' }}>
        <Box
          display="grid"
          gridTemplateColumns={'1fr auto'}
          gridAutoRows={'min-content'}
          columnGap={'.5rem'}
          className="custom-scrollbar timeline"
          sx={{ height: '100%', overflow: 'auto', direction: 'rtl' }}
        >
          {displayedElements.map((elem, elem_index) => {
            let color = 'var(--color-lightgray5)';
            if (elem.type === 'started') color = 'var(--color-azure)';
            else if (elem.type === 'ended') color = 'var(--color-fushia)';

            let lineClass = '';
            let bottomPadding = 0;
            const pb = 4;
            if (elem_index < nbElement - 1) {
              bottomPadding = pb;
              lineClass = 'timeline-line';

              // apply dashed line if difference is large
              const diff_next = elem.date.diff(displayedElements[elem_index + 1].date, 'year');
              if (diff_next > 1) {
                lineClass += ' dashed';
              }
            }
            // else {
            //   if (timelineElements.length > miniTimelineLimit) {
            //     bottomPadding = pb;
            //     lineClass = 'timeline-line dashed';
            //   }
            // }

            let countryName = null;
            const code = elem.country ?? null;
            if (code) {
              countryName = getCountryFromIso(elem.country, countries);
            }

            const content = (
              <Box pb={bottomPadding} pt={0} sx={{ minWidth: 0 }}>
                <Box className="date" mb={0.5}>
                  {elem.type === 'started' && <FormattedMessage id="started" defaultMessage={'Started'} />}
                  {elem.type === 'ended' && <FormattedMessage id="ended" defaultMessage={'Ended'} />}
                  {elem.type === 'single' && <FormattedMessage id="occured" defaultMessage={'Occured'} />} on {elem.date.format(elem.dateFormat)}
                </Box>
                <Box className="text">
                  <Box display="flex" alignItems={'center'} columnGap={'.5rem'}>
                    {elem.categories && elem.categories.map((cat) => <StatusTag key={cat} tag_label={cat} hideActive={true} type={'category'} />)}
                    {elem.subcategories && elem.subcategories.map((cat) => <StatusTag key={cat} tag_label={cat} hideActive={true} type={'label'} />)}
                  </Box>
                  {elem.status && (
                    <Box>
                      Status: <StatusTag tag_label={`${elem.status[0].toUpperCase()}${elem.status.toLowerCase().slice(1)}`} type={'label'} active activeColor={getStatusColor(elem.status)} />
                    </Box>
                  )}
                  {countryName && code && (
                    <Box flex={1} display="flex" alignItems={'center'}>
                      <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                        <Flag country={code} />
                      </Box>
                      <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                        {countryName}
                      </Box>
                    </Box>
                  )}
                  {elem.texts.map((text, textIndex) => {
                    return (
                      <Box key={textIndex}>
                        {text.label && (
                          <Typography component="span" sx={{ fontWeight: 500 }}>
                            {text.label}:{' '}
                          </Typography>
                        )}
                        {text.value && <Typography component="span">{text.value}</Typography>}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );

            return (
              <Fragment key={elem_index}>
                {/* {elem_index % 2 === 0 ? <Box></Box> : content} */}
                {content}

                <Box px={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Box p={'5px'} sx={{ background: 'var(--color-lightgray2)', color: color, borderRadius: '4px' }}>
                    <CalendarIcon sx={{ display: 'block', fontSize: 'var(--fs-14)' }} />
                  </Box>
                  <Box flex={1} className={lineClass}></Box>
                </Box>

                {/* {elem_index % 2 === 1 ? <Box></Box> : content} */}
              </Fragment>
            );
          })}

          {displayedElements.length === 0 && (
            <>
              <Box
                gridColumn={'1/-1'}
                display="flex"
                justifyContent="center"
                alignItems={'center'}
                sx={{ fontWeight: 500, textAlign: 'center', fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}
              >
                <FormattedMessage id="NothingToDisplay" defaultMessage={'Nothing to display'} />
                <br />
                <FormattedMessage id="editDateParametersTimeline" defaultMessage={'Edit your date selection to show timeline elements '} />
              </Box>
            </>
          )}
        </Box>

        <Box display="flex" flexDirection={'column'} alignItems={'center'} sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontSize: 'var(--fs-14)' }}>
            <FormattedMessage id="from" defaultMessage={'From'} /> {startingDate?.format('MMM DD, YYYY')} <FormattedMessage id="to" defaultMessage={'to'} /> {endingDate?.format('MMM DD, YYYY')}
          </Typography>
          <Box>
            <SearchFiltersMemo {...{ searchFiltersButtons, onActivate: setDates, hideClearButton: true }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default TimelineContent;
