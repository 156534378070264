import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PaperClipSideIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
        <path
          d="M1.351,14.567a4.9,4.9,0,0,1,.043-6.8L7.95,1.062a3.5,3.5,0,0,1,5.021,0,3.638,3.638,0,0,1,0,5.071L7.257,11.973a2.332,2.332,0,0,1-3.374-.031A2.422,2.422,0,0,1,3.928,8.6L8.42,4.011A.5.5,0,0,1,9.127,4l.714.7a.5.5,0,0,1,.008.707L5.357,10a.423.423,0,0,0-.02.572.333.333,0,0,0,.49.005l5.714-5.839a1.637,1.637,0,0,0,0-2.275,1.5,1.5,0,0,0-2.161,0l-6.555,6.7a2.894,2.894,0,0,0-.037,4.01,2.665,2.665,0,0,0,3.846.009l5.377-5.5a.5.5,0,0,1,.707-.008l.715.7a.5.5,0,0,1,.008.707l-5.377,5.5a4.663,4.663,0,0,1-6.712-.015Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
