import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EnvelopeDotIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M496 160A80 80 0 1 0 496 0a80 80 0 1 0 0 160zm16 224V190.9c-5.2 .7-10.6 1.1-16 1.1c-22.5 0-43.5-6.6-61-18.1L291.5 291.7c-20.7 17-50.4 17-71.1 0L48 150.1V128c0-8.8 7.2-16 16-16H388.6c-3-10.1-4.6-20.9-4.6-32c0-5.4 .4-10.8 1.1-16H64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64zM48 212.2L190 328.8c38.4 31.5 93.7 31.5 132 0L464 212.2V384c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V212.2z"
        />
      </svg>
    </SvgIcon>
  );
};
