/**
 * AppMenu.tsx
 * Display the application menu
 */
/* packages */
import { useContext, useState, memo, useEffect, ReactNode, useCallback } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink as RouterLink, useLocation, Location } from 'react-router-dom';

/* context */
import { UserContext } from 'contextProviders/UserProvider';
import { UnreadAlertsContext } from 'contextProviders/UnreadAlertsProvider';
import { useAuthenticationFunction } from 'contextProviders/AuthProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { useNotifications } from 'components/NotificationsPanel/NotificationsPanel';
import { useAddModal } from 'contextProviders/ModalProvider';

/* components */
import DisplayBrandLogo from 'utilities/DisplayBrandLogo';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import EditProfile from 'components/EditProfile/EditProfile';

import { ChevronIcon } from 'icons/chevron/chevron';
import { AvatarIcon } from 'icons/avatar/avatar';
import { PenIcon } from 'icons/pen/pen';
import { HelpIcon } from 'icons/help/help';
import { SignOutIcon } from 'icons/signOut/signOut';
import { EyeIcon } from 'icons/eye/eye';
import { GearIcon } from 'icons/gear/gear';
import { BellIcon } from 'icons/bell/bell';
import { TriangleIcon } from 'icons/triangle/triangle';

import { GetAvatar } from 'components/GetUser/GetUser';

/* utilities */
// import { prependBase64Photo } from 'models/user';
import { routerPages } from 'AppRouter';
import { checkPermissions, AllowedPageCheck } from 'utilities/CheckUserPermissions';
import { NotificationsContext } from 'contextProviders/NotificationsProvider';

/* types */
interface SinglePage {
  name: AllowedPageCheck;
  text: string | ReactNode;
  nbUnread?: number;
}
interface PageElement {
  group?: boolean;
  name: AllowedPageCheck;
  text: ReactNode | string;
  id?: string;
  elements?: SinglePage[];
  nbUnread?: number;
}

/* elements */
const openedDrawerWidth = 210;
const closedDrawerWidth = 80;
const transitionDuration = 225;

// styled components
const openedMixin = (theme: Theme): CSSObject => ({
  width: openedDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.enteringScreen,
    duration: transitionDuration,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: closedDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    // duration: theme.transitions.duration.leavingScreen,
    duration: transitionDuration,
  }),
  overflowX: 'hidden',

  //   width: `calc(${theme.spacing(7)} + 1px)`,
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(${theme.spacing(8)} + 1px)`,
  //   },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: openedDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',

  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    ...(open ? openedMixin(theme) : closedMixin(theme)),
    // backgroundColor: 'transparent',
    border: 'none',
    backgroundColor: '#151414',
  },
  ...(open && {
    ...openedMixin(theme),
    //   '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    //   '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MenuDivider = styled(Divider)(() => ({
  backgroundColor: '#666666',
}));

const MenuLink = styled(RouterLink)(() => ({
  color: 'white',
  textDecoration: 'none',
}));

const BottomMenuButton = styled(Button)(() => ({
  color: '#E5E5E5',
  textTransform: 'none',
  justifyContent: 'flex-start',
  fontSize: '.75rem',
  paddingBlock: '2px',
  paddingInline: 0,
  height: 'calc(1.125rem + 2 * 2px)',
  minWidth: 0,
  ':hover': {
    backgroundColor: 'transparent',
  },
  '.MuiSvgIcon-root': {
    fontSize: '1rem',
    color: '#CFD1D2',
  },
}));

const findMainMenu = (location: Location): string => {
  let mainMenu = 'null';

  if (location.pathname.startsWith(routerPages.investigate)) mainMenu = 'investigate';
  else if (location.pathname.startsWith(routerPages.manage)) mainMenu = 'manage';

  return mainMenu;
};

const findSubMenu = (location: Location): string => {
  let subMenu = 'sub-null';

  const setupPages = [routerPages.manageSetupOrganizations, routerPages.manageSetupTeams, routerPages.manageSetupRoles];
  const screeningsPages = [routerPages.manageScreeningsWorkflow, routerPages.manageScreeningsAlert, routerPages.manageScreeningsRisk, routerPages.manageScreeningsSchedule];

  if (setupPages.includes(location.pathname)) subMenu = 'setup';
  if (screeningsPages.includes(location.pathname)) subMenu = 'screenings';

  return subMenu;
};

const openCorrectMenu = (location: Location) => {
  // open the correct menu
  const mainMenu = findMainMenu(location);
  const inputElement = document.querySelector(`#${mainMenu}`) as HTMLInputElement;
  if (inputElement) inputElement.checked = true;

  // open the correct sub menu
  const subMenu = findSubMenu(location);
  const subInputElement = document.querySelector(`#sub-${subMenu}`) as HTMLInputElement;
  if (subInputElement) subInputElement.checked = true;

  // set the correct sub menu active
  document.querySelectorAll('label.sub-toggle-label').forEach((l) => l.classList.remove('active'));
  if (subInputElement) {
    subInputElement.parentElement?.querySelectorAll('label').forEach((l) => l.classList.add('active'));
  }

  // set the parent of the active link (menu-header)
  const menuHeaders = document.querySelector('.menu-item-container')?.querySelectorAll('.menu-header');
  if (menuHeaders) {
    menuHeaders.forEach((l) => l?.querySelector('.header-link')?.classList.remove('active-parent'));
    menuHeaders.forEach((m) => {
      const collapser = m.nextSibling as HTMLElement;

      const activeLinks = collapser?.querySelector('a.active');
      if (activeLinks) m.querySelector('.header-link')?.classList.add('active-parent');
    });
  }
};

// application menu
const AppMenu = () => {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // open the menu
    setOpen(true);
    openCorrectMenu(location);
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerClasses = open ? 'menu-opened' : 'menu-closed';
  return (
    <Drawer variant="permanent" open={open} className={`app-menu ${drawerClasses}`}>
      <Box display="flex" alignItems="center" px={1} py={2} height={'var(--header-height)'} minHeight={'var(--min-header-height)'}>
        <Box flex={1} display="flex" justifyContent={open ? 'center' : 'flex-start'} height="100%" px={open ? 2 : 1}>
          <RouterLink to={routerPages.investigate} style={{ display: 'contents' }}>
            <DisplayBrandLogo key={`${open}`} full={open} className={`logo-menu ${open ? '' : 'logo-menu-closed'}`} />
          </RouterLink>
        </Box>
        <IconButton className={'button-trigger'} sx={{ padding: '0', color: 'white', fontSize: '1rem' }} onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {<ChevronIcon fontSize="inherit" />}
        </IconButton>
      </Box>

      <MenuDivider />

      <Box flex={1} py={3} sx={{ overflowY: 'scroll' }} className="menu-item-container">
        <NavigationMenu />
      </Box>

      <MenuDivider />

      <BottomMenu />
    </Drawer>
  );
};

// navigation menu
const NavigationMenu = memo(() => {
  const showNotifications = useNotifications();
  const { newNotifications } = useContext(NotificationsContext);
  const { permissions } = useContext(UserContext);
  const { nbUnread } = useContext(UnreadAlertsContext);
  const intl = useIntl();

  const investigateElements: PageElement[] = [
    { name: 'investigateSearch', text: <FormattedMessage id="investigateSearchMenu" defaultMessage="Search" /> },
    { name: 'investigateExplore', text: <FormattedMessage id="investigateExploreMenu" defaultMessage="Explore" /> },
    { name: 'investigateAlerts', text: <FormattedMessage id="investigateAlertsMenu" defaultMessage="Alerts" />, nbUnread },
    { name: 'investigateRisks', text: <FormattedMessage id="investigateRisksMenu" defaultMessage="Risks" /> },
  ];

  const manageElements: PageElement[] = [
    {
      group: true,
      text: intl.formatMessage({
        id: 'manageSetupMenu',
        defaultMessage: 'Setup',
      }),
      name: 'manage',
      id: 'setup',
      elements: [
        { name: 'manageSetupOrganizations', text: <FormattedMessage id="manageSetupOrganizations" defaultMessage="Organizations" /> },
        { name: 'manageSetupTeams', text: <FormattedMessage id="manageSetupTeamsMenu" defaultMessage="Teams" /> },
        { name: 'manageSetupRoles', text: <FormattedMessage id="manageSetupRolesMenu" defaultMessage="Roles" /> },
      ],
    },
    { name: 'manageUsers', text: <FormattedMessage id="manageUsersMenu" defaultMessage="Users" /> },
    { name: 'manageDatasets', text: <FormattedMessage id="manageDatasetsMenu" defaultMessage="Datasets" /> },
    { name: 'manageLibrairies', text: <FormattedMessage id="manageLibrairiesMenu" defaultMessage="Libraries" /> },
    {
      group: true,
      text: intl.formatMessage({
        id: 'manageScreeningsMenu',
        defaultMessage: 'Screenings',
      }),
      name: 'manage',
      id: 'screenings',
      elements: [
        { name: 'manageScreeningsWorkflow', text: <FormattedMessage id="manageScreeningsWorkflowMenu" defaultMessage="Workflows" /> },
        { name: 'manageScreeningsAlert', text: <FormattedMessage id="manageScreeningsMenu" defaultMessage="Alert settings" /> },
        // { name: 'manageScreeningsRisk', text: <FormattedMessage id="manageScreeningsMenu" defaultMessage="screenings" /> },
        { name: 'manageScreeningsSchedule', text: <FormattedMessage id="manageScreeningsScheduleMenu" defaultMessage="Schedules" /> },
      ],
    },
    { name: 'manageAudits', text: <FormattedMessage id="manageAuditsMenu" defaultMessage="Audits" /> },
  ];

  // limit the active menu wrt permissions
  const investigateActiveMenus = investigateElements.filter((me) => checkPermissions(me.name, permissions));

  const manageActiveMenus = manageElements
    .map((me) => {
      if (!me.group) return me;
      return {
        ...me,
        elements: me.elements?.filter((sub) => checkPermissions(sub.name, permissions)) ?? [],
      };
    })
    .filter((me) => (me.group ? true : checkPermissions(me.name, permissions)));

  const maxSubManageMenus: number = Math.max(...manageActiveMenus.filter((mam) => mam.group).map((mam) => mam.elements?.length ?? 0));

  const nbManageMenus: number = manageActiveMenus.reduce((acc, ma) => (ma.group ? (ma.elements ? acc + 1 : acc) : acc + 1), 0) + maxSubManageMenus;

  return (
    <>
      
      <input className="menu-selector" type="radio" id="null" name="main-menu" value="null" />
      <input className="menu-selector" type="radio" id="sub-null" name="sub-menu" value="null" />
      
      {checkPermissions('investigate', permissions) && 
        <> 
    
      {/* investigate menu */}
      <input className="menu-selector" type="radio" id="investigate" name="main-menu" value="investigate" defaultChecked />
      <Box display={'flex'} className="menu-header">
        <MenuLink to={routerPages.investigate} className={'header-link'} end>
          <EyeIcon />{' '}
          <Typography component="span">
            <FormattedMessage id="investigateMenu" defaultMessage="Investigate" />
          </Typography>
        </MenuLink>
        <label className="toggle-label is-null" htmlFor="investigate">
          <TriangleIcon />
        </label>
        <label className="toggle-label is-active" htmlFor="null">
          <TriangleIcon />
        </label>
      </Box>
      <Box className="menu-collapse" display="flex" flexDirection="column" style={{ '--nb-menu-items': investigateActiveMenus.length } as React.CSSProperties}>
        {investigateActiveMenus.map((am) => {
          if (!routerPages[am.name]) {
            console.warn(am.name, 'does not exists in router pages');
          }
          return (
            <MenuLink key={am.name} to={routerPages[am.name]} className="nav-link">
              <Typography component="span">{am.text}</Typography>
              {(am.nbUnread ?? 0) > 0 && <div className="has-unread"></div>}
            </MenuLink>
          );
        })}
      </Box>
       </>
      }
      {/* manage menu */}
      {checkPermissions('manage', permissions) && 
        <> 
          <input className="menu-selector" type="radio" id="manage" name="main-menu" value="manage" />
          <Box display={'flex'} className="menu-header" mt={1}>
            <MenuLink to={routerPages.manage} className={'header-link'} end>
              <GearIcon />{' '}
              <Typography component="span">
                <FormattedMessage id="manageMenu" defaultMessage="Manage" />
              </Typography>
            </MenuLink>
            <label className="toggle-label is-null" htmlFor="manage">
              <TriangleIcon />
            </label>
            <label className="toggle-label is-active" htmlFor="null">
              <TriangleIcon />
            </label>
          </Box>
          <Box className="menu-collapse" display="flex" flexDirection="column" style={{ '--nb-menu-items': nbManageMenus } as React.CSSProperties}>
            {manageActiveMenus.map((am) => {
              if (am.group) {
                if (am.elements?.length) {
                  return (
                    <Box key={am.id}>
                      <input className="sub-menu-selector" type="radio" id={`sub-${am.id}`} name="sub-menu" value={`${am.name}`} />
                      <label className="sub-toggle-label is-null" htmlFor={`sub-${am.id}`}>
                        <Box className="label-inner">
                          {am.text}
                          <ChevronIcon />
                        </Box>
                      </label>
                      <label className="sub-toggle-label is-active" htmlFor="sub-null">
                        <Box className="label-inner">
                          {am.text}
                          <ChevronIcon />
                        </Box>
                      </label>
                      <Box className="sub-menu-collapse" display="flex" flexDirection="column" style={{ '--nb-menu-items': am.elements.length } as React.CSSProperties}>
                        {am.elements.map((sub_am) => {
                          return (
                            <MenuLink key={sub_am.name} to={routerPages[sub_am.name]} className="nav-link">
                              {sub_am.text}
                              {(sub_am.nbUnread ?? 0) > 0 && <div className="has-unread"></div>}
                            </MenuLink>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                } else {
                  // return <Fragment key={am.name}></Fragment>;
                  return null;
                }
              }
              return (
                <MenuLink key={am.name} to={routerPages[am.name]} className="nav-link">
                  {am.text}
                  {(am.nbUnread ?? 0) > 0 && <div className="has-unread"></div>}
                </MenuLink>
              );
            })}
          </Box>
      </>}

      <Button sx={{ color: 'white', p: 0, mt: 1, textAlign: 'left', minWidth: 0, ':hover': { backgroundColor: 'transparent' } }} className="header-link" disableRipple onClick={showNotifications}>
        <BellIcon />{' '}
        <Typography component="span">
          <FormattedMessage id="notificationsMenu" defaultMessage="Notifications" />
        </Typography>
        {newNotifications && <Box className="has-unread" display="inline-block" ml={'1px'} sx={{ verticalAlign: 'top' }}></Box>}
      </Button>

      {/* </>
      )} */}
    </>
  );
});

// Bottom menu
const BottomMenu = memo(() => {
  const { logoutUser } = useAuthenticationFunction();

  const { currentUser, changePassword, editUser, uploadAvatar, deleteAvatar } = useContext(UserContext);
  const { editSingleUser } = useContext(AllUsersContext);
  const intl = useIntl();

  const { toggleModal, blockModal, closeModal } = useAddModal();

  const handleEditProfile = useCallback(() => {
    if (!currentUser) return;
    toggleModal?.({
      title: intl.formatMessage({ id: 'editProfile', defaultMessage: 'Edit profile' }),
      modalContent: <EditProfile {...{ currentUser, blockModal, closeModal, changePassword, editUser, editSingleUser, uploadAvatar, deleteAvatar }} />,
    });
  }, [intl, toggleModal, currentUser, blockModal, closeModal, changePassword, editUser, editSingleUser, uploadAvatar, deleteAvatar]);

  // const initials = currentUser?.fullName
  //   ?.split(' ')
  //   .slice(0, 2)
  //   .map((s) => s[0].toUpperCase())
  //   .join('');

  let avatar: ReactNode = (
    <AvatarIcon className="avatar" sx={{ fontSize: 'var(--avatar-fs)', color: 'var(--color-lightgray4)', transition: 'font-size var(--app-menu-animation-duration, 250ms) linear' }} />
  );
  if (currentUser) {
    avatar = GetAvatar(currentUser.userName, [currentUser], false, 'calc(0.4 * var(--avatar-fs))');
  }

  return (
    <Box className="bottom-menu" px={4} py={2}>
      <Button
        className="bottom-profile"
        fullWidth
        onClick={handleEditProfile}
        sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', color: 'white', ':hover': { backgroundColor: 'transparent' } }}
      >
        <Box className="avatar-container" display={'flex'} alignItems={'center'} width={'100%'} textAlign={'left'} height={'45px'} mb={'5px'}>
          <Box width={'var(--avatar-fs)'} sx={{ fontSize: 'var(--avatar-fs)' }}>
            {avatar}
          </Box>
          <PenIcon className="pen" sx={{ fontSize: '10px', color: '#CFD1D2', ml: 1, mt: 'auto' }} />
        </Box>
        <Box className="username" width={'100%'} textAlign={'left'} fontWeight={700} fontSize={'.75rem'} overflow={'hidden'} textOverflow={'ellipsis'} sx={{ textTransform: 'none' }}>
          {currentUser?.fullName ?? currentUser?.userName}
        </Box>
      </Button>

      <Box display={'flex'} flexDirection={'column'} mt={3}>
        <Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_HELP_URL} sx={{ lineHeight: 1 }}>
          <BottomMenuButton className="bottom-menu-button" fullWidth>
            <HelpIcon />
            <Typography component="span" className="text">
              <FormattedMessage id="helpButton" defaultMessage="Help" />
            </Typography>
          </BottomMenuButton>
        </Link>
        <BottomMenuButton className="bottom-menu-button" fullWidth onClick={logoutUser} sx={{ mt: '1px' }}>
          <SignOutIcon />
          <Typography component="span" className="text">
            <FormattedMessage id="signoutpButton" defaultMessage="Sign out" />
          </Typography>
        </BottomMenuButton>
      </Box>
    </Box>
  );
});
export default AppMenu;
