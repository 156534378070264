/**
 * AppRouter.tsx
 * Define the routes for the application
 */
/* packages */
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { PropsWithChildren, useContext } from 'react';

/* pages */
import Registered from 'pages/Registered/Registered';
import Login from 'pages/login/Login';

import Investigate from 'pages/Investigate/Investigate';
import InvestigateDashboard from 'pages/Investigate/InvestigateDashboard';
import InvestigateSearch from 'pages/Investigate/InvestigateSearch';
import InvestigateExplore from 'pages/Investigate/InvestigateExplore';
import InvestigateAlerts from 'pages/Investigate/InvestigateAlerts';
// import InvestigateAlert from 'pages/Investigate/InvestigateAlert';

import Manage from 'pages/manage/Manage';
import ManageDashboard from 'pages/manage/ManageDashboard';

import ManageSetupOrganizations from 'pages/manage/ManageSetupOrganizations';
import ManageSetupTeams from 'pages/manage/ManageSetupTeams';
import ManageSetupRoles from 'pages/manage/ManageSetupRoles';
import ManageUsers from 'pages/manage/ManageUsers';
import ManageDatasets from 'pages/manage/ManageDatasets';
import ManageLibrairies from 'pages/manage/ManageLibrairies';
import ManageScreeningsWorkflow from 'pages/manage/ManageScreeningsWorkflow';
import ManageScreeningsAlert from 'pages/manage/ManageScreeningsAlert';
// import ManageScreeningsRisk from 'pages/manage/ManageScreeningsRisk';
import ManageScreeningsSchedule from 'pages/manage/ManageScreeningsSchedule';
import ManageAudits from 'pages/manage/ManageAudits';

/* contexts */
import { UserContext } from 'contextProviders/UserProvider';

/* utilities */
import { AllowedPageCheck, checkPermissions } from 'utilities/CheckUserPermissions';
import { displayError } from 'utilities/Logger';

/* elements */
// page urls
const loginPage = '/';
const registered = '/r';

const investigate = registered + '/i';

const investigateSearch = investigate + '/search';
const investigateExplore = investigate + '/explore';
const investigateAlerts = investigate + '/alerts';
// const investigateAlert = investigate + '/alert';
const investigateRisks = investigate + '/risks';

const manage = registered + '/m';

// const manageSetup = manage + '/setup';
const manageSetupOrganizations = manage + '/organisations';
const manageSetupTeams = manage + '/teams';
const manageSetupRoles = manage + '/roles';

const manageUsers = manage + '/users';
const manageDatasets = manage + '/datasets';
const manageLibrairies = manage + '/librairies';

// const manageScreenings = manage + '/screenings';
const manageScreeningsWorkflow = manage + '/workflow';
const manageScreeningsAlert = manage + '/alert';
// const manageScreeningsRisk = manage + '/risk';
const manageScreeningsSchedule = manage + '/schedule';

const manageAudits = manage + '/audits';

const registeredEntryPage = investigate;

// redirection error
const RedirectErrors = () => {
  return <Navigate to="/" replace />;
};

const RouteGuard = ({ page, children }: PropsWithChildren<{ page: AllowedPageCheck }>) => {
  const { permissions } = useContext(UserContext);

  if (!checkPermissions(page, permissions)) {
    displayError('Unauthorized page');
    return <Navigate to={registeredEntryPage} replace />;
  }

  return <>{children}</>;
};

const routerPages: { [key: string]: string } = {
  registeredEntryPage,
  loginPage,
  investigate,
  investigateSearch,
  investigateExplore,
  investigateAlerts,
  // investigateAlert,
  investigateRisks,

  manage,
  // manageSetup,
  manageSetupOrganizations,
  manageSetupTeams,
  manageSetupRoles,
  manageUsers,
  manageDatasets,
  manageLibrairies,
  // manageScreenings,
  manageScreeningsWorkflow,
  manageScreeningsAlert,
  // manageScreeningsRisk,
  manageScreeningsSchedule,
  manageAudits,
};

// router
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Login />,
      errorElement: <RedirectErrors />,
    },
    {
      path: registered,
      element: <Registered />,
      children: [
        {
          path: investigate,
          element: <Investigate />,
          children: [
            {
              // this is the entry point and should not be guarded
              index: true,
              element: <InvestigateDashboard />,
            },
            {
              path: investigateSearch,
              element: (
                <RouteGuard page="investigateSearch">
                  <InvestigateSearch />
                </RouteGuard>
              ),
            },
            {
              path: investigateExplore,
              element: (
                <RouteGuard page="investigateExplore">
                  <InvestigateExplore />
                </RouteGuard>
              ),
            },
            {
              path: investigateAlerts,
              element: (
                <RouteGuard page="investigateAlerts">
                  <InvestigateAlerts />
                </RouteGuard>
              ),
            },
            // {
            //   path: investigateAlert + '/:alertId',
            //   element: (
            //     <RouteGuard page="investigateAlert">
            //       <InvestigateAlert />
            //     </RouteGuard>
            //   ),
            // },
            {
              path: investigateRisks,
              element: (
                <RouteGuard page="investigateRisks">
                  <InvestigateExplore type="risks" />
                </RouteGuard>
              ),
            },
          ],
        },
        {
          path: manage,
          element: <Manage />,
          children: [
            {
              index: true,
              element: (
                <RouteGuard page="manage">
                  <ManageDashboard />
                </RouteGuard>
              ),
            },
            {
              path: manageSetupOrganizations,
              element: (
                <RouteGuard page="manageSetupOrganizations">
                  <ManageSetupOrganizations />
                </RouteGuard>
              ),
            },
            {
              path: manageSetupTeams,
              element: (
                <RouteGuard page="manageSetupTeams">
                  <ManageSetupTeams />
                </RouteGuard>
              ),
            },
            {
              path: manageSetupRoles,
              element: (
                <RouteGuard page="manageSetupRoles">
                  <ManageSetupRoles />
                </RouteGuard>
              ),
            },
            {
              path: manageUsers,
              element: (
                <RouteGuard page="manageUsers">
                  <ManageUsers />
                </RouteGuard>
              ),
            },
            {
              path: manageDatasets,
              element: (
                <RouteGuard page="manageDatasets">
                  <ManageDatasets />
                </RouteGuard>
              ),
            },
            {
              path: manageLibrairies,
              element: (
                <RouteGuard page="manageLibrairies">
                  <ManageLibrairies />
                </RouteGuard>
              ),
            },
            {
              path: manageScreeningsWorkflow,
              element: (
                <RouteGuard page="manageScreeningsWorkflow">
                  <ManageScreeningsWorkflow />
                </RouteGuard>
              ),
            },
            {
              path: manageScreeningsAlert,
              element: (
                <RouteGuard page="manageScreeningsAlert">
                  <ManageScreeningsAlert />
                </RouteGuard>
              ),
            },
            // {
            //   path: manageScreeningsRisk,
            //   element: (
            //     <RouteGuard page="manageScreeningsRisk">
            //       <ManageScreeningsRisk />
            //     </RouteGuard>
            //   ),
            // },
            {
              path: manageScreeningsSchedule,
              element: (
                <RouteGuard page="manageScreeningsSchedule">
                  <ManageScreeningsSchedule />
                </RouteGuard>
              ),
            },
            {
              path: manageAudits,
              element: (
                <RouteGuard page="manageAudits">
                  <ManageAudits />
                </RouteGuard>
              ),
            },
          ],
        },
      ],
    },
  ],
  {
    basename: process.env.REACT_APP_DIRECTORY,
  }
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

// exports
export default AppRouter;

export { routerPages };
