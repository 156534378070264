/*
Logger.tsx
Display loggin messages
 */
/* packages*/

export const displayError = (message: string) => {
  console.log(`%c${message}`, 'color:#f05454;font-weight:600');
};

export const displayWarning = (message: string) => {
  console.log(`%c${message}`, 'color:#da9c17;font-weight:500');
};
