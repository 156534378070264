import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const UnknownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path
          d="M1.977-1.063v-.128A3.943,3.943,0,0,1,2.1-2.256,1.6,1.6,0,0,1,2.438-2.9a2.624,2.624,0,0,1,.542-.443A3.454,3.454,0,0,0,3.4-3.654a1.348,1.348,0,0,0,.3-.364.918.918,0,0,0,.109-.448.84.84,0,0,0-.125-.461.845.845,0,0,0-.336-.3,1.019,1.019,0,0,0-.469-.107,1.041,1.041,0,0,0-.469.109.915.915,0,0,0-.361.321,1,1,0,0,0-.153.525H.343A2.249,2.249,0,0,1,.706-5.646a2.1,2.1,0,0,1,.919-.744,3.178,3.178,0,0,1,1.263-.245,3.381,3.381,0,0,1,1.341.25,2.087,2.087,0,0,1,.914.723,1.94,1.94,0,0,1,.331,1.141,2.01,2.01,0,0,1-.146.789,1.925,1.925,0,0,1-.409.611,3.091,3.091,0,0,1-.622.478,2.532,2.532,0,0,0-.492.374,1.232,1.232,0,0,0-.287.45,1.882,1.882,0,0,0-.1.628v.128Zm.753,2.44a.887.887,0,0,1-.648-.267A.87.87,0,0,1,1.816.462a.859.859,0,0,1,.267-.64.91.91,0,0,1,1.286,0,.867.867,0,0,1,.276.64.878.878,0,0,1-.13.461.973.973,0,0,1-.334.331A.863.863,0,0,1,2.73,1.376Z"
          transform="translate(5.091 10.629)"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};
