/**
 * PersonProfile.tsx
 */
/* packages */
import { createContext, PropsWithChildren, useState, useMemo, useCallback, useEffect, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';

/* contexts */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { CountriesContext } from 'contextProviders/CountriesProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { getCountryFromIso } from 'components/InvestigateSearch/IndividualContent';

/* types */
import { PersonDetailsQuery, PersonDetailsResponse, PersonType } from 'models/person';
import { MatchingDataType, MatchingLocation } from 'models/matchingData';
import { StatusPromiseResponse } from 'models/utils';

export interface MapLocations extends MatchingLocation {
  countryName?: string;
}
interface TimelineElement {
  type: 'started' | 'ended' | 'single';
  date: Dayjs;
  dateFormat: string;
  texts: { label: string; value: string }[];
  categories?: string[];
  subcategories?: string[];
  status?: string;
  country?: string;
}
interface PersonContextType {
  loading?: boolean;
  details?: PersonType;
  person?: MatchingDataType;
  getPersonDetails?(person?: MatchingDataType, abortController?: AbortController): void;
  changeProfile?(profile: MatchingDataType): void;
  exportProfile?(profile?: PersonType, abortController?: AbortController): Promise<StatusPromiseResponse>;
  hasSources?: boolean;
  hasLinks?: boolean;
  hasMore?: boolean;
  hasMap?: boolean;
  mapLocations?: { [key: string]: MapLocations[] };
  hasTimeline?: boolean;
  timelineElements?: TimelineElement[];
  profileHistory?: MatchingDataType[];
  setFromHistory?(profileIndex: number): void;
}
const PersonContext = createContext<PersonContextType>({});

export const parsePeriodDate = (dateString: string, started = true): [Dayjs, string] | null => {
  let parsedDate;

  parsedDate = dayjs(dateString, 'YYYY-MM-DD HH:mm:ss');
  if (parsedDate.isValid()) {
    return [parsedDate, 'MMM DD, YYYY'];
  }

  parsedDate = dayjs(dateString, 'YYYY-MM-DD');
  if (parsedDate.isValid()) {
    return [parsedDate, 'MMM DD, YYYY'];
  }

  parsedDate = dayjs(dateString, 'YYYY-MM');
  if (parsedDate.isValid()) {
    parsedDate = started ? parsedDate.startOf('month') : parsedDate.endOf('month');
    return [parsedDate, 'MMM YYYY'];
  }

  parsedDate = dayjs(dateString, 'YYYY');
  if (parsedDate.isValid()) {
    const baseMonth = started ? 0 : 11; // months are 0 index in dayjs
    parsedDate = parsedDate.month(baseMonth);
    parsedDate = started ? parsedDate.startOf('month') : parsedDate.endOf('month');
    return [parsedDate, 'YYYY'];
  }

  return null;
};
const PersonProvider = ({ person, children }: PropsWithChildren<{ person: MatchingDataType }>) => {
  const { postAuthenticatedRequest, getAuthenticatedRequest } = useAuthenticatedRequest();
  const { countries, loadingCountries } = useContext(CountriesContext);
  const intl = useIntl();

  const [currentPerson, setCurrentPerson] = useState(person);

  const [profileHistory, setProfileHistory] = useState<MatchingDataType[]>([]);

  const [details, setDetails] = useState<PersonType | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  // get details for a person
  const getPersonDetails = useCallback(
    async (person?: MatchingDataType, abortController?: AbortController) => {
      if (!person) return;

      setDetails(undefined);

      if (!person.id && !person.dataID) {
        return setLoading(false);
      }

      if (!person.dataset || (!person.dataset?.label && !person.dataset?.id)) {
        return setLoading(false);
      }

      setLoading(true);
      const detailsUrl = URLConstants.personSingleDetails;
      const detailsPayload: PersonDetailsQuery = {
        person: {
          dataset: {},
        },
      };

      if (person.id) detailsPayload.person.id = person.id;
      else if (person.dataID) detailsPayload.person.dataID = person.dataID;

      if (person.dataset.id) detailsPayload.person.dataset.id = person.dataset.id;
      else if (person.dataset.label) detailsPayload.person.dataset.label = person.dataset.label;

      try {
        const result = (await postAuthenticatedRequest(detailsUrl, detailsPayload, abortController)) as PersonDetailsResponse;
        if (result.person) {
          setDetails(result.person);
        }
      } catch (detailsError: any) {
        if (detailsError?.code === 'ERR_CANCELED') return;
      }
      setLoading(false);
    },
    [postAuthenticatedRequest]
  );

  const changeProfile = useCallback(
    (profile: MatchingDataType) => {
      // setProfileHistory((currentHistory) => [currentPerson, ...currentHistory]);
      if (!details) return;
      setCurrentPerson(profile);
      setProfileHistory((currentHistory) => [details, ...currentHistory]);
    },
    // [currentPerson]
    [details]
  );

  const exportProfile = useCallback(
    async (profile?: PersonType, abortController?: AbortController): Promise<StatusPromiseResponse> => {
      if (!profile || !profile.id) {
        return { status: 'error' };
      }
      const exportUrl = URLConstants.riskPersonsExport + profile.id;
      const filename = `profile_${profile.id}.zip`;
      try {
        const response = (await getAuthenticatedRequest(exportUrl, undefined, true)) as BlobPart;
        const downloadUrl = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); //any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return { status: 'success' };
      } catch (ExportError) {
        return { status: 'error' };
      }
    },
    [getAuthenticatedRequest]
  );

  const setFromHistory = useCallback(
    (profileIndex: number) => {
      // do not change profile when loading
      if (loading) return;

      setLoading(true);
      const previousProfile = profileHistory[profileIndex];
      // setCurrentPerson(previousProfile);
      setDetails(previousProfile);
      setProfileHistory((currentHistory) => currentHistory.slice(profileIndex + 1));
      setTimeout(() => {
        setLoading(false);
      }, 250);
    },
    [loading, profileHistory]
  );

  // trigger read person details on mount
  useEffect(() => {
    const abortController = new AbortController();

    getPersonDetails(currentPerson, abortController);

    return () => {
      abortController.abort();
    };
  }, [currentPerson, getPersonDetails]);

  const filterValidLocation = useCallback(
    // (locationObject: { latitude?: string; longitude?: string; country?: string }[]) => {
    (locationObject: MatchingLocation[]) => {
      if (loadingCountries || !countries) return null;

      const validLocation: MapLocations[] = locationObject
        .filter((loc) => loc.latitude && loc.longitude && loc.country)
        .map((loc) => ({
          ...loc,
          countryName: getCountryFromIso(loc.country, countries),
        }))
        .filter((loc) => loc.countryName);

      return validLocation;
    },
    [loadingCountries, countries]
  );

  const value = useMemo(() => {
    // find the map locations
    const mapLocations: {
      [key: string]: MapLocations[];
    } = {};

    const locationKeys = {
      address: details?.addresses,
      placeOfRegistry: details?.placesOfRegistry,
      nationality: details?.nationalities,
      placeOfBirth: details?.placesOfBirth,
      flag: details?.flags,
      placeOfBuild: details?.placeOfBuild,
    };

    Object.entries(locationKeys).forEach(([locKey, locValues]) => {
      const validLocations = locValues ? filterValidLocation(locValues) : [];
      if (validLocations && validLocations.length > 0) mapLocations[locKey] = validLocations;
    });

    // const validPlacesOfRegistry = details?.placesOfRegistry ? filterValidLocation(details?.placesOfRegistry) : [];
    // if (validPlacesOfRegistry && validPlacesOfRegistry.length > 0) mapLocations.placesOfRegistry = validPlacesOfRegistry;

    // find the other proflie links
    const activeLinks: PersonType['links'] = details?.links?.filter((link) => link.linked && (link.linked.dataID || link.linked.caption)) ?? [];

    // find the timeline elements
    const timelineElements: TimelineElement[] = [];
    details?.risks?.forEach((risk) => {
      if (!risk.periodsOfRisk || risk.periodsOfRisk.length <= 0) return;
      risk.periodsOfRisk.forEach((period) => {
        if (period.started) {
          const starting_date = parsePeriodDate(period.started);
          if (starting_date) {
            const [date, dateFormat] = starting_date;
            const newElement: TimelineElement = {
              type: 'started',
              date: date,
              dateFormat: dateFormat,
              categories: risk.categories,
              subcategories: risk.subcategories,
              status: risk.status,
              texts: [],
            };

            risk.riskInformations?.forEach((info) => {
              if (info.type === 'Country Code') {
                newElement.country = info.value;
              } else {
                newElement.texts.push({
                  label: info.type || '',
                  value: info.value || '',
                });
              }
            });
            if (newElement.texts.length > 0) {
              timelineElements.push(newElement);
            }
          }
        }
        if (period.ended) {
          const ending_date = parsePeriodDate(period.ended, false);
          if (ending_date) {
            const [date, dateFormat] = ending_date;
            const newElement: TimelineElement = {
              type: 'ended',
              date: date,
              dateFormat: dateFormat,
              categories: risk.categories,
              subcategories: risk.subcategories,
              status: risk.status,
              texts: [],
            };

            risk.riskInformations?.forEach((info) => {
              if (info.type === 'Country Code') {
                newElement.country = info.value;
              } else {
                newElement.texts.push({
                  label: info.type || '',
                  value: info.value || '',
                });
              }
            });
            if (newElement.texts.length > 0) {
              timelineElements.push(newElement);
            }
          }
        }
      });
    });

    details?.timeLineExtraValue?.forEach((tlev) => {
      if (!tlev.value) return;
      const tlev_date = parsePeriodDate(tlev.value);
      if (tlev_date) {
        const [date, dateFormat] = tlev_date;
        const tlevElement: TimelineElement = {
          type: 'single',
          date: date,
          dateFormat: dateFormat,
          texts: [{ label: '', value: tlev.type ?? '' }],
        };

        timelineElements.push(tlevElement);
      }
    });

    /* ALWAYS SHOW ADDITIONAL DATES ON THE TIMELINE */
    // if (timelineElements.length > 0) {
    // Date of birth
    details?.datesOfBirth?.forEach((detailDate) => {
      if (!detailDate.date) return;
      const date = parsePeriodDate(detailDate.date);
      if (date) {
        const [dateValue, dateFormat] = date;
        const dateElement: TimelineElement = {
          type: 'single',
          date: dateValue,
          dateFormat: dateFormat,
          texts: [{ label: '', value: intl.formatMessage({ id: 'DateOfBirth', defaultMessage: 'Date of birth' }) }],
        };
        timelineElements.push(dateElement);
      }
    });

    // Date of registry
    details?.datesOfRegistry?.forEach((detailDate) => {
      if (!detailDate.date) return;
      const date = parsePeriodDate(detailDate.date);
      if (date) {
        const [dateValue, dateFormat] = date;
        const dateElement: TimelineElement = {
          type: 'single',
          date: dateValue,
          dateFormat: dateFormat,
          texts: [{ label: '', value: intl.formatMessage({ id: 'DateOfRegistry', defaultMessage: 'Date of registry' }) }],
        };
        timelineElements.push(dateElement);
      }
    });
    // Date of build
    details?.datesOfBuild?.forEach((detailDate) => {
      if (!detailDate.date) return;
      const date = parsePeriodDate(detailDate.date);
      if (date) {
        const [dateValue, dateFormat] = date;
        const dateElement: TimelineElement = {
          type: 'single',
          date: dateValue,
          dateFormat: dateFormat,
          texts: [{ label: '', value: intl.formatMessage({ id: 'dateOfBuild', defaultMessage: 'Date of build' }) }],
        };
        timelineElements.push(dateElement);
      }
    });
    // Date of death
    details?.datesOfDeath?.forEach((detailDate) => {
      if (!detailDate.date) return;
      const date = parsePeriodDate(detailDate.date);
      if (date) {
        const [dateValue, dateFormat] = date;
        const dateElement: TimelineElement = {
          type: 'single',
          date: dateValue,
          dateFormat: dateFormat,
          texts: [{ label: '', value: intl.formatMessage({ id: 'dateOfDeath', defaultMessage: 'Date of death' }) }],
        };
        timelineElements.push(dateElement);
      }
    });
    // Date of dissolution
    details?.datesOfDissolution?.forEach((detailDate) => {
      if (!detailDate.date) return;
      const date = parsePeriodDate(detailDate.date);
      if (date) {
        const [dateValue, dateFormat] = date;
        const dateElement: TimelineElement = {
          type: 'single',
          date: dateValue,
          dateFormat: dateFormat,
          texts: [{ label: '', value: intl.formatMessage({ id: 'dateOfDissolution', defaultMessage: 'Date of dissolution' }) }],
        };
        timelineElements.push(dateElement);
      }
    });
    // }

    // sort the timeline
    timelineElements.sort((a, b) => (a.date.isBefore(b.date) ? 1 : -1));

    return {
      loading,
      details,
      person: currentPerson,
      getPersonDetails,
      changeProfile,
      exportProfile,
      hasSources: (details?.sources && details.sources.length > 0) ?? false,
      hasLinks: activeLinks.length > 0 ?? false,
      hasMore: (details?.additionalInformations && details.additionalInformations.length > 0) ?? false,
      hasMap: Object.keys(mapLocations).length > 0,
      mapLocations,
      hasTimeline: timelineElements.length > 0,
      timelineElements: timelineElements,
      profileHistory,
      setFromHistory,
    };
  }, [intl, loading, details, currentPerson, getPersonDetails, exportProfile, changeProfile, filterValidLocation, profileHistory, setFromHistory]);

  return <PersonContext.Provider value={value}>{children}</PersonContext.Provider>;
};

export { PersonContext, PersonProvider };
