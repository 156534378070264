/**
 * OrganisationContent.tsx
 * content of organisation card in search results
 */
/* packages */
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { StyledBadge, UnknownMessage } from './utils';

/* utilities */
import { GenerateTooltip, maxTooltipElements } from './IndividualContent';

/* types */
import { MatchingDataType } from 'models/matchingData';
// import { SearchCardType } from './utils';

const IdentificationNumberContent = (props: { data: MatchingDataType }) => {
  // const { countries } = useContext(CountriesContext);

  const { data } = props;

  const identityDocument = data.identityDocuments ? data.identityDocuments[0]?.number : null;
  const nbIdentityDocument = (data.identityDocuments ?? [0]).length - 1;

  let identityDocumentTooltip = null;
  if (nbIdentityDocument > 0 && data.identityDocuments) {
    identityDocumentTooltip = GenerateTooltip(
      data.identityDocuments.slice(1, maxTooltipElements + 1).map((id) => id.number ?? 'unknown'),
      nbIdentityDocument - maxTooltipElements
    );
  }

  const bic = data.bic;
  const nbBic = 0;

  const lei = data.lei;
  const nbLei = 0;

  const digitalCurrency = data.digitalCurrencies ? data.digitalCurrencies[0]?.identifier : null;
  const nbDigitalCurrency = (data.digitalCurrencies ?? [0]).length - 1;

  let digitalCurrencyTooltip = null;
  if (nbDigitalCurrency > 0 && data.digitalCurrencies) {
    digitalCurrencyTooltip = GenerateTooltip(
      data.digitalCurrencies.slice(1, maxTooltipElements + 1).map((id) => id.identifier ?? 'unknown'),
      nbDigitalCurrency - maxTooltipElements
    );
  }

  return (
    <>
      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="idDocumentNumber" defaultMessage={'Identification number'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbIdentityDocument} tooltip={identityDocumentTooltip}>
            {identityDocument ? identityDocument : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="bic" defaultMessage={'BIC'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbBic}>{bic ? bic : <UnknownMessage />}</StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="lei" defaultMessage={'LEI'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbLei}>{lei ? lei : <UnknownMessage />}</StyledBadge>
        </Box>
      </Box>
      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="digitalCurrency" defaultMessage={'Digital currency'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbDigitalCurrency} tooltip={digitalCurrencyTooltip}>
            {digitalCurrency ? digitalCurrency : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box>
    </>
  );
};

export default IdentificationNumberContent;
