import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
        <path d="M17.594,23.5A1.094,1.094,0,0,1,16.5,22.406V7.094a1.094,1.094,0,0,1,2.188,0V22.406A1.094,1.094,0,0,1,17.594,23.5Z" transform="translate(-8.844 -6)" fill="currentColor" />
        <path data-name="Tracé 68" d="M22.406,18.688H7.094a1.094,1.094,0,0,1,0-2.187H22.406a1.094,1.094,0,0,1,0,2.188Z" transform="translate(-6 -8.844)" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};
