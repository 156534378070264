import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
        <path
          d="M4.75.75a.75.75,0,0,0-1.5,0V2H2A2,2,0,0,0,0,4V14a2,2,0,0,0,2,2H12a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H10.75V.75a.75.75,0,1,0-1.5,0V2H4.75ZM1.5,6h11v8a.5.5,0,0,1-.5.5H2a.5.5,0,0,1-.5-.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
