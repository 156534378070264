import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const BicIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path
          d="M.443,0V-5.091H2.48a2.313,2.313,0,0,1,.938.167,1.253,1.253,0,0,1,.565.461,1.223,1.223,0,0,1,.189.676,1.094,1.094,0,0,1-.12.524,1.028,1.028,0,0,1-.328.367,1.347,1.347,0,0,1-.473.2v.05a1.162,1.162,0,0,1,.546.165,1.165,1.165,0,0,1,.415.423,1.262,1.262,0,0,1,.159.649,1.325,1.325,0,0,1-.2.721,1.357,1.357,0,0,1-.59.5A2.258,2.258,0,0,1,2.617,0ZM1.519-.881H2.4a1.018,1.018,0,0,0,.657-.172.569.569,0,0,0,.206-.46.688.688,0,0,0-.1-.373.691.691,0,0,0-.29-.255,1.008,1.008,0,0,0-.448-.092h-.9Zm0-2.08h.8a.95.95,0,0,0,.394-.078.648.648,0,0,0,.274-.222.591.591,0,0,0,.1-.346.561.561,0,0,0-.195-.445.817.817,0,0,0-.553-.169H1.519Zm4.626-2.13V0H5.069V-5.091Zm5.377,1.782H10.434a1.091,1.091,0,0,0-.123-.376.971.971,0,0,0-.236-.283,1.02,1.02,0,0,0-.331-.178,1.3,1.3,0,0,0-.408-.061,1.214,1.214,0,0,0-.687.195,1.263,1.263,0,0,0-.455.566,2.251,2.251,0,0,0-.162.9A2.271,2.271,0,0,0,8.2-1.63a1.231,1.231,0,0,0,.456.559,1.229,1.229,0,0,0,.679.188,1.352,1.352,0,0,0,.4-.057,1.04,1.04,0,0,0,.329-.167.969.969,0,0,0,.241-.27,1.054,1.054,0,0,0,.133-.363l1.088.006a1.98,1.98,0,0,1-.21.674,2.048,2.048,0,0,1-.449.579,2.091,2.091,0,0,1-.671.4A2.464,2.464,0,0,1,9.313.07,2.4,2.4,0,0,1,8.1-.238a2.172,2.172,0,0,1-.846-.893A2.991,2.991,0,0,1,6.94-2.545a2.964,2.964,0,0,1,.313-1.417,2.19,2.19,0,0,1,.85-.891,2.392,2.392,0,0,1,1.209-.307,2.633,2.633,0,0,1,.822.124,2.082,2.082,0,0,1,.672.361,1.9,1.9,0,0,1,.478.581A2.168,2.168,0,0,1,11.522-3.309Z"
          transform="translate(2.017 10.545)"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};
