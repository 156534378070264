import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LeiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path d="M.443,0V-5.091H1.519v4.2H3.7V0ZM4.417,0V-5.091h3.43V-4.2H5.493v1.213H7.67V-2.1H5.493V-.887H7.857V0ZM9.78-5.091V0H8.7V-5.091Z" transform="translate(2.557 10.545)" fill="#fff" />
      </svg>
    </SvgIcon>
  );
};
