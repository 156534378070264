/**
 * SnackBarProvider.tsx
 * Display a Snackbar
 */

/* packages */
import React, { useState, createContext, useContext, memo, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

/* components */
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { WarningIcon } from 'icons/warning/warning';
import { CheckFilledIcon } from 'icons/checkedFilled/checkFilled';

/* types */
interface SnackbarValueT {
  message: string;
  key: number;
  severity?: AlertColor;
}
type AddSnackbarT = (message: string, severity?: AlertColor) => void;

interface SnackbarContextT {
  snackbar: SnackbarValueT[];
  setSnackbar: React.Dispatch<React.SetStateAction<SnackbarValueT[]>>;
}

/* elements */
// contexts
const SnackbarValueContext = createContext<SnackbarContextT>({ snackbar: [], setSnackbar: () => {} });
const SnackbarSetContext = createContext<AddSnackbarT>((message: string, severity?: AlertColor) => {});

// actual Snackbar
const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="left" />;
};

const autoHideduration = 2500;

const AppSnackbar = () => {
  const theme = useTheme();
  const { snackbar, setSnackbar } = useContext(SnackbarValueContext);

  const [open, setOpen] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarValueT | undefined>(undefined);

  React.useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open, setSnackbar]);

  const handleClose = (_: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const { message, key, severity } = messageInfo ?? {};

  const alertBackground = severity === 'error' ? theme.palette.lightRed.main : theme.palette.green.main;
  const IconElement = severity === 'error' ? WarningIcon : CheckFilledIcon;

  return (
    <>
      <Snackbar
        key={key ?? undefined}
        open={open}
        autoHideDuration={autoHideduration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ bottom: { xs: 0 }, right: { xs: 0 } }}
      >
        <Alert
          onClose={handleClose}
          severity={severity ?? 'success'}
          icon={<IconElement fontSize="inherit" />}
          sx={{ width: '100%', whiteSpace: 'pre-line', color: 'white', backgroundColor: alertBackground }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

// snackbar providers
const SnackbarSetProvider = memo(
  ({
    setSnackbar,
    children,
  }: React.PropsWithChildren<{
    setSnackbar: React.Dispatch<React.SetStateAction<SnackbarValueT[]>>;
  }>) => {
    const addSnackbar = useCallback(
      (message: string, severity?: AlertColor) => {
        setSnackbar((prev) => [...prev, { message, key: new Date().getTime(), severity }]);
      },
      [setSnackbar]
    );

    return <SnackbarSetContext.Provider value={addSnackbar}>{children}</SnackbarSetContext.Provider>;
  }
);

const SnackbarProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [snackbar, setSnackbar] = useState<SnackbarValueT[]>([]);

  const snackbarContextValue = useMemo(
    () => ({
      snackbar,
      setSnackbar,
    }),
    [snackbar, setSnackbar]
  );

  return (
    <SnackbarValueContext.Provider value={snackbarContextValue}>
      <SnackbarSetProvider setSnackbar={setSnackbar}>
        {children}
        <AppSnackbar />
      </SnackbarSetProvider>
    </SnackbarValueContext.Provider>
  );
};

// export hooks
const useAddSnackbar = () => useContext(SnackbarSetContext);

// exports
export { SnackbarProvider };
export { useAddSnackbar };
