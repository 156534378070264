/**
 * DisplayAuditResults.tsx
 * Display each row of audits
 */
/* packages */
import { PropsWithChildren, ReactNode, memo } from 'react';
import { styled } from '@mui/material/styles';

/* hooks */

/* components */
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';

/* utilities */
import { DateString } from 'models/utils';
import { ChevronIcon } from 'icons/chevron/chevron';
import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ArrowDownSolidIcon } from 'icons/arrowDownSolid/arrowDownSolid';

/* types */
interface AuditColumns {
  id?: string;
  action?: string;
  error?: string;
  entity?: string;
  entityDescription?: string;
  status?: ReactNode;
  started?: DateString;
  ended?: DateString;
  totalRecords?: number;
  totalRecordsProcessed?: number;
  createdBy?: ReactNode;
  createdOn?: string;
}
interface DisplayAuditResultsProps {
  tableData: TableRowData[];
  tableHeaders: TableHeadElement[];
  tableColumnTemplate: string;
}
interface DisplayAuditProps {
  data: AuditColumns;
  tableColumnTemplate: string;
  tableHeaders: TableHeadElement[];
}
interface DisplayAuditRowProps extends DisplayAuditProps {}
const DisplayAuditResults = ({ tableData, tableHeaders, tableColumnTemplate }: DisplayAuditResultsProps) => {
  return (
    <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={tableColumnTemplate} sx={{ overflowX: 'auto' }}>
      {tableHeaders.map((th, thIndex) => {
        return (
          <Box
            key={th.id}
            className={`header-element noBottomRadius with-border ${th.sorted ? 'has-sorting' : ''} ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
            {...(th.sorted
              ? {
                  onClick: th.sorted,
                }
              : {})}
          >
            {th.label.toUpperCase()}{' '}
            {th.sorted &&
              (th.direction ? (
                <ArrowDownSolidIcon sx={{ fontSize: 10, color: 'inherit', rotate: th.direction === 'asc' ? '180deg' : '0deg' }} />
              ) : (
                <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
              ))}
          </Box>
        );
      })}

      {tableData?.map((auditRow) => {
        return <DisplayAudit key={auditRow.id} tableHeaders={tableHeaders} data={auditRow.columns} tableColumnTemplate={tableColumnTemplate} />;
      })}
    </Box>
  );
};
const DisplayAudit = memo(({ data, tableColumnTemplate, tableHeaders }: DisplayAuditProps) => {
  return (
    <Box
      rowGap={'1px'}
      sx={{
        gridColumn: '1/-1',
        borderInline: '1px solid var(--color-grayHeaderBorder)',
        '&:not(:last-of-type)': {
          borderBottom: '1px solid var(--color-grayHeaderBorder)',
        },
      }}
    >
      {data.error ? (
        <Accordion
          elevation={0}
          sx={{ '&:hover .auditRow': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }, '& .accordion-chevron': { rotate: '180deg' }, '&.Mui-expanded .accordion-chevron': { rotate: '270deg' } }}
        >
          <AccordionSummary
            expandIcon={null}
            sx={{
              px: 0,
              minHeight: 0,
              '&.Mui-expanded': {
                minHeight: 0,
              },
              '& .MuiAccordionSummary-content': {
                m: 0,
                '& .expand-icon': {
                  rotate: '180deg',
                },
                '&.Mui-expanded': {
                  m: 0,
                  '& .expand-icon': {
                    rotate: '-90deg',
                  },
                },
              },
              '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
            }}
          >
            <DisplayAuditRow key={data.id} {...{ data, tableColumnTemplate, tableHeaders }} />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Box px={2} py={3} sx={{ backgroundColor: 'var(--color-lightgray6)' }}>
              <Typography color="error" sx={{ fontSize: 'var(--fs-14)' }}>
                {data.error}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <DisplayAuditRow key={data.id} {...{ data, tableColumnTemplate, tableHeaders }} />
      )}
    </Box>
  );
});

const StyledAuditElementDefaultDefault = styled(Box)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontSize: 'var(--fs-14)',
  padding: theme.spacing(2),
  wordBreak: 'break-all',
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
}));

const StyledAuditElementDefault = ({ children, ...props }: PropsWithChildren<BoxProps>) => {
  return (
    <StyledAuditElementDefaultDefault className="cell-element" {...props}>
      {children}
    </StyledAuditElementDefaultDefault>
  );
};

export const StyledAuditElement = ({ children, ...props }: PropsWithChildren<BoxProps>) => {
  return (
    <StyledAuditElementDefault className="cell-element" {...props}>
      {children}
    </StyledAuditElementDefault>
  );
};

const DisplayAuditRow = memo(({ data, tableColumnTemplate, tableHeaders }: DisplayAuditRowProps) => {
  return (
    <Box className="auditRow" display={'grid'} gridTemplateColumns={tableColumnTemplate} alignItems={'center'} sx={{ borderRadius: '5px' }}>
      {tableHeaders.map((th) => (
        <StyledAuditElement key={th.id}>
          {data[th.id as keyof AuditColumns]}
          {data.error && th.id === 'status' && <ChevronIcon className={'accordion-chevron'} sx={{ fontSize: 'var(--fs-10)', color: 'var(--color-gray2)', transition: 'rotate 150ms linear' }} />}
        </StyledAuditElement>
      ))}
    </Box>
  );
});

export default DisplayAuditResults;
