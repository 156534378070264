/**
 * FilterDatasets.tsx
 */
/* packages */
import { useState, useCallback, memo, useMemo, useRef, forwardRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import SearchText from 'components/SearchElements/SearchText/SearchText';

import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';

/* utilities */

import { DatasetFolder, Dataset } from 'models/datasets';

/* types */

export interface SelectedFiltersDatasetsValues {
  search: string;
  type: string[];
  datasetFolder: string[];
}
interface SearchFormType {
  disabled?: boolean;
  onChangeFilter(filters: SelectedFiltersDatasetsValues, debounce?: boolean): void;
  datasets?: Dataset[];
  datasetFolders?: DatasetFolder[];
}

const SearchFiltersMemo = memo(SearchFilters);
const FilterDatasetsForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { disabled, onChangeFilter, datasets, datasetFolders } = props;

    const [searchInput, setSearchInput] = useState<string>('');

    // // define required ref
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const typeRef = useRef<FilterButtonRefType | null>(null);
    const datasetFolderlRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      const filtersButton: SearchFiltersButtonType[] = [];
      if (datasets && datasets.length > 0) {
        const types = Array.from(new Set(datasets.filter((d) => d.type).map((d) => d.type ?? '')));
        filtersButton.push({
          ref: typeRef,
          text: <FormattedMessage id="type" defaultMessage="Type" />,
          inputName: 'type',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'type', defaultMessage: 'Type' })}
              list={types.map((type) => ({
                key: type,
                value: type,
              }))}
            />
          ),
        });
      }
      if (datasetFolders && datasetFolders.length > 0) {
        // const labels = datasetFolders.map((df) => {id:df.id, label:df.label});
        filtersButton.push({
          ref: datasetFolderlRef,
          text: <FormattedMessage id="folder" defaultMessage="Folder" />,
          inputName: 'datasetFolder',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'folder', defaultMessage: 'Folder' })}
              // list={labels.map((label) => ({
              //   key: label.id,
              //   value: label.label,
              // }))}
              list={datasetFolders.map((folder) => ({
                key: String(folder.id),
                value: folder.label,
              }))}
            />
          ),
        });
      }
      return filtersButton;
    }, [intl, datasets, datasetFolders]);

    const onActivate = useCallback(
      (searchTerm?: string, debounce: boolean = false) => {
        const selectedFilters: SelectedFiltersDatasetsValues = {
          search: searchTerm ?? searchInputRef.current?.value ?? '',
          type: typeRef.current?.getValue().values || [],
          datasetFolder: datasetFolderlRef.current?.getValue().values || [],
        };
        onChangeFilter(selectedFilters, debounce);
      },
      [onChangeFilter]
    );

    const updateSearchInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        onActivate(searchTerm, searchTerm ? true : false);
      },
      [onActivate]
    );

    const clearSearchInput = useCallback(() => {
      const searchTerm = '';
      setSearchInput(searchTerm);

      onActivate(searchTerm);
    }, [onActivate]);

    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    return (
      <Box width={'100%'} display={'flex'} px={0} py={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                ref={searchInputRef}
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                disabled={disabled}
                clearAction={clearSearchInput}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box display={'flex'} gap={'1rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
              <Typography sx={{ fontSize: 'var(--fs-14)', fontWeight: 500 }}>
                <FormattedMessage id="filterBy" defaultMessage={'Filter by'} />
              </Typography>
              <SearchFiltersMemo disabled={disabled} {...{ searchFiltersButtons, onActivate }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

export default FilterDatasetsForm;
