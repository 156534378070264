/**
 * ConfigProvider.ts
 * Read the configuration on file and provide the values
 */

/* packages */
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* models & types */
import { ConfigType, configUrl } from 'models/config';

interface ConfigContextType {
  config: ConfigType | null;
  readingConfig: boolean;
}

/* elements */
export const ConfigContext = createContext<ConfigContextType>({ config: null, readingConfig: true });

export const ConfigProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [config, setConfig] = useState<ConfigType | null>(null);
  const [readingConfig, setReadingConfig] = useState(true);

  const readConfig = useCallback(() => {
    setConfig(null);
    setReadingConfig(true);

    const controller = new AbortController();
    Axios.get(configUrl, {
      signal: controller.signal,
    })
      .then((response) => {
        const { data } = response;
        const configData = data as ConfigType;

        setConfig(configData);

        URLConstants.setURL(configData.apiUrl, configData.screenaUrl);

        setReadingConfig(false);
      })
      .catch((error) => {
        if (error.code === 'ERR_CANCELED') return;

        URLConstants.clearUrl();

        setReadingConfig(false);
      });

    return controller;
  }, []);

  // trigger configuration reading on startup
  useEffect(() => {
    const axiosController = readConfig();

    return () => {
      axiosController.abort();
    };
  }, [readConfig]);

  // memo the output value
  const configContextValue = useMemo(
    () => ({
      config,
      readingConfig,
    }),
    [config, readingConfig]
  );

  return <ConfigContext.Provider value={configContextValue}>{children}</ConfigContext.Provider>;
};
