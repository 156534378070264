/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useRef, useMemo, useEffect, memo, PropsWithoutRef, forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { VariableSizeList as VirtualList, ListChildComponentProps } from 'react-window';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';

/* utilities */
// import { searchConstant } from 'models/searchDatasets';

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterVirtualCheckboxesProps {
  title: string;
  list: { key: string; value: string }[];
  currentValue?: FilterValueType;
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  algoFields?: string[];
  algoValue?: string[];
}

interface VirtualListElements {
  list: FilterVirtualCheckboxesProps['list'];
  checked: boolean;
  setRowHeight(key: string, size: number): void;
  checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void;
}

interface RowContentProps {
  itemKey: string;
  itemValue: string;
  checked: boolean;
  checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void;
}

interface AlgoSelectionType {
  [key: string]: { checked: boolean; value: string };
}

interface ResetAlgoInput {
  currentAlgo?: { [key: string]: string };
  algoFields: string[];
  algoValue: string[];
}

/* elements */
const resetAlgo = ({ currentAlgo, algoFields, algoValue }: ResetAlgoInput) => {
  const initAlgo = algoFields.reduce((acc, cf) => ({ ...acc, [cf]: { checked: false, value: algoValue[0] } }), {} as AlgoSelectionType);

  if (currentAlgo) {
    Object.entries(currentAlgo).forEach(([algo, state]) => {
      if (Object.keys(initAlgo).includes(algo)) {
        initAlgo[algo].checked = true;
        initAlgo[algo].value = state;
      }
    });
  }
  return initAlgo;
};

const parseAlgoSelected = (algoSelected: AlgoSelectionType) => {
  return Object.entries(algoSelected)
    .filter(([_, state]) => state.checked)
    .reduce((acc, [algo, state]) => ({ ...acc, [algo]: state.value }), {});
};

const CountryFilterVirtualCheckboxes = ({ title, list, currentValue, setNewFilterValue, triggerSetUpdate, algoFields, algoValue }: FilterVirtualCheckboxesProps) => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<string[]>(currentValue?.values ?? []);

  const [algoSelected, setAlgoSelected] = useState<AlgoSelectionType>(() => {
    return resetAlgo({ currentAlgo: currentValue?.algo, algoFields: algoFields ?? [], algoValue: algoValue ?? [] });
  });

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValues, selectedAlgo: FilterValueType['algo']) => {
      if (setNewFilterValue) setNewFilterValue({ nbValues: newValue.length, values: newValue, algo: selectedAlgo });
    },
    [setNewFilterValue]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValues = [];
    setSelectedValues(newValue);

    const newAlgos = resetAlgo({
      currentAlgo: undefined,
      algoFields: algoFields ?? [],
      algoValue: algoValue ?? [],
    });
    setAlgoSelected(newAlgos);

    applyFilterValue(newValue, parseAlgoSelected(newAlgos));
  };

  const checkboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setSelectedValues((currentValues) => {
      if (checked) return [...currentValues, checkboxName];
      else return currentValues.filter((cv) => cv !== checkboxName);
    });
  }, []);

  const selectCountryField = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setAlgoSelected((currentFields) => ({ ...currentFields, [checkboxName]: { ...currentFields[checkboxName], checked: checked } }));
  }, []);

  const setAlgoForCountry = useCallback((event: SelectChangeEvent<unknown>) => {
    const selectName = event.target.name;
    const selectValue = event.target.value as string;
    setAlgoSelected((currentFields) => ({ ...currentFields, [selectName]: { ...currentFields[selectName], value: selectValue } }));
  }, []);

  const hasAlgoSelected: boolean = Object.values(algoSelected).findIndex((v) => v.checked) >= 0;
  const hasValueSelected: boolean = selectedValues.length > 0;

  // propagate on value change
  const validateSelection = useCallback(() => {
    if (hasAlgoSelected !== hasValueSelected) return;

    const selectedAlgo = parseAlgoSelected(algoSelected);
    applyFilterValue(selectedValues, selectedAlgo);
    // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues, algo: algoSelected });
  }, [applyFilterValue, algoSelected, selectedValues, hasAlgoSelected, hasValueSelected]);

  // trigger automatic state update
  useEffect(() => {
    if (triggerSetUpdate && hasAlgoSelected) {
      validateSelection();
    }
  }, [triggerSetUpdate, applyFilterValue, hasAlgoSelected, validateSelection]);

  const searchInputLower = searchInput.toLowerCase();
  const virtualList = list.filter((l) => {
    if (selectedValues.includes(l.key)) return false;
    if (searchInput) return l.value.toLowerCase().includes(searchInputLower);
    return true;
  });

  const selectedVirtualList = useMemo(
    () =>
      list.filter((l) => {
        return selectedValues.includes(l.key);
      }),
    [list, selectedValues]
  );

  return (
    <Box className="custom-scrollbar" borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5, maxHeight: '60vh', overflowY: 'auto' }}>
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValues.length <= 0}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton> */}

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        {/* <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={hasAlgoSelected !== hasValueSelected}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button> */}
      </Box>

      {/* algo selection */}
      {algoFields && algoValue && (
        <Box py={0.5} display="grid" gap={0.5} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          {algoFields.map((af) => {
            const checked = algoSelected[af].checked;
            return (
              <Box key={af}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      name={af}
                      color={'primary'}
                      checked={checked}
                      onChange={selectCountryField}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        py: 1,
                        borderRadius: '5px 0 0 5px',
                        '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
                        '&:hover': { backgroundColor: 'var(--color-hover-background)' },
                      }}
                    />
                  }
                  label={
                    <Typography display="flex" alignItems="center" flex={1} fontSize={'.875rem'} py={0.5} sx={{ color: 'var(--color-gray2)', borderRadius: '0 5px 5px 0' }}>
                      <FormattedMessage id={af} />
                    </Typography>
                  }
                  sx={{ width: '100%', display: 'flex', alignItems: 'stretch', ml: 0, mr: 0, px: 2, '&:hover > *': { backgroundColor: 'var(--color-hover-background)' } }}
                />

                {checked && (
                  <Box px={2} py={1} sx={{ background: 'vaf(--color-lightgray)', backgroundColor: '#F7F8FA', fontSize: '.8rem' }}>
                    {/* <Select name={af} fullWidth size="small" value={algoSelected[af].value} onChange={setAlgoForCountry} sx={{ backgroundColor: 'white', color: 'inherit', fontSize: 'inherit' }}>
                      {algoValue.map((ca) => (
                        <MenuItem key={ca} value={ca} sx={{ fontSize: '.8rem' }}>
                          <FormattedMessage id={ca} />
                        </MenuItem>
                      ))}
                    </Select> */}

                    <SearchSelect
                      name={af}
                      fullWidth
                      size="small"
                      value={algoSelected[af].value}
                      onChange={setAlgoForCountry}
                      placeholder={intl.formatMessage({ id: 'selectAlgorithm' })}
                      choices={algoValue.map((av) => ({ key: av, value: <FormattedMessage id={av} /> }))}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box px={2} py={0.5} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        <SearchText
          variant="standard"
          fullWidth
          value={searchInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(event.target.value);
          }}
          placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
          inputProps={{ name: 'searchInput' }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{ pl: 1 }}
          adormentSize={14}
        />
      </Box>

      <Box px={2} py={1} display={'flex'} flexDirection={'column'}>
        {selectedValues.length > 0 && (
          <>
            <Typography color={'darkgray'} flex={1} fontSize={14} px={1} py={0.5} fontWeight={500} textAlign={'left'}>
              <FormattedMessage id="selected" defaultMessage={'Selected'} />
            </Typography>

            <MemoizedVirtualList virtualList={selectedVirtualList} checked={true} checkboxChanged={checkboxChanged} />
          </>
        )}
        <Typography color={'darkgray'} flex={1} fontSize={14} px={1} py={0.5} fontWeight={500} textAlign={'left'}>
          <FormattedMessage id="all" defaultMessage={'All'} />
        </Typography>

        <MemoizedVirtualList virtualList={virtualList} checked={false} checkboxChanged={checkboxChanged} />

        {virtualList.length === 0 && (
          <Box px={2}>
            <Typography fontSize={'.875rem'} sx={{ color: 'var(--color-gray2)' }}>
              <FormattedMessage id="noMatch" defaultMessage={'No match'} />
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          fullWidth
          className="no-hover"
          // variant="text"
          onClick={clearFilter}
          size="small"
          disabled={selectedValues.length <= 0}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#F8F6F6',
              color: '#C9C8C7',
              borderColor: '#F8F6F6',
              boxShadow: 'none',
            },
            // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
          }}
        >
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

const itemSize = 37;
const listMaxHeight = 150;
const virtualWidth = 250;
const rowHeights: { [key: string]: number } = {};

const MemoizedVirtualList = memo(
  ({
    virtualList,
    checked,
    checkboxChanged,
  }: PropsWithoutRef<{ virtualList: FilterVirtualCheckboxesProps['list']; checked: boolean; checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void }>) => {
    const listRef = useRef<VirtualList<VirtualListElements> | null>(null);

    const getRowHeight = useCallback(
      (index: number) => {
        const key = virtualList[index].key;
        return rowHeights[key] ?? itemSize;
      },
      [virtualList]
    );

    const setRowHeight = useCallback((key: string, size: number) => {
      if (listRef.current) listRef.current.resetAfterIndex(0);
      if (!(key in rowHeights)) {
        rowHeights[key] = size;
      }
    }, []);

    const nbVirtualItems = virtualList.length;

    const getHeight = useMemo(() => {
      const minNbItems = 5;
      const initElements = virtualList.slice(0, minNbItems).map((el) => rowHeights[el.key] ?? itemSize);
      const initSize = initElements.reduce((acc, cur) => acc + cur, 0);
      return Math.min(listMaxHeight, initSize);
    }, [virtualList]);

    return (
      <VirtualList
        className="custom-scrollbar"
        ref={listRef}
        itemData={{ list: virtualList, checked, setRowHeight, checkboxChanged }}
        height={getHeight}
        itemSize={getRowHeight}
        width={virtualWidth}
        itemCount={nbVirtualItems}
        overscanCount={10}
      >
        {RenderRow}
      </VirtualList>
    );
  }
);

const RenderRow = (props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const { list, checked, setRowHeight, checkboxChanged } = data as VirtualListElements;
  const labelRef = useRef<HTMLSpanElement | null>(null);

  const item = list[index];

  useEffect(() => {
    if (labelRef.current) {
      setRowHeight(item.key, labelRef.current.clientHeight);
    }
  }, [index, labelRef, setRowHeight, item.key, item.value]);

  const rowContentData = { itemKey: item.key, itemValue: item.value, checked, checkboxChanged };
  return (
    <div className="row-container" style={{ ...style, display: 'flex' }}>
      <RowContent ref={labelRef} {...rowContentData} />
    </div>
  );
};

const RowContent = memo(
  forwardRef<HTMLSpanElement, RowContentProps>(({ itemKey, itemValue, checked, checkboxChanged }, ref) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={itemKey}
            color={'primary'}
            checked={checked}
            onChange={checkboxChanged}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              py: 1,
              '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
              '&:hover': { backgroundColor: 'var(--color-hover-background)' },
            }}
          />
        }
        label={
          <Typography ref={ref} fontSize={'.875rem'} pt={1} pb={1} sx={{ color: 'var(--color-gray2)' }}>
            {itemValue}
          </Typography>
        }
        className="rounded-border"
        sx={{
          ml: 0,
          mr: 0,
          '&:hover': { backgroundColor: 'var(--color-hover-background)' },
          '& .MuiFormControlLabel-label': { display: 'flex' },
        }}
      />
    );
  })
);

export default CountryFilterVirtualCheckboxes;
