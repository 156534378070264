import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PlayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.2" viewBox="0 0 16 19.2">
        <path d="M8.743,1.429a1,1,0,0,1,1.715,0l7.834,13.056A1,1,0,0,1,17.434,16H1.766a1,1,0,0,1-.857-1.514Z" transform="translate(16) rotate(90)" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};
