/**
 * NotificationsPanel.tsx
 * Display a the notification panel
 */

/* packages */
import React, { useState, createContext, useContext, memo, useCallback, useMemo, PropsWithChildren } from 'react';

/* contexts */
import { NotificationsContext } from 'contextProviders/NotificationsProvider';

/* components */
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import NotificationsContent from './NotificationsContent';

/* types */
type SetNotificationsT = () => void;

interface NotificationsContextT {
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}

/* elements */
// contexts
const NotificationsPanelContext = createContext<NotificationsContextT>({ showNotifications: false, setShowNotifications: () => {} });
const NotificationSetContext = createContext<SetNotificationsT>(() => {});

const NotificationsPanel = () => {
  const { showNotifications, setShowNotifications } = useContext(NotificationsPanelContext);

  return (
    <RightSidePanel
      open={showNotifications}
      onClose={() => {
        setShowNotifications(false);
      }}
    >
      <NotificationsContent setShowNotifications={setShowNotifications} />
    </RightSidePanel>
    // <Drawer
    //   anchor={'right'}
    //   open={showNotifications}
    //   onClose={() => {
    //     setShowNotifications(false);
    //   }}
    //   sx={{
    //     '& .MuiModal-backdrop': {
    //       backgroundColor: 'transparent',
    //     },
    //   }}
    //   PaperProps={{
    //     elevation: 0,
    //     sx: {
    //       backgroundColor: 'white',
    //       borderLeft: '1px solid var(--color-grayHeaderBorder)',
    //     },
    //   }}
    // >
    //   <NotificationsContent setShowNotifications={setShowNotifications} />
    // </Drawer>
  );
};

export const RightSidePanel = memo((props: PropsWithChildren<DrawerProps>) => {
  const { children, ...drawerProps } = props;

  return (
    <Drawer
      anchor={'right'}
      {...drawerProps}
      sx={{
        ...drawerProps.sx,
        '& .MuiModal-backdrop': {
          backgroundColor: 'transparent',
        },
      }}
      PaperProps={{
        ...drawerProps?.PaperProps,
        elevation: 0,
        sx: {
          ...drawerProps?.PaperProps?.sx,
          backgroundColor: 'white',
          borderLeft: '1px solid var(--color-grayHeaderBorder)',
        },
      }}
    >
      {children}
    </Drawer>
  );
});

// snackbar providers
const NotificationSetProvider = memo(
  ({
    setNotifications,
    children,
  }: React.PropsWithChildren<{
    setNotifications: NotificationsContextT['setShowNotifications'];
  }>) => {
    const { setNotificationPanelOpen } = useContext(NotificationsContext);

    const showNotifications = useCallback(() => {
      setNotifications(true);
      setNotificationPanelOpen?.();
    }, [setNotifications, setNotificationPanelOpen]);

    return <NotificationSetContext.Provider value={showNotifications}>{children}</NotificationSetContext.Provider>;
  }
);

const NotificationsPanelProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  const notificationsContextValue = useMemo(
    () => ({
      showNotifications,
      setShowNotifications,
    }),
    [showNotifications, setShowNotifications]
  );

  return (
    <NotificationsPanelContext.Provider value={notificationsContextValue}>
      <NotificationSetProvider setNotifications={setShowNotifications}>
        {children}
        <NotificationsPanel />
      </NotificationSetProvider>
    </NotificationsPanelContext.Provider>
  );
};

// export hooks
const useNotifications = () => useContext(NotificationSetContext);

// exports
export { NotificationsPanelProvider };
export { useNotifications };
