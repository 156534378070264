/**
 * UnreadAlertsProvider.ts
 * Provide number of unread alerts
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
/* types */
// import { CreateSavedSearchQuery, SavedSearchResponse, SearchFilterType } from 'models/savedSearch';

interface UnreadAlertsContextType {
  // statusQueried: boolean;
  loadingUnread: boolean;
  nbUnread: number;
  listUnread?(abortController?: AbortController): void;
}
/* elements */
const UnreadAlertsContext = createContext<UnreadAlertsContextType>({
  // statusQueried: false,
  loadingUnread: false,
  nbUnread: 0,
  listUnread: undefined,
});

const UnreadAlertsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingUnread, setLoadingUnread] = useState<boolean>(false);
  const [nbUnread, setNbUnread] = useState<number>(0);

  const listUnreadOngoing = useRef(false);
  const listUnread = useCallback(
    async (abortController?: AbortController) => {
      if (listUnreadOngoing.current) return;

      // check permissions
      const allowed = checkPermissions('investigateAlerts', permissions);
      if (!allowed) return;
      if (!currentUserId) return;

      listUnreadOngoing.current = true;
      setLoadingUnread(true);
      // setNbUnread(0);
      try {
        const listUrl = URLConstants.alertUnreadCount;
        const results = (await getAuthenticatedRequest(listUrl, abortController ?? undefined)) as { amountOfUnreadAlerts: number };

        // // setStatusQueried(true);
        setLoadingUnread(false);
        setNbUnread(results.amountOfUnreadAlerts ?? 0);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') {
          listUnreadOngoing.current = false;
          return;
        }

        // setStatusQueried(true);
        setLoadingUnread(false);
        setNbUnread(0);
      }
      listUnreadOngoing.current = false;
    },
    [permissions, getAuthenticatedRequest, currentUserId]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listUnread();
  }, [currentUserId, listUnread]);

  const outputValue = useMemo(
    (): UnreadAlertsContextType => ({
      // statusQueried,
      loadingUnread,
      nbUnread,
      listUnread,
    }),
    [loadingUnread, nbUnread, listUnread]
  );
  return <UnreadAlertsContext.Provider value={outputValue}>{children}</UnreadAlertsContext.Provider>;
};

/* exports */
export { UnreadAlertsContext, UnreadAlertsProvider };
