/**
 * ListSchedules.tsx
 */
/* packages */
import { useContext, useState, useMemo, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import { styled } from '@mui/material/styles';
import cronstrue from 'cronstrue';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Loader from 'components/Loader/Loader';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';
import StatusTag from 'components/StatusTag/StatusTag';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ArrowDownSolidIcon } from 'icons/arrowDownSolid/arrowDownSolid';
import { GarbageIcon } from 'icons/garbage/garbage';
import { StopIcon } from 'icons/stop/stop';
import { PlayIcon } from 'icons/play/play';

/* utilities */
import { displayWarning } from 'utilities/Logger';
import { GetUser } from 'components/GetUser/GetUser';

/* types */
import { JobType } from 'models/job';
import { ContentWithDarkTooltip, UnknownMessage } from 'components/InvestigateSearch/utils';
import { StyledOrganizationElement } from 'pages/manage/ManageSetupOrganizations';

interface ListSchedulesProps {
  searching: ReturnType<typeof setTimeout> | null;
  filtered: boolean;
  schedules?: JobType[];
  handleCreateSchedule(chedule?: JobType): void;
  handleDeleteSchedule(schedule: JobType): void;
  handleRunSchedule(schedule: JobType, run: 'start' | 'stop'): void;
}

interface DisplayScheduleProps {
  schedule: JobType;
  handleCreateSchedule: ListSchedulesProps['handleCreateSchedule'];
  handleDeleteSchedule: ListSchedulesProps['handleDeleteSchedule'];
  handleRunSchedule: ListSchedulesProps['handleRunSchedule'];
}

/* elements */
const DEFAULTCOLUMNWIDTH = '180px';
const COLUMNTEMPLATE = 'minmax(300px, 1fr) minmax(230px, 1fr) minmax(150px, 1fr) repeat(2, minmax(180px, 1fr)) minmax(180px, 1fr) 100px';

const getSortedSchedules = (schedules: JobType[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!schedules) return schedules;

  if (!sortedDirection || !sortedDirection) {
    return schedules;
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;
  switch (sortedColumn) {
    case 'name':
      return schedules.sort((s1, s2) => direction * (s1.name ?? '').localeCompare(s2.name ?? ''));
    case 'createdon':
      return schedules.sort((s1, s2) => direction * (s1.creationDate ?? '').localeCompare(s2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return schedules;
  }
};

const ListSchedules = memo(({ searching, filtered, schedules, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }: ListSchedulesProps) => {
  const intl = useIntl();

  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'name',
          defaultMessage: 'Name',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'name' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('name');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'name' ? sortedDirection : false,
        minWidth: '250px',
      },
      {
        id: 'cron',
        label: intl.formatMessage({
          id: 'cron',
          defaultMessage: 'Cron',
        }),
        minWidth: '200px',
      },
      {
        id: 'active',
        label: intl.formatMessage({
          id: 'active',
          defaultMessage: 'Active',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'active' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('active');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'active' ? sortedDirection : false,
        minWidth: '150px',
      },
      {
        id: 'createdby',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created by',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
      },
      {
        id: 'createdon',
        label: intl.formatMessage({
          id: 'createdon',
          defaultMessage: 'Created on',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
        sorted: () => {
          const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdon' ? sortedDirection : false,
      },
      {
        id: 'nextExecution',
        label: intl.formatMessage({
          id: 'nextExecution',
          defaultMessage: 'Next execution',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
        sorted: () => {
          const newDirection = sortedColumn !== 'nextExecution' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('nextExecution');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'nextExecution' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  if (searching || !schedules) {
    return (
      <Box pt={'30vh'}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box mt={2}>
      {!schedules || schedules.length <= 0 ? (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            {filtered ? (
              <FormattedMessage id="noMatchedSchedules" defaultMessage={'No schedule matches your search'} />
            ) : (
              <FormattedMessage id="noSchedule" defaultMessage={'No schedule has been defined'} />
            )}
          </Typography>
        </Box>
      ) : (
        <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={COLUMNTEMPLATE} sx={{ overflowX: 'auto' }}>
          {tableHeaders.map((th, thIndex) => {
            return (
              <Box
                key={th.id}
                className={`header-element with-border noBottomRadius ${th.sorted ? 'has-sorting' : ''} ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                {...(th.sorted
                  ? {
                      onClick: th.sorted,
                    }
                  : {})}
              >
                {th.label.toUpperCase()}{' '}
                {th.sorted &&
                  (th.direction ? (
                    <ArrowDownSolidIcon sx={{ fontSize: 10, color: 'inherit', rotate: th.direction === 'asc' ? '180deg' : '0deg' }} />
                  ) : (
                    <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                  ))}
              </Box>
            );
          })}

          {/* root dataset */}
          {getSortedSchedules(schedules, sortedColumn, sortedDirection)?.map((schedule) => {
            return <DisplaySchedule key={schedule.id} {...{ schedule, sortedColumn, sortedDirection, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }} />;
          })}
        </Box>
      )}
    </Box>
  );
});

// const StyledScheduleElement = styled(Box)(({ theme }) => ({
//   color: 'var(--color-darkgray)',
//   fontSize: 'var(--fs-14)',
//   padding: theme.spacing(2),
//   wordBreak: 'break-word',
// }));

const StyledScheduleElement = StyledOrganizationElement;

const DisplaySchedule = memo(({ schedule, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }: DisplayScheduleProps) => {
  // const borderKey = topBorderOnly ? 'borderTop' : 'border';
  const intl = useIntl();
  const { allUsers } = useContext(AllUsersContext);

  let cronText = '';
  try {
    cronText = cronstrue.toString(schedule.cronExpression ?? '', {
      verbose: true,
      use24HourTimeFormat: true,
    });
  } catch {}

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handleCreateSchedule(schedule);
      }}
      sx={{ gridColumn: '1/-1', cursor: 'pointer', borderBottom: '1px solid var(--color-grayHeaderBorder)', '&:hover': { backgroundColor: 'var(--color-lightgray2)' } }}
    >
      {/* sx={{ borderRadius: '5px', [borderKey]: '1px solid var(--color-grayHeaderBorder)' }} */}
      <Box display={'grid'} gridTemplateColumns={COLUMNTEMPLATE} alignItems={'center'} sx={{ borderInline: '1px solid var(--color-grayHeaderBorder)' }}>
        <StyledScheduleElement>
          <span>{schedule.name}</span>
        </StyledScheduleElement>
        <StyledScheduleElement>
          <ContentWithDarkTooltip tooltip={schedule.cronExpression} placement="top">
            <span>{cronText}</span>
          </ContentWithDarkTooltip>
        </StyledScheduleElement>
        <StyledScheduleElement>
          <StatusTag
            active={true}
            activeColor={schedule.active ? 'var(--color-azure)' : 'var(--color-lightgray4)'}
            tag_label={schedule.active ? intl.formatMessage({ id: 'active', defaultMessage: 'Active' }) : intl.formatMessage({ id: 'unactive', defaultMessage: 'Unactive' })}
          />
        </StyledScheduleElement>
        <StyledScheduleElement>{GetUser(schedule.createdBy ?? '', allUsers, true, '20px')}</StyledScheduleElement>
        <StyledScheduleElement>{schedule.creationDate && <DisplayDate date={schedule.creationDate} />}</StyledScheduleElement>
        <StyledScheduleElement>{schedule.nextExecutionTime ? <DisplayDate date={schedule.nextExecutionTime} /> : <UnknownMessage />}</StyledScheduleElement>
        <StyledScheduleElement sx={{ textAlign: 'right' }}>
          <Box display="flex" columnGap={'10px'} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <IconButton
              className="square-icon-button"
              sx={{
                fontSize: 'var(--fs-14)',
                color: schedule.isRunning ? 'var(--color-azure)' : 'var(--color-lightgray4)',
                '&:hover': {
                  color: schedule.isRunning ? 'var(--color-fushia)' : 'var(--color-azure)',
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                const runParam = schedule.isRunning ? 'stop' : 'start';
                handleRunSchedule(schedule, runParam);
              }}
            >
              {schedule.isRunning ? <StopIcon fontSize="inherit" sx={{ color: 'inherit' }} /> : <PlayIcon fontSize="inherit" sx={{ color: 'inherit' }} />}
            </IconButton>

            <IconButton
              sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', borderRadius: '5px', '&:hover': { backgroundColor: 'var(--color-hover-button)', color: 'var(--color-fushia)' } }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDeleteSchedule(schedule);
              }}
            >
              <GarbageIcon fontSize="inherit" sx={{ color: 'inherit' }} />
            </IconButton>
          </Box>
        </StyledScheduleElement>
      </Box>
    </Box>
  );
});

export default ListSchedules;
