/**
 * GenericError.tsx
 * display a generic error message
 */
/* components */
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import DisplayBrandLogo from 'utilities/DisplayBrandLogo';

import { WarningIcon } from 'icons/warning/warning';
import { useTheme } from '@mui/material/styles';

const ConfigError = ({ message }: React.PropsWithChildren<{ message: string }>) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={3}
      alignItems="center"
      className="bckg-lightgray"
      sx={{
        py: 5,
        px: 3,
      }}
    >
      <DisplayBrandLogo full className="in-page-logo" />
      <Alert
        icon={<WarningIcon fontSize="inherit" />}
        sx={{
          color: 'white',
          backgroundColor: theme.palette.lightRed.main,
        }}
      >
        {message}
      </Alert>
    </Stack>
  );
};

export default ConfigError;
