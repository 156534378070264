import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CheckFilledIcon = (props: SvgIconProps & { backgroundColor?: string }) => {
  const { backgroundColor, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps}>
      <svg style={{ padding: '.2em', backgroundColor: backgroundColor ?? 'white', borderRadius: '50%' }} xmlns="http://www.w3.org/2000/svg" width="22.5" height="16.779" viewBox="0 0 22.5 16.779">
        <path
          d="M-6287.58-7545.33l12.938-12.937a1.125,1.125,0,0,0,0-1.591l-1.591-1.591a1.125,1.125,0,0,0-1.591,0l-10.551,10.551-4.926-4.926a1.126,1.126,0,0,0-1.591,0l-1.591,1.591a1.125,1.125,0,0,0,0,1.591l7.313,7.313A1.125,1.125,0,0,0-6287.58-7545.33Z"
          transform="translate(6296.813 7561.778)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
