/**
 * AlertScreening.tsx
 * delete alert screening status modal
 */
/* packages */
import { useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { AlertScreeningType } from 'models/alertScreening';

/* types */
interface DeleteAlertScreeningProps {
  alertScreening: AlertScreeningType;
  removeAlertScreening(alertScreeningId: number): Promise<StatusPromiseResponse>;
}

const DeleteAlertScreening = memo(({ alertScreening, removeAlertScreening }: DeleteAlertScreeningProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingAlertScreening, setDeletingAlertScreening] = useState(false);

  const deleteAlertScreening = useCallback(async () => {
    blockModal?.(true);
    setDeletingAlertScreening(true);

    const result = await removeAlertScreening(alertScreening.id);
    setDeletingAlertScreening(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteAlertScreeningSuccess',
        defaultMessage: 'Alert settings deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteAlertScreeningError',
        defaultMessage: 'An error occured while deleting the alert settings',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, alertScreening, removeAlertScreening, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Alert screening: {alertScreening.dataId || alertScreening.id}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteAlertScreeningMessage" defaultMessage={'Are you sure that you want to delete these alert settings?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingAlertScreening ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingAlertScreening} onClick={deleteAlertScreening} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default DeleteAlertScreening;
