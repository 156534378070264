/**
 * ManageAudits.tsx
 * Manage Audit page
 */
/* packages */
import { memo, useCallback, useRef, useState, forwardRef, useMemo, useContext, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';

/* hooks */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { SelectChangeEvent } from '@mui/material';

import TitleLayout from 'components/Layouts/TitleLayout';
import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import DateRangeFilter from 'components/SearchFilters/DateRangeFilter';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import Pagination, { defaultNumberPages } from 'components/Pagination/Pagination';
import Loader from 'components/Loader/Loader';
import DisplayDate from 'components/DisplayDate/DisplayDate';

import ReloadButton from 'components/ReloadButton/ReloadButton';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';
import DisplayAuditResults from 'components/ManageAudits/DisplayAuditResults';
import { TableRowData } from 'components/TableResults/TableRow';

import StatusTag from 'components/StatusTag/StatusTag';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { GetUser } from 'components/GetUser/GetUser';
import { ActiveStatusColors } from 'models/audit';

/* types */
import { Audit, SearchAuditsPayload, SearchAuditsResponse } from 'models/audit';

// import { displayError } from 'utilities/Logger';

export interface AuditList {
  audits: Audit[];
  nbAudits: number;
}
interface ManageAuditsContentProps {
  listAudits(type: string, payload: SearchAuditsPayload, abortController?: AbortController): Promise<AuditList>;
}
interface SearchValues {
  currentPage?: number;
  maxPerPage?: string;
  sortedColumn?: string;
  sortedDirection?: SortedDirectionType;
  searchInput?: string;
  dates?: [Dayjs, Dayjs];
  status?: string[];
}
interface SearchFormProps {
  submitAction(params: SearchValues): void;
  makingSearch: boolean;
}
interface DisplaySearchResultsProps extends AuditList {
  activeTab: string;
  sortedColumn: string;
  sortedDirection: SortedDirectionType;
  makeSortedSearch(columnId: string, columnDirection: SortedDirectionType): Promise<void>;
  currentPage: number;
  maxPerPage: string;
  setMaxAndSearch(event: SelectChangeEvent): void;
  changePage(shift: 1 | -1, nbAudits: number, currentPage: number, maxPerPage: string): void;
}
/* elements */
const ManageAudits = () => {
  const intl = useIntl();
  const { postAuthenticatedRequest } = useAuthenticatedRequest();

  const [initialLoading, setInitialLoading] = useState(true);
  const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);

  const listAudits = useCallback(
    async (type: string, payload: SearchAuditsPayload, abortController?: AbortController): Promise<AuditList> => {
      try {
        const auditUrl = URLConstants.auditList.replace('*auditType*', type);
        // const auditCountUrl = URLConstants.auditCount.replace('*auditType*', type);
        // const [auditResults, countResults] = (await Promise.all([
        //   postAuthenticatedRequest(auditUrl, payload, abortController ?? undefined),
        //   getAuthenticatedRequest(auditCountUrl, abortController ?? undefined),
        // ])) as [SearchAuditsResponse, SearchAuditsCountResponse];

        const auditResults = (await postAuthenticatedRequest(auditUrl, payload, abortController ?? undefined)) as SearchAuditsResponse;

        if (!auditResults.audits) {
          throw Error('invalid');
        }
        return {
          audits: auditResults.audits,
          nbAudits: auditResults.numberOfObjects,
          // nbAudits: countResults.numberOfObjects ?? auditResults.audits.length,
        };
      } catch (searchError: any) {
        throw searchError;
      }
    },
    [postAuthenticatedRequest]
  );

  const load = useCallback(() => {
    listAllUsers?.();
  }, [listAllUsers]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const loading = initialLoading || loadingAllUsers;

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageAuditsTitle', defaultMessage: 'Audits' })}>
      {loading ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <ManageAuditsContent {...{ listAudits }} />
      )}
    </TitleLayout>
  );
};

const DEFAULTMAXPERPAGE = defaultNumberPages;
const ManageAuditsContent = memo(({ listAudits }: ManageAuditsContentProps) => {
  const [makingSearch, setMakingSearch] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('application');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(DEFAULTMAXPERPAGE));
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const [searchParams, setSearchParams] = useState<SearchValues>({});

  const [audits, setAudits] = useState<Audit[] | undefined>();
  const [nbAudits, setNbAudits] = useState<number>(0);

  const searchFormRef = useRef<HTMLFormElement>(null);

  const computeSearchPayload = useCallback(async (params: SearchValues): Promise<SearchAuditsPayload> => {
    const payload: SearchAuditsPayload = {
      pageNumber: params.currentPage ?? 0,
      maxPerPage: DEFAULTMAXPERPAGE,
    };

    const maxPage = Number(params.maxPerPage);
    if (!isNaN(maxPage)) payload.maxPerPage = maxPage;

    if (params.sortedColumn && params.sortedDirection) {
      payload.sortField = params.sortedColumn;
      payload.sortOrder = params.sortedDirection;
    }

    if (params.searchInput) {
      payload.keyword = params.searchInput.trim();
    }

    if (params.status) {
      payload.status = params.status as typeof payload.status;
    }

    if (params.dates) {
      payload.date1 = params.dates[0].format('DD/MM/YYYY');
      payload.date2 = params.dates[1].format('DD/MM/YYYY');
    }

    return payload;
  }, []);

  const listingAudits = useRef(false);
  const makeSearch = useCallback(
    async (params: SearchValues) => {
      if (listingAudits.current) return;

      listingAudits.current = true;
      setMakingSearch(true);

      const payload = await computeSearchPayload(params);
      setAudits(undefined);
      setNbAudits(0);

      try {
        const auditResponses = await listAudits(activeTab, payload);

        setAudits(auditResponses.audits);
        setNbAudits(auditResponses.nbAudits);
      } catch (listError) {
        console.error(listError);
      }

      setMakingSearch(false);
      listingAudits.current = false;
    },
    [activeTab, listAudits, computeSearchPayload]
  );

  const makeSortedSearch = useCallback(
    async (columnId: string, columnDirection: SortedDirectionType) => {
      setSortedColumn(columnId);
      setSortedDirection(columnDirection);

      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);

      let currentParams: SearchValues = {};
      await setSearchParams((params) => {
        const newParams = {
          ...params,
          currentPage: 0,
          sortedColumn: columnId,
          sortedDirection: columnDirection,
        };
        currentParams = newParams;
        return newParams;
      });

      if (currentParams.maxPerPage) {
        makeSearch(currentParams);
      }

      // makeSearch(undefined, newCurrentPage);
    },
    [makeSearch]
  );

  const setMaxAndSearch = useCallback(
    (event: SelectChangeEvent) => {
      const newMaxPerPage = event.target.value;
      if (newMaxPerPage === maxPerPage) return;

      const newCurrentPage = 0;

      const newParams = {
        ...searchParams,
        currentPage: newCurrentPage,
        maxPerPage: newMaxPerPage,
      };
      makeSearch(newParams);

      setMaxPerPage(newMaxPerPage);
      setCurrentPage(newCurrentPage);
      setSearchParams(newParams);

      // makeSearch(undefined, newCurrentPage, newMaxPerPage);
    },
    [makeSearch, maxPerPage, searchParams]
  );

  const changePage = useCallback(
    async (shift: 1 | -1, nbAudits: number, currentPage: number, maxPerPage: string) => {
      const newPage = currentPage + shift;

      // check page is in bounds
      if (newPage < 0) return;
      if (newPage > nbAudits / Number(maxPerPage)) return;

      setCurrentPage(newPage);

      const newParams = {
        ...searchParams,
        currentPage: newPage,
      };
      setSearchParams(newParams);
      makeSearch(newParams);

      // makeSearch(undefined, newPage, maxPerPage);
    },
    [makeSearch, searchParams]
  );

  const submitSearchForm = useCallback(
    (params: SearchValues) => {
      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);
      setMaxPerPage(String(DEFAULTMAXPERPAGE));

      setSortedColumn('');
      setSortedDirection(false);

      const allParams: SearchValues = {
        ...params,
        currentPage: 0,
        maxPerPage: String(DEFAULTMAXPERPAGE),
        sortedColumn: '',
        sortedDirection: false,
      };

      setSearchParams(allParams);

      makeSearch(allParams);
    },
    [makeSearch]
  );

  const handleTabChange = useCallback((event: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);

    // states updates & makesearch triggered by search forms activation
    // const newCurrentPage = 0;
    // setCurrentPage(newCurrentPage);

    // setSortedColumn('');
    // setSortedDirection(false)

    // makeSearch(newTab, newCurrentPage);
  }, []);

  const auditTabs = useMemo(
    () => [
      { text: 'General', value: 'application' },
      { text: 'Users', value: 'user' },
      { text: 'Tasks', value: 'job' },
      { text: 'Imports', value: 'import' },
      { text: 'Watchlists', value: 'watchlist' },
    ],
    []
  );

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      {/* search */}
      <SearchForm ref={searchFormRef} {...{ submitAction: submitSearchForm, makingSearch }} />

      {/* Search Tabs */}
      <TabsNavigation skipAll activeTab={activeTab} tabTitles={auditTabs} label={'audit category'} onChange={handleTabChange} disabled={makingSearch} />

      {makingSearch ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          {!audits ? (
            <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <ReloadButton
                onClick={() => {
                  makeSearch(searchParams);
                }}
              />
            </Box>
          ) : (
            <DisplaySearchResults {...{ activeTab, audits, nbAudits, sortedColumn, sortedDirection, makeSortedSearch, currentPage, maxPerPage, setMaxAndSearch, changePage }} />
          )}
        </>
      )}
    </Box>
  );
});

const SearchFiltersMemo = memo(SearchFilters);

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormProps>((props, ref) => {
    const intl = useIntl();
    const { submitAction, makingSearch } = props;

    const [searchInput, setSearchInput] = useState<string>('');

    // const labels = useMemo(() => Array.from(new Set(datasets?.map((ds) => ds.label).filter((c) => c ?? false))), [datasets]);

    // define required ref
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const dateRef = useRef<FilterButtonRefType | null>(null);
    const statusRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(
      () => [
        {
          ref: dateRef,
          text: <FormattedMessage id="date" defaultMessage="Date" />,
          inputName: 'date',
          filterContent: <DateRangeFilter title={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })} />,
        },
        {
          ref: statusRef,
          text: <FormattedMessage id="status" defaultMessage="Status" />,
          inputName: 'status',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
              list={[
                { key: 'failed', value: intl.formatMessage({ id: 'failed', defaultMessage: 'Failed' }) },
                { key: 'completed', value: intl.formatMessage({ id: 'completed', defaultMessage: 'Completed' }) },
              ]}
            />
          ),
        },
      ],
      [intl]
    );

    const onSubmit = useCallback(
      (event?: React.SyntheticEvent) => {
        event?.preventDefault();

        const searchValues: SearchValues = {};

        const searchInputValue = searchInputRef.current?.value ?? '';
        if (searchInputValue) searchValues.searchInput = searchInputValue;

        const dateValues = dateRef.current?.getValue();
        if (dateValues && dateValues?.nbValues > 0 && dateValues?.dayValue && dateValues?.dayEndValue) searchValues.dates = [dateValues?.dayValue, dateValues?.dayEndValue];

        const statusValues = statusRef.current?.getValue();
        if (statusValues && statusValues.nbValues > 0 && statusValues.values) searchValues.status = statusValues.values;

        submitAction(searchValues);
      },
      [submitAction]
    );

    const updateSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    }, []);
    const clearSearchInput = useCallback(() => {
      setSearchInput('');
    }, []);
    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);
    const searchBigInputProps = useMemo(() => {
      return { readOnly: makingSearch };
    }, [makingSearch]);

    return (
      <Box width={'100%'} display={'flex'} px={0} pb={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                ref={searchInputRef}
                fullWidth
                clearAction={clearSearchInput}
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                InputProps={searchBigInputProps}
                // disabled={makingSearch}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Box>
              <Button type="submit" variant="contained" disableElevation disabled={makingSearch} onClick={onSubmit} sx={{ textTransform: 'none' }}>
                <FormattedMessage id="searchButton" defaultMessage="Search" />
              </Button>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box>
              <SearchFiltersMemo disabled={makingSearch} {...{ searchFiltersButtons, onActivate: onSubmit }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

const SearchPagination = memo(Pagination);

const DisplaySearchResults = memo(
  ({ activeTab, audits, nbAudits, sortedColumn, sortedDirection, makeSortedSearch, currentPage, maxPerPage, setMaxAndSearch, changePage }: DisplaySearchResultsProps) => {
    const intl = useIntl();

    const { allUsers } = useContext(AllUsersContext);

    const [tableHeaders, tableColumnTemplate] = useMemo((): [TableHeadElement[], string] => {
      let headers: TableHeadElement[];
      let columnTemplate: string = '';
      switch (activeTab) {
        case 'user':
          headers = [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'action',
              label: intl.formatMessage({
                id: 'action',
                defaultMessage: 'Action',
              }),
              sorted: () => {
                makeSortedSearch?.('action', sortedColumn !== 'action' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'action' ? sortedDirection : false,
              minWidth: '200px',
            },
            {
              id: 'entity',
              label: intl.formatMessage({
                id: 'entity',
                defaultMessage: 'Entity',
              }),
              sorted: () => {
                makeSortedSearch?.('entity', sortedColumn !== 'entity' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'entity' ? sortedDirection : false,
              minWidth: '200px',
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('created', sortedColumn !== 'created' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'created' ? sortedDirection : false,
            },
          ];
          columnTemplate = '80px minmax(200px, 1fr) minmax(200px, 1fr) repeat(2, minmax(180px, 1fr))';
          break;
        case 'job':
        case 'watchlist':
          columnTemplate = '200px repeat(2, minmax(180px, 1fr)) repeat(3, minmax(180px, 1fr))';
          headers = [            
            {
              id: 'entity',
              label: intl.formatMessage({
                id: 'entity',
                defaultMessage: 'Dataset',
              }),
              sorted: () => {
                makeSortedSearch?.('entity', sortedColumn !== 'entity' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'entity' ? sortedDirection : false,
              minWidth: '200px',
            },
            {
              id: 'started',
              label: intl.formatMessage({
                id: 'started',
                defaultMessage: 'Started',
              }),
              sorted: () => {
                makeSortedSearch?.('started', sortedColumn !== 'started' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'started' ? sortedDirection : false,
              minWidth: '180px',
            },
            {
              id: 'ended',
              label: intl.formatMessage({
                id: 'ended',
                defaultMessage: 'Ended',
              }),
              sorted: () => {
                makeSortedSearch?.('ended', sortedColumn !== 'ended' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'ended' ? sortedDirection : false,
              minWidth: '180px',
            },
            {
              id: 'status',
              label: intl.formatMessage({
                id: 'status',
                defaultMessage: 'Status',
              }),
              minWidth: '180px',
            },
            {
              id: 'totalRecords',
              label: intl.formatMessage({
                id: 'totalRecords',
                defaultMessage: 'Total records',
              }),
             
            },
            {
              id: 'totalRecordsProcessed',
              label: intl.formatMessage({
                id: 'totalRecordsProcessed',
                defaultMessage: 'Total records processed',
              }),
             
            }
          ];
          break;
        case 'import':
          columnTemplate = '100px minmax(200px, 1fr) repeat(2, minmax(180px, 1fr)) 150px 150px 220px repeat(2, minmax(180px, 1fr))';
          headers = [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'action',
              label: intl.formatMessage({
                id: 'action',
                defaultMessage: 'Action',
              }),
              sorted: () => {
                makeSortedSearch?.('action', sortedColumn !== 'action' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'action' ? sortedDirection : false,
              minWidth: '200px',
            },
            {
              id: 'started',
              label: intl.formatMessage({
                id: 'started',
                defaultMessage: 'Started',
              }),
              sorted: () => {
                makeSortedSearch?.('started', sortedColumn !== 'started' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'started' ? sortedDirection : false,
              minWidth: '180px',
            },
            {
              id: 'ended',
              label: intl.formatMessage({
                id: 'ended',
                defaultMessage: 'Ended',
              }),
              sorted: () => {
                makeSortedSearch?.('ended', sortedColumn !== 'ended' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'ended' ? sortedDirection : false,
              minWidth: '180px',
            },
            {
              id: 'status',
              label: intl.formatMessage({
                id: 'status',
                defaultMessage: 'Status',
              }),
            },
            {
              id: 'totalRecords',
              label: intl.formatMessage({
                id: 'totalRecords',
                defaultMessage: 'Total records',
              }),
            },
            {
              id: 'totalRecordsProcessed',
              label: intl.formatMessage({
                id: 'totalRecordsProcessed',
                defaultMessage: 'Total records processed',
              }),
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              // minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              // minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('created', sortedColumn !== 'created' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'created' ? sortedDirection : false,
            },
          ];
          break;
        default:
          // application
          headers = [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'action',
              label: intl.formatMessage({
                id: 'action',
                defaultMessage: 'Action',
              }),
              sorted: () => {
                makeSortedSearch?.('action', sortedColumn !== 'action' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'action' ? sortedDirection : false,
              minWidth: '400px',
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('created', sortedColumn !== 'created' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'created' ? sortedDirection : false,
            },
          ];
          columnTemplate = '80px minmax(400px, 1fr) repeat(2, minmax(180px, 1fr))';
      }
      return [headers, columnTemplate];
    }, [intl, activeTab, sortedColumn, sortedDirection, makeSortedSearch]);

    const tableData = useMemo((): TableRowData[] => {
      return audits.map((audit, auditIndex) => ({
        id: audit.id ? String(audit.id) : String(auditIndex),
        columns: {
          id: audit.id ? String(audit.id) : String(auditIndex),
          action: audit.action ?? '',
          error: audit.error,
          entity: audit.entityDescription ?? '',
          status: audit.status ? <StatusTag tag_label={audit.status} active activeColor={ActiveStatusColors[audit.status]} hideBorder /> : '',
          started: audit.started ? <DisplayDate date={audit.started} /> : '',
          ended: audit.ended ? <DisplayDate date={audit.ended} /> : '',
          totalRecords: audit.totalRecords ?? '',
          totalRecordsProcessed: audit.totalRecordsProcessed ?? '',
          createdBy: audit.createdBy ? GetUser(audit.createdBy ?? '', allUsers) : '',
          createdOn: audit.created ? <DisplayDate date={audit.created} /> : '',
        },
      }));
    }, [audits, allUsers]);

    return (
      <Box mt={3}>
        {(nbAudits ?? 0) > 0 && audits && audits.length > 0 && (
          <DisplayAuditResults {...{ tableData, tableHeaders, tableColumnTemplate }} />
          // <Box>
          //   <TableResults hasBoxTitle={false} hasUnread={false} hasSelection={false} tableData={tableData} tableHead={tableHeaders} />
          // </Box>
        )}

        {audits && nbAudits > 0 && (
          <SearchPagination
            {...{
              maxPerPage: Number(maxPerPage),
              currentPage,
              nbResults: nbAudits ?? 0,
              setMaxAndCallback: setMaxAndSearch,
              changePageCallback: (shift) => {
                changePage(shift, nbAudits, currentPage, maxPerPage);
              },
            }}
          />
        )}
      </Box>
    );
  }
);

export default ManageAudits;
