import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ClockRotateLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M2.344,2.344,1.281,1.281A.751.751,0,0,0,0,1.809V5.25A.748.748,0,0,0,.75,6H4.191a.751.751,0,0,0,.531-1.281l-.963-.963a6,6,0,1,1,.812,9.169,1,1,0,1,0-1.147,1.641A8,8,0,1,0,2.344,2.344ZM8,4a.748.748,0,0,0-.75.75V8a.749.749,0,0,0,.219.531l2.25,2.25a.749.749,0,0,0,1.059-1.059L8.747,7.691V4.75A.748.748,0,0,0,8,4Z"
          fill="currentColor"
        />
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="18.667" height="16" viewBox="0 0 18.667 16">
        <path
          d="M12.167,4.5a8,8,0,0,0-8,8H1.5l3.458,3.458.062.124L8.611,12.5H5.944a6.252,6.252,0,1,1,1.831,4.391L6.513,18.153A8,8,0,1,0,12.167,4.5Zm-.889,4.444v4.444l3.8,2.258.64-1.076-3.111-1.849V8.944Z"
          transform="translate(-1.5 -4.5)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
