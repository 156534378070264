/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useCallback, useState, memo, useMemo, useEffect, Fragment, PropsWithChildren } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';

/* hooks */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { useAddModal } from 'contextProviders/ModalProvider';
// import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* components */
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material';

import TitleLayout from 'components/Layouts/TitleLayout';
import Loader from 'components/Loader/Loader';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import Pagination, { defaultNumberPages } from 'components/Pagination/Pagination';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { UnknownMessage } from 'components/InvestigateSearch/utils';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';

import { ValidatedIcon } from 'icons/validated/validated';
import { CrossFilledIcon } from 'icons/crossFilled/crossFilled';
import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ArrowDownSolidIcon } from 'icons/arrowDownSolid/arrowDownSolid';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import { AllowedLibrariesDisplay, AllowedLibrariesDisplayMap, ImpotLibraryResponse, LibraryColumnLabel, LibraryElement, LibraryName, LibraryResponse } from 'models/library';
import { StatusPromiseResponse } from 'models/utils';
import ImportLibrary from 'components/ManageLibraries/ImportLibrary';

interface ManageLibrariesContentProps {
  loadingLibrary: boolean;
  getlibrary(lib: LibraryName): void;
  library?: LibraryName;
  searchInput?: string;
  libraryData?: LibraryElement[];
  handleImportLibrary?(libraryName: LibraryName): void;
}

interface DisplayLibraryDataProps {
  libraryData?: ManageLibrariesContentProps['libraryData'];
  library?: ManageLibrariesContentProps['library'];
  searchInput?: ManageLibrariesContentProps['searchInput'];
  handleImportLibrary?: ManageLibrariesContentProps['handleImportLibrary'];
}

/* elements */

const ManageLibraries = () => {
  const intl = useIntl();
  const { getAuthenticatedRequest, postAuthenticatedRequest } = useAuthenticatedRequest();
  const { toggleModal } = useAddModal();

  const [library, setlibrary] = useState<LibraryName | undefined>(undefined);
  const [loadingLibrary, setLoadinglibrary] = useState<boolean>(false);
  const [libraryData, setLibraryData] = useState<LibraryElement[] | undefined>(undefined);

  const [searchInput, setSearchInput] = useState<string>('');

  const getlibrary = useCallback(
    async (lib: LibraryName) => {
      setLoadinglibrary(true);
      setLibraryData(undefined);
      try {
        const libraryUrl = URLConstants.getLibraryData + `${lib}`;
        const results = (await getAuthenticatedRequest(libraryUrl)) as LibraryResponse;

        const res = results.libraryData;
        if (res) {
          setLibraryData(res.map((r, rI) => ({ ...r, elementId: rI })));
        }
      } catch (searchError: any) {}
      setLoadinglibrary(false);
    },
    [getAuthenticatedRequest]
  );

  const exportLibrary = useCallback(
    async (libraryName: LibraryName, abortController?: AbortController): Promise<StatusPromiseResponse> => {
      if (!libraryName) return { status: 'error' };

      const exportUrl = URLConstants.exportLibraryData + `${libraryName}`;
      const filename = `${libraryName.replaceAll(' ', '_') ?? 'library'}.zip`;

      try {
        const response = (await getAuthenticatedRequest(exportUrl, undefined, true)) as BlobPart;

        const downloadUrl = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); //any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return { status: 'success' };
      } catch (ExportError) {
        return { status: 'error' };
      }
    },
    [getAuthenticatedRequest]
  );

  const importLibrary = useCallback(
    async (file: File, libraryName: LibraryName): Promise<ImpotLibraryResponse> => {
      const importUrl = URLConstants.importLibraryData; // + `${libraryName}`;

      const formData = new FormData();

      // formData.append('upload_file', file, file.name);
      formData.append('upload_file', file, `${libraryName}.csv`);
      try {
        const result = (await postAuthenticatedRequest(importUrl, formData, undefined, {
          contentType: 'multipart/form-data',
          skipStringify: true,
        })) as ImpotLibraryResponse;

        getlibrary(libraryName);
        return result;
      } catch (uploadError: any) {
        // console.error(uploadError);
        throw uploadError;
      }
    },
    [postAuthenticatedRequest, getlibrary]
  );

  const handleImportLibrary = useCallback(
    (libraryName: LibraryName) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'importLibraryy', defaultMessage: 'Import library' }),
        modalContent: <ImportLibrary {...{ libraryName, exportLibrary, importLibrary }} />,
        noContentPadding: true,
      });
    },
    [intl, toggleModal, exportLibrary, importLibrary]
  );

  const handleChangelibrary = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newLib = event.target.value as LibraryName;
      setlibrary(newLib);
      getlibrary(newLib);
      setSearchInput('');
    },
    [getlibrary]
  );

  const clearSearchInput = useCallback(() => {
    const searchTerm = '';
    setSearchInput(searchTerm);
  }, []);

  const updateSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchInput(searchTerm);
  }, []);

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageLibrariesTitle', defaultMessage: 'Libraries' })}>
      <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
        <SearchSelect
          name="library"
          value={library ?? ''}
          onChange={handleChangelibrary}
          placeholder={intl.formatMessage({ id: 'select', defaultMessage: 'Select library' })}
          choices={AllowedLibrariesDisplay}
          MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
          fullWidth
          disabled={loadingLibrary}
          sx={{ maxWidth: { xs: '100%', md: '300px' } }}
        />

        <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

        <Typography sx={{ fontSize: 'var(--fs-14)', fontWeight: 500 }}>
          <FormattedMessage id="filterBy" defaultMessage={'Filter by'} />
        </Typography>

        <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '400px' } }}>
          <SearchText
            fullWidth
            value={searchInput}
            onChange={updateSearchInput}
            placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
            disabled={!library || loadingLibrary}
            clearAction={clearSearchInput}
          />
        </Box>
      </Box>

      {/*
      <ManageLibrariesContent {...{ loadingLibrary, getlibrary, library, libraryData, searchInput, handleImportLibrary }} /> 
      */}

      {library ? (
        <ManageLibrariesContent {...{ loadingLibrary, getlibrary, library, libraryData, searchInput, handleImportLibrary }} />
      ) : (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Typography px={1.5} py={1} className="rounded-border" sx={{ fontWeight: 500, backgroundColor: '#E4E2E2' }}>
            <FormattedMessage id="emptyManageLibraries" />
        </Typography>
        </Box>
      )}

    </TitleLayout>
  );
};

const ManageLibrariesContent = memo(({ getlibrary, loadingLibrary, library, libraryData, searchInput, handleImportLibrary }: ManageLibrariesContentProps) => {
  if (loadingLibrary) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  if (!libraryData) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {library && (
          <ReloadButton
            onClick={() => {
              if (library) getlibrary(library);
            }}
          />
        )}
      </Box>
    );
  }
  return <DisplayLibraryData {...{ libraryData, library, searchInput, handleImportLibrary }} />;
});

const getFilteredData = (data?: LibraryElement[], search?: string) => {
  if (!data || !search) return data;

  const lowerSearch = search.toLowerCase();

  return data.filter((d) => {
    return Object.values(d).filter((s) => (String(s) ?? '').toLowerCase().includes(lowerSearch)).length > 0;
  });
};

const getSortedData = (data: LibraryElement[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!data) return data;

  if (!sortedDirection || !sortedDirection) {
    return data;
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;

  return [...data].sort((d1, d2) => direction * (String(d1[sortedColumn]) ?? '').localeCompare(String(d2[sortedColumn]) ?? ''));
};

const StyledLibraryElementDefault = styled(Box)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontSize: 'var(--fs-14)',
  padding: theme.spacing(2),
  wordBreak: 'break-word',
  borderTop: '1px solid var(--color-grayHeaderBorder)',
  '& svg': { fontSize: 'inherit' },
}));

const StyledLibraryElement = ({ children, ...props }: PropsWithChildren<BoxProps>) => {
  return (
    <StyledLibraryElementDefault className="cell-element" {...props}>
      {children}
    </StyledLibraryElementDefault>
  );
};

const SearchPagination = memo(Pagination);
// const DEBOUNCETIMING = 500;
const DisplayLibraryData = memo(({ libraryData, library, searchInput, handleImportLibrary }: DisplayLibraryDataProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(defaultNumberPages));
  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  // const [searching, setSearching] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [filteredData, setFilteredData] = useState<LibraryElement[] | undefined>(libraryData);
  const [sortedData, setSortedData] = useState<LibraryElement[] | undefined>(libraryData);
  // const [visibleData, setVisibleData] = useState<LibraryElement[] | undefined>(undefined);

  const columns = useMemo(() => {
    // parse data keys
    const keys: string[] = [];

    (libraryData ?? []).forEach((d) => {
      const curKeys = Object.keys(d);
      curKeys.forEach((k) => {
        if (!keys.includes(k)) keys.push(k);
      });
    });

    const idCol = keys.indexOf('elementId');
    if (idCol >= 0) {
      keys.splice(idCol, 1);
    }
    return keys;
  }, [libraryData]);

  const columnTemplate = useMemo(() => `repeat(${columns.length}, minmax(200px,1fr))`, [columns]);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return columns.map((col) => ({
      id: col,
      label: LibraryColumnLabel[col] ?? col,
      sorted: () => {
        const newDirection = sortedColumn !== col ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
        setSortedColumn(col);
        setSortedDirection(newDirection);
      },
      direction: sortedColumn === col ? sortedDirection : false,
    }));
  }, [columns, sortedColumn, sortedDirection]);

  // filter on search
  useEffect(() => {
    // setSearching((curTimeout) => {
    //   // clear current timeout
    //   if (curTimeout) clearTimeout(curTimeout);

    //   if (!searchInput) {
    //     setFilteredData(libraryData);
    //     return null;
    //   }

    //   const filterTimeout = setTimeout(() => {
    //     setFilteredData(getFilteredData(libraryData, searchInput));
    //   }, DEBOUNCETIMING);
    //   return filterTimeout;
    // })
    setFilteredData(getFilteredData(libraryData, searchInput));
    setCurrentPage(0);
  }, [searchInput, libraryData]);

  // cleanup
  // useEffect(() => {
  //   return () => {
  //     setSearching((curTimeout) => {
  //       if (curTimeout) clearTimeout(curTimeout);
  //       return null;
  //     });
  //   };
  // }, []);

  useEffect(() => {
    setSortedData(getSortedData(filteredData, sortedColumn, sortedDirection));
    setCurrentPage(0);
  }, [filteredData, sortedColumn, sortedDirection]);

  const visibleData = sortedData?.slice(currentPage * Number(maxPerPage), (currentPage + 1) * Number(maxPerPage));

  const setMaxAndSearch = (event: SelectChangeEvent) => {
    const newMaxPerPage = event.target.value;
    if (newMaxPerPage === maxPerPage) return;

    const newCurrentPage = 0;
    setMaxPerPage(newMaxPerPage);
    setCurrentPage(newCurrentPage);
  };

  const changePage = (shift: 1 | -1) => {
    const newPage = currentPage + shift;

    if (!sortedData) return;

    // check page is in bounds
    if (newPage < 0) return;
    if (newPage > sortedData.length / Number(maxPerPage)) return;

    setCurrentPage(newPage);
  };

  // console.log('visibledata', visibleData);

  return (
    <Box mt={2}>
      <Box
        px={2}
        py={2}
        border={1}
        borderColor={'var(--color-grayHeaderBorder)'}
        display={'flex'}
        sx={{ flexFlow: 'row wrap', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderBottom: 'none' }}
        alignItems={'center'}
      >
        <Box flex="1">
          <Typography fontWeight={600} fontSize={'1.25rem'} sx={{ color: 'var(--color-darkgray)', textTransform: 'capitalize' }}>
            {/* <Typography component="span" fontWeight={'inherit'} fontSize={'inherit'} sx={{ color: 'var(--color-gray1)' }}> */}
            { (library && AllowedLibrariesDisplayMap[library]) ?? library}
          </Typography>
        </Box>
        <Box display="flex" alignItems={'center'}>
          <ShadowedButton
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              library && handleImportLibrary?.(library);
            }}
            sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
          >
            <FormattedMessage id="importInLibrary" defaultMessage="Import" />
          </ShadowedButton>
        </Box>
      </Box>

      {!libraryData || libraryData.length <= 0 ? (
        <Box p={1} mb={2} borderTop={'1px solid var(--color-grayHeaderBorder)'}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            <FormattedMessage id="noLibrarySchedule" defaultMessage={'No data has been defined in this library'} />
          </Typography>
        </Box>
      ) : (
        <Box>
          {(libraryData?.length ?? 0) > 0 && (
            <Box>
              <Box className="custom-scrollbar-horizontal table-results" display="grid" gridTemplateColumns={columnTemplate} sx={{ overflowX: 'auto', borderRadius: 0 }}>
                {tableHeaders.map((th, thIndex) => {
                  return (
                    <Box
                      key={th.id}
                      className={`header-element ${th.sorted ? 'has-sorting' : ''} ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                      {...(th.sorted
                        ? {
                            onClick: th.sorted,
                          }
                        : {})}
                    >
                      {LibraryColumnLabel[th.label.toUpperCase()] ?? th.label.toUpperCase()}{' '}
                      {th.sorted &&
                        (th.direction ? (
                          <ArrowDownSolidIcon sx={{ fontSize: 10, color: 'inherit', rotate: th.direction === 'asc' ? '180deg' : '0deg' }} />
                        ) : (
                          <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                        ))}
                    </Box>
                  );
                })}

                {visibleData?.map((vd) => {
                  return (
                    <Fragment key={vd.elementId}>
                      {columns.map((c) => {
                        const value = vd[c];
                        if (typeof value === 'boolean') {
                          return (
                            <StyledLibraryElement key={c}>
                              {value ? <ValidatedIcon sx={{ color: 'var(--color-azure)' }} /> : <CrossFilledIcon sx={{ color: 'var(--color-fushia)' }} />}
                            </StyledLibraryElement>
                          );
                        }
                        return <StyledLibraryElement key={c}>{vd[c] ? String(vd[c]) : <UnknownMessage />}</StyledLibraryElement>;
                        // return <StyledLibraryElement key={c}>{vd[c]}</StyledLibraryElement>;
                      })}
                    </Fragment>
                  );
                })}
              </Box>

              {/* {searching && (
                <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Loader />
                </Box>
              )} */}

              {(visibleData?.length ?? 0) <= 0 && (
                <Box p={1} mb={2} borderTop={'1px solid var(--color-grayHeaderBorder)'}>
                  <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
                    {searchInput ? (
                      <FormattedMessage id="noMatchedData" defaultMessage={'No data matches your search'} />
                    ) : (
                      <FormattedMessage id="noLibrarySchedule" defaultMessage={'No data has been defined in this library'} />
                    )}
                  </Typography>
                </Box>
              )}

              {(visibleData?.length ?? 0) > 0 && (
                <SearchPagination
                  {...{
                    maxPerPage: Number(maxPerPage),
                    currentPage,
                    nbResults: filteredData?.length ?? 0,
                    setMaxAndCallback: setMaxAndSearch,
                    changePageCallback: changePage,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});
export default ManageLibraries;

/*

        
        */
