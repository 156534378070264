/**
 * DeleteFolder.tsx
 * delete folder moodal
 */
/* packages */
import { useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
import { Dataset } from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';

/* types */
interface DeleteFolderProps {
  dataset: Dataset;
  removeDataset(datasetId: number): Promise<StatusPromiseResponse>;
}

const DeleteDataset = memo(({ dataset, removeDataset }: DeleteFolderProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingDataset, setDeletingDataset] = useState(false);

  const deleteDataset = useCallback(async () => {
    blockModal?.(true);
    setDeletingDataset(true);

    const result = await removeDataset(dataset.id);
    setDeletingDataset(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteDatasetDatasetSuccess',
        defaultMessage: 'Dataset deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteDatasetDatasetError',
        defaultMessage: 'An error occured while deleting the dataset',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, dataset, removeDataset, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Dataset: {dataset.label}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteDatasetMessage" defaultMessage={'Are you sure that you want to delete this dataset?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingDataset ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingDataset} onClick={deleteDataset} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default DeleteDataset;
