/**
 * MapContent.tsx
 * Display the map tab of view profile page
 */
/* packages */
import { useMemo, memo, useCallback, useContext, useState, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
import { MapLocations, PersonContext } from './PersonProvider';

/* components */
import Box from '@mui/material/Box';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';

// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

// import { Flag } from 'components/InvestigateSearch/utils';

/* utilities */
import { internalPageMaxWidth } from './PersonProfile';

/* types */
// import { MatchingLocation } from 'models/matchingData';

interface MapContentProps {}
interface MarkerType {
  latitude: string;
  longitude: string;
  country: string;
  countryName: string;
  label: Set<string>;
  displayedLabel: { [key: string]: MapLocations[] };
}
export type Markers = { [key: string]: MarkerType };

/* elements */
const multiColor = 'var(--color-darkgray)';

export const leafletSvgIcon = (color?: string) => {
  const ratio = 1.5;
  return L.divIcon({
    html: `
  <svg xmlns="http://www.w3.org/2000/svg" width="${12 * ratio}" height="${16 * ratio}" viewBox="0 0 12 16" color="${color ?? 'var(--color-azure)'}">
    <path d="M5.383,15.677C.843,9.095,0,8.419,0,6A6,6,0,1,1,12,6c0,2.419-.843,3.095-5.383,9.677a.75.75,0,0,1-1.233,0ZM6,8.5A2.5,2.5,0,1,0,3.5,6,2.5,2.5,0,0,0,6,8.5Z" fill="currentColor" />
  </svg>`,
    className: 'svg-icon',
    iconSize: [12 * ratio, 16 * ratio],
    iconAnchor: [(12 * ratio) / 2, 16 * ratio],
    popupAnchor: [0, -16 * ratio],
  });
};

export const locationKeys = {
  nationality: {
    // text: <FormattedMessage id="Nationalities" defaultMessage="Nationalities" />,
    label: <FormattedMessage id="Nationality" defaultMessage="Nationality" />,
    color: 'var(--color-fushia)',
  },
  address: {
    // text: <FormattedMessage id="Addresses" defaultMessage="Addresses" />,
    label: <FormattedMessage id="Address" defaultMessage="Address" />,
    color: 'var(--color-azure)',
  },
  placeOfBirth: {
    // text: <FormattedMessage id="PlacesOfBirth" defaultMessage="Places of birth" />,
    label: <FormattedMessage id="PlaceOfBirth" defaultMessage="Place of birth" />,
    color: 'var(--color-blue)',
  },
  placeOfRegistry: {
    // text: <FormattedMessage id="PlacesOfRegistry" defaultMessage="Places of registry" />,
    label: <FormattedMessage id="PlacesOfRegistry" defaultMessage="Places of registry" />,
    color: 'var(--color-orange)',
  },
  flag: {
    // text: <FormattedMessage id="Flags" defaultMessage="Flags" />,
    label: <FormattedMessage id="Flag" defaultMessage="Flag" />,
    color: 'var(--color-green)',
  },
  placeOfBuild: {
    // text: <FormattedMessage id="PlacesOfBuild" defaultMessage="Places of build" />,
    label: <FormattedMessage id="PlaceOfBuild" defaultMessage="Place of build" />,
    color: 'var(--color-yellow)',
  },
};

const MapContent = memo((props: MapContentProps) => {
  const { details, hasMap, mapLocations } = useContext(PersonContext);
  const intl = useIntl();
  // const intl = useIntl();

  // const locationKeys = useMemo(
  //   () => ({
  //     nationalities: {
  //       text: intl.formatMessage({ id: 'Nationalities', defaultMessage: 'Nationalities' }),
  //       label: intl.formatMessage({ id: 'Nationality', defaultMessage: 'Nationality' }),
  //       color: 'var(--color-fushia)',
  //     },
  //     addresses: { text: intl.formatMessage({ id: 'Addresses', defaultMessage: 'Addresses' }), label: intl.formatMessage({ id: 'Address', defaultMessage: 'Address' }), color: 'var(--color-green)' },
  //     placesOfBirth: {
  //       text: intl.formatMessage({ id: 'PlacesOfBirth', defaultMessage: 'Places of birth' }),
  //       label: intl.formatMessage({ id: 'PlaceOfBirth', defaultMessage: 'Place of birth' }),
  //       color: 'var(--color-blue)',
  //     },
  //     placesOfRegistry: {
  //       text: intl.formatMessage({ id: 'PlacesOfRegistry', defaultMessage: 'Places of registry' }),
  //       label: intl.formatMessage({ id: 'PlacesOfRegistry', defaultMessage: 'Places of registry' }),
  //       color: 'var(--color-orange)',
  //     },
  //     flags: { text: intl.formatMessage({ id: 'Flags', defaultMessage: 'Flags' }), label: intl.formatMessage({ id: 'Flag', defaultMessage: 'Flag' }), color: 'var(--color-azure)' },
  //     placeOfBuild: {
  //       text: intl.formatMessage({ id: 'PlacesOfBuild', defaultMessage: 'Places of build' }),
  //       label: intl.formatMessage({ id: 'PlaceOfBuild', defaultMessage: 'Place of build' }),
  //       color: 'var(--color-yellow)',
  //     },
  //   }),
  //   [intl]
  // );

  const [selectedLocations, setSelectedLocation] = useState(Object.fromEntries(Object.keys(locationKeys).map((k) => [k, true])));

  // const checkboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //   const checkboxName = event.target.name;
  //   const checked = event.target.checked;

  //   setSelectedLocation((currentValues) => ({ ...currentValues, [checkboxName]: checked }));
  // }, []);

  const switchChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const switchName = event.target.name;
    const checked = event.target.checked;

    setSelectedLocation((currentValues) => ({ ...currentValues, [switchName]: checked }));
  }, []);

  if (!details || !hasMap || !mapLocations) return <></>;

  // compute markers
  const markers: Markers = {};

  Object.entries(mapLocations)
    .filter(([locKey, locations]) => selectedLocations[locKey])
    .forEach(([locKey, locations]) => {
      locations.forEach((location) => {
        const markerKey = `${location.latitude}//${location.longitude}`;
        if (markerKey in markers) {
          if (locKey in locationKeys) {
            // markers[markerKey].label.add(locationKeys[locKey as keyof typeof locationKeys].label);
            markers[markerKey].label.add(locKey);
            if (locKey in markers[markerKey].displayedLabel) markers[markerKey].displayedLabel[locKey].push(location);
            else markers[markerKey].displayedLabel[locKey] = [location];
          }
        } else {
          if (locKey in locationKeys) {
            markers[markerKey] = {
              latitude: location.latitude ?? '',
              longitude: location.longitude ?? '',
              country: location.country ?? '',
              countryName: location.countryName ?? '',
              label: new Set([locKey]),
              displayedLabel: {
                [locKey]: [location],
              },
            };
          }
        }
      });
    });

  const zoom = 3;
  const pos = Object.entries(markers).map(([_, marker]) => {
    return [Number(marker.latitude), Number(marker.longitude)];
  });

  const latitudeCenter = pos.map((p) => p[0]).reduce((a, c) => a + c, 0) / pos.length;
  const longitudeCenter = pos.map((p) => p[1]).reduce((a, c) => a + c, 0) / pos.length;

  return (
    <Box px={2} py={2} height={'100%'} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'1fr auto'} columnGap={'1.5rem'} rowGap={'1.25rem'} height={'100%'}>
        {/* map container */}
        <Box height={'100%'} minHeight={'350px'}>
          <MapContainer
            center={[isNaN(latitudeCenter) ? 0 : latitudeCenter, isNaN(longitudeCenter) ? 0 : longitudeCenter]}
            // center={parentMap.getCenter()}
            minZoom={2}
            maxZoom={8}
            zoom={zoom}
            scrollWheelZoom={false}
            style={{ height: '100%' }}
          >
            <TileLayer
              attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />

            {Object.entries(markers).map(([key, marker]) => {
              const lat = Number(marker.latitude);
              const long = Number(marker.longitude);
              const labels = Array.from(marker.label);

              const color = labels.length > 1 ? multiColor : locationKeys[labels[0] as keyof typeof locationKeys].color;
              const displayedLabels = Object.entries(marker.displayedLabel);

              let inPopup = false;
              return (
                <Marker
                  key={key}
                  position={[lat, long]}
                  icon={leafletSvgIcon(color)}
                  eventHandlers={{
                    mouseover: (event) => {
                      inPopup = false;
                      event.target.openPopup();
                    },
                    mouseout: (event) => {
                      setTimeout(() => {
                        if (!inPopup) event.target.closePopup();
                      }, 150);
                    },
                  }}
                >
                  <Popup
                    eventHandlers={{
                      mouseover: () => {
                        inPopup = true;
                      },
                      mouseout: (event) => {
                        inPopup = false;
                        event.target._source?.closePopup();
                      },
                    }}
                    interactive
                  >
                    <Box sx={{ fontSize: 'var(--fs-14)', color: 'white' }}>
                      {/* <Box mb={1} display="flex" alignItems={'flex-start'}>
                        <Box component={'span'} display={'inline-flex'} pt={0.5} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                          <Flag country={country} />
                        </Box>
                        <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                          <Typography fontSize={'inherit'}>{countryName}</Typography>
                        </Box>
                      </Box> */}
                      <Box display={'grid'} className="custom-scrollbar" rowGap={'1rem'} columnGap=".5rem" sx={{ gridTemplateColumns: 'auto 1fr', maxHeight: 'min(350px, 50vh)', overflow: 'auto' }}>
                        {displayedLabels.map(([l, li]) => (
                          <Fragment key={l}>
                            <Typography fontSize={'inherit'} fontWeight={500} color="inherit">
                              <FormattedMessage id={li.length > 1 ? `${l}_plural` : l} />:
                            </Typography>
                            <Box display="grid" rowGap=".5rem">
                              {li.map((t, ti) => (
                                <Box key={t?.id ?? `${l}-${ti}`} sx={{ pr: 1, lineHeight: 1.2 }}>
                                  {t.fullAddressDisplay && (
                                    <>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.fullAddressDisplay}
                                      </Typography>
                                    </>
                                  )}
                                  {/* {t.placeName && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="placeName" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.placeName}
                                      </Typography>
                                    </>
                                  )}
                                  {t.streetNumber && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="streetNumber" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.streetNumber}
                                      </Typography>
                                    </>
                                  )}
                                  {t.streetName && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="streetName" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.streetName}
                                      </Typography>
                                    </>
                                  )}
                                  {t.street && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="street" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.street}
                                      </Typography>
                                    </>
                                  )}
                                  {t.poBox && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="poBox" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.poBox}
                                      </Typography>
                                    </>
                                  )}
                                  {t.zipCode && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="zipCode" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.zipCode}
                                      </Typography>
                                    </>
                                  )}
                                  {t.city && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="city" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.city}
                                      </Typography>
                                    </>
                                  )}
                                  {t.state && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="state" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.state}
                                      </Typography>
                                    </>
                                  )}
                                  {t.countryName && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="country" />:
                                      </Typography>
                                      <Box display="flex" gap={'.25rem'} alignItems={'center'}>
                                        <Flag country={t.country} />
                                        <Typography fontSize={'inherit'} color="inherit">
                                          {t.countryName}
                                        </Typography>
                                      </Box>
                                    </>
                                  )}
                                  {t.type && (
                                    <>
                                      <Typography fontWeight={500} fontSize={'inherit'} color="inherit">
                                        <FormattedMessage id="type" />:
                                      </Typography>
                                      <Typography fontSize={'inherit'} color="inherit">
                                        {t.type}
                                      </Typography>
                                    </>
                                  )} */}
                                </Box>
                              ))}
                            </Box>
                          </Fragment>
                        ))}
                      </Box>
                    </Box>
                  </Popup>
                </Marker>
              );
            })}

            <AutoFit markers={markers} />
          </MapContainer>
        </Box>

        {/* legend */}
        <Box display="flex" flexDirection={'column'}>
          <Typography fontSize={'.875rem'} fontWeight={500} sx={{ color: 'var(--color-gray2)' }}>
            <FormattedMessage id="selectLocations" defaultMessage={'Select visible locations'} />
          </Typography>
          {Object.entries(locationKeys)
            .filter(([locKey, locInfo]) => locKey in mapLocations)
            .map(([locKey, locInfo]) => {
              return (
                <SwitchWithLabel
                  key={locKey}
                  label={intl.formatMessage({ id: `${locKey}_plural` })}
                  switchColor={locInfo.color}
                  switchPosition={'left'}
                  inputSwitchProps={{ checked: selectedLocations[locKey], onChange: switchChanged, name: locKey }}
                />
              );
              // return <LegendSelect key={locKey} name={locKey} text={locInfo.text} color={locInfo.color} checked={selectedLocations[locKey]} checkboxChanged={checkboxChanged} />;
            })}
        </Box>
      </Box>
    </Box>
  );
});

// const LegendSelect = memo(
//   ({ name, text, checked, checkboxChanged, color }: { name: string; text: ReactNode; checked: boolean; checkboxChanged(event: React.ChangeEvent<HTMLInputElement>): void; color: string }) => {
//     return (
//       <FormControlLabel
//         control={
//           <Checkbox
//             color={'primary'}
//             name={name}
//             checked={checked}
//             onChange={checkboxChanged}
//             inputProps={{ 'aria-label': 'controlled' }}
//             sx={{ py: 1, '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? color ?? 'unset' : 'var(--color-grayHeaderBorder)' } }}
//           />
//         }
//         label={
//           <Typography fontSize={'.875rem'} sx={{ color: 'var(--color-gray2)' }}>
//             {text}
//           </Typography>
//         }
//         sx={{ ml: 0, mr: 0, '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
//       />
//     );
//   }
// );

export const AutoFit = ({ markers, minLatitudeDiff, minLongitudeDiff }: { markers: Markers; minLatitudeDiff?: number; minLongitudeDiff?: number }) => {
  const latDiff = useMemo(() => minLatitudeDiff ?? 10, [minLatitudeDiff]);
  const longDiff = useMemo(() => minLongitudeDiff ?? 9, [minLongitudeDiff]);

  const map = useMap();
  useEffect(() => {
    // on render, fit the map to markers
    if (!map) return;

    if (Object.values(markers).length <= 0) return;

    const allLatitude = Object.values(markers).map((marker) => Number(marker.latitude ?? 0));
    const allLongitude = Object.values(markers).map((marker) => Number(marker.longitude ?? 0));

    const bounds: [number, number][] = [
      [Math.min(...allLatitude), Math.min(...allLongitude)],
      [Math.max(...allLatitude), Math.max(...allLongitude)],
    ];

    if (bounds[1][0] - bounds[0][0] < latDiff) {
      bounds[0][0] -= latDiff / 2;
      bounds[1][0] += latDiff / 2;
    }
    if (bounds[1][1] - bounds[0][1] < longDiff) {
      bounds[0][1] -= longDiff / 2;
      bounds[1][1] += longDiff / 2;
    }
    map.fitBounds(bounds);
  }, [map, markers, latDiff, longDiff]);

  return <></>;
};

export default MapContent;
