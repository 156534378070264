/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useMemo, useCallback, useContext, useState, useRef } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { ApiResponse } from 'models/axiosRequest';

/* types */
import { RoleType } from 'models/user';

interface RolesContextType {
  loadingRoles: boolean;
  roles?: RoleType[];
  listRoles?(abortController?: AbortController): Promise<RoleType[] | undefined>;
  addOrUpdateRole?(data: Partial<RoleType>, create: boolean, abortController?: AbortController): Promise<RoleType | undefined>;
  deleteRole?(roleId: number, abortController?: AbortController): Promise<DeleteRolesResponseType | undefined>;
}

// export interface RoleType {
//   id: number;
//   name: string;
//   description?: string;
//   creationDate?: DateString;
//   createdBy?: string;
//   updatedDate?: DateString;
//   updatedBy?: string;
//   deleted?: boolean;
//   permissions: PermissionString[];
//   // teams?: TeamType[];
// }

interface ListRolesResponseType extends ApiResponse {
  roles: RoleType[];
}

interface UpdateRolesResponseType extends ApiResponse {
  role: RoleType;
}

interface DeleteRolesResponseType extends ApiResponse {
  operationResult: boolean;
}

/* elements */
const RolesContext = createContext<RolesContextType>({
  loadingRoles: false,
  roles: undefined,
});

const RolesProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  const { permissions } = useContext(UserContext);

  const listRolesOngoing = useRef(false);

  const [roles, setRoles] = useState<RoleType[] | undefined>(undefined);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);

  const listRoles = useCallback(
    async (abortController?: AbortController) => {
      if (listRolesOngoing.current) return;

      // check permissions
      // const allowed = checkPermissions('manageSetupRoles', permissions);
      // if (!allowed) return [];

      listRolesOngoing.current = true;
      setLoadingRoles(true);
      setRoles(undefined);

      try {
        const listRolesUrl = URLConstants.fullRoleList;
        const results = (await getAuthenticatedRequest(listRolesUrl, abortController ?? undefined)) as ListRolesResponseType;

        const roles = results.roles ?? [];
        setLoadingRoles(false);
        setRoles(roles);
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        setLoadingRoles(false);
        setRoles(undefined);
      }
      listRolesOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );

  const addOrUpdateRole = useCallback(
    async (data: Partial<RoleType>, create = true, abortController?: AbortController): Promise<RoleType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupRoles', permissions);
      if (!allowed) return undefined;

      try {
        const editRolesUrl = create ? URLConstants.roleCreate : URLConstants.roleUpdate;
        const results = (await postAuthenticatedRequest(editRolesUrl, { role: data }, abortController ?? undefined)) as UpdateRolesResponseType;

        const updatedRole = results.role;

        setRoles((currentRoles) => {
          if (!updatedRole) return currentRoles;
          if (!currentRoles) return [updatedRole];

          const newRoles = [...currentRoles];
          const roleIndex = newRoles?.findIndex((r) => r.id === updatedRole.id);
          if (roleIndex >= 0) {
            newRoles[roleIndex] = updatedRole;
          } else {
            newRoles.push(updatedRole);
          }
          return newRoles;
        });

        return updatedRole;
        // const roles = results.roles ?? [];
        // return roles;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [permissions, postAuthenticatedRequest]
  );

  const deleteRole = useCallback(
    async (roleId: number, abortController?: AbortController): Promise<DeleteRolesResponseType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupRoles', permissions);
      if (!allowed) return undefined;

      try {
        const deleteRolesUrl = URLConstants.roleDelete + `${roleId}`;
        const results = (await deleteAuthenticatedRequest(deleteRolesUrl, abortController ?? undefined)) as DeleteRolesResponseType;

        if (results.operationResult) {
          setRoles((currentRoles) => {
            return currentRoles?.filter((r) => r.id !== roleId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [permissions, deleteAuthenticatedRequest]
  );

  const outputValue = useMemo(
    (): RolesContextType => ({
      loadingRoles,
      roles,
      listRoles,
      addOrUpdateRole,
      deleteRole,
    }),
    [loadingRoles, roles, listRoles, addOrUpdateRole, deleteRole]
  );
  return <RolesContext.Provider value={outputValue}>{children}</RolesContext.Provider>;
};

/* exports */
export { RolesContext, RolesProvider };
