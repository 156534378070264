import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.999" viewBox="0 0 18 13.999">
        <path
          d="M9.023,33.5a7.314,7.314,0,0,0-5,2.115A11.125,11.125,0,0,0,1.568,39a11.162,11.162,0,0,0,2.456,3.384,7.328,7.328,0,0,0,5,2.115,7.314,7.314,0,0,0,5-2.115A11.125,11.125,0,0,0,16.479,39a11.162,11.162,0,0,0-2.456-3.384A7.328,7.328,0,0,0,9.023,33.5ZM3.005,34.519A8.793,8.793,0,0,1,9.023,32a8.793,8.793,0,0,1,6.018,2.519,12.475,12.475,0,0,1,2.906,4.1.994.994,0,0,1,0,.769,12.443,12.443,0,0,1-2.906,4.1A8.793,8.793,0,0,1,9.023,46,8.793,8.793,0,0,1,3.005,43.48a12.381,12.381,0,0,1-2.9-4.1.994.994,0,0,1,0-.769A12.381,12.381,0,0,1,3.005,34.519ZM9.023,41.5a2.5,2.5,0,0,0,0-5H8.961a2.029,2.029,0,0,1,.062.5,2,2,0,0,1-2,2,2.029,2.029,0,0,1-.5-.062V39A2.5,2.5,0,0,0,9.023,41.5Zm0-6.5a4,4,0,1,1-4,4A4,4,0,0,1,9.023,35Z"
          transform="translate(-0.025 -32)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
