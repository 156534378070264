/**
 * ModalProvider.tsx
 * Display a modal
 */

/* packages */
import React, { useState, createContext, useContext, memo, useCallback, useMemo, useEffect, ReactNode } from 'react';
// import { useTheme } from '@mui/material/styles';

/* components */
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import { CrossIcon } from 'icons/cross/cross';
/* types */
interface ModalValueType {
  close?: boolean;
  title?: string;
  modalContent?: ReactNode;
  blocked?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  noContentPadding?: boolean;
}
type SetModalType = {
  toggleModal?(modalValue: ModalValueType): void;
  closeModal?(): void;
  blockModal?(blocked: boolean): void;
};

interface ModalContextType {
  modal?: ModalValueType;
  setModal: React.Dispatch<React.SetStateAction<ModalValueType>>;
}

/* elements */
// contexts
const ModalValueContext = createContext<ModalContextType>({ modal: { close: false, blocked: false }, setModal: () => {} });
const ModalSetContext = createContext<SetModalType>({});

// actual Modal

const AppModal = () => {
  // const theme = useTheme();
  const { modal } = useContext(ModalValueContext);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => !modal?.blocked && setOpen(false);

  useEffect(() => {
    if (modal?.close) setOpen(false);
    else setOpen(true);
  }, [modal]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={modal?.maxWidth ?? 'md'}
      PaperProps={{
        sx: { borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' },
      }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0,0,0,0.7)' },
        },
      }}
    >
      <Box display="flex" width="100%" gap={'1rem'} pl={2} pr={1} alignItems={'flex-start'}>
        <DialogTitle sx={{ m: 0, p: 2, flex: 1, fontWeight: 600, lineHeight: 1.2, fontSize: '1.25rem', color: 'var(--color-darkgray)' }} id="customized-dialog-title">
          {modal?.title ?? ''}&nbsp;
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            fontSize: '1rem',
            mt: 'var(--fs-12)',
            color: 'var(--color-lightgray4)',
            borderRadius: '5px',
            '&:hover': { backgroundColor: 'var(--color-hover-button)', color: 'var(--color-table-content)' },
          }}
        >
          <CrossIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <DialogContent dividers sx={{ paddingInline: 0, ...(modal?.noContentPadding ? { paddingBlock: 0 } : {}) }}>
        {modal?.modalContent}
      </DialogContent>
    </Dialog>
  );
};

// modal providers
const ModalSetProvider = memo(
  ({
    setModal,
    children,
  }: React.PropsWithChildren<{
    setModal: ModalContextType['setModal'];
  }>) => {
    const toggleModal = useCallback(
      (modalValue: ModalValueType) => {
        setModal(modalValue);
      },
      [setModal]
    );
    const closeModal = useCallback(() => {
      setModal((modalValue) => (!modalValue.close ? { ...modalValue, close: true } : modalValue));
    }, [setModal]);

    const blockModal = useCallback(
      (blocked: boolean) => {
        setModal((modalValue) => (modalValue.blocked !== blocked ? { ...modalValue, blocked } : modalValue));
      },
      [setModal]
    );

    const setValue = useMemo(
      () => ({
        toggleModal,
        closeModal,
        blockModal,
      }),
      [toggleModal, closeModal, blockModal]
    );

    return <ModalSetContext.Provider value={setValue}>{children}</ModalSetContext.Provider>;
  }
);

const ModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [modal, setModal] = useState<ModalValueType>({ close: true });

  const modalContextValue = useMemo(
    () => ({
      modal,
      setModal,
    }),
    [modal, setModal]
  );

  return (
    <ModalValueContext.Provider value={modalContextValue}>
      <ModalSetProvider setModal={setModal}>
        {children}
        <AppModal />
      </ModalSetProvider>
    </ModalValueContext.Provider>
  );
};

// export hooks
const useAddModal = () => useContext(ModalSetContext);

// exports
export { ModalProvider };
export { useAddModal };
