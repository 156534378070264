/**
 * job.ts
 * Define job
 */

import { ApiResponse } from './axiosRequest';
import { DateString } from './utils';

export const AllowedJobType = ['Matching', 'Import', 'Export'] as const;
export type AllowedJobTypeTypeT = (typeof AllowedJobType)[number];
const AllowedJobParamsKey = ['sourceDataSet', 'matchingProfile'] as const;
export type AllowedJobParamsKeyType = (typeof AllowedJobParamsKey)[number];

export const paramsFromJobType: { [key in AllowedJobTypeTypeT]: AllowedJobParamsKeyType[] } = {
  Matching: ['sourceDataSet', 'matchingProfile'],
  Import: ['sourceDataSet'],
  Export: ['sourceDataSet'],
};

// sourceDataset => value is dataset label
// matchingProfile => string

export interface JobType {
  id: number;

  name?: string;
  description?: string;

  cronExpression?: string;

  lastExecutionTime?: DateString;
  nextExecutionTime?: DateString;

  active?: boolean;

  jobType?: AllowedJobTypeTypeT;

  isRunning?: boolean;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  jobParams?: JobParam[];
}

export interface JobParam {
  id?: number;
  key?: AllowedJobParamsKeyType;
  value?: string;
}

export interface ListScheduleResponse extends ApiResponse {
  jobs: JobType[];
}

export interface EditScheduleResponse extends ApiResponse {
  job: JobType;
}
export interface RunScheduleResponse extends EditScheduleResponse {}

export interface DeleteScheduleResponse extends ApiResponse {
  operationResult: boolean;
}
