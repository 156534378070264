/**
 * UnknownContent.tsx
 * content of unknown card in search results
 */
/* packages */
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* contexts */
import { CountriesContext } from 'contextProviders/CountriesProvider';

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { parsePeriodDate } from 'components/PersonProfile/PersonProvider';
import { StyledBadge, Flag, UnknownMessage, ContentWithDarkTooltip } from './utils';

/* utilities */
import { GenerateTooltip, getCountryFromIso, maxTooltipElements, FullNameTooltip } from './IndividualContent';
// import { GetGender } from 'utilities/TermMatching';

/* types */
import { formatMatchingName, MatchingDataType, MatchingDate, MatchingLocation } from 'models/matchingData';
import { FormatDayJSDate } from 'models/utils';

const UnknownContent = (props: { data: MatchingDataType; resume?: boolean }) => {
  const { countries } = useContext(CountriesContext);
  const intl = useIntl();

  const { data } = props;

  const fullName = data.names[0];
  const nbFullName = Math.max(data.names.length - 1 ?? 0, 0);

  let nameTooltip = null;
  if (nbFullName > 0 && data.names) {
    nameTooltip = GenerateTooltip(
      data.names.slice(1, maxTooltipElements + 1).map((name) => formatMatchingName(name)),
      nbFullName - maxTooltipElements
    );
  }

  let fullNameTooltip = null;
  if (data.names[0]) {
    const curName = data.names[0];
    if (curName.fullName) {
      fullNameTooltip = <FullNameTooltip content={[[intl.formatMessage({ id: 'fullName', defaultMessage: 'Full name' }), curName.fullName]]} />;
    } else {
      const content: [string, string][] = [];
      if (curName.givenName) {
        content.push([intl.formatMessage({ id: 'givenName', defaultMessage: 'Given name' }), curName.givenName]);
      }
      if (curName.fatherName) {
        content.push([intl.formatMessage({ id: 'fatherName', defaultMessage: 'Father name' }), curName.fatherName]);
      }
      if (curName.motherName) {
        content.push([intl.formatMessage({ id: 'motherName', defaultMessage: 'Mother name' }), curName.motherName]);
      }
      if (curName.surname) {
        content.push([intl.formatMessage({ id: 'surname', defaultMessage: 'Surname' }), curName.surname]);
      }
      if (curName.type) {
        content.push([intl.formatMessage({ id: 'type', defaultMessage: 'Type' }), curName.type]);
      }

      if (content.length > 0) {
        fullNameTooltip = <FullNameTooltip content={content} />;
      }
    }
  }

  // const gender = GetGender(data.sex?.toUpperCase());
  // const nbGender = 0;
  let countryArray: MatchingLocation[] = [];
  let countryLabel = <FormattedMessage id="nationality" defaultMessage={'Nationality'} />;

  if (data.nationalities) {
    countryArray = data.nationalities;
  } else if (data.addresses) {
    countryArray = data.addresses;
    countryLabel = <FormattedMessage id="address" defaultMessage={'Address'} />;
  } else if (data.flags) {
    countryArray = data.flags;
    countryLabel = <FormattedMessage id="flag" defaultMessage={'Flag'} />;
  }

  let countryCode,
    country,
    nbCountries = 0,
    countryTooltip;
  if (countryArray.length > 0) {
    countryCode = countryArray[0]?.country ?? null;
    country = getCountryFromIso(countryCode, countries);
    nbCountries = (countryArray ?? [0]).length - 1;

    countryTooltip = null;
    if (nbCountries > 0 && countryArray.length > 1) {
      countryTooltip = GenerateTooltip(
        countryArray.slice(1, maxTooltipElements + 1).map((nat) => {
          const addCountryCode = nat.country ?? null;
          const addCountry = getCountryFromIso(addCountryCode, countries);
          return (
            <>
              <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                <Flag country={addCountryCode ?? undefined} noBorder />
              </Box>
              <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                {nat.fullAddressDisplay ?? addCountry ?? 'unkown'}
              </Box>
            </>
          );
        }),
        nbCountries - maxTooltipElements
      );
    }
  }

  // date
  let datesArray: MatchingDate[] = [];
  let dateLabel = <FormattedMessage id="dateOfBirth" defaultMessage={'Date of birth'} />;

  if (data.datesOfBirth) {
    datesArray = data.datesOfBirth;
  } else if (data.datesOfBuild) {
    datesArray = data.datesOfBuild;
    dateLabel = <FormattedMessage id="dateOfBuild" defaultMessage={'Date of build'} />;
  } else if (data.datesOfRegistry) {
    datesArray = data.datesOfRegistry;
    dateLabel = <FormattedMessage id="dateOfRegistry" defaultMessage={'Date of registry'} />;
  }

  let dateEntry,
    nbDates = 0,
    dateTooltip;

  if (datesArray.length > 0) {
    const dateBase = datesArray[0]?.date ?? '';
    const parsedReg = parsePeriodDate(dateBase);
    dateEntry = parsedReg !== null ? parsedReg[0].format(FormatDayJSDate) : null;
    // dateEntry = datesArray[0]?.date ?? null;
    nbDates = (datesArray ?? [0]).length - 1;

    dateTooltip = null;
    if (nbDates > 0 && datesArray.length > 1) {
      dateTooltip = GenerateTooltip(
        datesArray.slice(1, maxTooltipElements + 1).map((dob) => {
          const regDate = dob.date ?? '';
          const parsedReg = parsePeriodDate(regDate);
          return parsedReg !== null ? parsedReg[0].format(FormatDayJSDate) : null;
          // return dob.date
        }),
        nbDates - maxTooltipElements
      );
    }
  }

  // const placeOfBirthCountryCode = data.placesOfBirth ? data.placesOfBirth[0]?.country : null;
  // const placeOfBirthCountry = getCountryFromIso(placeOfBirthCountryCode, countries);
  // // const placeOfBirthCountryIndex = placeOfBirthCountryCode ? countries?.findIndex((c) => c.iso2 === placeOfBirthCountryCode) : -1;
  // // const placeOfBirthCountry = countries && placeOfBirthCountryIndex && placeOfBirthCountryIndex >= 0 ? countries[placeOfBirthCountryIndex].name : null;
  // const nbplaceOfBirth = (data.placesOfBirth ?? [0]).length - 1;

  // let placeOfBirthTooltip = null;
  // if (nbplaceOfBirth > 0 && data.placesOfBirth) {
  //   placeOfBirthTooltip = GenerateTooltip(
  //     data.placesOfBirth.slice(1, maxTooltipElements + 1).map((pob) => getCountryFromIso(pob.country, countries) ?? 'unkown'),
  //     nbNationality - maxTooltipElements
  //   );
  // }

  const addressCountryCode = data.addresses ? data.addresses[0]?.country : null;
  const addressCountry = getCountryFromIso(addressCountryCode, countries);
  // const addressCountryIndex = addressCountryCode ? countries?.findIndex((c) => c.iso2 === addressCountryCode) : -1;
  // const addressCountry = countries && addressCountryIndex && addressCountryIndex >= 0 ? countries[addressCountryIndex].name : null;
  const nbAddresses = (data.addresses ?? [0]).length - 1;

  let adressTooltip = null;
  if (nbAddresses > 0 && data.addresses) {
    adressTooltip = GenerateTooltip(
      data.addresses.slice(1, maxTooltipElements + 1).map((add) => {
        const addCountryCode = add.country ?? null;
        const addCountry = getCountryFromIso(addCountryCode, countries);
        return (
          <>
            <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
              <Flag country={addCountryCode ?? undefined} noBorder />
            </Box>
            <Box display="inline" sx={{ verticalAlign: 'middle' }}>
              {add.fullAddressDisplay ?? addCountry ?? 'unkown'}
            </Box>
          </>
        );
      }),
      nbAddresses - maxTooltipElements
    );
  }

  let currentAddressTooltip = null;
  if (data.addresses) {
    const curAddress = data.addresses[0];
    const content: [string, string][] = [];
    if (curAddress.placeName) {
      content.push([intl.formatMessage({ id: 'placeName', defaultMessage: 'Place name' }), curAddress.placeName]);
    }
    if (curAddress.streetNumber) {
      content.push([intl.formatMessage({ id: 'streetNumber', defaultMessage: 'Street number' }), curAddress.streetNumber]);
    }
    if (curAddress.streetName) {
      content.push([intl.formatMessage({ id: 'streetName', defaultMessage: 'Street name' }), curAddress.streetName]);
    }
    if (curAddress.street) {
      content.push([intl.formatMessage({ id: 'street', defaultMessage: 'Street' }), curAddress.street]);
    }
    if (curAddress.poBox) {
      content.push([intl.formatMessage({ id: 'poBox', defaultMessage: 'PO box' }), curAddress.poBox]);
    }
    if (curAddress.zipCode) {
      content.push([intl.formatMessage({ id: 'zipCode', defaultMessage: 'ZIP code' }), curAddress.zipCode]);
    }
    if (curAddress.city) {
      content.push([intl.formatMessage({ id: 'city', defaultMessage: 'City' }), curAddress.city]);
    }
    if (curAddress.state) {
      content.push([intl.formatMessage({ id: 'state', defaultMessage: 'State' }), curAddress.state]);
    }
    if (curAddress.country) {
      const countryName = getCountryFromIso(curAddress.country, countries);
      content.push([intl.formatMessage({ id: 'country', defaultMessage: 'Country' }), countryName ?? curAddress.country]);
    }
    if (curAddress.type) {
      content.push([intl.formatMessage({ id: 'type', defaultMessage: 'Type' }), curAddress.type]);
    }

    if (content.length > 0) {
      currentAddressTooltip = <FullNameTooltip content={content} />;
    }
  }

  // const identityDocument = data.identityDocuments ? data.identityDocuments[0]?.number : null;
  // const nbIdentityDocument = (data.identityDocuments ?? [0]).length - 1;

  // let identityDocumentTooltip = null;
  // if (nbIdentityDocument > 0 && data.identityDocuments) {
  //   identityDocumentTooltip = GenerateTooltip(
  //     data.identityDocuments.slice(1, maxTooltipElements + 1).map((id) => id.number ?? 'unknown'),
  //     nbIdentityDocument - maxTooltipElements
  //   );
  // }

  // const digitalCurrency = data.digitalCurrencies ? data.digitalCurrencies[0]?.identifier : null;
  // const nbDigitalCurrency = (data.digitalCurrencies ?? [0]).length - 1;

  // let digitalCurrencyTooltip = null;
  // if (nbDigitalCurrency > 0 && data.digitalCurrencies) {
  //   digitalCurrencyTooltip = GenerateTooltip(
  //     data.digitalCurrencies.slice(1, maxTooltipElements + 1).map((id) => id.identifier ?? 'unknown'),
  //     nbDigitalCurrency - maxTooltipElements
  //   );
  // }

  return (
    <>
      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="fullName" defaultMessage={'Full name'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge tooltip={nameTooltip} badgeContent={nbFullName}>
            {fullName ? (
              <Tooltip
                placement="bottom"
                title={fullNameTooltip}
                slotProps={{ tooltip: { sx: { mt: '5px!important', px: 2, py: 2, backgroundColor: 'var(--color-darkgray)', fontSize: 'var(--fs-12)', fontWeight: 400 } } }}
              >
                <Box sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatMatchingName(fullName)}</Box>
              </Tooltip>
            ) : (
              <UnknownMessage />
            )}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          {countryLabel}:
        </Typography>
        <Box className="search-card-content-text">
          <Box className="search-card-content-text capitalize">
            <StyledBadge badgeContent={nbCountries} tooltip={countryTooltip}>
              {country ? (
                <Box display="flex" alignItems={'center'}>
                  <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                    <Flag country={countryCode ?? undefined} />
                  </Box>
                  <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                    {country.toLowerCase()}
                  </Box>
                </Box>
              ) : (
                <UnknownMessage />
              )}
            </StyledBadge>
          </Box>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          {dateLabel}:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbDates} tooltip={dateTooltip}>
            {dateEntry ?? <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="address" defaultMessage={'Address'} />:
        </Typography>
        <Box className="search-card-content-text capitalize">
          <StyledBadge badgeContent={nbAddresses} tooltip={adressTooltip}>
            {addressCountry ? (
              <ContentWithDarkTooltip tooltip={currentAddressTooltip} placement="bottom">
                <Box display="flex" alignItems={'center'}>
                  <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                    <Flag country={addressCountryCode ?? undefined} />
                  </Box>
                  <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                    {addressCountry.toLowerCase()}
                  </Box>
                </Box>
              </ContentWithDarkTooltip>
            ) : (
              <UnknownMessage />
            )}
          </StyledBadge>
        </Box>
      </Box>
    </>
  );
};

export default UnknownContent;
