import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const StopIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <rect width="16" height="16" rx="2" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};
