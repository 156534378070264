/**
 * ShadowedButton.tsx
 */
/* packages */
import { forwardRef, PropsWithChildren } from 'react';

/* components */
import Button, { ButtonProps } from '@mui/material/Button';
import { RefreshIcon } from 'icons/refresh/refresh';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

/* elemensts */
const ReloadButton = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(({ children, ...others }, ref) => {
  return (
    <Button
      ref={ref}
      sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }}
      {...others}
      onClick={(event) => {
        event.currentTarget.blur();
        others?.onClick?.(event);
      }}
    >
      <RefreshIcon sx={{ mb: 2 }} />
      <Box>
        <Typography>
          <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
        </Typography>
        <Typography>
          <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
        </Typography>
      </Box>
    </Button>
  );
});

export default ReloadButton;
