import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.133" viewBox="0 0 16 18.133">
        <path d="M17.527,8.133,17.1,6H7.5V24.133H9.633V16.667h5.973l.427,2.133H23.5V8.133Z" transform="translate(-7.5 -6)" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};
