/**
 * InvestigateSearch.tsx
 */
/* packages */
import React, { ReactNode, useState, useContext, useCallback, SyntheticEvent, PropsWithChildren, memo, useMemo, forwardRef, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* contexts */
import { WorkflowContext } from 'contextProviders/WorkflowProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { SavedSearchFilterContext } from 'contextProviders/SavedSearchFilterProvider';
import { UnreadAlertsContext } from 'contextProviders/UnreadAlertsProvider';
import { UserContext } from 'contextProviders/UserProvider';
import { AlertsContext } from 'contextProviders/AlertsProvider';

/* hooks */
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { useAddModal } from 'contextProviders/ModalProvider';
import { useViewAlert } from './InvestigateAlert';

/* components */
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';

import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import TableResults from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';

import Pagination, { defaultNumberPages } from 'components/Pagination/Pagination';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType, FilterValueType } from 'components/SearchFilters/SearchFilters';
import FilterVirtualCheckboxes from 'components/SearchFilters/FilterVirtualCheckboxes';
import DateRangeFilter from 'components/SearchFilters/DateRangeFilter';

import DisplayDate from 'components/DisplayDate/DisplayDate';
import { StyledBadge, UnknownMessage } from 'components/InvestigateSearch/utils';

import StatusTag from 'components/StatusTag/StatusTag';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import { GearFilledIcon } from 'icons/gearFilled/gearFilled';
import { RefreshIcon } from 'icons/refresh/refresh';
import { ExportArrowIcon } from 'icons/exportArrow/exportArrow';
import { ExportCSVIcon } from 'icons/exportCSV/exportCSV';
import { EnvelopeOpenIcon } from 'icons/envelopeOpen/envelopeOpen';
import { EnvelopeDotIcon } from 'icons/envelopeDot/envelopeDot';

import { SetActionPayload, EditStatusOrOwner } from './InvestigateAlert';
// import { useAddModal as useFullPageModal } from 'contextProviders/PageModalProvider';
// import InvestigateAlert from './InvestigateAlert';

/* utilities */
import { GetTeam, GetUser } from 'components/GetUser/GetUser';
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { routerPages } from 'AppRouter';
import { URLConstants } from 'common/URLconstants';
import { formatMatchingName } from 'models/matchingData';
import { GenerateTooltip } from 'components/InvestigateSearch/IndividualContent';

/* types */
import { Alert } from 'models/alerts';
import { WorkflowStatusType } from 'models/workflow';
import { TeamType } from 'models/teams';
import { SearchFilterType, savedSearchFields, CreateSavedSearchQuery } from 'models/savedSearch';
import { UserType } from 'models/user';
import { SearchAlertsCountResponse, SearchAlertsPayloadType, SearchAlertsResponse, SearchSetAsReadQuery } from 'models/alerts';

import { TableRefType } from 'components/TableResults/TableResults';

interface InvestigateAlertTabsProps {
  workflowStatus: WorkflowStatusType[] | undefined;
  teams: TeamType[] | undefined;
  allUsers: UserType[] | undefined;
  listUnread?(): void;
}

interface SearchFormType {
  submitAction(event?: SyntheticEvent): void;
  makingSearch: boolean;
  teams: TeamType[] | undefined;
  allUsers: UserType[] | undefined;
  canSaveFilters?: boolean;
  lastSearchQuery?: SearchAlertsPayloadType;
  exportResults(format: string, selection?: readonly string[]): void;
  getSelection?(): readonly string[] | undefined;
}

interface DisplaySearchResultsType {
  searchResults: (SearchAlertsResponse & SearchAlertsCountResponse) | null;
  // exportResults(format: string, selection?: readonly string[]): void;
  // lastSearchQuery?: SearchAlertsPayloadType;
  makeSortedSearch?(sortField: string, sortOrder: 'asc' | 'desc'): void;
  setAsRead?(ids?: readonly string[], unread?: boolean): void;
  updateSearchResult?(alert: Alert): void;
  tableRef: React.RefObject<TableRefType>;
}
interface ExportButtonProps {
  exportResults: SearchFormType['exportResults'];
  getSelection?(): readonly string[] | undefined;
}

/* elements */
const FILTERNAMES = {
  alertId: 'alertId',
  teams: 'teams',
  users: 'users',
  period: 'period',
};

const parseAlertsFilters = (filters: SearchFilterType[]) => {
  return (
    filters?.filter((f) => {
      const searchFields = f.searchFields?.map((sf) => sf.field);
      return (
        searchFields?.includes(savedSearchFields.alertId) ||
        searchFields?.includes(savedSearchFields.keyword) ||
        searchFields?.includes(savedSearchFields.group) ||
        searchFields?.includes(savedSearchFields.user) ||
        (searchFields?.includes(savedSearchFields.dateStart) && searchFields?.includes(savedSearchFields.dateEnd))
      );
    }) ?? []
  );
};

const InvestigateAlerts = () => {
  const { loadingStatus, workflowStatus, listStatus } = useContext(WorkflowContext);
  const { loadingTeams, teams, listTeams } = useContext(TeamsContext);
  const { loadingFilters, filters, listFilters } = useContext(SavedSearchFilterContext);
  const { loadingAllUsers, allUsers, listAllUsers } = useContext(AllUsersContext);
  const { nbUnread, listUnread } = useContext(UnreadAlertsContext);

  // refresh datasets
  const refreshData = () => {
    listStatus?.();
    listTeams?.();
    listAllUsers?.();
    listFilters?.();
    listUnread?.();
  };

  const reloadMissingData = () => {
    if (!workflowStatus) listStatus?.();
    if (!teams) listTeams?.();
    if (!allUsers) listAllUsers?.();
    if (!filters) listFilters?.();
    listUnread?.();
  };

  // display the loading layout
  if (loadingStatus || loadingTeams || loadingAllUsers || loadingFilters)
    return (
      <InvestigateAlertsLayout nbUnreadAlerts={0}>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      </InvestigateAlertsLayout>
    );

  // display a reload button if the required data are not fetched
  if ((!loadingStatus && !workflowStatus) || (!loadingTeams && !teams) || (!loadingAllUsers && !allUsers) || (!loadingFilters && !filters))
    return (
      <InvestigateAlertsLayout nbUnreadAlerts={0}>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={reloadMissingData}>
            <RefreshIcon sx={{ mb: 2 }} />
            <Box>
              <Typography>
                <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
              </Typography>
              <Typography>
                <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
              </Typography>
            </Box>
          </Button>
        </Box>
      </InvestigateAlertsLayout>
    );

  //

  return (
    <InvestigateAlertsLayout nbUnreadAlerts={nbUnread} refreshData={refreshData}>
      <InvestigateAlertsTabs {...{ workflowStatus, teams, allUsers, listUnread }} />
    </InvestigateAlertsLayout>
  );
};

const InvestigateAlertsLayout = ({ refreshData, nbUnreadAlerts, children }: PropsWithChildren<{ refreshData?(): void; nbUnreadAlerts: number }>) => {
  const intl = useIntl();
  const { permissions } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <Box flex={1}>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            color="red"
            badgeContent={nbUnreadAlerts}
            max={99}
            sx={{ '& .MuiBadge-badge': { fontWeight: '700', transform: 'scale(1) translate(calc(100%), -50%)' } }}
          >
            <PageTitle title={intl.formatMessage({ id: 'investigateAlertsTitle', defaultMessage: 'Alerts' })} />
          </Badge>
        </Box>
        {refreshData && (
          <Box>
            <ShadowedButton onClick={refreshData}>
              <RefreshIcon fontSize="inherit" sx={{ mr: 1 }} />
              <FormattedMessage id="refreshAlerts" defaultMessage="Refresh" />
            </ShadowedButton>
          </Box>
        )}

        {checkPermissions('manageScreeningsAlert', permissions) && (
          <Box>
            <Link to={routerPages.manageScreeningsAlert}>
              <ShadowedButton>
                <GearFilledIcon fontSize="inherit" sx={{ mr: 1 }} />
                <FormattedMessage id="alertsManageScreeningsAlert" defaultMessage="Manage screenings" />
              </ShadowedButton>
            </Link>
          </Box>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const SearchPagination = memo(Pagination);

const addPaginationToPayload = (params: { payload?: SearchAlertsPayloadType; currentPage: number; maxPerPage: string }) => {
  const { payload, currentPage, maxPerPage } = params;
  if (!payload) return payload;

  payload.pageNumber = currentPage;
  payload.maxPerPage = Number(maxPerPage);
  return payload;
};

const InvestigateAlertsTabs = memo((props: InvestigateAlertTabsProps) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();
  const { postAuthenticatedRequest } = useAuthenticatedRequest();

  const { currentUserId } = useContext(UserContext);

  const [activeStatus, setActiveStatus] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(defaultNumberPages));
  const [searchResults, setSearchResults] = useState<(SearchAlertsResponse & SearchAlertsCountResponse) | null>(null);
  const [makingSearch, setMakingSearch] = useState<boolean>(false);
  const [lastSearchQuery, setLastSearchQuery] = useState<SearchAlertsPayloadType>();

  const tableRef = useRef<TableRefType>(null);

  const getSelection = useCallback(() => {
    return tableRef.current?.getSelected();
  }, []);

  const { workflowStatus, teams, allUsers, listUnread } = props;

  const searchFormRef = useRef<HTMLFormElement>(null);

  const computeSearchPayload = useCallback((activeStatus: string) => {
    const formData = new FormData(searchFormRef.current ?? undefined);

    const searchInput = formData.get('searchInput') as string;

    // we don't need a valid search input here
    // if (!searchInput) return;

    const searchPayload: SearchAlertsPayloadType = {
      pageNumber: 0,
      maxPerPage: defaultNumberPages,
    };

    let alert: SearchAlertsPayloadType['alert'] = {};
    if (activeStatus) {
      alert.status = { id: Number(activeStatus) };
    }

    if (searchInput) {
      searchPayload.keyword = searchInput.trim();
      // alert.source = { names: [{ fullName: searchInput.trim() }] };
    }
    const alertIdValue = JSON.parse(formData.get(FILTERNAMES.alertId) as string) as FilterValueType;
    if (alertIdValue && alertIdValue.nbValues > 0) {
      alert.id = Number(alertIdValue.value);
    }

    const teamValues = JSON.parse(formData.get(FILTERNAMES.teams) as string) as FilterValueType;
    if (teamValues && teamValues.nbValues > 0 && teamValues.values) {
      searchPayload.groupFilter = teamValues.values.map((v) => Number(v));
    }

    const userValues = JSON.parse(formData.get(FILTERNAMES.users) as string) as FilterValueType;
    if (userValues && userValues.nbValues > 0 && userValues.values) {
      searchPayload.userFilter = userValues.values.map((v) => Number(v));
    }

    const periodValues = JSON.parse(formData.get(FILTERNAMES.period) as string) as FilterValueType;
    if (periodValues && periodValues.nbValues > 0) {
      const dateStart = dayjs(periodValues.dayValue);
      const dateEnd = dayjs(periodValues.dayEndValue);

      if (dateStart && dateEnd) {
        searchPayload.dateRangeField = 'alertDTG';
        searchPayload.date1 = dateStart.format('DD/MM/YYYY');
        searchPayload.date2 = dateEnd.format('DD/MM/YYYY');
      }
    }

    if (Object.keys(alert).length > 0) {
      searchPayload.alert = alert;
    }

    return searchPayload;
  }, []);

  const makeSearch = useCallback(
    async (searchPayload?: SearchAlertsPayloadType) => {
      if (!searchFormRef.current) return;
      if (!currentUserId) return;
      if (!searchPayload) return;

      if (makingSearch) return;

      setMakingSearch(true);
      setSearchResults(null);
      setLastSearchQuery(undefined);

      try {
        // const searchPayload = computeSearchPayload(formData, searchInput as string, currentPage, maxPerPage, activeStatus);

        const queries = [postAuthenticatedRequest(URLConstants.alertList, searchPayload), postAuthenticatedRequest(URLConstants.alertCount, searchPayload)];

        const [alertResponse, alertCount] = (await Promise.all(queries)) as [SearchAlertsResponse, SearchAlertsCountResponse];

        setLastSearchQuery(searchPayload);

        const newSearchResults = {
          alerts: alertResponse.alerts ?? [],
          amountOfAlerts: alertCount.amountOfAlerts,
        } as SearchAlertsResponse & SearchAlertsCountResponse;

        if (searchPayload.sortField && searchPayload.sortOrder) {
          newSearchResults.sortField = searchPayload.sortField;
          newSearchResults.sortOrder = searchPayload.sortOrder.toLowerCase();
        }

        setSearchResults(newSearchResults);
      } catch (searchError) {
        addSnackbar(
          intl.formatMessage({
            id: 'searchRequestError',
            defaultMessage: 'An error occured for your request',
          }),
          'error'
        );
      }

      setMakingSearch(false);
    },
    [currentUserId, makingSearch, addSnackbar, intl, postAuthenticatedRequest]
  );

  const handleTabChange = (event: React.SyntheticEvent, tabValue: 'string') => {
    const newStatus = tabValue;
    setActiveStatus(newStatus);

    const newCurrentPage = 0;
    setCurrentPage(newCurrentPage);

    const searchPayload = computeSearchPayload(newStatus);
    if (!searchPayload) return;

    const paginatedPayload = addPaginationToPayload({ payload: searchPayload, currentPage: newCurrentPage, maxPerPage: maxPerPage });

    makeSearch(paginatedPayload);
  };

  const setMaxAndSearch = (event: SelectChangeEvent) => {
    const newMaxPerPage = event.target.value;
    if (newMaxPerPage === maxPerPage) return;

    const newCurrentPage = 0;
    setMaxPerPage(newMaxPerPage);
    setCurrentPage(newCurrentPage);

    // take the lastest search payload
    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newCurrentPage, maxPerPage: newMaxPerPage });

    makeSearch(paginatedPayload);
  };

  const changePage = (shift: 1 | -1) => {
    if (!searchResults || !searchResults.amountOfAlerts) return;
    if (!lastSearchQuery) return;

    const newPage = currentPage + shift;

    // check page is in bounds
    if (newPage < 0) return;
    if (newPage > searchResults.amountOfAlerts / Number(maxPerPage)) return;

    setCurrentPage(newPage);

    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newPage, maxPerPage: maxPerPage });

    makeSearch(paginatedPayload);
  };

  const submitSearchForm = (event?: React.SyntheticEvent) => {
    event?.preventDefault();

    const newCurrentPage = 0;
    setCurrentPage(newCurrentPage);

    const searchPayload = computeSearchPayload(activeStatus);
    if (!searchPayload) return;

    const paginatedPayload = addPaginationToPayload({
      payload: searchPayload,
      currentPage: newCurrentPage,
      maxPerPage: maxPerPage,
    });
    makeSearch(paginatedPayload);
  };

  const makeSortedSearch = useCallback(
    async (sortField: string, sortOrder: 'asc' | 'desc') => {
      if (!lastSearchQuery) return;

      const searchPayload = lastSearchQuery;
      searchPayload.sortField = sortField;
      searchPayload.sortOrder = sortOrder.toUpperCase();

      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);

      searchPayload.pageNumber = newCurrentPage;

      makeSearch(searchPayload);
    },
    [makeSearch, lastSearchQuery]
  );

  const setAsRead = useCallback(
    async (ids?: readonly string[], unread?: boolean) => {
      if (!ids) return;
      if (!currentUserId) return;
      if (makingSearch) return;

      const parsedIds = ids.map((id) => Number(id));

      setMakingSearch(true);

      try {
        const payload: SearchSetAsReadQuery = {
          alertIds: parsedIds,
        };

        const unreadUrl = unread ? URLConstants.alertSetAsUnread : URLConstants.alertSetAsRead;

        await postAuthenticatedRequest(unreadUrl, payload);

        // trigger reload of nb unread after set
        listUnread?.();

        setSearchResults((currentResults) => {
          if (!currentResults?.alerts) return currentResults;

          const res = { ...currentResults };

          parsedIds.forEach((id) => {
            res.alerts.filter((alert) => alert.id === id)[0].unread = unread ? true : false;
          });

          return res;
        });
      } catch (searchError) {
        addSnackbar(
          intl.formatMessage({
            id: 'searchRequestError',
            defaultMessage: 'An error occured for your request',
          }),
          'error'
        );
      }

      setMakingSearch(false);
    },
    [currentUserId, makingSearch, addSnackbar, intl, postAuthenticatedRequest, listUnread]
  );

  const updateSearchResult = useCallback((alert: Alert) => {
    setSearchResults((result) => {
      if (!result || !alert.id) return result;

      const alertIndex = result?.alerts.findIndex((res) => res.id === alert.id) ?? -1;
      if (alertIndex < 0) return result;

      const newResults = { ...result };
      newResults.alerts[alertIndex] = alert;

      return newResults;
    });
  }, []);

  // trigger search on load
  useEffect(() => {
    // list unread
    listUnread?.();

    // makeSearch(currentPage, maxPerPage, activeStatus);
    submitSearchForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportResults = async (format: 'csv' | 'pdf', selection?: readonly string[]) => {
    let exportUrl;
    let filename = '';
    switch (format) {
      case 'csv':
        exportUrl = URLConstants.alertExportCSV;
        filename = 'alerts.zip';
        break;
      // case 'pdf':
      //   exportUrl = URLConstants.alertExportPDF;
      //   filename = 'search.pdf';
      //   break;
      default:
        break;
    }
    if (!exportUrl) return;
    if (!lastSearchQuery) return;

    setMakingSearch(true);

    try {
      const exportQuery = { ...lastSearchQuery };
      if (selection) {
        exportQuery.alertFilter = selection.map((s) => Number(s));
      }
      const response = (await postAuthenticatedRequest(exportUrl, exportQuery, undefined, { blob: true })) as BlobPart;

      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); //any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (ExportError) {
      addSnackbar(
        intl.formatMessage({
          id: 'searchExportError',
          defaultMessage: 'An error occured while exporting your results',
        }),
        'error'
      );
    }

    setMakingSearch(false);
  };

  const workflowStatusMemo = useMemo(() => {
    return (workflowStatus ?? []).filter((ws) => ws && ws.code).map((ws) => ({ text: ws.code ?? ws.code, value: ws.id }));
  }, [workflowStatus]);

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      {/* Search Tabs */}
      <TabsNavigation activeTab={activeStatus} tabTitles={workflowStatusMemo} onChange={handleTabChange} label={'workflow status'} disabled={makingSearch} />

      {/* Search Form */}
      <SearchForm
        ref={searchFormRef}
        {...{
          submitAction: submitSearchForm,
          makingSearch,
          teams,
          allUsers,
          canSaveFilters: false,
          lastSearchQuery,
          exportResults,
          getSelection,
        }}
      />

      {/* Search results */}
      {makingSearch ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <DisplaySearchResults {...{ searchResults, makeSortedSearch, setAsRead, updateSearchResult, tableRef }} />
      )}

      {/* Search pagination */}
      {!makingSearch && searchResults && (searchResults.amountOfAlerts ?? 0) > 0 && (
        <SearchPagination
          {...{
            maxPerPage: Number(maxPerPage),
            currentPage,
            nbResults: searchResults.amountOfAlerts,
            setMaxAndCallback: setMaxAndSearch,
            changePageCallback: changePage,
          }}
        />
      )}
    </Box>
  );
});

const SearchFiltersMemo = memo(SearchFilters);

const SaveFilters = ({ savePayload, createFilter }: { savePayload: CreateSavedSearchQuery; createFilter?: (query: CreateSavedSearchQuery) => void }) => {
  const { closeModal } = useAddModal();
  const intl = useIntl();
  const [name, setName] = useState<string>('');

  return (
    <Box sx={{ minWidth: 300 }}>
      <Box px={3}>
        <Typography className="modal-label">
          <FormattedMessage id="name" defaultMessage={'Name'} />
        </Typography>
        <SearchText
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
          placeholder={intl.formatMessage({
            id: 'name',
            defaultMessage: 'Name',
          })}
          InputProps={{
            startAdornment: null,
          }}
        />
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          onClick={() => {
            closeModal?.();
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </ShadowedButton>

        <Button
          type="button"
          variant="contained"
          disabled={!name}
          disableElevation
          onClick={() => {
            savePayload.name = name;
            createFilter?.(savePayload);
            closeModal?.();
          }}
          sx={{ textTransform: 'none' }}
        >
          <FormattedMessage id="save" defaultMessage="Save" />
        </Button>
      </Box>
    </Box>
  );
};

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { submitAction, makingSearch, teams, allUsers, canSaveFilters } = props;
    const { lastSearchQuery, exportResults, getSelection } = props;
    const { filters, deleteFilter, createFilter } = useContext(SavedSearchFilterContext);

    const { toggleModal } = useAddModal();

    const savedFilters = parseAlertsFilters(filters ?? []);

    const [searchInput, setSearchInput] = useState<string>('');

    // define required ref
    const inputIdRef = useRef<FilterButtonRefType | null>(null);
    const teamsRef = useRef<FilterButtonRefType | null>(null);
    const usersRef = useRef<FilterButtonRefType | null>(null);
    const dateRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      const buttons: SearchFiltersButtonType[] = [       
        {
          ref: dateRef,
          text: <FormattedMessage id="date" defaultMessage="Date" />,
          inputName: FILTERNAMES.period,
          filterContent: <DateRangeFilter title={intl.formatMessage({ id: 'selectRangePeriod', defaultMessage: 'Select date' })} />,
        },
        {
          ref: teamsRef,
          text: <FormattedMessage id="team" defaultMessage="Team" />,
          inputName: FILTERNAMES.teams,
          filterContent: (
            <FilterVirtualCheckboxes
              title={intl.formatMessage({ id: 'team', defaultMessage: 'Team' })}
              list={(teams ?? []).map((t) => ({ key: String(t.id), value: t.name ?? t.description }))}
              emptyMessage={intl.formatMessage({ id: 'noSearchTeam', defaultMessage: "There's no team yet. Please add them through your administration setup panel" })}
            />
          ),
        },
        {
          ref: usersRef,
          text: <FormattedMessage id="user" defaultMessage="User" />,
          inputName: FILTERNAMES.users,
          filterContent: (
            <FilterVirtualCheckboxes
              title={intl.formatMessage({ id: 'user', defaultMessage: 'User' })}
              list={(allUsers ?? []).map((u) => ({ key: String(u.id), value: u.fullName ?? u.userName }))}
              emptyMessage={intl.formatMessage({ id: 'noSearchUser', defaultMessage: "There's no user yet. Please add them through your administration users panel" })}
            />
          ),
        },
      ];

      return buttons;
    }, [intl, teams, allUsers]);

    const loadSavedFilter = useCallback(
      (filter: SearchFilterType) => {
        let filterObject: { [key: string]: any } = {};
        filter.searchFields?.forEach((sf) => {
          switch (sf.field) {
            case savedSearchFields.alertId:
            case savedSearchFields.keyword:
            case savedSearchFields.dateStart:
            case savedSearchFields.dateEnd:
              filterObject[sf.field] = sf.valueStr;
              break;
            case savedSearchFields.group:
            case savedSearchFields.user:
              filterObject[sf.field] = sf.valueStr;
              filterObject[sf.field] = sf.listOfValueInt;
              break;
            default:
              break;
          }
        });

        if (filterObject[savedSearchFields.alertId]) {
          inputIdRef.current?.setFilter({
            nbValues: 1,
            value: filterObject[savedSearchFields.alertId],
          });
        } else {
          inputIdRef.current?.setFilter({
            nbValues: 0,
          });
        }

        if (filterObject[savedSearchFields.dateStart] && filterObject[savedSearchFields.dateEnd]) {
          dateRef.current?.setFilter({
            nbValues: 1,
            dayValue: dayjs(filterObject[savedSearchFields.dateStart], 'DD/MM/YYYY'),
            dayEndValue: dayjs(filterObject[savedSearchFields.dateEnd], 'DD/MM/YYYY'),
          });
        } else {
          dateRef.current?.setFilter({
            nbValues: 0,
          });
        }

        if (filterObject[savedSearchFields.group] && teams) {
          const validTeamsId = teams.map((t) => t.id);
          const filteredValues = (filterObject[savedSearchFields.group] as []).filter((v) => validTeamsId.includes(v)).map((gs) => String(gs));
          teamsRef.current?.setFilter({
            nbValues: filteredValues.length,
            values: filteredValues,
          });
        } else {
          teamsRef.current?.setFilter({
            nbValues: 0,
          });
        }

        if (filterObject[savedSearchFields.user] && allUsers) {
          const validUserId = allUsers?.map((u) => u.id);
          const filteredValues = (filterObject[savedSearchFields.user] as []).filter((u) => validUserId.includes(u)).map((gs) => String(gs));
          usersRef.current?.setFilter({
            nbValues: filteredValues.length,
            values: filteredValues,
          });
        } else {
          usersRef.current?.setFilter({
            nbValues: 0,
          });
        }

        if (filterObject[savedSearchFields.keyword]) {
          setSearchInput(filterObject[savedSearchFields.keyword]);
        } else {
          setSearchInput('');
        }
      },
      [teams, allUsers]
    );

    const deleteSavedFilter = useCallback(
      (filterId: number) => {
        deleteFilter?.(filterId);
      },
      [deleteFilter]
    );

    const saveCurrentFilter = useCallback(() => {
      const savePayload: CreateSavedSearchQuery = {
        searchFields: [],
      };

      if (searchInput) {
        savePayload.searchFields?.push({
          field: savedSearchFields.keyword,
          valueStr: searchInput,
        });
      }

      if (inputIdRef && inputIdRef.current?.getValue) {
        const idValue = inputIdRef.current.getValue();
        if (idValue.nbValues > 0 && idValue.value) {
          savePayload.searchFields?.push({
            field: savedSearchFields.alertId,
            valueStr: idValue.value,
          });
        }
      }

      if (teamsRef && teamsRef.current?.getValue) {
        const teamValue = teamsRef.current.getValue();
        if (teamValue.nbValues > 0 && teamValue.values) {
          savePayload.searchFields?.push({
            field: savedSearchFields.group,
            listOfValueInt: teamValue.values.map((v) => Number(v)),
          });
        }
      }

      if (usersRef && usersRef.current?.getValue) {
        const userValue = usersRef.current.getValue();
        if (userValue.nbValues > 0 && userValue.values) {
          savePayload.searchFields?.push({
            field: savedSearchFields.user,
            listOfValueInt: userValue.values.map((v) => Number(v)),
          });
        }
      }

      if (dateRef && dateRef.current?.getValue) {
        const dateValue = dateRef.current.getValue();
        if (dateValue.nbValues > 0 && dateValue.dayValue && dateValue.dayEndValue) {
          savePayload.searchFields?.push({
            field: savedSearchFields.dateStart,
            valueStr: dateValue.dayValue.format('DD/MM/YYYY'),
          });
          savePayload.searchFields?.push({
            field: savedSearchFields.dateEnd,
            valueStr: dateValue.dayEndValue.format('DD/MM/YYYY'),
          });
        }
      }

      if (savePayload.searchFields?.length === 0) return;

      toggleModal?.({ title: intl.formatMessage({ id: 'saveFilters', defaultMessage: 'Save filters' }), modalContent: <SaveFilters savePayload={savePayload} createFilter={createFilter} /> });
    }, [toggleModal, searchInput, intl, createFilter]);

    const updateSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    }, []);
    const clearSearchInput = useCallback(async () => {
      await setSearchInput('');
      submitAction();
    }, [submitAction]);

    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    return (
      <Box width={'100%'} display={'flex'} alignItems={'flex-start'} px={0} py={4} columnGap={'1rem'}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '250px' } }}>
              <SearchText
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                clearAction={clearSearchInput}
                placeholder={intl.formatMessage({ id: 'searchKeywordsAlertPlaceholder', defaultMessage: 'Search alerts' })}
                inputProps={searchInputProps}
                disabled={makingSearch}
              />
            </Box>

            <Box>
              <Button type="submit" variant="contained" disableElevation disabled={makingSearch} onClick={submitAction} sx={{ textTransform: 'none' }}>
                <FormattedMessage id="searchButton" defaultMessage="Search" />
              </Button>
            </Box>

            {searchFiltersButtons.length > 0 && (
              <>
                <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

                <SearchFiltersMemo
                  disabled={makingSearch}
                  {...{ searchFiltersButtons, canSaveFilters, savedFilters, loadSavedFilter, deleteSavedFilter, saveCurrentFilter, hasExternalFilterValue: !!searchInput }}
                />
              </>
            )}
          </Box>
        </form>
        {lastSearchQuery && (
          <Box ml={'auto'} pt={0.5}>
            <ExportButton
              {...{
                exportResults,
                getSelection,
              }}
            />
          </Box>
        )}
      </Box>
    );
  })
);

const ExportButton = ({ exportResults, getSelection }: ExportButtonProps) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onButtonClick = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  // const buttonStyle: React.CSSProperties = {
  //   fontSize: 'var(--fs-14)',
  //   fontWeight: 400,
  //   letterSpacing: '0.01em',
  //   color: '#151414',
  //   padding: '8px',
  //   lineHeight: 1.2,
  // };

  return (
    <>
      <Button
        ref={buttonRef}
        type="submit"
        variant="contained"
        disableElevation
        sx={{
          '.MuiButton-startIcon svg': {
            fontSize: '12px',
          },
        }}
        startIcon={<ExportArrowIcon />}
        onClick={onButtonClick}
      >
        <FormattedMessage id="Export" defaultMessage="Export" />
      </Button>
      <Popover
        id={'popover-button'}
        open={filterOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
        slotProps={{ paper: { sx: { boxShadow: 'rgba(51, 51, 51, 0.08) 0px 12px 24px' } } }}
      >
        <Box
          mt={0.5}
          p={1}
          display="flex"
          flexDirection={'column'}
          alignItems={'flex-start'}
          sx={{ backgroundColor: 'white', borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)', boxShadow: 'rgba(51, 51, 51, 0.08) 0px 12px 24px' }}
        >
          <ShadowedButton
            fullWidth
            variant="text"
            className="no-hover"
            onClick={() => {
              handleClose();
              exportResults('csv');
            }}
            sx={{ justifyContent: 'flex-start', whiteSpace: 'nowrap', '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
          >
            <ExportCSVIcon fontSize="inherit" sx={{ mr: 1, fontSize: '16px' }} />
            <FormattedMessage id="exportAll" defaultMessage="Export all as CSV" />
          </ShadowedButton>

          <ShadowedButton
            fullWidth
            variant="text"
            className="no-hover"
            onClick={() => {
              const selection = getSelection?.();
              handleClose();

              if (!selection || selection.length <= 0) {
                addSnackbar(
                  intl.formatMessage({
                    id: 'noValidAlertsSelection',
                    defaultMessage: 'You must select some alerts',
                  }),
                  'error'
                );
                return;
              }

              exportResults('csv', selection);
            }}
            sx={{ justifyContent: 'flex-start', textAlign: 'right', whiteSpace: 'nowrap', '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
          >
            <ExportCSVIcon fontSize="inherit" sx={{ mr: 1, fontSize: '16px' }} />
            <FormattedMessage id="exportSelection" defaultMessage="Export selection as CSV" />
          </ShadowedButton>
        </Box>
      </Popover>
    </>
  );
};

const DisplaySearchResults = memo((props: DisplaySearchResultsType) => {
  const { searchResults, makeSortedSearch, setAsRead, updateSearchResult } = props;
  const { tableRef } = props;

  const { teams } = useContext(TeamsContext);
  const { allUsers } = useContext(AllUsersContext);

  const { editAlertStatus } = useContext(AlertsContext);
  const { toggleModal } = useAddModal();
  const addSnackbar = useAddSnackbar();

  const [nbSelected, setNbSelected] = useState<number>(0);

  const intl = useIntl();
  const viewAlert = useViewAlert();

  const handleEditOwner = useCallback(
    (alert: Alert) => {
      if (!alert) return;
      const setOwner = async (payload: SetActionPayload) => {
        try {
          const alertResult = await editAlertStatus?.({
            newStatusId: payload.newStatusId,
            comment: payload.comment,
            userType: payload.userType,
            userId: payload.userId ? Number(payload.userId) : -1,
            alertId: alert.id,
            editUser: true,
          });

          if (alertResult) {
            updateSearchResult?.(alertResult);
          }
        } catch {
          addSnackbar(
            intl.formatMessage({
              id: 'editStatusError',
              defaultMessage: 'An error occured while editing alert status',
            }),
            'error'
          );
        }
      };

      toggleModal?.({
        title: intl.formatMessage({ id: 'editAlertOwner', defaultMessage: 'Edit alert owner' }),
        modalContent: <EditStatusOrOwner {...{ alert, allUsers, teams, setAction: setOwner, editOwner: true }} />,
      });
    },
    [intl, toggleModal, allUsers, teams, addSnackbar, editAlertStatus, updateSearchResult]
  );

  const [bulkAction, setBulkAction] = useState<string>('setAsRead');

  const alertsHeader = useMemo((): TableHeadElement[] => {
    let sortedColumn = searchResults?.sortField ?? '';
    let sortedDirection = searchResults?.sortOrder ?? false;

    return [
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'alert',
          defaultMessage: 'alert',
        }),
        minWidth: '180px',
        // sorted: () => {
        //   makeSortedSearch?.('name', sortedColumn !== 'name' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        // },
        // direction: sortedColumn === 'name' ? sortedDirection : false,
      },
      {
        id: 'clientData',
        label: intl.formatMessage({
          id: 'clientData',
          defaultMessage: 'Client Data',
        }),
        minWidth: '180px',
      },
      {
        id: 'bestMatch',
        label: intl.formatMessage({
          id: 'highestHit',
          defaultMessage: 'Highest Hit',
        }),
        minWidth: '180px',
      },
      {
        id: 'bestMatchList',
        label: intl.formatMessage({
          id: 'watchlist',
          defaultMessage: 'Watchlist',
        }),
      },
      {
        id: 'score',
        label: intl.formatMessage({
          id: 'highestScore',
          defaultMessage: 'Highest Score',
        }),
        sorted: () => {
          makeSortedSearch?.('matchScore', sortedColumn !== 'matchScore' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'matchScore' ? sortedDirection : false,
      },
      {
        id: 'owner',
        label: intl.formatMessage({
          id: 'owner',
          defaultMessage: 'Owner',
        }),
      },
      {
        id: 'status',
        label: intl.formatMessage({
          id: 'status',
          defaultMessage: 'Status',
        }),
      },
      {
        id: 'date',
        label: intl.formatMessage({
          id: 'date',
          defaultMessage: 'Date',
        }),
        sorted: () => {
          makeSortedSearch?.('alertDate', sortedColumn !== 'alertDate' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
        },
        direction: sortedColumn === 'alertDate' ? sortedDirection : false,
      },
      // {
      //   id: 'alertId',
      //   label: intl.formatMessage({
      //     id: 'alertId',
      //     defaultMessage: 'Alert Id',
      //   }),
      //   sorted: () => {
      //     makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
      //   },
      //   direction: sortedColumn === 'id' ? sortedDirection : false,
      // },
    ];
  }, [intl, searchResults, makeSortedSearch]);

  const alerts = useMemo((): TableRowData[] => {
    return (
      searchResults?.alerts.map((a, a_ind) => {
        let alertDate = null;
        if (a.alertDate) {
          alertDate = <DisplayDate date={a.alertDate} timezone={a.alertTimeZone} />;
        }
        let owner: string | ReactNode = <UnknownMessage customText={intl.formatMessage({ id: 'unassigned' })} />;
        if (a?.assignedToGroup) {
          owner = GetTeam(a?.assignedToGroup.name, teams);
        } else if (a?.assignedToUser) {
          owner = GetUser(a?.assignedToUser.userName, allUsers);
        }

        const allMatchListLabels = Array.from(
          new Set(
            a.target
              ?.map((t) => {
                if (!t.watchListPersons) return null;
                return t.watchListPersons.label ?? null;
              })
              .filter((l) => l !== null)
          )
        );

        const nbMatch = Math.max((a.target ?? []).length - 1, 0);
        let bestMatchTooltip = null;
        if (nbMatch > 0 && a.target) {
          bestMatchTooltip = GenerateTooltip(
            a.target.slice(1, 5 + 1).map((target) => formatMatchingName(target.watchListPersons?.names[0])),
            nbMatch - 5
          );
        }

        return {
          id: String(a.id) ?? String(a_ind),
          columns: {
            name: (
              <Box>
                <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }}>
                  {a.name ?? a.description ?? ''}
                </Typography>
                <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'var(--color-gray-new)', fontSize: 'var(--fs-12)' }}>
                  {a.id ?? ''}
                </Typography>
              </Box>
            ),
            date: alertDate,
            clientData: (
              <Box>
                <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit', whiteSpace: 'nowrap', maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {formatMatchingName(a.source?.names?.[0]) ?? ''}
                </Typography>
                <Typography
                  fontSize="inherit"
                  fontWeight="inherit"
                  sx={{ color: 'var(--color-gray-new)', fontSize: 'var(--fs-12)', whiteSpace: 'nowrap', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {a.source?.dataID ?? ''}
                </Typography>
              </Box>
            ),
            // bestMatch: formatMatchingName(a.target?.[0].watchListPersons?.names?.[0]),
            bestMatch: (
              <Box>
                <StyledBadge tooltip={bestMatchTooltip} badgeContent={nbMatch}>
                  <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit', pr: '2rem', whiteSpace: 'nowrap', maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {a.target?.[0].watchListPersons?.names?.[0] ? formatMatchingName(a.target?.[0].watchListPersons?.names?.[0]) : 'Unknown'}
                  </Typography>
                </StyledBadge>
                <Typography
                  fontSize="inherit"
                  fontWeight="inherit"
                  sx={{ color: 'var(--color-gray-new)', fontSize: 'var(--fs-12)', whiteSpace: 'nowrap', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {a.target?.[0].watchListPersons?.dataID ?? ''}
                </Typography>
              </Box>
            ),

            bestMatchList:
              allMatchListLabels.length > 0 ? (
                <Box display="flex" columnGap={'.5rem'} alignItems={'center'}>
                  <StatusTag tag_label={allMatchListLabels[0] ?? ''} hideActive={true} bg={'white'} textColor="#6E6C6B" />
                  {allMatchListLabels.length > 1 && (
                    <Tooltip
                      placement="right"
                      slotProps={{
                        tooltip: {
                          className: 'custom-scrollbar',
                          sx: {
                            backgroundColor: 'white',
                            border: '1px solid var(--color-grayHeaderBorder)',
                            maxHeight: 200,
                            overflow: 'auto',
                          },
                        },
                      }}
                      title={
                        <Box px={1} py={1} display="flex" flexDirection="column" rowGap={'.5rem'}>
                          {allMatchListLabels.slice(1).map((l) => (
                            <Box key={l}>{l && <StatusTag tag_label={l} hideActive={true} bg={'white'} textColor="#6E6C6B" />}</Box>
                          ))}
                        </Box>
                      }
                    >
                      <Box>
                        <StatusTag tag_label={`+${allMatchListLabels.length - 1}`} hideActive={true} bg={'white'} textColor="#6E6C6B" />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              ) : (
                ''
              ),
            score: <GaugeMeter value={a.matchScore} />,
            owner: (
              <Box display="flex" alignItems="center" sx={{ height: '100%', '&:hover .edit-box': { visibility: 'visible', pointerEvents: 'all' } }}>
                {owner}
                <Box ml={0.5} className="edit-box" sx={{ visibility: owner ? 'hidden' : 'hidden', pointerEvents: owner ? 'none' : 'none' }}>
                  <ShadowedButton
                    sx={{ ml: 1, px: 1, py: 0.5, minWidth: 0, backgroundColor: 'white' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEditOwner(a);
                    }}
                  >
                    <FormattedMessage id="edit" defaultMessage="Edit" />
                  </ShadowedButton>
                </Box>
              </Box>
            ),
            status: (
              <Box>
                <StatusTag tag_label={a.status?.code} bg={'white'} />
              </Box>
            ),
            // alertId: (
            //   <Box display={'flex'} alignItems={'center'}>
            //     <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }} mr={2}>
            //       {a.id}
            //     </Typography>
            //     {/* <ShadowedButton
            //     onClick={() => {
            //       viewAlert(a.id);
            //     }}
            //     sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
            //   >
            //     <FormattedMessage id="viewMore" defaultMessage="View More" />
            //   </ShadowedButton> */}
            //   </Box>
            // ),
          },
          unread: a.unread ?? false,
          rowClick: () => {
            viewAlert(a.id, (alert: Alert) => {
              updateSearchResult?.(alert);
            });
          },
        };
      }) ?? []
    );
  }, [searchResults, viewAlert, allUsers, teams, updateSearchResult, intl, handleEditOwner]);

  const selectBulkAction = (event: any) => {
    const selectValue = event.target.value;
    setBulkAction(selectValue);
  };
  const bulkActionChoices = useMemo(() => {
    return [
      // {
      //   key: 'editStatus',
      //   value: <FormattedMessage id="editStatus" defaultMessage="Edit Status" />,
      // },
      {
        key: 'setAsRead',
        value: (
          <Box display="flex" alignItems={'center'}>
            <EnvelopeOpenIcon sx={{ mr: 0.5, fontSize: 'var(--fs-12)' }} />
            <FormattedMessage id="MarkAsRead" defaultMessage="Mark as read" />
          </Box>
        ),
        action: setAsRead,
      },
      {
        key: 'setAsUnread',
        value: (
          <Box display="flex" alignItems={'center'}>
            <EnvelopeDotIcon sx={{ mr: 0.5, fontSize: 'var(--fs-12)' }} />
            <FormattedMessage id="MarkAsUnread" defaultMessage="Mark as unread" />
          </Box>
        ),
        action: (selected: readonly string[]) => setAsRead?.(selected, true),
      },
    ];
  }, [setAsRead]);

  const applyBulk = () => {
    const selected = tableRef.current?.getSelected();
    if ((selected?.length ?? 0) <= 0) return;

    const action = bulkActionChoices.filter((ba) => ba.key === bulkAction)?.[0]?.action ?? null;

    if (action) {
      action(selected);
    }
  };

  const onRowSelect = useCallback((selection: readonly string[]) => {
    setNbSelected(selection.length);
  }, []);

  if (!searchResults || !searchResults.alerts) return <></>;

  return (
    <Box>
      {searchResults.amountOfAlerts <= 0 && (
        <Box px={2} py={1} border={1} borderColor={'var(--color-grayHeaderBorder)'} display={'flex'} sx={{ flexFlow: 'row wrap', borderRadius: '5px' }} alignItems={'center'}>
          <Typography fontWeight={600} fontSize={'1.25rem'} sx={{ color: 'var(--color-darkgray)', textTransform: 'capitalize' }}>
            <Typography component="span" fontWeight={'inherit'} fontSize={'inherit'} sx={{ color: 'var(--color-gray1)' }}>
              {searchResults.amountOfAlerts}
            </Typography>{' '}
            {searchResults.amountOfAlerts > 1 ? <FormattedMessage id="results" defaultMessage="results" /> : <FormattedMessage id="result" defaultMessage="result" />}
          </Typography>
        </Box>
      )}
      {searchResults.amountOfAlerts > 0 && searchResults.alerts.length > 0 && (
        <Box>
          {nbSelected > 0 && false && (
            <Box display="flex" alignItems={'center'} gap="1.5rem" mb={2}>
              <Box display="flex" alignItems={'center'} columnGap={'.5rem'}>
                <Typography py={'5px'} pr={1} sx={{ fontSize: 'var(--fs-14)', color: 'inherit' }}>
                  {nbSelected} <FormattedMessage id="selected" defaultMessage={'selected'} />
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Button
                  onClick={() => {
                    tableRef?.current?.clearSelection();
                    setNbSelected(0);
                  }}
                  color="primary"
                  sx={{ py: 0 }}
                >
                  Deselect
                </Button>
              </Box>
              <Box maxWidth={200} fontSize={'.875rem'}>
                <SearchSelect
                  value={bulkAction}
                  onChange={selectBulkAction}
                  fullWidth
                  choices={bulkActionChoices}
                  placeholder={intl.formatMessage({ id: 'bulkActions', defaultMessage: 'Bulk Actions' })}
                  hidePlaceholderChoice
                  MenuProps={{
                    elevation: 0,
                    sx: {
                      '& .MuiPaper-root': {
                        mt: '2px',
                        borderRadius: '5px',
                        border: '1px solid var(--color-grayHeaderBorder)',

                        '& .MuiMenuItem-root ': {
                          letterSpacing: '0.01em',
                          color: '#151414',
                          lineHeight: 1.2,
                          fontWeight: 500,
                        },
                      },
                    },
                  }}
                  sx={{
                    fontWeight: 500,
                  }}
                />
              </Box>
              <ShadowedButton onClick={applyBulk} disabled={!bulkAction}>
                <FormattedMessage id="apply" defaultMessage="Apply" />
              </ShadowedButton>
            </Box>
          )}

          {/* searchResults.alerts.map(sr=>({})) */}
          <TableResults ref={tableRef} hasUnread={true} hasSelection={true} tableData={alerts} tableHead={alertsHeader} onSelectHandler={onRowSelect} />
        </Box>
      )}
    </Box>
  );
});

export default InvestigateAlerts;
