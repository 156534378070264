/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
/* types */
import { DeleteTeamResponseType, TeamType, TeamsResponse, UpdateTeamResponseType } from 'models/teams';

interface TeamsContextType {
  // statusQueried: boolean;
  loadingTeams: boolean;
  teams?: TeamType[];
  listTeams?(abortController?: AbortController): void;
  addOrUpdateTeam?(data: Partial<TeamType>, create?: boolean, abortController?: AbortController): Promise<TeamType | undefined>;
  deleteTeam?(teamId: number, abortController?: AbortController): Promise<DeleteTeamResponseType | undefined>;
}
/* elements */
const TeamsContext = createContext<TeamsContextType>({
  // statusQueried: false,
  loadingTeams: false,
  teams: undefined,
  listTeams: undefined,
});

const TeamsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  const listTeamsOngoing = useRef(false);

  const [loadingTeams, setLoadingTeams] = useState<boolean>(false);
  const [teams, setTeams] = useState<TeamType[] | undefined>(undefined);

  const listTeams = useCallback(
    async (abortController?: AbortController) => {
      if (listTeamsOngoing.current) return;

      // check permissions
      // const allowed = checkPermissions('listAllUsers', permissions);
      // if (!allowed) return;

      listTeamsOngoing.current = true;
      setLoadingTeams(true);
      setTeams(undefined);
      try {
        const listTeamsUrl = URLConstants.listTeams;
        const results = (await getAuthenticatedRequest(listTeamsUrl, abortController ?? undefined)) as TeamsResponse;

        const teamsResults = results.groups ?? [];

        // setStatusQueried(true);
        setLoadingTeams(false);
        setTeams(teamsResults);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingTeams(false);
        setTeams(undefined);
      }
      listTeamsOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );

  const addOrUpdateTeam = useCallback(
    async (data: Partial<TeamType>, create = true, abortController?: AbortController): Promise<TeamType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupTeams', permissions);
      if (!allowed) return undefined;

      try {
        const editTeamUrl = create ? URLConstants.teamCreate : URLConstants.teamUpdate;
        const results = (await postAuthenticatedRequest(editTeamUrl, { group: data }, abortController ?? undefined)) as UpdateTeamResponseType;

        const updatedTeam = results.group;
        setTeams((currentTeams) => {
          if (!updatedTeam) return currentTeams;
          if (!currentTeams) return [updatedTeam];

          const newTeams = [...currentTeams];
          const teamIndex = currentTeams?.findIndex((r) => r.id === updatedTeam.id);
          if (teamIndex >= 0) {
            newTeams[teamIndex] = updatedTeam;
          } else {
            newTeams.push(updatedTeam);
          }
          return newTeams;
        });

        return updatedTeam;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [permissions, postAuthenticatedRequest]
  );

  const deleteTeam = useCallback(
    async (teamId: number, abortController?: AbortController): Promise<DeleteTeamResponseType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupTeams', permissions);
      if (!allowed) return undefined;

      try {
        const deleteTeamUrl = URLConstants.teamDelete + `${teamId}`;
        const results = (await deleteAuthenticatedRequest(deleteTeamUrl, abortController ?? undefined)) as DeleteTeamResponseType;

        if (results.operationResult) {
          setTeams((currentTeams) => {
            return currentTeams?.filter((t) => t.id !== teamId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [permissions, deleteAuthenticatedRequest]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;
    listTeams();
  }, [currentUserId, listTeams]);

  const outputValue = useMemo(
    (): TeamsContextType => ({
      // statusQueried,
      loadingTeams,
      teams,
      listTeams,
      addOrUpdateTeam,
      deleteTeam,
    }),
    [loadingTeams, teams, listTeams, addOrUpdateTeam, deleteTeam]
  );
  return <TeamsContext.Provider value={outputValue}>{children}</TeamsContext.Provider>;
};

/* exports */
export { TeamsContext, TeamsProvider };
