import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DoubleChevronIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10.545" height="14.016" viewBox="0 0 10.545 14.016">
        <path
          d="M11.462,15.458l3.987-3.99a.75.75,0,0,1,1.064,0,.76.76,0,0,1,0,1.067L12,17.056a.752.752,0,0,1-1.039.022l-4.549-4.54a.754.754,0,1,1,1.064-1.067Z"
          transform="translate(-6.188 -3.26)"
          fill="currentColor"
        />
        <path
          d="M5.274,4.212,9.261.221a.75.75,0,0,1,1.064,0,.76.76,0,0,1,0,1.067L5.808,5.81a.752.752,0,0,1-1.039.022L.22,1.292A.754.754,0,1,1,1.284.224Z"
          transform="translate(10.545 6.029) rotate(180)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
