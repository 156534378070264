/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterCheckboxesProps {
  title: string;
  list: { key: string; value: string; displayValue?: ReactNode }[];
  currentValue?: { values: string[] };
  setNewFilterValue?(newValue: FilterValueType): void;
  triggerSetUpdate?: boolean;
  checkboxesAllButton?: boolean;
  maxHeight?: string;
}

/* elements */
const FilterCheckboxes = ({ title, list, currentValue, setNewFilterValue, triggerSetUpdate, checkboxesAllButton, maxHeight }: FilterCheckboxesProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(currentValue?.values ?? []);

  const applyFilterValue = useCallback(
    (newValues: typeof selectedValues) => {
      if (setNewFilterValue) setNewFilterValue({ nbValues: newValues.length, values: newValues });
    },
    [setNewFilterValue]
  );

  useEffect(() => {
    if (triggerSetUpdate) applyFilterValue(selectedValues);
  }, [triggerSetUpdate, applyFilterValue, selectedValues]);

  const clearFilter = () => {
    const newValues: typeof selectedValues = [];
    setSelectedValues(newValues);
    applyFilterValue(newValues);
  };

  const checkboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setSelectedValues((currentValues) => {
      if (checked) return [...currentValues, checkboxName];
      else return currentValues.filter((cv) => cv !== checkboxName);
    });
  }, []);

  const allCheckboxChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedValues((currentSelection) => {
        if (currentSelection.length === list.length) return [];
        return list.map((l) => l.key);
      });
    },
    [list]
  );

  const allChecked = selectedValues.length === list.length;

  // propagate on value change
  // const validateSelection = () => {
  //   applyFilterValue(selectedValues);
  //   // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues });
  // };

  return (
    <Box borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValues.length <= 0}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton> */}

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        {/* <Button variant="contained" disableElevation size="small" onClick={validateSelection}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button> */}
      </Box>

      <Box px={2} py={1} display={'flex'} flexDirection={'column'} className="custom-scrollbar" sx={{ maxHeight: maxHeight ?? 'min(450px, 80vh)', overflowY: 'auto' }}>
        <FormGroup>
          {checkboxesAllButton && (
            <FormControlLabel
              key={'select_all'}
              control={
                <Checkbox
                  color={'primary'}
                  checked={allChecked}
                  onChange={allCheckboxChanged}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    py: 1,
                    '& .MuiSvgIcon-root': { fontSize: 20, color: allChecked ? 'unset' : 'var(--color-grayHeaderBorder)' },
                    '&:hover': { backgroundColor: 'var(--color-hover-background)' },
                  }}
                />
              }
              label={
                <Typography display="flex" alignItems="center" fontSize={'.875rem'} py={0.5} flex={1} sx={{ color: 'var(--color-gray2)' }}>
                  <FormattedMessage id="selectAll" defaultMessage={'Select all'} />
                </Typography>
              }
              className="rounded-border"
              sx={{ display: 'flex', alignItems: 'stretch', pr: 2, ml: 0, mr: 0, '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
            />
          )}
          {list.map((l) => {
            const checked = selectedValues.includes(l.key);
            return (
              <FormControlLabel
                key={l.key}
                control={
                  <Checkbox
                    color={'primary'}
                    name={l.key}
                    checked={checked}
                    onChange={checkboxChanged}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      py: 1,
                      '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' },
                      '&:hover': { backgroundColor: 'var(--color-hover-background)' },
                    }}
                  />
                }
                label={
                  l.displayValue ? (
                    <Box display="flex" alignItems="center" fontSize={'.875rem'} sx={{ color: 'var(--color-gray2)' }}>
                      {l.displayValue}
                    </Box>
                  ) : (
                    <Typography display="flex" alignItems="center" fontSize={'.875rem'} sx={{ color: 'var(--color-gray2)' }}>
                      {l.value}
                    </Typography>
                  )
                }
                className="rounded-border"
                sx={{ display: 'flex', alignItems: 'stretch', pr: 2, ml: 0, mr: 0, '&:hover': { backgroundColor: 'var(--color-hover-background)' } }}
              />
            );
          })}
        </FormGroup>
      </Box>

      <Box display="flex" justifyContent="center" py={2} px={2} sx={{ position: 'sticky', bottom: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          fullWidth
          className="no-hover"
          // variant="text"
          onClick={clearFilter}
          size="small"
          disabled={selectedValues.length <= 0}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#F8F6F6',
              color: '#C9C8C7',
              borderColor: '#F8F6F6',
              boxShadow: 'none',
            },
            // '&:hover': { backgroundColor: 'var(--color-hover-button)' },
          }}
        >
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>
      </Box>
    </Box>
  );
};

export default FilterCheckboxes;
