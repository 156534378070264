/**
 * matchingData.ts
 * Define matching data types
 */
import { SearchDataset } from './searchDatasets';
import { TeamType } from './teams';
import { UserType, OrganizationUnit } from './user';
import { DateStringISO, DateString } from './utils';
import { WatchListPerson } from './person';
import { CommentType } from './comments';
import { RiskCategoryType, RiskType } from './risks';

export interface MatchingNameType {
  id?: number;

  fullName?: string;
  surname?: string;
  givenName?: string;
  fatherName?: string;
  motherName?: string;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  normalized?: string;
  type?: string;
}

export interface MatchingDate {
  description?: string;
  date?: DateString | string;
  normalized?: string;
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
}
export interface MatchingLocation {
  id?: number;
  dataId?: string;
  datasetId?: string;
  normalized?: string;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  placeName?: string;
  street?: string;
  city?: string;
  country?: string;
  streetNumber?: string;
  streetName?: string;
  poBox?: string;
  zipCode?: string;
  state?: string;
  type?: string;
  fullAddressDisplay?: string;

  longitude?: string;
  latitude?: string;
}

export interface Mrz {
  line1?: string;
  line2?: string;
  line3?: string;
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
}

export interface MatchingIdDoc {
  id?: number;
  dataId?: string;
  type?: string;

  country?: string;
  number?: string;
  description?: string;
  issuingBody?: string;
  issuedBy?: string;
  issuingCountry?: string;

  dateOfIssue?: DateString; //MatchingDate;
  authority?: string;

  dateOfExpiry?: DateString; //MatchingDate;
  expiryDate?: string;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
}

export interface MatchingDigitalCurrency {
  id?: string;
  currency?: string;
  identifier?: string;
}

export interface MatchingContactInformation {
  id?: number;
  type?: string;
  value?: string;
}

export interface MatchingDataType {
  dataID?: string;
  id?: number;

  entityType?: string; // check
  names: MatchingNameType[]; // check

  categories?: string[]; // check
  titles?: string[]; // check
  cultureCodes?: string[]; // check
  active?: boolean; // check

  dataset?: SearchDataset; // check
  revision?: number; // check
  created?: DateStringISO; // check
  updated?: DateStringISO; // check

  creationDate?: DateString; // check
  createdBy?: string; // check
  updatedDate?: DateString; // check
  updatedBy?: string; // check

  lastMatched?: DateStringISO; // check
  // additionalInformation?: string[];
  additionalInformations?: { type?: string; value?: string }[];

  label?: string; // check
  sex?: string; // check

  riskSince?: DateString; // check
  customerRisks?: RiskCategoryType[]; // check
  risks?: RiskType[]; // check
  type?: string; // check

  timeLineExtraValue?: {
    type?: string;
    value?: string;
  }[];

  addresses?: MatchingLocation[]; // check
  datesOfRegistry?: MatchingDate[]; // check
  placesOfRegistry?: MatchingLocation[]; // check
  identityDocuments?: MatchingIdDoc[]; // check
  bic?: string; // check
  lei?: string; // check
  leis?: string[]; // check
  bics?: string[]; // check
  digitalCurrencies?: MatchingDigitalCurrency[]; // check

  narrative?: string; // check
  sectors?: string[]; // check
  classifications?: string[]; // check

  dissolved?: boolean; // check
  datesOfDissolution?: MatchingDate[]; // check

  nationalities?: MatchingLocation[]; // check
  datesOfBirth?: MatchingDate[]; // check
  placesOfBirth?: MatchingLocation[]; // check

  flags?: MatchingLocation[]; // check
  datesOfBuild?: MatchingDate[]; // check
  placeOfBuild?: MatchingLocation[]; // check

  originalScript?: string; // check
  score?: number; // check

  mrzs?: Mrz[]; // check
  identificationDocuments?: MatchingIdDoc[]; // check

  keywords?: string[]; // check
  sources?: {
    path?: string;
    credibility?: string;
    language?: string;
    title?: string;
    summary?: string;
    keywords?: string[];
    dateOfPublication?: string;
    dateOfCollection?: DateString;
  }[];

  threshold?: number; // check
  dataSetId?: string; // check

  deleted?: string; // check
  deleteDT?: string; // check
  deceased?: string; // check
  lastCheckedDT?: string; // check

  datesOfDeath?: MatchingDate[]; // check

  comments?: CommentType[]; // check

  // contactInformation?: MatchingContactInformation[];

  positions?: string[]; // check

  version?: number; // check

  medias?: {
    description?: string;
    type?: string;
    value?: string;
  }[]; // check

  links?: LinkType[]; // check
  contactInformations?: MatchingContactInformation[]; // check
}

interface LinkType {
  linked?: {
    caption?: string;
    dataID?: string;
    dataset?: MatchingDataset;
    entityType?: string;
  };
  categories?: string[];
  subcategories?: string[];
  status?: string;
  caption?: string;
  periodsOfLink?: {
    description?: string;
    started?: string;
    ended?: string;
  }[];
  linkInformations?: { description?: string; type?: string; value?: string }[];
  weight?: number;
}

interface MatchingDatasetFolder {
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  id?: number;
  active?: boolean;
  parentId?: number;
  label?: string;
  description?: string;

  organizations?: OrganizationUnit[];
  groups?: TeamType[];
  users?: UserType[];
}

export interface MatchingDataset {
  id?: number;
  label?: string;
  description?: string;
  classification?: string;
  category?: string;

  active?: boolean;
  totalCount?: number;

  // Type can be watchlist / Customer, Beneficiary
  type?: string;

  deleted?: boolean;
  fullName?: string;

  // Person, Alerts, Transactions, ....
  sourceOfData?: number;

  records?: MatchingDataType[];

  dataSetFolder?: MatchingDatasetFolder;

  dateOfPublication?: DateStringISO;
  dateOfUpload?: DateStringISO;

  sourceOfDataType?: string;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  // Security
  organizations?: OrganizationUnit[];
  groups?: TeamType[];
  readOnlyGroups?: TeamType[];
  users?: UserType[];
}

export interface MatchingDetails {
  id?: number;
  matchType?: string;
  matchScore?: number;

  trueHit?: boolean;
  comments?: CommentType[];

  watchListPersons?: WatchListPerson;
  addresses?: MatchingLocation[];
}

export const formatMatchingName = (name: MatchingNameType | undefined): string => {
  if (!name) return '';

  let fullNameConcat: string = '';

  if (name.fullName && name.fullName.length > 0) {
    fullNameConcat += `${name.fullName}`;
    return fullNameConcat.trim();
  }

  if (name.givenName) {
    fullNameConcat += `${name.givenName} `;
  }

  if (name.fatherName) {
    fullNameConcat += `${name.fatherName} `;
  }
  if (name.motherName) {
    fullNameConcat += `${name.motherName} `;
  }

  if (name.surname) {
    fullNameConcat += `${name.surname} `;
  }

  return fullNameConcat.trim();
};
