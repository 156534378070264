/**
 * Loader.tsx
 * display a loader
 */
/* utilities */
import { CSSProperties } from 'react';
import './style.scss';

/* elements */
// const Loader = ({ dotSize }: { dotSize?: string }) => {
const Loader = ({ cssStyle, containerStyle }: { cssStyle?: CSSProperties; containerStyle?: CSSProperties }) => {
  return (
    <div id="screenaLoader" style={containerStyle ?? {}}>
      <div className="loader" style={cssStyle ?? {}}>
        {/* <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div> */}
      </div>
    </div>
  );
};

export default Loader;
