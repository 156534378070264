/**
 * PageTitle.tsx
 * Display the page title
 */
/* packages */
import { PropsWithChildren } from 'react';

/* components */
import Box from '@mui/material/Box';

/* types */
interface PageHeaderProps {
  variableHeight?: boolean;
  noBorder?: boolean;
  noMarginBottom?: boolean;
}
const PageHeader = ({ variableHeight, noBorder, noMarginBottom, children }: PropsWithChildren<PageHeaderProps>) => {
  return (
    <Box
      display="flex"
      columnGap={'10px'}
      px={'var(--main-inline-padding)'}
      ml={'calc(-1*var(--main-inline-padding))'}
      mr={'calc(-1*var(--main-inline-padding))'}
      mb={noMarginBottom ? 0 : 3}
      py={3}
      height={variableHeight ? 'unset' : 'var(--header-height)'}
      minHeight={'var(--min-header-height)'}
      alignItems={'center'}
      borderBottom={noBorder ? 'unset' : '1px solid var(--color-grayHeaderBorder)'}
    >
      {children}
    </Box>
  );
};

export default PageHeader;
