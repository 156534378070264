/**
 * DeleteSchedule.tsx
 * delete schedule modal
 */
/* packages */
import { useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { JobType } from 'models/job';

/* types */
interface DeleteScheduleProps {
  schedule: JobType;
  removeSchedule(scheduleId: number): Promise<StatusPromiseResponse>;
}

const DeleteSchedule = memo(({ schedule, removeSchedule }: DeleteScheduleProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingSchedule, setDeletingSchedule] = useState(false);

  const deleteSchedule = useCallback(async () => {
    blockModal?.(true);
    setDeletingSchedule(true);

    const result = await removeSchedule(schedule.id);
    setDeletingSchedule(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteScheduleSuccess',
        defaultMessage: 'Schedule deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteScheduleError',
        defaultMessage: 'An error occured while deleting the schedule',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, schedule, removeSchedule, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Schedule: {schedule.name ?? schedule.id}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteScheduleMessage" defaultMessage={'Are you sure that you want to delete this schedule?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingSchedule ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingSchedule} onClick={deleteSchedule} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default DeleteSchedule;
