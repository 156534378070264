/**
 * WorkflowContent.tsx
 * show the workflow content of a single alert page
 */
/* packages */
import { useState, useCallback, useContext, useRef, memo, forwardRef, useMemo, ReactNode, useEffect, SyntheticEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

/*  contexts */
import { AlertContext } from './alertProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { UserContext } from 'contextProviders/UserProvider';

/* hooks */
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { useAddModal } from 'contextProviders/ModalProvider';

import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType, FilterValueType } from 'components/SearchFilters/SearchFilters';

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { DocumentElement } from './DocumentsContent';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import StatusTag from 'components/StatusTag/StatusTag';
import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import FilterTextfield from 'components/SearchFilters/FilterTextfield';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';

// import { PokeIcon } from 'icons/poke/poke';
import { FlagIcon } from 'icons/flag/flag';
import { AvatarIcon } from 'icons/avatar/avatar';
import { PaperClipSideIcon } from 'icons/paperClipSide/paperClipSide';
import { PaperClipAddIcon } from 'icons/paperClipAdd/paperClipAdd';
// import { CrossFilledIcon } from 'icons/crossFilled/crossFilled';
import { GlobeIcon } from 'icons/globe/globe';
import { CrossIcon } from 'icons/cross/cross';

import { maxFileSize, allowedExtension, clearRegularInput } from './DocumentsContent';
/* utilities */
import { GetUser, GetAvatar, GetTeam } from 'components/GetUser/GetUser';
// import { URLConstants } from 'common/URLconstants';

/* types */
import { AlertContextType } from './alertProvider';
import { CommentType } from 'models/comments';
import { WorkflowAudit } from 'models/workflow';
import { DateStringFormat } from 'models/utils';

interface SearchFormType {
  submitAction(): void;
  existingStatus?: string[];
  existingUsers?: string[];
}

interface CommentOrWorkflow {
  content: CommentType | WorkflowAudit;
  type: 'comment' | 'workflow' | 'creation';
  nbReplys?: number;
  replyId?: number;
  date: Dayjs;
}

interface CommentElementProps {
  alertId?: number;
  content: CommentOrWorkflow['content'];
  type: CommentOrWorkflow['type'];

  isCommentUpdating: boolean;
  setCommentAsFavorite?: AlertContextType['setCommentAsFavorite'];
  // defineReply?(commentId?: number): void;
  nbReplys: number;
  replyId?: number;
}
interface NewCommentProps {
  alertId?: number;
  replyingId?: number;
  // replyingTo?: CommentType;
  // defineReply?(commentId?: number): void;
  commentId?: string;
  onSendAction?(): void;
  closeAction?(): void;
}

interface CommentsListProps {
  selectedComments: CommentOrWorkflow[];
}

interface ActiveFiltersType {
  comment?: string;
  status?: string[];
  flagged?: boolean;
  attached?: boolean;
  users?: string[];
}

/* elements */
const FILTERNAMES = {
  comment: 'comment',
  status: 'status',
  flags: 'flags',
  attachment: 'attachment',
  users: 'users',
};

const SYSTEMUSER = 'SYSTEM';
const avatarSize = '45px';
const avatarFontSize = '20px';
const avatarGap = '1rem';

export const WorkflowContent = () => {
  const { alert } = useContext(AlertContext);

  const [selectedComments, setSelectedComments] = useState<CommentOrWorkflow[]>([]);
  // const [replyingTo, setReplyingTo] = useState<CommentType | undefined>();

  const [activeFilters, setActiveFilters] = useState<ActiveFiltersType | null>(null);

  const searchFormRef = useRef<HTMLFormElement>(null);

  const creationWorkflow: WorkflowAudit = useMemo(
    () =>
      ({
        id: -1,
        creationDate: alert?.creationDate,
        createdBy: SYSTEMUSER,
        comment: (
          <Box display="flex" alignItems={'center'} gap=".5rem">
            <Typography component={'span'} sx={{ color: 'inherit', fontWeight: 500, fontSize: 'inherit' }}>
              Alert created
            </Typography>{' '}
            <GaugeMeter value={alert?.matchScore ?? 0} backgroundColor={'white'} />
          </Box>
        ),
      } as WorkflowAudit & { comment: ReactNode }),
    [alert?.creationDate, alert?.matchScore]
  );

  const allComments: CommentOrWorkflow[] = useMemo(() => {
    const allComs: CommentOrWorkflow[] = [];

    // add comments & store replys
    const replys: { [key: number]: CommentOrWorkflow[] } = {};

    alert?.comments?.forEach((com) => {
      if (com.replyTo) {
        if (!com.id) return;
        const comAsType: CommentOrWorkflow = {
          content: com,
          type: 'comment',
          date: dayjs(com.creationDate, DateStringFormat),
          replyId: com.replyTo,
        };
        if (replys[com.replyTo]) replys[com.replyTo].push(comAsType);
        else replys[com.replyTo] = [comAsType];
      } else {
        allComs.push({
          content: com,
          type: 'comment',
          date: dayjs(com.creationDate, DateStringFormat),
          nbReplys: 0,
          replyId: com.id,
        });
      }
    });

    // add workflow change
    if (alert?.workflowAudits)
      alert?.workflowAudits?.forEach((workflow) => {
        allComs.push({
          content: workflow,
          type: 'workflow',
          date: dayjs(workflow.creationDate, DateStringFormat),
        });
      });

    // add initial status
    let statusAsWorkflowAudit: WorkflowAudit;
    if (!alert?.workflowAudits) {
      // initial status is the current status
      statusAsWorkflowAudit = {
        comment: '',
        toStatus: alert?.status,
        id: -1,
        creationDate: alert?.creationDate,
        createdBy: SYSTEMUSER,
      };
    } else {
      // initial status is the 1st audit "from" status
      statusAsWorkflowAudit = {
        comment: '',
        toStatus: alert?.workflowAudits[0].fromStatus,
        id: -1,
        creationDate: alert?.creationDate,
        createdBy: SYSTEMUSER,
      };
    }

    if (statusAsWorkflowAudit) {
      allComs.push({
        content: statusAsWorkflowAudit,
        type: 'workflow',
        date: dayjs(alert?.creationDate, DateStringFormat).add(1, 'second'),
      });
    }

    // add a fake creation comment
    allComs.push({
      content: creationWorkflow,
      type: 'creation',
      date: dayjs(alert?.creationDate, DateStringFormat),
    });

    // sort by date
    allComs.sort((com1, com2) => com2.date.diff(com1.date));

    // insert the replys
    Object.entries(replys).forEach(([com_id, com_replys]) => {
      // validate comment id exists
      const com_index = allComs.findIndex((com) => com.type === 'comment' && com.content.id === Number(com_id));
      if (com_index < 0) return;

      // sort replys
      com_replys.sort((com1, com2) => com2.date.diff(com1.date));

      // set "reply to" only for the last reply
      // com_replys[0].replyId = Number(com_id);

      // set number of replys & remove reply to in base comment
      allComs[com_index].nbReplys = com_replys.length;
      // allComs[com_index].replyId = undefined;

      // insert replys
      com_replys.forEach((rep) => allComs.splice(com_index + 1, 0, rep));
    });

    return allComs;
  }, [alert?.status, alert?.comments, alert?.workflowAudits, alert?.creationDate, creationWorkflow]);

  const submitAction = useCallback(() => {
    const formData = new FormData(searchFormRef.current ?? undefined);

    const newActiveFilters: ActiveFiltersType = {};

    const filterComment = JSON.parse(formData.get(FILTERNAMES.comment) as string) as FilterValueType;
    const filterStatus = JSON.parse(formData.get(FILTERNAMES.status) as string) as FilterValueType;
    const filterFlags = JSON.parse(formData.get(FILTERNAMES.flags) as string) as FilterValueType;
    const filterAttachment = JSON.parse(formData.get(FILTERNAMES.attachment) as string) as FilterValueType;
    const filterUsers = JSON.parse(formData.get(FILTERNAMES.users) as string) as FilterValueType;

    if (filterComment && filterComment.nbValues > 0) {
      newActiveFilters.comment = filterComment.value;
    }
    if (filterStatus && filterStatus.nbValues > 0) {
      newActiveFilters.status = filterStatus.values;
    }
    if (filterFlags && filterFlags.nbValues > 0) {
      newActiveFilters.flagged = filterFlags.booleanValue;
    }
    if (filterAttachment && filterAttachment.nbValues > 0) {
      newActiveFilters.attached = filterAttachment.booleanValue;
    }
    if (filterUsers && filterUsers.nbValues > 0) {
      newActiveFilters.users = filterUsers.values;
    }

    if (Object.keys(newActiveFilters).length === 0) setActiveFilters(null);
    else setActiveFilters(newActiveFilters);
  }, []);

  // filter comments on change of active filters
  useEffect(() => {
    if (activeFilters === null) return setSelectedComments(allComments);

    let newComments = allComments;

    if (activeFilters.comment) {
      newComments = newComments.filter((com) => com.type === 'comment' && (com.content as CommentType).comment?.toLowerCase().includes(activeFilters.comment?.toLowerCase() ?? ''));
    }

    if (activeFilters.status) {
      newComments = newComments.filter((com) => com.type === 'workflow' && activeFilters.status?.includes((com.content as WorkflowAudit).toStatus?.description ?? ''));
    }

    if (activeFilters.flagged) {
      newComments = newComments.filter((com) => com.type === 'comment' && (com.content as CommentType).favorite);
    }

    if (activeFilters.attached) {
      newComments = newComments.filter((com) => com.type === 'comment' && (com.content as CommentType).fileId);
    }

    if (activeFilters.users) {
      newComments = newComments.filter((com) => activeFilters.users?.includes(com.content.createdBy ?? ''));
    }

    setSelectedComments(newComments);
  }, [activeFilters, allComments]);
  // useEffect(() => {
  //   setSelectedComments(allComments);
  // }, [allComments]);

  // const defineReply = useCallback(
  //   (commentId?: number) => {
  //     if (!commentId) setReplyingTo(undefined);
  //     else {
  //       setReplyingTo(alert?.comments?.filter((com) => com.id === commentId)[0]);
  //       document.querySelector('#newComment')?.scrollIntoView();
  //     }
  //   },
  //   [alert]
  // );

  const existingStatus = useMemo(() => {
    const status: string[] = [];

    alert?.workflowAudits?.forEach((workflow) => {
      if (workflow.fromStatus?.description) status.unshift(workflow.fromStatus?.description);
    });
    if (alert?.status?.description) status.unshift(alert?.status?.description);

    return status;
  }, [alert]);

  const existingUsers = useMemo(() => {
    const users = new Set<string>();

    alert?.comments?.forEach((comment) => {
      if (comment.createdBy) users.add(comment.createdBy);
    });

    alert?.workflowAudits?.forEach((workflow) => {
      if (workflow.createdBy) users.add(workflow.createdBy);
    });
    users.add(SYSTEMUSER);

    return Array.from(users);
  }, [alert]);

  return (
    <Box>
      {/* <Typography fontSize={'1.25rem'} fontWeight={600}>
        <FormattedMessage id="Workflow" defaultMessage={'Workflow'} />
      </Typography> */}

      <Box sx={{ maxWidth: 800, margin: 'auto' }}>
        {/* <Filter/> */}
        <SearchForm
          ref={searchFormRef}
          {...{
            submitAction,
            existingStatus,
            existingUsers,
          }}
        />

        <NewComment alertId={alert?.id} />

        <CommentsList {...{ selectedComments }} />
      </Box>
    </Box>
  );
};

const SearchFiltersMemo = memo(SearchFilters);

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { allUsers } = useContext(AllUsersContext);
    const { submitAction, existingStatus, existingUsers } = props;

    // define required ref
    const inputIdRef = useRef<FilterButtonRefType | null>(null);
    const statusRef = useRef<FilterButtonRefType | null>(null);
    const flagsRef = useRef<FilterButtonRefType | null>(null);
    const attachmentRef = useRef<FilterButtonRefType | null>(null);
    const usersRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      const buttons: SearchFiltersButtonType[] = [
        {
          ref: inputIdRef,
          text: <FormattedMessage id="comment" defaultMessage="Comment" />,
          inputName: FILTERNAMES.comment,
          filterContent: <FilterTextfield title={intl.formatMessage({ id: 'searchInComment', defaultMessage: 'Search in comment' })} />,
          placeholder: intl.formatMessage({ id: 'search', defaultMessage: 'Search' }),
        },
      ];

      if (existingStatus && existingStatus.length > 0) {
        buttons.push({
          ref: statusRef,
          text: <FormattedMessage id="status" defaultMessage="Status" />,
          inputName: FILTERNAMES.status,
          filterContent: <FilterCheckboxes title={intl.formatMessage({ id: 'status', defaultMessage: 'Status' })} list={existingStatus.map((status) => ({ key: status, value: status }))} />,
          checkboxesAllButton: true,
        });
      }

      buttons.push({
        ref: flagsRef,
        text: <FormattedMessage id="flag" defaultMessage="Flags" />,
        inputName: FILTERNAMES.flags,
        trueFalseButton: true,
        hideNbValues: true,
      });
      buttons.push({
        ref: attachmentRef,
        text: <FormattedMessage id="attachment" defaultMessage="Attachment" />,
        inputName: FILTERNAMES.attachment,
        trueFalseButton: true,
        hideNbValues: true,
      });

      if (existingUsers && existingUsers.length > 0) {
        buttons.push({
          ref: usersRef,
          text: <FormattedMessage id="people" defaultMessage="People" />,
          inputName: FILTERNAMES.users,
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'People', defaultMessage: 'People' })}
              list={existingUsers.map((user) => ({ key: user, value: GetUser(user, allUsers, false) as string }))}
            />
          ),
        });
      }
      return buttons;
    }, [intl, existingStatus, existingUsers, allUsers]);

    return (
      <Box width={'100%'} display={'flex'} px={0} pl={`calc(${avatarSize} + ${avatarGap})`} pb={2}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            {searchFiltersButtons.length > 0 && <SearchFiltersMemo disabled={false} onActivate={submitAction} {...{ searchFiltersButtons }} />}
          </Box>
        </form>
      </Box>
    );
  })
);

const NewComment = memo(({ alertId, replyingId, commentId, onSendAction, closeAction }: NewCommentProps) => {
  const { toggleModal } = useAddModal();

  const { currentUser } = useContext(UserContext);

  const intl = useIntl();
  // const { allUsers } = useContext(AllUsersContext);
  const { addComment, uploadAlertDocument } = useContext(AlertContext);

  const [newComment, setNewComment] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  // const [fileError, setFileError] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const addSnackbar = useAddSnackbar();

  const [sendingComment, setSendingComment] = useState<boolean>(false);

  const hanldeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  }, []);

  const clearInputs = useCallback(() => {
    setSelectedFile(undefined);
    if (!inputRef.current) return;
    clearRegularInput(inputRef.current);
    setLink(null);
  }, []);

  const validateFile = useCallback(
    (receivedFile: File | null | undefined) => {
      if (!receivedFile) {
        return clearInputs();
      }

      const fileExtension = receivedFile.name.split('.').pop()?.toLowerCase();
      if (!allowedExtension.includes(`.${fileExtension}`)) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileInvalidExtension',
          defaultMessage: 'Invalid file type',
        });
        addSnackbar(fileErrorMessage, 'error');

        // setFileError(fileErrorMessage);

        return clearInputs();
      }

      if (receivedFile.size > maxFileSize) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileSizeTooLarge',
          defaultMessage: 'File size too large',
        });
        addSnackbar(fileErrorMessage, 'error');
        // setFileError(fileErrorMessage);

        return clearInputs();
      }
      setSelectedFile(receivedFile);
    },
    [intl, addSnackbar, clearInputs]
  );

  const handleSetLink = useCallback((link: string | null) => {
    setLink(link ? link.trim() : null);
  }, []);

  const handleEditLink = useCallback(() => {
    if (!alert) return;
    toggleModal?.({
      title: link ? intl.formatMessage({ id: 'editLinkUrl', defaultMessage: 'Edit link' }) : intl.formatMessage({ id: 'addLinkUrl', defaultMessage: 'Add link' }),
      modalContent: <EditLinkModal {...{ link, handleSetLink }} />,
    });
  }, [intl, toggleModal, link, handleSetLink]);

  const checkFile = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const receivedFile = target.files?.[0];
      // setFileError(null);
      validateFile(receivedFile);
    },
    [validateFile]
  );

  const sendComment = async () => {
    if (!newComment) return;
    setSendingComment(true);

    let fileId;
    if (selectedFile) {
      const uploadFileData = new FormData();

      const file = selectedFile;
      uploadFileData.append('file', file, file.name);

      uploadFileData.append('alertId', String(alertId));
      uploadFileData.append('description', '');
      try {
        const fileResult = await uploadAlertDocument?.(uploadFileData);
        fileId = fileResult?.id;
      } catch (uploadError) {
        const uploadErrorMessage = intl.formatMessage({
          id: 'uploadError',
          defaultMessage: 'An error occured while upload your file',
        });
        addSnackbar(uploadErrorMessage, 'error');
        setSendingComment(false);

        return;
      }
    }
    try {
      let replyId: number | undefined;
      if (replyingId) {
        replyId = replyingId;
      }
      await addComment?.(newComment, alertId, fileId, replyId, link);

      setNewComment('');
      clearInputs();
      // defineReply?.();
      onSendAction?.();
    } catch (newCommentError: any) {
      const errorMessage = intl.formatMessage({
        id: 'newCommentError',
        defaultMessage: 'An error occured while creating your comment',
      });
      addSnackbar(errorMessage, 'error');
    }

    setSendingComment(false);
  };

  let defaultAvatar: ReactNode = <AvatarIcon className="avatar" sx={{ fontSize: `inherit`, color: 'var(--color-lightgray4)' }} />;
  if (currentUser) {
    defaultAvatar = GetAvatar(currentUser.userName, [currentUser], false, `calc(0.4 * ${avatarSize})`);
  }

  return (
    <Box id="newComment">
      {replyingId && closeAction && (
        <Box ml={`calc(${avatarSize} + ${avatarGap})`} mb={1} display="flex" alignItems={'center'}>
          {/* <Button
            variant="text"
            color="gray2"
            onClick={closeAction}
            sx={(theme) => ({ display: 'flex', alignItems: 'center', color: 'var(--color-gray2)', '&:hover': { color: theme.palette.red.main } })}
          >
            <CrossFilledIcon sx={{ fontSize: 'var(--fs-12)', color: 'inherit', mr: 1 }} />
            <FormattedMessage id="closeReply" defaultMessage={'Close reply'} />
          </Button> */}
        </Box>
      )}

      <Box display={'flex'} mb={4} gap={avatarGap}>
        <Box width={avatarSize} sx={{ fontSize: avatarSize, color: 'var(--color-lightgray4)' }}>
          {/* <AvatarIcon sx={{ fontSize: 'inherit', color: 'inherit' }} /> */}
          {defaultAvatar}
        </Box>
        <Box
          flex={1}
          sx={{
            minWidth: 0,
            color: 'var(--color-gray2)',
            fontSize: 'var(--fs-14)',
            // border: '1px solid var(--color-grayHeaderBorder)',
            // borderRadius: 1,
          }}
        >
          <form id="newCommentForm" style={{ width: '100%' }}>
            {/* <TextField
              disabled={sendingComment}
              value={newComment}
              onChange={hanldeChange}
              placeholder={replyingId ? intl.formatMessage({ id: 'addReply', defaultMessage: 'Add a reply...' }) : intl.formatMessage({ id: 'addComment', defaultMessage: 'Add a comment...' })}
              fullWidth
              multiline
              rows={replyingId ? 3 : 5}
              inputProps={{
                sx: {
                  fontSize: 'var(--fs-14)',
                },
              }}
              sx={{
                p: 0,
                '& .MuiInputBase-root': {
                  paddingBlock: 'var(--fs-10)',
                },
              }}
            /> */}
            <SearchText
              disabled={sendingComment}
              value={newComment}
              onChange={hanldeChange}
              placeholder={replyingId ? intl.formatMessage({ id: 'addReply', defaultMessage: 'Add a reply...' }) : intl.formatMessage({ id: 'addComment', defaultMessage: 'Add a comment...' })}
              fullWidth
              multiline
              rows={replyingId ? 3 : 5}
              InputProps={{
                startAdornment: null,
              }}
            />
            <Input
              id={`newCommentFile${commentId?.replaceAll(' ', '') ?? ''}`}
              onChange={checkFile}
              disableUnderline
              type={'file'}
              name={`newCommentFile${commentId?.replaceAll(' ', '') ?? ''}`}
              inputProps={{
                ref: inputRef,
                accept: allowedExtension.join(', '),
                disabled: sendingComment,
                hidden: true,
                sx: {
                  display: 'none',
                },
              }}
            />

            <Box display={'flex'} gap={'0.5rem'} mt={1} alignItems={'center'}>
              {!selectedFile && (
                <label htmlFor={`newCommentFile${commentId?.replaceAll(' ', '') ?? ''}`}>
                  <ShadowedButton component={'span'} disabled={sendingComment}>
                    <PaperClipAddIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
                    <FormattedMessage id="Addfile" defaultMessage="Add file" />
                  </ShadowedButton>
                </label>
              )}
              <Box flex={1} display="flex" sx={{ minWidth: 0 }} gap={'1rem'}>
                {selectedFile && (
                  <Box
                    // className="button-box-shadow"
                    display="inline-flex"
                    alignItems={'center'}
                    px={2}
                    sx={{ minWidth: 0, py: '1px', background: 'white', borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' }}
                  >
                    <PaperClipSideIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
                    <Typography
                      component="span"
                      flex={1}
                      pt={'5px'}
                      pb={'6px'}
                      sx={{ minWidth: 0, fontWeight: 500, fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {selectedFile.name}
                    </Typography>
                    <IconButton
                      disabled={sendingComment}
                      onClick={clearInputs}
                      sx={{ p: 0.5, borderRadius: '5px', ml: 1, color: 'var(--color-gray2)', '&:hover': { backgroundColor: 'var(--color-hover-button)' } }}
                    >
                      <CrossIcon sx={{ fontSize: 'var(--fs-8)', color: 'inherit' }} />
                    </IconButton>
                  </Box>
                )}
                {/* {fileError && (
                  <Typography textAlign={'left'} fontWeight={'medium'} fontSize={'var(--fs-12)'} sx={{ color: 'var(--color-fushia)' }}>
                    {fileError}
                  </Typography>
                )} */}

                {!link ? (
                  <ShadowedButton
                    className="no-hover"
                    color={link ? 'primary' : 'darkgray'}
                    onClick={handleEditLink}
                    component={'span'}
                    disabled={sendingComment}
                    iconColor={link ? 'var(--color-azure)' : undefined}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    <GlobeIcon sx={{ mr: 1, fontSize: 'var(--fs-14)' }} />
                    {link ? <FormattedMessage id="EditLink" defaultMessage="Edit link" /> : <FormattedMessage id="AddLink" defaultMessage="Add link" />}
                  </ShadowedButton>
                ) : (
                  <Box
                    // className="button-box-shadow"
                    display="inline-flex"
                    alignItems={'center'}
                    px={2}
                    sx={{ minWidth: 0, py: '1px', background: 'white', borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' }}
                  >
                    <GlobeIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
                    <Typography
                      component="span"
                      flex={1}
                      pt={'5px'}
                      pb={'6px'}
                      sx={{ minWidth: 0, fontWeight: 500, fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {link}
                    </Typography>
                    <IconButton
                      disabled={sendingComment}
                      onClick={() => {
                        setLink('');
                      }}
                      sx={{ p: 0.5, borderRadius: '5px', ml: 1, color: 'var(--color-gray2)', '&:hover': { backgroundColor: 'var(--color-hover-button)' } }}
                    >
                      <CrossIcon sx={{ fontSize: 'var(--fs-8)', color: 'inherit' }} />
                    </IconButton>
                  </Box>
                )}
              </Box>

              {replyingId && closeAction && (
                <ShadowedButton type={'button'} onClick={closeAction} sx={{ ml: 'auto' }}>
                  <FormattedMessage id="cancel" defaultMessage={'Cancel'} />
                </ShadowedButton>
              )}

              <Button color="primary" variant="contained" disableElevation onClick={sendComment} disabled={sendingComment || !newComment} sx={{ ml: 'auto' }}>
                <FormattedMessage id="Send" defaultMessage="Send" />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
});

const isValidURL = (link: string) => {
  const res = link.match(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
  return res !== null;
};

const EditLinkModal = memo(({ link, handleSetLink }: { link: string | null; handleSetLink(link: string | null): void }) => {
  const { closeModal } = useAddModal();
  const intl = useIntl();
  const [inputLink, setInputLink] = useState<string | null>(link);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLink(event.target.value);
  }, []);

  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const isValidLink = !inputLink || isValidURL(inputLink ?? '');
  let buttonTooltip: string = '';
  if (!isValidLink) {
    buttonTooltip = intl.formatMessage({ id: 'invalidUrlLink', defaultMessage: 'Invalid url link' });
  }

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">
          <FormattedMessage id="link" defaultMessage={'Link'} />
        </Typography>

        <SearchText
          fullWidth
          name="name"
          value={inputLink ?? ''}
          onChange={handleChangeText}
          placeholder={intl.formatMessage({ id: 'setValidLink', defaultMessage: 'Insert a valid web link' })}
          InputProps={MemoInputProps}
        />
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          onClick={() => {
            closeModal?.();
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </ShadowedButton>

        <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
          <Button
            type="button"
            variant="contained"
            disabled={!isValidLink}
            disableElevation
            onClick={() => {
              handleSetLink(inputLink);
              closeModal?.();
            }}
            sx={{ textTransform: 'none' }}
          >
            <FormattedMessage id="setLink" defaultMessage={'Set link'} />
          </Button>
        </ContentWithDarkTooltip>
      </Box>
    </Box>
  );
});
const CommentsList = memo(({ selectedComments }: CommentsListProps) => {
  const { alert, updatingComment, setCommentAsFavorite } = useContext(AlertContext);

  return (
    <Box>
      {selectedComments.length === 0 && (
        <Box>
          <Typography textAlign={'center'} sx={{ fontWeight: 'medium', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
            <FormattedMessage id="noWorkflowComment" defaultMessage={'Nothing to show with your current filters'} />
          </Typography>
        </Box>
      )}
      {selectedComments.map((comment) => {
        const isCommentUpdating = updatingComment?.[comment.content.id ?? 0] ?? false;
        return (
          <CommentElement
            key={`${comment.type}_${comment.content.id}`}
            alertId={alert?.id}
            content={comment.content}
            type={comment.type}
            nbReplys={comment.nbReplys ?? 0}
            replyId={comment.replyId}
            {...{ setCommentAsFavorite, isCommentUpdating }}
          />
        );
      })}
    </Box>
  );
});

const CommentElement = memo(({ content, type, isCommentUpdating, setCommentAsFavorite, alertId, nbReplys, replyId }: CommentElementProps) => {
  const { allUsers } = useContext(AllUsersContext);
  const { teams } = useContext(TeamsContext);
  const { documents } = useContext(AlertContext);
  const fileRef = useRef<HTMLDivElement>(null);

  const [showReplyBox, setShowReplyBox] = useState<boolean>(false);

  const closeReplyBox = useCallback(() => {
    setShowReplyBox(false);
  }, []);

  const date = dayjs(content.creationDate, DateStringFormat);
  let creator: ReactNode;
  let avatar: ReactNode;
  let comment: string | ReactNode;
  let linkedFile;
  let linkedUrl;
  let isReply = false;

  switch (type) {
    case 'comment':
      const commentContent = content as CommentType;
      creator = GetUser(commentContent.createdBy ?? '', allUsers, false);
      avatar = GetAvatar(commentContent.createdBy ?? '', allUsers, false, avatarFontSize);
      comment = commentContent.comment;
      if (commentContent.fileId) {
        linkedFile = documents?.filter((doc) => doc.id === commentContent.fileId)[0];
      }
      linkedUrl = commentContent.linkUrl ?? null;

      if (linkedUrl && !linkedUrl.match(/^(?:https?:)?\/\//i)) {
        linkedUrl = 'https://' + linkedUrl;
      }

      // if (linkedUrl && linkedUrl.startsWith('https//'))

      if (commentContent.replyTo) isReply = true;

      break;
    case 'workflow':
      const workflowContent = content as WorkflowAudit;
      creator = GetUser(workflowContent.createdBy ?? '', allUsers, false);

      // if (workflowContent.createdBy === SYSTEMUSER) avatar = <PokeIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />;
      // else avatar = GetAvatar(workflowContent.createdBy ?? '', allUsers, false, avatarFontSize);
      avatar = GetAvatar(workflowContent.createdBy ?? '', allUsers, false, avatarFontSize);

      comment = workflowContent.comment;

      break;
    case 'creation':
      creator = GetUser(SYSTEMUSER, allUsers, false);
      // avatar = <PokeIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />;
      avatar = GetAvatar(SYSTEMUSER, allUsers, false, avatarFontSize);
      comment = content.comment;

      break;
    default:
      break;
  }

  // const linkedFile = type === 'comment' && (content as CommentType).fileId ?
  // const hasFile =  && documents?.filter();

  if (!avatar) return <></>;

  const contentAsAudit = content as WorkflowAudit;

  return (
    <Box id={`${type}_${content.id}`} display={'flex'} mb={2} gap={avatarGap} sx={{ pl: isReply ? `calc(${avatarSize} + ${avatarGap})` : 0 }}>
      <Box width={avatarSize} display="grid" gridTemplateColumns={avatarSize} sx={{ fontSize: avatarSize, color: 'var(--color-lightgray4)' }}>
        {avatar}
      </Box>
      <Box flex={1} sx={{ minWidth: 0 }}>
        <Box
          px={2}
          pt={1}
          pb={2}
          sx={{
            color: 'var(--color-gray2)',
            fontSize: 'var(--fs-14)',
            backgroundColor: 'var(--color-lightgray6)',
            border: '1px solid var(--color-grayHeaderBorder)',
            borderRadius: 1,
          }}
        >
          <Box display="flex" mb={2} alignItems={'center'} columnGap={'.5rem'}>
            <Typography fontWeight="medium" sx={{ fontSize: 'inherit', color: 'var(--color-darkgray)', minWidth: 0 }}>
              {creator}
            </Typography>
            <Box display={'flex'} gap={'.5rem'} sx={{ pt: '2px', fontSize: 'var(--fs-12)', whiteSpace: 'nowrap' }}>
              {date.format('MMM DD, YYYY HH:mm:ss UTC')}
              {/* <Divider flexItem orientation="vertical" /> */}
            </Box>
            <Box flex={1} sx={{ textAlign: 'right' }}>
              {type === 'comment' && (
                <IconButton
                  sx={{
                    py: 1, //'calc(0.25 * var(--fs-14))',
                    px: 1, //'calc(0.25 * var(--fs-14))',
                    ml: 1,
                    color: (content as CommentType).favorite ? 'var(--color-azure)' : 'var(--color-lightgray5)',
                    fontSize: 'var(--fs-14)',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: 'var(--color-hover-button)',
                      color: (content as CommentType).favorite ? 'var(--color-azure)' : 'var(--color-table-content)',
                    },
                  }}
                  disabled={isCommentUpdating}
                  onClick={() => setCommentAsFavorite?.(!(content as CommentType).favorite, content.id, alertId)}
                >
                  {<FlagIcon fontSize="inherit" />}
                </IconButton>
              )}
            </Box>
          </Box>

          {type === 'workflow' && contentAsAudit.toStatus && contentAsAudit.fromStatus?.id !== contentAsAudit.toStatus?.id && (
            <Box mb={1}>
              <Typography component="span" mr={1} sx={{ fontSize: 'inherit', color: 'inherit', fontWeight: 500 }}>
                <FormattedMessage id="changedAlertStatusTo" defaultMessage={'Changed status to'} />
              </Typography>

              <StatusTag tag_label={contentAsAudit.toStatus?.description} active={contentAsAudit.toStatus?.closeStatus} bg="white" />
            </Box>
          )}
          {type === 'workflow' && contentAsAudit.fromStatus?.id === contentAsAudit.toStatus?.id && (
            <Box mb={1}>
              {(contentAsAudit.assignedToGroup || contentAsAudit.assignedToUser || contentAsAudit.unassigned) && (
                <>
                  {contentAsAudit.unassigned ? (
                    <Typography component="span" mr={1} sx={{ fontSize: 'inherit', fontWeight: 500, color: 'inherit', verticalAlign: 'middle' }}>
                      <FormattedMessage id="removeOwner" defaultMessage={'Unassigned owner'} />
                    </Typography>
                  ) : (
                    <Typography component="span" mr={1} sx={{ fontSize: 'inherit', fontWeight: 500, color: 'inherit', verticalAlign: 'middle' }}>
                      <FormattedMessage id="changedAlertOwnerTo" defaultMessage={'Changed owner for'} />
                    </Typography>
                  )}
                  {contentAsAudit.assignedToGroup && (
                    <Box display="inline-block" sx={{ verticalAlign: 'middle', fontWeight: 500, color: 'var(--color-darkgray)' }}>
                      {GetTeam((content as WorkflowAudit)?.assignedToGroup ?? '', teams)}
                    </Box>
                  )}
                  {contentAsAudit.assignedToUser && (
                    <Box display="inline-block" sx={{ verticalAlign: 'middle', fontWeight: 500, color: 'var(--color-darkgray)' }}>
                      {GetUser((content as WorkflowAudit)?.assignedToUser ?? '', allUsers)}
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}

          <Box pr={2}>{comment}</Box>

          {linkedFile && (
            <>
              <Tooltip placement="bottom-start" title={<DocumentElement document={linkedFile} />} slotProps={{ tooltip: { sx: { p: 0, backgroundColor: 'white' } } }}>
                <Box
                  ref={fileRef}
                  mt={2}
                  // className="button-box-shadow"
                  display="inline-flex"
                  alignItems={'center'}
                  px={2}
                  sx={{
                    minWidth: 0,
                    maxWidth: '100%',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    py: 0.5,
                    background: 'white',
                    borderRadius: '5px',
                    border: '1px solid var(--color-grayHeaderBorder)',
                    color: 'var(--color-darkgray)',
                    fontWeight: 500,
                    '&:hover': { color: 'var(--color-azure)', svg: { color: 'var(--color-azure)' } },
                  }}
                >
                  <PaperClipSideIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
                  <span style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{linkedFile.fileName}</span>
                </Box>
              </Tooltip>
            </>
          )}

          {linkedUrl && (
            <Box>
              <Link
                className="no-color"
                href={linkedUrl}
                target="_blank"
                rel="noreferrer"
                sx={{ color: 'var(--color-darkgray)', fontWeight: 500, '&:hover': { color: 'var(--color-azure)', svg: { color: 'var(--color-azure)' } } }}
              >
                <Box
                  mt={2}
                  // className="button-box-shadow"
                  display="inline-flex"
                  alignItems={'center'}
                  px={2}
                  sx={{
                    minWidth: 0,
                    maxWidth: '100%',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    py: 0.5,
                    background: 'white',
                    borderRadius: '5px',
                    border: '1px solid var(--color-grayHeaderBorder)',
                  }}
                >
                  <GlobeIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
                  {/* <FormattedMessage id="openLinkUrl" defaultMessage={'Open link'} /> */}
                  <span style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{linkedUrl}</span>
                </Box>
              </Link>
            </Box>
          )}
        </Box>

        <Box sx={{ width: '100%' }} mt={0.5} px={0} display="flex" alignItems={'center'}>
          {type === 'comment' && replyId && (
            <Button
              onClick={() => {
                // defineReply?.(replyId)
                setShowReplyBox(true);
              }}
              color="gray2"
              sx={{ px: 2, py: 0, minWidth: 0, fontWeight: '500', fontSize: 'var(--fs-12)' }}
            >
              <FormattedMessage id="reply" defaultMessage={'Reply'} />
            </Button>
          )}
          {type === 'comment' && nbReplys > 0 && (
            <Typography sx={{ ml: 'auto', fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }}>
              {nbReplys} {nbReplys > 1 ? <FormattedMessage id="replys" defaultMessage={'Replies'} /> : <FormattedMessage id="reply" defaultMessage={'Reply'} />}
            </Typography>
          )}
        </Box>
        {showReplyBox && <NewComment alertId={alertId} {...{ replyingId: replyId, commentId: `${content.id}_${replyId}`, onSendAction: closeReplyBox, closeAction: closeReplyBox }} />}
      </Box>
    </Box>
  );
});
