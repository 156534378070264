/**
 * App.tsx
 */

/* packages */
import { useContext } from 'react';
import { useIntl } from 'react-intl';

/* contexts */
import { ConfigContext } from 'contextProviders/ConfigProvider';

/* components */
import Loader from 'components/Loader/Loader';
import GenericError from 'components/GenericError/GenericError';
import FullPageBox from 'components/FullPageBox/FullPageBox';

/* utilities */
import AppRouter from 'AppRouter';

/* elements */

const App = () => {
  // Ensure config is loaded before showing the routes
  const config = useContext(ConfigContext);
  const intl = useIntl();

  if (config.readingConfig)
    return (
      <FullPageBox>
        <Loader />
      </FullPageBox>
    );

  if (!config.config)
    return (
      <FullPageBox>
        <GenericError message={intl.formatMessage({ id: 'configReadError', defaultMessage: 'Configuration file could not be read. Contact your network administrator.' })} />
      </FullPageBox>
    );

  return <AppRouter />;
};

export default App;
