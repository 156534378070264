/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useMemo, useCallback, useContext, useRef, useState } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { ApiResponse } from 'models/axiosRequest';

/* types */
import { OrganizationUnit } from 'models/user';

interface OrganizationsContextType {
  loadingOrganizations: boolean;
  organizations?: OrganizationUnit[] | undefined;
  listOrganizations?(abortController?: AbortController): Promise<OrganizationUnit[] | undefined>;
  addOrUpdateOrganization?(data: Partial<OrganizationUnit>, create: boolean, abortController?: AbortController): Promise<OrganizationUnit | undefined>;
  deleteOrganization?(orgId: number, abortController?: AbortController): Promise<DeleteOrganizationResponseType | undefined>;
}

interface ListOrganizationResponseType extends ApiResponse {
  organizationUnits: OrganizationUnit[];
}

interface UpdateOrganizationResponseType extends ApiResponse {
  organizationUnit: OrganizationUnit;
}

interface DeleteOrganizationResponseType extends ApiResponse {
  operationResult: boolean;
}

/* elements */
const OrganizationsContext = createContext<OrganizationsContextType>({
  loadingOrganizations: false,
});

const OrganizationsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  // const { getAuthenticatedRequest } = useAuthenticatedRequest();
  const { permissions } = useContext(UserContext);

  const listOrganizationsOngoing = useRef(false);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingOrganizations, setLoadingOrganizations] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<OrganizationUnit[] | undefined>(undefined);

  const listOrganizations = useCallback(
    async (abortController?: AbortController): Promise<OrganizationUnit[] | undefined> => {
      if (listOrganizationsOngoing.current) return;

      // check permissions
      // const allowed = checkPermissions('manageSetupOrganizations', permissions);
      // if (!allowed) return;

      listOrganizationsOngoing.current = true;
      setLoadingOrganizations(true);
      setOrganizations(undefined);

      try {
        const listOrganizationsUrl = URLConstants.fullOrganizationUnitList;
        const results = (await getAuthenticatedRequest(listOrganizationsUrl, abortController ?? undefined)) as ListOrganizationResponseType;

        const organizationsResults = results.organizationUnits;
        setLoadingOrganizations(false);
        setOrganizations(organizationsResults);
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        setLoadingOrganizations(false);
        setOrganizations(undefined);
      }
      listOrganizationsOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );

  const addOrUpdateOrganization = useCallback(
    async (data: Partial<OrganizationUnit>, create = true, abortController?: AbortController): Promise<OrganizationUnit | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupOrganizations', permissions);
      if (!allowed) return undefined;

      try {
        const editOrganizationUrl = create ? URLConstants.organizationUnitCreate : URLConstants.organizationUnitUpdate;
        const results = (await postAuthenticatedRequest(editOrganizationUrl, { organizationUnit: data }, abortController ?? undefined)) as UpdateOrganizationResponseType;

        const updatedOrganization = results.organizationUnit;

        setOrganizations((currentOrganizations) => {
          if (!updatedOrganization) return currentOrganizations;
          if (!currentOrganizations) return [updatedOrganization];

          const newOrganizations = [...currentOrganizations];
          const orgIndex = currentOrganizations?.findIndex((r) => r.id === updatedOrganization.id);
          if (orgIndex >= 0) {
            newOrganizations[orgIndex] = updatedOrganization;
          } else {
            newOrganizations.push(updatedOrganization);
          }
          return newOrganizations;
        });

        return updatedOrganization;
      } catch (updateError: any) {
        if (updateError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(updateError);
      }
    },
    [permissions, postAuthenticatedRequest]
  );

  const deleteOrganization = useCallback(
    async (orgId: number, abortController?: AbortController): Promise<DeleteOrganizationResponseType | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageSetupOrganizations', permissions);
      if (!allowed) return undefined;

      try {
        const deleteOrganizationUrl = URLConstants.organizationUnitDelete + `${orgId}`;
        const results = (await deleteAuthenticatedRequest(deleteOrganizationUrl, abortController ?? undefined)) as DeleteOrganizationResponseType;

        if (results.operationResult) {
          setOrganizations((currentOrganizations) => {
            return currentOrganizations?.filter((org) => org.id !== orgId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (deleteError: any) {
        if (deleteError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(deleteError);
      }
    },
    [permissions, deleteAuthenticatedRequest]
  );

  const outputValue = useMemo(
    (): OrganizationsContextType => ({
      loadingOrganizations,
      organizations,
      listOrganizations,
      addOrUpdateOrganization,
      deleteOrganization,
    }),
    [organizations, loadingOrganizations, listOrganizations, addOrUpdateOrganization, deleteOrganization]
  );
  return <OrganizationsContext.Provider value={outputValue}>{children}</OrganizationsContext.Provider>;
};

/* exports */
export { OrganizationsContext, OrganizationsProvider };
