/**
 * DeleteFolder.tsx
 * delete folder moodal
 */
/* packages */
import { useState, useCallback, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */
/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */
import { DatasetFolder } from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';

/* types */
interface DeleteFolderProps {
  folder: DatasetFolder;
  removeFolder(removedId: number): Promise<StatusPromiseResponse>;
}

const DeleteFolder = memo(({ folder, removeFolder }: DeleteFolderProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingFolder, setDeletingFolder] = useState(false);

  const deleteFolder = useCallback(async () => {
    blockModal?.(true);
    setDeletingFolder(true);

    const result = await removeFolder(folder.id);
    setDeletingFolder(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteDatasetFolderSuccess',
        defaultMessage: 'Folder deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteDatasetFolderError',
        defaultMessage: 'An error occured while deleting the folder',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, folder, removeFolder, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Folder: {folder.label}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteDatasetFolderMessage" defaultMessage={'Are you sure that you want to delete this folder?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingFolder ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingFolder} onClick={deleteFolder} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default DeleteFolder;
